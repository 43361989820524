import { faAlignJustify } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Table, TableProps } from 'antd'
import { styled } from 'goober'
import update from 'immutability-helper'
import React, { useCallback, useRef } from 'react'
import { DndProvider, useDrag, useDrop } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

import theme from 'theme/goober'

const DragType = 'DraggableBodyRow'
interface IDraggableBodyRowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  index: number
  moveRow: (dragIndex: number, hoverIndex: number) => void
  isSortable?: boolean
}
const DraggableBodyRow = ({
  index,
  moveRow,
  className,
  style,
  isSortable = true,
  ...restProps
}: IDraggableBodyRowProps) => {
  const ref = useRef<HTMLTableRowElement>(null)
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: DragType,
    collect: (monitor: any) => {
      const { index: dragIndex } = monitor.getItem() || {}
      if (dragIndex === index) {
        return {}
      }
      return {
        isOver: monitor.isOver(),
        dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
      }
    },
    drop: (item: { index: number }) => {
      moveRow(item.index, index)
    },
  })
  const [collected, drag] = useDrag({
    type: DragType,
    item: { index },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  })
  if (isSortable) drop(drag(ref))
  return (
    <tr
      ref={ref}
      className={`${className}${isOver ? dropClassName : ''} ${collected.isDragging ? 'dragging' : ''}`}
      style={{ ...style }}
      {...restProps}
    />
  )
}

const DragIcon = styled(FontAwesomeIcon)`
  color: ${theme.color.grayFlatButton};
  cursor: grab;
  &:hover {
    color: ${theme.color.lightBluePrimary};
  }
`
const dragColumn = {
  title: 'เรียง',
  width: '16px',
  render: () => <DragIcon icon={faAlignJustify} />,
}

const DragIconDisabled = styled(FontAwesomeIcon)`
  color: ${theme.color.grayFlatButton};
  cursor: no-drop;
`
const dragColumnDisabled = {
  ...dragColumn,
  render: () => <DragIconDisabled icon={faAlignJustify} />,
}

type PickType = 'columns' | 'dataSource' | 'pagination' | 'loading' | 'rowKey'
interface ISortableTableProps<RecordType> extends Pick<TableProps<RecordType>, PickType> {
  isSortable?: boolean
  onSortEnd: (sortedSeq: number, dragRecord: RecordType, newDataSource: readonly RecordType[]) => void
}
export const SortableTable = <RecordType extends object>(props: ISortableTableProps<RecordType>) => {
  const { columns, dataSource = [], onSortEnd, isSortable = true, pagination, ...restProps } = props
  const sortableColumns = [isSortable ? dragColumn : dragColumnDisabled, ...(columns || [])]

  const moveRow = useCallback(
    (_dragIndex: number, _hoverIndex: number) => {
      let indexOffset = 0
      const dragIndex = _dragIndex + indexOffset
      const hoverIndex = _hoverIndex + indexOffset
      const dragRecord = dataSource[dragIndex]
      const newDataSource = update(dataSource, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragRecord],
        ],
      })
      onSortEnd?.(hoverIndex + 1, dragRecord, newDataSource)
    },
    [dataSource, onSortEnd],
  )

  return (
    <DndProvider backend={HTML5Backend}>
      <Table
        {...restProps}
        scroll={{ x: '100%' }}
        pagination={false}
        columns={sortableColumns}
        dataSource={dataSource}
        components={{
          body: {
            row: DraggableBodyRow,
          },
        }}
        onRow={(_, index) => {
          const attr = {
            index,
            moveRow,
            isSortable,
          }
          return attr as React.HTMLAttributes<any>
        }}
      />
    </DndProvider>
  )
}
