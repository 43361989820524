import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Form } from 'antd'
import { useCallback } from 'react'

import { Input } from 'components/Input'
import { TextArea } from 'components/Input'
import { Txt } from 'components/Txt'

export const WikiTextField = () => {
  const [form] = Form.useForm()

  const handleFieldsChange = useCallback((values: any) => {
    console.log('== values', values)
  }, [])

  return (
    <>
      <Form
        layout="vertical"
        form={form}
        onFinish={handleFieldsChange}
        initialValues={{ content: '<h1>This is Header 1</h1>' }}
      >
        <Form.Item label="Default">
          <Input placeholder="placeholder" />
        </Form.Item>
        <Form.Item label="Default with Icon">
          <Input
            placeholder="placeholder"
            prefix={<FontAwesomeIcon icon={faSearch} />}
            suffix={<FontAwesomeIcon icon={faSearch} />}
          />
        </Form.Item>
        <Form.Item label="Size: small">
          <Input placeholder="placeholder" size="small" />
        </Form.Item>
        <Form.Item label="Size: small with Icon">
          <Input
            placeholder="placeholder"
            size="small"
            prefix={<FontAwesomeIcon icon={faSearch} />}
            suffix={<FontAwesomeIcon icon={faSearch} />}
          />
        </Form.Item>
        <Form.Item label="TextArea">
          <TextArea placeholder="placeholder" autoSize={{ minRows: 4, maxRows: 4 }} />
        </Form.Item>
      </Form>
      <Txt ag="header30">Disable</Txt>
      <Form layout="vertical" disabled>
        <Form.Item label="Default">
          <Input placeholder="placeholder" />
        </Form.Item>
        <Form.Item label="Default with Icon">
          <Input
            placeholder="placeholder"
            prefix={<FontAwesomeIcon icon={faSearch} />}
            suffix={<FontAwesomeIcon icon={faSearch} />}
          />
        </Form.Item>
        <Form.Item label="Size: small">
          <Input placeholder="placeholder" size="small" />
        </Form.Item>
        <Form.Item label="Size: small with Icon">
          <Input
            placeholder="placeholder"
            size="small"
            prefix={<FontAwesomeIcon icon={faSearch} />}
            suffix={<FontAwesomeIcon icon={faSearch} />}
          />
        </Form.Item>
        <Form.Item label="TextArea">
          <TextArea placeholder="placeholder" autoSize={{ minRows: 4, maxRows: 4 }} />
        </Form.Item>
      </Form>
      <div style={{ textAlign: 'start', marginTop: 16, textDecoration: 'underline' }}>
        <a href="https://ant.design/components/form/" target="_blank" rel="noreferrer">
          Antd Form Link
        </a>
      </div>
      {/* <div style={{ textAlign: 'start', marginTop: 16, whiteSpace: 'pre' }}>{codeText}</div> */}
    </>
  )
}
