import { Col, Row } from 'antd'
import { useCallback, useState } from 'react'

import { SearchInput } from '_backend/components/SearchInput'
import { Txt } from 'components/Txt'

const dummyUrl = 'https://dummyjson.com/products'

export const DemoSearchInput = () => {
  const [searchResults, setSearchResults] = useState<any[]>([])
  const [loading, setLoading] = useState(false)

  const handleSearch = useCallback((value: string) => {
    const searchParams = new URLSearchParams({ q: value })
    const searchUrl = `${dummyUrl}/search?${searchParams}`
    setLoading(true)
    fetch(searchUrl)
      .then((res) => {
        if (!res.ok) {
          return Error('Oh no')
        }
        return res.json()
      })
      .then((data) => {
        setLoading(false)
        setSearchResults(data.products)
      })
  }, [])

  return (
    <>
      <Row>
        <Txt ag="header24">Search Input</Txt>
      </Row>
      <Row gutter={16}>
        <Col>
          <SearchInput placeholder="ค้นหา product (e.g. iPhone)" onSearch={handleSearch} width="500px" />
        </Col>
      </Row>
      <Row>
        <Col>
          {loading ? (
            <div>Loading...</div>
          ) : (
            <ul>
              {searchResults.map((r: any) => (
                <li style={{ textAlign: 'left' }} key={r.id}>
                  {r.title}
                </li>
              ))}
            </ul>
          )}
        </Col>
      </Row>
    </>
  )
}
