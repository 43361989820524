import { styled } from 'goober'

import { Txt } from 'components/Txt'
import { mediaQuery } from 'helpers/responsive-helper'

interface ICoverPageDetailProps {
  title?: string
  url?: string
  insideUrl?: string
}

const CoverContainer = styled('div')`
  position: relative;
`
const CoverBackground = styled('div')`
  margin: 0 auto;
  padding-top: 140px;
  background-color: #4dace9;
  width: 100%;
  max-width: 1600px;
  @media ${mediaQuery.xs} {
    padding-top: 24px;
  }
`
const Img = styled('img')`
  display: block;
  object-fit: contain;
  margin: 0 auto;
  width: 100%;
  max-width: 1600px;
  min-height: 200px;
  max-height: 768px;
`

const Title = styled('div')`
  display: flex;
  justify-content: center;
  word-wrap: break-word;
  word-break: break-word;
  padding: 0 24px 24px 24px;
  max-width: 1160px;
  margin: 0 auto;
  .ant-typography {
    @media ${mediaQuery.xs} {
      font-size: calc(24px + 2vw);
    }
  }
`

export const CoverPageDetail = (props: ICoverPageDetailProps) => {
  const { title, url } = props
  return (
    <CoverContainer>
      <CoverBackground>
        <Title>
          <Txt ag="header40" color="white">
            {title}
          </Txt>
        </Title>
        {url && <Img src={url} />}
      </CoverBackground>
    </CoverContainer>
  )
}
