import { Button, Col, notification, Row } from 'antd'
import { useCallback, useState } from 'react'

import { AppModal } from 'components/AppModal'
import { Txt } from 'components/Txt'
import { useBackendGetAddressQry } from 'services/address/address-service'

import { AddressSelectItem } from './AddressSelectOption'

interface IModalFullReceiptProps {
  orderId: number
  userId: number
  visible: boolean
  onDismiss: () => void
  onSubmit: (addressId: number) => void
}

export const ModalFullReceipt = (props: IModalFullReceiptProps) => {
  const { visible, userId, onDismiss, onSubmit } = props
  const { data: addressList } = useBackendGetAddressQry(userId)
  const [selectedAddressId, setSelectedAddressId] = useState<number>()

  const handleSubmit = useCallback(() => {
    if (selectedAddressId) {
      onSubmit(selectedAddressId)
    } else {
      notification.error({ message: 'ผิดพลาด', description: 'กรุณาเลือกที่อยู่' })
    }
  }, [onSubmit, selectedAddressId])

  const handleSelectAddress = useCallback((addressId: number) => {
    setSelectedAddressId(addressId)
  }, [])

  const handleCancel = useCallback(() => {
    onDismiss()
  }, [onDismiss])

  return (
    <AppModal visible={visible} width={700}>
      <AppModal.Header onCloseClick={onDismiss}>ออกใบกำกับภาษี</AppModal.Header>
      <AppModal.Body>
        <Row>
          <Col>
            <Txt ag="body20">เคยมีการออกใบเสร็จแบบย่อไปแล้ว ท่านต้องการยกเลิกและออกใบกำกับภาษีใช่หรือไม่</Txt>
          </Col>
          <Col>
            <Txt ag="body20">หากท่านยืนยันออกใบกำกับภาษีกรุณากรอกข้อมูล</Txt>
          </Col>
        </Row>
        <Row style={{ marginTop: 36, marginBottom: 8 }}>
          <Col span={24} className="text-center">
            <Txt ag="subtitle20">ข้อมูลที่อยู่เพื่อออกใบกำกับภาษี </Txt>
            <Txt ag="subtitle20" color="redPrimary">
              *
            </Txt>
          </Col>
        </Row>
        {addressList?.map((address) => (
          <AddressSelectItem
            key={address.id}
            {...address}
            onSelect={handleSelectAddress}
            selectedId={selectedAddressId}
          />
        ))}
      </AppModal.Body>
      <AppModal.Footer>
        <Button onClick={handleCancel}>ยกเลิก</Button>
        <Button type="primary" onClick={handleSubmit}>
          ยืนยัน
        </Button>
      </AppModal.Footer>
    </AppModal>
  )
}
