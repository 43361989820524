import { Button, Form, Space } from 'antd'
import { Rule, RuleObject } from 'antd/lib/form'
import { useCallback, useMemo } from 'react'

import { AppModal } from 'components/AppModal'
import { Input } from 'components/Input'
import { ISubmitServiceLink } from 'services/service-link/service-link-service-param'

export interface IModalServiceLinkFormValues extends ISubmitServiceLink {
  recordIndex: number
  isEditMode?: boolean
}

interface IModalServiceLinkProps {
  visible: boolean
  initialValues?: Partial<IModalServiceLinkFormValues>
  serviceLinksSelected?: string[]
  onDismiss: () => void
  onConfirm: (values: IModalServiceLinkFormValues) => void
}

export const ModalServiceLink = (props: IModalServiceLinkProps) => {
  const { initialValues: preInitialValues = {}, serviceLinksSelected = [], visible, onDismiss, onConfirm } = props

  const [form] = Form.useForm<IModalServiceLinkFormValues>()

  const isEditMode = useMemo(() => {
    if (!!preInitialValues?.id) return true
    if (preInitialValues?.id === 0) return true
    return false
  }, [preInitialValues?.id])

  const initialValues = useMemo((): Partial<IModalServiceLinkFormValues> => {
    const { id, description = '', link = '' } = preInitialValues
    return {
      id,
      description,
      link,
    }
  }, [preInitialValues])

  const handleDismiss = useCallback(() => {
    form.resetFields()
    onDismiss()
  }, [form, onDismiss])

  const onSubmit = useCallback(
    (values: IModalServiceLinkFormValues) => {
      const { id = 0, recordIndex = 0 } = preInitialValues
      onConfirm({ ...values, id, recordIndex, isEditMode })
      form.resetFields()
    },
    [form, isEditMode, onConfirm, preInitialValues],
  )

  const validateSelectDuplicateLink = useMemo((): Rule => {
    const validator = (rule: RuleObject, newLink: string) => {
      const { link } = preInitialValues
      let isValid: boolean = true

      const linkChange = newLink && link && link !== newLink

      const isDuplicate = serviceLinksSelected?.includes(newLink)

      if ((linkChange || !link) && isDuplicate) {
        isValid = false
      }

      const errorMessage = 'ไม่สามารถกำหนดลิงค์ซ้ำได้'
      if (isValid) {
        return Promise.resolve()
      }
      return Promise.reject(new Error(errorMessage))
    }
    return {
      validator,
    }
  }, [preInitialValues, serviceLinksSelected])

  const modalHeaderLabel = useMemo(() => {
    if (isEditMode) return 'แก้ไขลิงก์ (Link)'
    return 'เพิ่มลิงก์ (Link)'
  }, [isEditMode])

  const submitButtonLabel = useMemo(() => {
    if (isEditMode) return 'บันทึก'
    return 'เพิ่มลิงก์'
  }, [isEditMode])

  return (
    <AppModal visible={visible} destroyOnClose forceRender>
      <Form form={form} initialValues={initialValues} onFinish={onSubmit} layout="vertical" autoComplete="off">
        <AppModal.Header onCloseClick={handleDismiss}>{modalHeaderLabel}</AppModal.Header>
        <AppModal.Body>
          <Form.Item
            label="คำอธิบาย"
            name="description"
          >
            <Input placeholder="กรอกคำอธิบาย" />
          </Form.Item>
          <Form.Item
            label="ลิงก์"
            name="link"
            required
            rules={[
              {
                type: 'string',
                required: true,
                message: 'โปรดกรอกลิงก์',
              },
              {
                type: 'url',
                message: 'รูปแบบลิงก์ไม่ถูกต้อง',
              },
              {
                pattern: /https?:\/\/.*/,
                message: 'URL ต้องขึ้นต้นด้วย https:// หรือ http://',
              },
              validateSelectDuplicateLink,
            ]}
          >
            <Input placeholder="กรอกลิงก์" />
          </Form.Item>
        </AppModal.Body>
        <AppModal.Footer>
          <Space size={24} direction="horizontal">
            <Button onClick={handleDismiss}>ยกเลิก</Button>
            <Button type="primary" htmlType="submit">
              {submitButtonLabel}
            </Button>
          </Space>
        </AppModal.Footer>
      </Form>
    </AppModal>
  )
}
