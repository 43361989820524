import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { setup } from 'goober'
import { prefix } from 'goober/prefixer'
import React, { createContext, useContext } from 'react'
import ReactDOM from 'react-dom/client'

import { getEnv } from 'helpers/env-config-helper'

import App from './App'
import reportWebVitals from './reportWebVitals'
import theme from './theme/goober'
const ThemeContext = createContext(theme)
const useTheme = () => useContext(ThemeContext)
setup(React.createElement, prefix, useTheme)

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

if (getEnv('sentryDsn')) {
  Sentry.init({
    dsn: getEnv('sentryDsn'),
    integrations: [new BrowserTracing()],
    environment: getEnv('sentryEnvironment'),

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  })
}

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
