import { Navigate, useLocation } from 'react-router-dom'

import { getToken } from 'services/auth/auth-service'

interface NotAuthProps {
  children: JSX.Element
}

export const NoAuth = (props: NotAuthProps) => {
  const { children } = props
  const isLogin = getToken()
  const location = useLocation()
  if (isLogin) {
    return <Navigate replace to="/" state={{ from: location }} />
  }
  return children
}
