import { Button, Card, Col, Form, Row, Select } from 'antd'
import Table, { ColumnsType } from 'antd/lib/table'
import dayjs from 'dayjs'
import { styled } from 'goober'
import { useCallback, useMemo, useState } from 'react'
import { useLocation, useParams, useSearchParams } from 'react-router-dom'

import { ContentBody } from '_backend/components/layout/ContentBody'
import { ContentHeader } from '_backend/components/layout/ContentHeader'
import { apiDateFormat } from '_backend/constants/date-format'
import RangePicker from 'components/RangePicker'
import { Txt } from 'components/Txt'
import { getColumnNumberByPage } from 'helpers/column-helper'
import { mediaQuery } from 'helpers/responsive-helper'
import { truncateString } from 'helpers/string-helper'
import { GetServiceTypeEnum } from 'services/enco-service/enco-service-param'
import { IServiceData } from 'services/enco-service/enco-service-response'
import { useGetServicesQRY } from 'services/enco-service/enco-service-service'
import { IGetReportParams } from 'services/report/report-params'
import { useExportReportMTT, useGetReportQRY } from 'services/report/report-service'

import { ReportFilterFormValue } from './ReportFilterModal'

const isNumberBlank = (value?: string | null) => {
  if (!value) return undefined
  return Number(value)
}
const isDateBlank = (value?: string | null) => {
  if (!value) return undefined
  return dayjs(value)
}

const convertToParams = (serviceId?: number, start?: dayjs.Dayjs, end?: dayjs.Dayjs) => {
  let returnValues: any = {}
  if (serviceId) returnValues['serviceId'] = serviceId
  if (start) returnValues['start'] = start
  if (end) returnValues['end'] = end
  return returnValues
}

export const PageReport = () => {
  const { search } = useLocation()
  const { reportId: reportIdParam } = useParams()
  const query = useMemo(() => new URLSearchParams(search), [search])
  const reportId = Number(reportIdParam)
  const serviceId = useMemo(() => isNumberBlank(query.get('serviceId')), [query])
  const start = useMemo(() => isDateBlank(query.get('start')), [query])
  const end = useMemo(() => isDateBlank(query.get('end')), [query])

  const [currentPage, setCurrentPage] = useState(1)
  const [currentPageSize, setCurrentPageSize] = useState(10)

  const initialSearchParams = useMemo(() => convertToParams(serviceId, start, end), [serviceId, start, end])
  const [searchParams, setSearchParams] = useSearchParams(initialSearchParams)

  const filter = useMemo((): IGetReportParams => {
    let newFilter: IGetReportParams = { reportId }
    if (serviceId) newFilter.serviceId = serviceId
    if (searchParams.get('start')) newFilter.startDate = dayjs(searchParams.get('start')).format(apiDateFormat)
    if (searchParams.get('end')) newFilter.endDate = dayjs(searchParams.get('end')).format(apiDateFormat)
    return newFilter
  }, [serviceId, reportId, searchParams])

  const { data: report } = useGetReportQRY(filter)
  const { data: service } = useGetServicesQRY({ serviceType: GetServiceTypeEnum.ALL })
  const { mutateAsync: downloadExcel } = useExportReportMTT()

  const handleClickDownload = useCallback(() => {
    downloadExcel(filter)
  }, [downloadExcel, filter])

  const handleFilterChange = (values: ReportFilterFormValue) => {
    let params: any = {}
    if (values.serviceId) params['serviceId'] = values.serviceId
    if (values.rangeDate) {
      if (values.rangeDate[0]) params['start'] = values.rangeDate[0]
      if (values.rangeDate[1]) params['end'] = values.rangeDate[1]
    }
    setSearchParams(params)
  }

  const reportObject = useMemo(() => {
    if (report) return JSON.parse(report.reportData)
    return []
  }, [report])

  const columns = useMemo((): ColumnsType<any> => {
    const columnKeys: ColumnsType<any> = []
    if (reportObject[0]) {
      Object.keys(reportObject[0]).forEach((key) => {
        columnKeys.push({
          title: key,
          dataIndex: key,
          align: 'center',
          render: (value: any) => (typeof value === 'object' ? '' : <span>{truncateString(value, 20)}</span>),
        })
      })
    }
    return [
      {
        title: 'ลำดับ',
        align: 'center',
        render: (_: any, record: any, index: number) => (
          <Txt ag="body20">{getColumnNumberByPage(currentPage, index, currentPageSize)}</Txt>
        ),
      },
      ...columnKeys,
    ]
  }, [reportObject, currentPage, currentPageSize])

  const handlePageChange = useCallback((page: number, pageSize: number) => {
    setCurrentPage(page)
    setCurrentPageSize(pageSize)
  }, [])

  if (!service) return null
  return (
    <>
      <ContentHeader title="Report" subTitle={report?.reportName} />
      <ContentBody>
        <FilterExportSection
          onDownload={handleClickDownload}
          services={service}
          initialValues={{ serviceId, start, end }}
          onFilterChange={handleFilterChange}
        />

        <Table
          scroll={{ x: '100%' }}
          rowKey="id"
          dataSource={reportObject}
          columns={columns}
          pagination={{
            showSizeChanger: true,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
            onChange: handlePageChange,
            current: currentPage,
          }}
        />
      </ContentBody>
    </>
  )
}

const FilterServiceCol = styled(Col)`
  padding-right: 32px;
  margin-bottom: 12px;
  @media ${mediaQuery.mdLte} {
    padding-right: 0px;
  }
`

interface IInitialValue {
  serviceId?: number
  start?: dayjs.Dayjs
  end?: dayjs.Dayjs
}

interface FilterExportSectionProps {
  services: IServiceData[]
  initialValues?: IInitialValue
  onFilterChange: (values: ReportFilterFormValue) => void
  onDownload: () => void
}

const FilterExportSection = (props: FilterExportSectionProps) => {
  const { initialValues, onFilterChange, services, onDownload } = props
  const [form] = Form.useForm()

  const filterInitialValues = useMemo(() => {
    let filter: any = {}
    if (initialValues?.serviceId) filter['serviceId'] = initialValues?.serviceId
    if (initialValues?.start && initialValues?.end) filter['rangeDate'] = [initialValues?.start, initialValues?.end]

    return filter
  }, [initialValues])

  const handleFinishForm = useCallback(() => {
    onDownload()
  }, [onDownload])

  const handleFormChange = () => {
    onFilterChange(form.getFieldsValue())
  }

  return (
    <Card style={{ marginBottom: 16 }}>
      <Form layout="vertical" form={form} initialValues={filterInitialValues} onFinish={handleFinishForm}>
        <Row style={{ width: '100%' }}>
          <FilterServiceCol xs={24} lg={4}>
            <Form.Item style={{ margin: 0 }} name="serviceId" label="ชื่อบริการ">
              <Select placeholder="เลือกบริการ" onChange={handleFormChange}>
                {services
                  .filter((service) => Boolean(service.active))
                  .map((service) => (
                    <Select.Option key={service.id} value={service.id}>
                      {service.name}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </FilterServiceCol>
          <Col xs={24} lg={8}>
            <Form.Item name="rangeDate" style={{ margin: 0 }} label="ช่วงเดือนที่ต้องการเรียกดูข้อมูล">
              <RangePicker allowClear={false} onChange={handleFormChange} />
            </Form.Item>
          </Col>
          <Col xs={24} lg={{ span: 3, offset: 9 }}>
            <Form.Item label=" " style={{ margin: 0, width: '100%' }}>
              <Button htmlType="submit" style={{ width: '100%' }}>
                ดาวน์โหลดรายงาน
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  )
}
