import { Avatar, Dropdown, Menu } from 'antd'
import { styled } from 'goober'
import { PropsWithChildren, useCallback, useMemo } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'

import iconUser from 'assets/images/icon-user.svg'
import logoEnCo from 'assets/images/logo-enco.png'
import { menuItems, MENU_CONFIGS, MENU_LIST } from 'constants/menu-config'
import { profileMenuItems, PROFILE_MENU_LIST } from 'constants/profile-menu-config'
import { mediaQuery } from 'helpers/responsive-helper'
import { useGetMenuWeb, useGetUserInfoQRY } from 'services/auth/auth-service'

import { NotificationMenu } from './NotificationMenu'

import type { MenuProps } from 'antd'

const HeaderWrapper = styled('div')`
  display: flex;
  height: 80px;
  max-width: 1160px;
  margin-left: auto;
  margin-right: auto;
`
const Banner = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-width: 225px;
  background-color: white;
  a,
  img {
    width: 98px;
    @media ${mediaQuery.xsOnly} {
      width: 75px;
    }
  }
  @media ${mediaQuery.xsOnly} {
    min-width: 150px;
  }
`
const MenuWrapper = styled('div')`
  display: flex;
  justify-content: flex-end;
  height: 100%;
  width: 100%;
  background-color: #d1dde8;
  opacity: 0.9;
`
const ProfileMenu = styled('div')`
  display: flex;
  align-items: center;
  padding: 0 42px;
  width: 100%;
  max-width: 150px;
  background-color: #d1dde8;
  opacity: 0.9;

  justify-content: space-between;

  .profile-menu-dropdown ul {
    min-width: 160px;
  }
`

export const useSelectMenu = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const selectedMenuKeys = useMemo(() => {
    const menu = MENU_LIST.find((m) => m.path && pathname.includes(m.path))
    const menuKey = menu?.code?.toString()
    const menuKeys = menuKey ? [menuKey] : []
    const subMenu = MENU_CONFIGS.find((config) => {
      if (config.children) {
        return config.children.some((c) => c.code.toString() === menuKey)
      } else {
        return false
      }
    })
    if (subMenu) menuKeys.push(subMenu.code.toString())
    return menuKeys
  }, [pathname])

  const handleClickMenu: MenuProps['onClick'] = useCallback(
    (e: { key: string }) => {
      const menu = MENU_LIST.find((m) => m.code.toString() === e.key)
      const path = menu?.path
      path && navigate(path)
    },
    [navigate],
  )

  return { selectedMenuKeys, handleClickMenu }
}

export const useSelectProfileMenu = () => {
  const { pathname } = useLocation()

  const selectedProfileMenuKeys = useMemo(() => {
    const menu = PROFILE_MENU_LIST.find((m) => m.path && pathname.includes(m.path))
    const menuKey = menu?.code?.toString()
    return menuKey ? [menuKey] : []
  }, [pathname])

  return { selectedProfileMenuKeys }
}

export const HeadMenu = (props: PropsWithChildren) => {
  const { data: userInfo } = useGetUserInfoQRY()
  const { selectedMenuKeys, handleClickMenu } = useSelectMenu()
  const { selectedProfileMenuKeys } = useSelectProfileMenu()
  const { data: authMenu } = useGetMenuWeb()

  const profileMenuFiltered = useMemo(() => {
    if (!authMenu || authMenu?.length <= 0) {
      return profileMenuItems?.filter((v) => v?.key !== '1')
    }
    return profileMenuItems
  }, [authMenu])

  return (
    <HeaderWrapper>
      <Banner>
        <Link to="/home">
          <img src={logoEnCo} alt="enco-logo" />
        </Link>
      </Banner>
      <MenuWrapper>
        <Menu
          theme="light"
          mode="horizontal"
          selectedKeys={selectedMenuKeys}
          items={menuItems}
          onClick={handleClickMenu}
        />
      </MenuWrapper>
      <ProfileMenu>
        <Dropdown
          overlayClassName="profile-menu-dropdown"
          overlay={<Menu mode="horizontal" selectedKeys={selectedProfileMenuKeys} items={profileMenuFiltered} />}
        >
          <Avatar src={userInfo?.profilePic ? userInfo?.profilePic : iconUser} style={{ cursor: 'pointer' }} />
        </Dropdown>
        <NotificationMenu />
      </ProfileMenu>
    </HeaderWrapper>
  )
}
