import { Form, Space } from 'antd'
import { styled } from 'goober'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { SlotType } from '_backend/constants/item-management-constants'
import { AppButton } from 'components/AppButton'
import { Txt } from 'components/Txt'
import { LayoutCardPage } from 'components/layout/LayoutCardPage'
import { getEnv } from 'helpers/env-config-helper'
import { numberFormat } from 'helpers/number-helper'
import { appPaths } from 'paths/path-config'
import { ENCO_TYPE_OZONE_ID } from 'services/enco-service/enco-service-service'
import { useGetServiceOrderQRY } from 'services/order-response/order-response-service-service'
import theme from 'theme/goober'

const Title = styled('div')`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 26px;
`
const Content = styled('div')`
  margin: 0 auto;
  width: 100%;
  max-width: 500px;
  margin-top: 40px;
  margin-bottom: 40px;
`
const RowContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0;
  width: 100%;
  max-width: 500px;
  word-wrap: break-word;
  border-bottom: 1px solid ${theme.color.grayBackground};
`
const TotalItemContainer = styled(RowContainer)`
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  justify-content: center;
  /* height: 50px; */
  width: 100%;
  border-bottom: 1px solid ${theme.color.grayBackground};
  .summary-title {
    text-align: left;
    min-width: 100px;
  }
  .summary-value {
    text-align: right;
  }
`

export const PageOrderCheckout = () => {
  const navigate = useNavigate()
  const query = useParams()
  const orderId = Number(query.orderId)
  const { data: order } = useGetServiceOrderQRY(orderId)
  const [scriptLoaded, setScriptLoaded] = useState(false)

  useEffect(() => {
    const existingScript = document.getElementById('k_payment_script')
    const form = document.getElementById('checkout_form')
    if (order?.receiptNo) {
      navigate(appPaths.orderPaymentCompleted({ routeParam: { orderId } }))
    } else if (order && form && !existingScript) {
      const script = document.createElement('script')
      script.id = 'k_payment_script'
      script.type = 'text/javascript'
      script.src = getEnv('kBankScriptSrc')
      script.setAttribute('data-apikey', getEnv('kBankApiKey'))
      script.setAttribute('data-amount', order.net.toFixed(2))
      script.setAttribute('data-payment-methods', 'qr')
      script.setAttribute('data-order-id', order.bankOrderId)
      script.setAttribute('data-show-button', 'false')
      script.onload = () => {
        // prettier-ignore
        (window as any).KPayment.create()
        setScriptLoaded(true)
      }
      form.appendChild(script)
      document.body.prepend(form)
    }
  }, [navigate, order, orderId])

  const mapBookingItem = useCallback(
    (slotType: SlotType) => {
      const bookingItems = order?.orderItem?.filter((item) => item.slotType === slotType)
      if (!bookingItems || bookingItems.length === 0) return null
      return bookingItems.map((item) => {
        const totalPrice = item.unitPrice * item.qty
        const itemDisplay = `${item.itemnm}`
        return (
          <RowContainer key={item.id}>
            <Txt ag="body24">{itemDisplay}</Txt>
            <Txt ag="body24">{totalPrice} บาท</Txt>
          </RowContainer>
        )
      })
    },
    [order],
  )
  const bookingItemPeak = useCallback(() => mapBookingItem(SlotType.PEAK), [mapBookingItem])
  const bookingItemNormal = useCallback(() => {
    if (order?.tbServiceId !== ENCO_TYPE_OZONE_ID) {
      return mapBookingItem(SlotType.NORMAL)
    }
  }, [mapBookingItem, order?.tbServiceId])

  const mapOtherItems = useCallback(() => {
    const otherItems =
      order?.orderItem?.filter(
        (item) => ![SlotType.NORMAL.toString(), SlotType.PEAK.toString()].includes(item.slotType),
      ) || []
    return (
      otherItems.map((item) => {
        const qty = item.qty
        const unitPrice = item.unitPrice
        const label = `${item.itemnm} (${numberFormat(unitPrice)} * ${qty})`
        const totalPrice = unitPrice * qty
        const totalPriceLabel = `${numberFormat(totalPrice)} บาท`
        return { label, totalPriceLabel }
      }) || []
    )
  }, [order?.orderItem])

  const renderOtherItems = useCallback(
    () =>
      mapOtherItems().map((item) => (
        <RowContainer key={item.label}>
          <Txt ag="body24">{item.label}</Txt>
          <Txt ag="body24">{item.totalPriceLabel}</Txt>
        </RowContainer>
      )),
    [mapOtherItems],
  )

  const renderOrderItems = useCallback(
    () =>
      order?.orderItem.map((item) => (
        <RowContainer key={item.id}>
          <Txt ag="body24">{item.itemnm}</Txt>
          <Txt ag="body24">{item.unitPrice} บาท</Txt>
        </RowContainer>
      )),
    [order?.orderItem],
  )

  return (
    <LayoutCardPage>
      <Title>
        <Txt ag="header40" color="lightBluePrimary">
          สรุปรายการสินค้าและบริการ
        </Txt>
      </Title>
      <Content>
        {bookingItemPeak()}
        {bookingItemNormal()}
        {renderOtherItems()}
        {order?.tbServiceId === ENCO_TYPE_OZONE_ID && renderOrderItems()}
        <TotalItemContainer>
          <Txt ag="header30" className="summary-title">
            Total
          </Txt>
          <Txt ag="header30" className="summary-value">
            {numberFormat(order?.total || 0)} บาท
          </Txt>
          <Txt ag="header30" color="grayPrimaryText" className="summary-title">
            Discount
          </Txt>
          <Txt ag="header30" color="grayPrimaryText" className="summary-value">
            {numberFormat(order?.discount || 0)} บาท
          </Txt>
          <Txt ag="header30" className="summary-title">
            Credit
          </Txt>
          <Txt ag="header30" className="summary-value">
            {numberFormat(order?.credit || 0)} บาท
          </Txt>
          <Txt ag="header30" className="summary-title">
            Net
          </Txt>
          <Txt ag="header30" className="summary-value">
            {numberFormat(order?.net || 0)} บาท
          </Txt>
        </TotalItemContainer>
      </Content>
      <Form id="checkout_form" method="GET" action={appPaths.orderPaymentCompleted({ routeParam: { orderId } })} />
      {scriptLoaded && (
        <Space direction="vertical" align="center" size="middle" className="w-100">
          <AppButton onClick={() => (window as any).KPayment.show()}>จ่ายเงิน</AppButton>
        </Space>
      )}
    </LayoutCardPage>
  )
}
