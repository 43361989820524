import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Card, Row } from 'antd'
import { styled } from 'goober'
import { isEmpty } from 'lodash'
import { useCallback } from 'react'

import { Txt } from 'components/Txt'
import { IGetAddrResponse } from 'services/address/address-response'
import theme from 'theme/goober'

interface AddressSelectItemProps extends IGetAddrResponse {
  selectedId?: number
  onSelect: (addressId: number) => void
}

const StyledCard = styled(Card)`
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  max-width: 500px;
  width: 100%;
  height: 150px;
  padding-right: 64px;
  margin-bottom: 16px;
  overflow: hidden;
  word-break: break-word;
  margin-left: auto;
  margin-right: auto;
`

const SelectIcon = styled(FontAwesomeIcon)<{ selected: boolean }>`
  color: ${(props) => (props.selected ? theme.color.lightBluePrimary : 'white')};
  border: 1px solid ${theme.color.lightBluePrimary};
  border-radius: 50px;
  cursor: ${(props) => (props.selected ? 'context' : 'pointer')};
  border-width: ${(props) => (props.selected ? 0 : 1)}px;
`
const IconContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  right: 16px;
  top: 16px;
`

export const AddressSelectItem = (props: AddressSelectItemProps) => {
  const { onSelect, fullname, address, tax, id, selectedId } = props

  const handleSelect = useCallback(() => {
    onSelect(id)
  }, [id, onSelect])

  return (
    <StyledCard>
      <IconContainer>
        <Txt ag="body18" color="lightBluePrimary">
          เลือก
        </Txt>
        <SelectIcon selected={selectedId === id} icon={faCheckCircle} onClick={handleSelect} />
      </IconContainer>
      <Row style={{ minHeight: 30 }}>
        <Txt ag="header28" color="lightBluePrimary">
          {isEmpty(fullname) ? '-' : fullname}
        </Txt>
      </Row>
      <Row style={{ minHeight: 30 }}>
        <Txt ag="body20">{isEmpty(address) ? '-' : address}</Txt>
      </Row>
      <Row>
        <Txt ag="body20">เลขประจำตัวผู้เสียภาษี : {tax || '-'}</Txt>
      </Row>
    </StyledCard>
  )
}
