import { faGear } from '@fortawesome/free-solid-svg-icons'
import { Dropdown, Menu, Tag } from 'antd'
import { ItemType } from 'antd/lib/menu/hooks/useItems'
import Table, { ColumnType } from 'antd/lib/table'
import { useCallback, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import { Txt } from 'components/Txt'
import { getColumnNumberByPage } from 'helpers/column-helper'
import { IGetSearchServicePropResponse } from 'services/property-management/property-management-response'
import { useGetSearchServicePropQRY } from 'services/property-management/property-management-service'

import { GearIcon } from './ServiceTable'

interface ServiceTablePropsType {
  txtSearch?: string
  renderMenuItems: (record: IGetSearchServicePropResponse) => ItemType[]
}
export const TablePropertyManagement = (props: ServiceTablePropsType) => {
  const query = useParams()
  const serviceId = Number(query.serviceId)
  const { txtSearch, renderMenuItems } = props
  const [currentPage, setCurrentPage] = useState(1)
  const [currentPageSize, setCurrentPageSize] = useState(10)

  const { data: dataSource, isLoading: isSearchServicePropLoading } = useGetSearchServicePropQRY({
    ServiceId: serviceId,
    Name: txtSearch,
  })

  const handlePageChange = useCallback((page: number, pageSize: number) => {
    setCurrentPage(page)
    setCurrentPageSize(pageSize)
  }, [])

  const columns = useMemo(
    (): ColumnType<IGetSearchServicePropResponse>[] => [
      {
        title: 'ลำดับ',
        width: '50px',
        align: 'center',
        render: (_: any, record: IGetSearchServicePropResponse, index: number) => (
          <Txt ag="body20">{getColumnNumberByPage(currentPage, index, currentPageSize)}</Txt>
        ),
      },
      {
        title: 'ชื่อ Property',
        dataIndex: 'name',
      },
      {
        title: 'สถานะ',
        dataIndex: 'active',
        render: (_: any, record: IGetSearchServicePropResponse) => {
          const { id, active } = record

          return (
            <Tag color={active ? 'success' : 'default'} key={id}>
              {active ? 'Active' : 'In Active'}
            </Tag>
          )
        },
      },
      {
        title: 'Modify By',
        dataIndex: 'modifystfnm',
      },

      {
        title: 'Modify Date',
        dataIndex: 'modifydatenm',
      },
      {
        title: '',
        dataIndex: 'action',
        width: '16px',
        render: (value, record) => (
          <Dropdown trigger={['click']} overlay={<Menu items={renderMenuItems(record)} />} placement="bottomRight">
            <div>
              <GearIcon icon={faGear} />
            </div>
          </Dropdown>
        ),
      },
    ],
    [currentPage, renderMenuItems, currentPageSize],
  )

  return (
    <Table
      scroll={{ x: '100%' }}
      dataSource={dataSource}
      columns={columns}
      loading={isSearchServicePropLoading}
      rowKey="id"
      pagination={{
        showSizeChanger: true,
        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
        onChange: handlePageChange,
      }}
    />
  )
}
