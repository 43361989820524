import { Avatar, Drawer, Dropdown, Menu } from 'antd'
import { styled } from 'goober'
import Hamburger from 'hamburger-react'
import { MenuInfo } from 'rc-menu/lib/interface'
import { useCallback, useMemo, useState } from 'react'

import iconUser from 'assets/images/icon-user.svg'
import logoEnCo from 'assets/images/logo-enco.png'
import { menuItems } from 'constants/menu-config'
import { profileMenuItems } from 'constants/profile-menu-config'
import { useGetMenuWeb, useGetUserInfoQRY } from 'services/auth/auth-service'

import { useSelectMenu, useSelectProfileMenu } from './HeaderMenu'
import { NotificationMenu } from './NotificationMenu'

const Space = styled('div')`
  height: 68px;
`
const ContentLayout = styled('div')`
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  box-shadow: inset 0px -1px 0px #f3f3f4;
  box-sizing: border-box;
  height: 68px;
  z-index: 9999;
  left: 0;
  right: 0;
  top: 0;
  padding: 0 24px 0 12px;
`
const ProfileMenu = styled('div')`
  display: flex;
  grid-column-gap: 16px;
`

export const MobileHeaderMenu = () => {
  const { data: userInfo } = useGetUserInfoQRY()
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const { selectedMenuKeys, handleClickMenu } = useSelectMenu()
  const { selectedProfileMenuKeys } = useSelectProfileMenu()
  const { data: authMenu } = useGetMenuWeb()

  const profileMenuFiltered = useMemo(() => {
    if (!authMenu || authMenu?.length <= 0) {
      return profileMenuItems?.filter((v) => v?.key !== '1')
    }
    return profileMenuItems
  }, [authMenu])

  const handleOnClose = useCallback(() => {
    setIsDrawerOpen(false)
  }, [])

  const handleOnMenuClick = useCallback(
    (e: MenuInfo) => {
      handleClickMenu(e)
      setIsDrawerOpen(false)
    },
    [handleClickMenu],
  )

  return (
    <>
      <Space />
      <ContentLayout>
        <Hamburger size={18} color="#9e9ea7" toggled={isDrawerOpen} toggle={setIsDrawerOpen} />
        <img src={logoEnCo} alt="enco-logo" style={{ height: 34 }} />
        <ProfileMenu>
          <Dropdown
            overlayClassName="profile-menu-dropdown"
            overlay={<Menu mode="horizontal" selectedKeys={selectedProfileMenuKeys} items={profileMenuFiltered} />}
          >
            <Avatar src={userInfo?.profilePic ? userInfo?.profilePic : iconUser} style={{ cursor: 'pointer' }} />
          </Dropdown>
          <NotificationMenu />
        </ProfileMenu>
      </ContentLayout>
      <Drawer
        placement="left"
        closable={false}
        onClose={handleOnClose}
        visible={isDrawerOpen}
        style={{ marginTop: 68 }}
        contentWrapperStyle={{ boxShadow: 'unset', width: '100%' }}
        bodyStyle={{ padding: 0 }}
        maskStyle={{ background: 'transparent' }}
      >
        <Menu
          theme="light"
          mode="vertical"
          selectedKeys={selectedMenuKeys}
          items={menuItems}
          onClick={handleOnMenuClick}
        />
      </Drawer>
    </>
  )
}
