import dayjs from 'dayjs'
import { first } from 'lodash'
import { useCallback, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { ContentBody } from '_backend/components/layout/ContentBody'
import { ContentHeader } from '_backend/components/layout/ContentHeader'
import { backendPaths } from 'paths/backend-path-config'
import { useGetServiceQRY } from 'services/enco-service/enco-service-service'
import { ISavePromoParams } from 'services/promotion-code/promotion-code-param'
import { useGetPromotionCodeQRY, useSavePromoMTT } from 'services/promotion-code/promotion-code-service'

import { PromotionCodeForm } from './PromotionCodeForm'

export const PagePromotionCodeEdit = () => {
  const navigate = useNavigate()

  const query = useParams()
  const serviceId = Number(query.serviceId)

  const promotionId = Number(query.promotionId)

  const { data: dataSource, isLoading } = useGetPromotionCodeQRY(Number(serviceId))
  const { data: service } = useGetServiceQRY(Number(serviceId))

  const { mutateAsync: mutateSaveServicePromo } = useSavePromoMTT()

  const promotionEdit = dataSource?.filter((item) => item.id === promotionId)
  const newPromoEdit = first(promotionEdit)

  const initialValues = useMemo(
    (): Partial<ISavePromoParams> => ({
      active: newPromoEdit?.active,
      id: promotionId,
      mdPromoTypeId: newPromoEdit?.mdPromoTypeId,
      description: newPromoEdit?.description,
      discountUnit: newPromoEdit?.discountUnit,
      tbQuotaId: newPromoEdit?.tbQuotaId,
      rangeDate: [dayjs(newPromoEdit?.startdate), dayjs(newPromoEdit?.expiredate)],
    }),
    [newPromoEdit, promotionId],
  )

  const onCancel = useCallback(() => {
    navigate(
      backendPaths.serviceEncoPromotionCodeList({
        routeParam: {
          serviceId: Number(serviceId),
        },
      }),
    )
  }, [navigate, serviceId])

  const onSubmit = useCallback(
    async (values: ISavePromoParams) => {
      try {
        if (newPromoEdit) {
          mutateSaveServicePromo({
            id: promotionId,
            tbServiceId: newPromoEdit?.tbServiceId,
            mdPromoTypeId: newPromoEdit?.mdPromoTypeId,
            startdate: newPromoEdit?.startdate,
            expiredate: newPromoEdit?.expiredate,
            description: newPromoEdit?.description,
            tbQuotaId: newPromoEdit?.tbQuotaId || undefined,
            discountUnit: newPromoEdit?.discountUnit,
            active: values?.active,
            codeQty: newPromoEdit?.codeQty,
            fixCode: newPromoEdit?.fixCode,
            genDigit: newPromoEdit?.genDigit,
            promoDtItems: newPromoEdit.promoDtDtos || [],
          })
        }
        navigate(
          backendPaths.serviceEncoPromotionCodeList({
            routeParam: {
              serviceId: Number(serviceId),
            },
          }),
        )
      } catch (error) {
        return Promise.reject(error)
      }
    },
    [mutateSaveServicePromo, navigate, newPromoEdit, promotionId, serviceId],
  )

  return (
    <>
      <ContentHeader title={service?.name} subTitle="แก้ไขโปรโมชั่นโค้ด" />
      <ContentBody>
        {!isLoading && (
          <PromotionCodeForm
            initialValues={initialValues}
            onSubmit={onSubmit}
            onCancel={onCancel}
            promoDetailData={newPromoEdit?.promoDtDtos}
            headerPromotionTxt={'แก้ไขโปรโมชั่นโค้ด'}
          />
        )}
      </ContentBody>
    </>
  )
}
