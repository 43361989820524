import { notification } from 'antd'
import { Rule } from 'antd/lib/form'

export const notifyFormValidateFailed = () =>
  notification.error({
    message: 'ผิดพลาด',
    description: 'ข้อมูลไม่ถูกต้อง กรุณาตรวจสอบ',
  })

export const ruleStringRequired: Rule = {
  type: 'string',
  required: true,
  message: 'โปรดกรอก${label}',
}
