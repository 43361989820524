import { Col, Row } from 'antd'
import { useCallback, useState } from 'react'

import { ISearchAutoCompleteOption, SearchAutoComplete } from '_backend/components/SearchAutocomplete'
import { Txt } from 'components/Txt'

const dummyUrl = 'https://dummyjson.com/products'

export const DemoSearchAutoComplete = () => {
  const [options, setOptions] = useState<ISearchAutoCompleteOption[]>([])
  const [products, setProducts] = useState<any[]>([])
  const [selectedProduct, setSelectedProduct] = useState<any>(null)
  const [loading, setLoading] = useState(false)

  const handleSearch = useCallback((value: string) => {
    const searchParams = new URLSearchParams({ q: value })
    const searchUrl = `${dummyUrl}/search?${searchParams}`
    setLoading(true)
    fetch(searchUrl)
      .then((res) => {
        if (!res.ok) {
          return Error('Oh no')
        }
        return res.json()
      })
      .then((data) => {
        setLoading(false)
        setProducts(data.products)
        setOptions(data.products.map((r: any) => ({ text: r.title, value: r.id })))
      })
  }, [])
  const handleChange = useCallback(
    (value?: string) => {
      const product = products.find((r: any) => r.id === value)
      setSelectedProduct(product)
    },
    [products],
  )

  return (
    <>
      <Row>
        <Txt ag="header24">Search AutoComplete</Txt>
      </Row>
      <Row gutter={16}>
        <Col>
          <SearchAutoComplete
            options={options}
            onSearch={handleSearch}
            onChange={handleChange}
            isLoading={loading}
            width="500px"
            placeholder="ค้นหา product (e.g. iPhone)"
          />
        </Col>
      </Row>
      <Row>
        <Col>ID: {selectedProduct?.id}</Col>
      </Row>
      <Row>
        <Col>Title: {selectedProduct?.title}</Col>
      </Row>
      <Row>
        <Col>Brand: {selectedProduct?.brand}</Col>
      </Row>
    </>
  )
}
