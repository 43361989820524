import { useCallback, useState } from 'react'

import { ButtonWarning } from '_backend/components/ButtonWarning'
import { ISearchOrderResponse } from 'services/order-response/order-response-service-response'
import { useDownloadOrderFullReceiptMTT } from 'services/order-response/order-response-service-service'

import { ModalFullReceipt } from './ModalFullReceipt'

interface IButtonFullReceiptProps {
  order: ISearchOrderResponse
}

export const ButtonFullReceipt = (props: IButtonFullReceiptProps) => {
  const { order } = props
  const { mdReceiptTypeId } = order
  const [modalVisible, setModalVisible] = useState(false)
  const { mutateAsync: downloadFullReceipt, isLoading } = useDownloadOrderFullReceiptMTT()

  const handleClick = useCallback(() => {
    if (mdReceiptTypeId === 2) {
      downloadFullReceipt({ tbOrderId: order.id })
    } else {
      setModalVisible(true)
    }
  }, [downloadFullReceipt, mdReceiptTypeId, order.id])

  const handleModalSubmit = useCallback(
    (addressId: number) => {
      setModalVisible(false)
      downloadFullReceipt({ tbOrderId: order.id, tbUserAddrId: addressId })
    },
    [downloadFullReceipt, order.id],
  )

  return (
    <>
      <ButtonWarning loading={isLoading} onClick={handleClick}>
        ออกใบกำกับภาษี
      </ButtonWarning>
      <ModalFullReceipt
        orderId={order.id}
        userId={order.userId}
        visible={modalVisible}
        onDismiss={() => setModalVisible(false)}
        onSubmit={handleModalSubmit}
      />
    </>
  )
}
