import { useMutation, useQuery } from '@tanstack/react-query'
import { chunk } from 'lodash'

import { FileHelper } from 'helpers/file-helper'
import { encoClient } from 'services/http-client/enco-client'
import { IGetOrderResponseParams } from 'services/order-response/order-response-service-param'

import { ResponseType } from '../response-type'

import { IGetOrderStatus, IGetOrderType, IMyNoti, IMyOrder, IMyOrderPaginate } from './order-enco-service-response'

export const MY_ORDER_PATH = '/MyOrder'
const MY_NOTI_PATH = '/MyNoti'
const GET_ORDER_TYPE = '/GetOrderType'
const GET_MASTER_ORDER_STATUS = '/GetMasterOrderStatus'
const EXPORT_ORDER_SEARCH = '/ExportOrderSearch'

export const useGetMyOrderQRY = () => {
  return useQuery([MY_ORDER_PATH], async () => {
    const response = await encoClient.get<ResponseType<IMyOrder[]>>(MY_ORDER_PATH)
    return response.data.data
  })
}

export const useGetMyOrderPaginateQRY = (page: number = 1, pageSize: number = 5) => {
  const pageNotLessThanOne = Math.max(page, 1)

  return useQuery<IMyOrderPaginate<IMyOrder>>(
    [MY_ORDER_PATH, { page: pageNotLessThanOne, pageSize }],
    async () => {
      const response = await encoClient.get<ResponseType<IMyOrder[]>>(MY_ORDER_PATH)
      const myOrders = response.data.data

      const items = chunk(myOrders, pageSize)

      const totalItem = myOrders.length
      const totalPage = items.length

      return {
        items: items[pageNotLessThanOne - 1],
        page,
        pageSize,
        totalPage,
        totalItem,
      }
    },
    {
      keepPreviousData: true,
    },
  )
}

export const useGetMyNotiQTY = () => {
  return useQuery([MY_NOTI_PATH], async () => {
    const response = await encoClient.get<ResponseType<IMyNoti[]>>(MY_NOTI_PATH)
    return response.data.data
  })
}

export const useGetMasterOrderTypeQTY = () => {
  return useQuery(
    [GET_ORDER_TYPE],
    async () => {
      const res = await encoClient.get<ResponseType<IGetOrderType[]>>(GET_ORDER_TYPE)
      return res.data.data
    },
    {
      cacheTime: Infinity,
      staleTime: Infinity,
    },
  )
}

export const useGetMasterOrderStatusQTY = () => {
  return useQuery(
    [GET_MASTER_ORDER_STATUS],
    async () => {
      const res = await encoClient.get<ResponseType<IGetOrderStatus[]>>(GET_MASTER_ORDER_STATUS)
      return res.data.data
    },
    {
      cacheTime: Infinity,
      staleTime: Infinity,
    },
  )
}

export const useExportOrderSearch = () => {
  return useMutation(async (params: IGetOrderResponseParams) => {
    const res = await encoClient.post<ResponseType<{ fileName: string; fileString: string }>>(
      EXPORT_ORDER_SEARCH,
      params,
    )
    const data = res.data.data
    const base64Response = await fetch(
      `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${data.fileString}`,
    )
    const blob = await base64Response.blob()
    const blobUrl = URL.createObjectURL(blob)
    FileHelper.download(blobUrl, data.fileName)
  })
}
