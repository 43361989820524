import { Button, Checkbox, Col, Form, List, Row } from 'antd'
import { styled } from 'goober'
import { chain, every, some } from 'lodash'
import { useCallback, useEffect, useMemo } from 'react'

import { AppModal } from 'components/AppModal'
import { Txt } from 'components/Txt'
import { ISubmitKioskMasterProductParams } from 'services/kiosk-master/kiosk-master-params'
import theme from 'theme/goober'

const CheckboxContainer = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column;

  .checked-all {
    border-bottom: 1px solid #f0f0f0;
  }

  .checkbox-list {
    width: 100%;
    max-height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .ant-list-item.is-active {
    background-color: ${theme.color.lightBlueBackground2};
  }
`

export interface IModalKioskMasterProductPropertyFormValues
  extends Pick<ISubmitKioskMasterProductParams, 'mdKioskProductPropListDto'> {}

interface IModalKioskMasterProductPropertyProps {
  visible: boolean
  initialValues?: Partial<IModalKioskMasterProductPropertyFormValues>
  onDismiss: () => void
  onConfirm: (values: IModalKioskMasterProductPropertyFormValues) => void
}
export const ModalKioskMasterProductProperty = (props: IModalKioskMasterProductPropertyProps) => {
  const { initialValues: preInitialValues = {}, visible, onDismiss, onConfirm } = props

  const [form] = Form.useForm<IModalKioskMasterProductPropertyFormValues>()
  const mdKioskProductPropListDtoInForm = Form.useWatch('mdKioskProductPropListDto', form)

  const isAllChecked = useMemo(() => {
    return every(mdKioskProductPropListDtoInForm, (e) => e.isActive)
  }, [mdKioskProductPropListDtoInForm])

  const isSomeChecked = useMemo(() => {
    return some(mdKioskProductPropListDtoInForm, (e) => e.isActive)
  }, [mdKioskProductPropListDtoInForm])

  const initialValues = useMemo((): Partial<IModalKioskMasterProductPropertyFormValues> => {
    const { mdKioskProductPropListDto } = preInitialValues
    return {
      mdKioskProductPropListDto: chain(mdKioskProductPropListDto ?? [])
        .orderBy(['servicePropName'], ['asc'])
        .value(),
    }
  }, [preInitialValues])

  const handleDismiss = useCallback(() => {
    form.resetFields()
    onDismiss()
  }, [form, onDismiss])

  const onSubmit = useCallback(
    (values: IModalKioskMasterProductPropertyFormValues) => {
      onConfirm({
        mdKioskProductPropListDto: values.mdKioskProductPropListDto.map((e) => {
          e.id ??= 0
          e.isActive = e.isActive ? 1 : 0
          return e
        }),
      })
      form.resetFields()
    },
    [form, onConfirm],
  )

  const onCheckAllChange = useCallback(() => {
    form.setFieldsValue({
      mdKioskProductPropListDto: mdKioskProductPropListDtoInForm.map((e) => ({ ...e, isActive: isAllChecked ? 0 : 1 })),
    })
  }, [form, isAllChecked, mdKioskProductPropListDtoInForm])

  useEffect(() => {
    form.setFieldsValue(initialValues)
  }, [form, initialValues])

  return (
    <AppModal visible={visible} destroyOnClose forceRender>
      <Form form={form} onFinish={onSubmit} layout="vertical" autoComplete="off">
        <AppModal.Header onCloseClick={handleDismiss}>กำหนดรายการ Kiosk ที่เปิดขาย</AppModal.Header>
        <AppModal.Body>
          <Form.List name="mdKioskProductPropListDto">
            {(fields) => (
              <CheckboxContainer>
                <Col className="checked-all" span={24}>
                  <List.Item>
                    <List.Item.Meta
                      avatar={
                        <Checkbox
                          indeterminate={isSomeChecked && !isAllChecked}
                          onChange={onCheckAllChange}
                          checked={isAllChecked}
                        />
                      }
                      title="Select all"
                    />
                  </List.Item>
                </Col>
                <List
                  className="checkbox-list"
                  itemLayout="horizontal"
                  dataSource={fields}
                  renderItem={(field) => (
                    <Form.Item shouldUpdate dependencies={[field.name, 'isActive']} noStyle>
                      {({ getFieldValue }) => {
                        const isActive = getFieldValue(['mdKioskProductPropListDto', field.name, 'isActive'])
                        return (
                          <List.Item className={isActive ? 'is-active' : undefined}>
                            <List.Item.Meta
                              avatar={
                                <Form.Item name={[field.name, 'isActive']} valuePropName="checked" noStyle>
                                  <Checkbox />
                                </Form.Item>
                              }
                              title={
                                <Form.Item name={[field.name, 'servicePropName']} valuePropName="children" noStyle>
                                  <Txt ag="body18" />
                                </Form.Item>
                              }
                            />
                          </List.Item>
                        )
                      }}
                    </Form.Item>
                  )}
                />
              </CheckboxContainer>
            )}
          </Form.List>
        </AppModal.Body>
        <Row className="custom-modal-footer" wrap={false} justify="space-between" style={{ width: '100%' }}>
          <Col>
            <Button onClick={handleDismiss}>ปิด</Button>
          </Col>
          <Col>
            <Button type="primary" htmlType="submit">
              บันทึก
            </Button>
          </Col>
        </Row>
      </Form>
    </AppModal>
  )
}
