import { useMutation, useQuery, useQueryClient, UseQueryOptions } from '@tanstack/react-query'

import { encoClient } from 'services/http-client/enco-client'

import { ResponseType } from '../response-type'

import { ICustomerGetListParams, ICustomSaveParams } from './customer-param'
import { ICustomerGetListResponse } from './customer-response'

const CUSTOMER_GET_LIST_PATH = '/GetCustomer'
const CUSTOMER_SAVE_PATH = '/SaveCustomer'

export const useGetCustomerListQRY = (
  params?: ICustomerGetListParams,
  options?: UseQueryOptions<ICustomerGetListResponse[]>,
) => {
  return useQuery<ICustomerGetListResponse[]>(
    [CUSTOMER_GET_LIST_PATH, params],
    async () => {
      const response = await encoClient.post<ResponseType<ICustomerGetListResponse[]>>(CUSTOMER_GET_LIST_PATH, params)
      return response.data.data || []
    },
    options,
  )
}

export const useCustomerSaveMTT = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async (param: ICustomSaveParams) => {
      const res = await encoClient.post<ResponseType<ICustomerGetListResponse>>(CUSTOMER_SAVE_PATH, param)
      return res.data.data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([CUSTOMER_GET_LIST_PATH])
      },
    },
  )
}

export const useCustomerDeleteMTT = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async (id: number) => {
      const res = await encoClient.delete<ResponseType<ICustomerGetListResponse>>(CUSTOMER_SAVE_PATH, {
        data: { id },
      })
      return res.data.data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([CUSTOMER_GET_LIST_PATH])
      },
    },
  )
}
