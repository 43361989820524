import { useMutation, useQuery } from '@tanstack/react-query'

import { ContentType } from 'services/http-client/base-http-client'
import { encoClient } from 'services/http-client/enco-client'
import { ResponseType } from 'services/response-type'

import { ISearchItemParams, IServiceItemSaveParams } from './item-management-param'
import { IGetItemTypeResponse, IGetServiceItemResponse } from './item-management-response'

const GET_SERVICE_ITEM_BY_ID_PATH = '/ServiceItem'
const GET_SERVICE_ITEM_LIST_PATH = '/SearchServiceItem'
const SAVE_SERVICE_ITEM_PATH = '/ServiceItem'
const DELETE_SERVICE_ITEM_PATH = '/ServiceItem'
const GET_ITEM_TYPE_LIST_PATH = '/GetMasterItemType'

export const useGetServiceItemByIdQRY = (itemId: number) => {
  return useQuery([GET_SERVICE_ITEM_BY_ID_PATH, itemId], async () => {
    const res = await encoClient.get<ResponseType<IGetServiceItemResponse>>(GET_SERVICE_ITEM_BY_ID_PATH, {
      params: { id: itemId },
    })
    return res.data.data
  })
}

export const useGetServiceItemListQRY = (params: ISearchItemParams) => {
  return useQuery([GET_SERVICE_ITEM_LIST_PATH, params], async () => {
    const res = await encoClient.post<ResponseType<IGetServiceItemResponse[]>>(GET_SERVICE_ITEM_LIST_PATH, params, {
      headers: { 'Content-Type': ContentType.FORM_DATA },
    })
    return res.data.data
  })
}

export const useSaveServiceItemMTT = () => {
  return useMutation<unknown, unknown, IServiceItemSaveParams>(async (data) => {
    const res = await encoClient.post<ResponseType<IGetServiceItemResponse>>(`${SAVE_SERVICE_ITEM_PATH}`, data, {
      headers: { 'Content-Type': ContentType.FORM_DATA },
    })
    return res.data.data
  })
}

export const useGetItemTypeListQRY = () => {
  return useQuery([GET_ITEM_TYPE_LIST_PATH], async () => {
    const res = await encoClient.get<ResponseType<IGetItemTypeResponse[]>>(GET_ITEM_TYPE_LIST_PATH)
    return res.data.data
  })
}

export const useDeleteServiceItemMTT = () => {
  return useMutation<unknown, unknown, { id: number }>(async (data) => {
    const res = await encoClient.delete<ResponseType<null>>(`${DELETE_SERVICE_ITEM_PATH}`, {
      headers: { 'Content-Type': ContentType.FORM_DATA },
      data
    })
    return res.data.data
  })
}