import { sanitizeUrl } from '@braintree/sanitize-url'
import { Button, Radio, Space, Table } from 'antd'
import { ColumnType } from 'antd/lib/table'
import { useCallback, useMemo, useState } from 'react'
import { FaEye } from 'react-icons/fa'
import { Link, useNavigate } from 'react-router-dom'

import { SearchInput } from '_backend/components/SearchInput'
import { ContentBody } from '_backend/components/layout/ContentBody'
import { ContentHeader } from '_backend/components/layout/ContentHeader'
import { Txt } from 'components/Txt'
import { getColumnNumberByPage } from 'helpers/column-helper'
import { backendPaths } from 'paths/backend-path-config'
import { IQuotationGetListResponse, IQuotationSearchOrderResponse } from 'services/sale/quotation/quotation-response'
import { useQuotationGetListQRY, useQuotationSearchOrderQRY } from 'services/sale/quotation/quotation-service'

import { ModalSaleCreateOption } from '../ModalSaleCreateOption'
import { TooltipSaleNo } from '../TooltipSaleNo'

export const PageQuotationList = () => {
  const [txtSearch, setTxtSearch] = useState('')
  const { data: dataSource = [], isFetching } = useQuotationGetListQRY({ txtSearch })
  const [currentPage, setCurrentPage] = useState(1)
  const [currentPageSize, setCurrentPageSize] = useState(10)
  const [modalCreateOptionVisible, setModalCreateOptionVisible] = useState(false)
  const { data: createOptionDataSource = [] } = useQuotationSearchOrderQRY({}, { enabled: modalCreateOptionVisible })
  const navigate = useNavigate()

  const handlePageChange = useCallback((page: number, pageSize: number) => {
    setCurrentPage(page)
    setCurrentPageSize(pageSize)
  }, [])
  const handleSearch = useCallback((value: string) => {
    setTxtSearch(value)
  }, [])
  const handleClickCreate = useCallback(() => {
    setModalCreateOptionVisible(true)
  }, [])
  const handleDismissCreateOptionModal = useCallback(() => {
    setModalCreateOptionVisible(false)
  }, [])
  const handleSubmitCreateOptionModal = useCallback(
    (orderId: number) => {
      navigate(backendPaths.quotationNew({ queryParam: { orderId } }))
    },
    [navigate],
  )

  const columns = useMemo(
    (): ColumnType<IQuotationGetListResponse>[] => [
      {
        title: 'ลำดับ',
        width: '50px',
        align: 'center',
        render: (_: any, record: IQuotationGetListResponse, index: number) => (
          <Txt ag="body20">{getColumnNumberByPage(currentPage, index, currentPageSize)}</Txt>
        ),
      },
      {
        title: 'Quotation No.',
        width: '120px',
        dataIndex: 'quotaNo',
        render: (quotaNo: string, record: IQuotationGetListResponse, index: number) => (
          <TooltipSaleNo saleNo={quotaNo} attention={record.attention} totalPrice={record.totalPrice} />
        ),
      },
      {
        title: 'Quotation Datetime',
        width: '120px',
        dataIndex: 'quodatedisplay',
      },
      {
        title: 'Order No.',
        width: '120px',
        dataIndex: 'orderNo',
      },
      {
        title: 'Order Datetime',
        width: '120px',
        dataIndex: 'orderDatedisplay',
      },
      {
        title: 'Status',
        width: '100px',
        dataIndex: 'mdQuotaStName',
      },
      {
        title: 'Modify Date',
        width: '120px',
        dataIndex: 'modifydatedisplay',
      },
      {
        title: '',
        dataIndex: 'action',
        width: '100px',
        className: 'text-center',
        render: (_: any, record: IQuotationGetListResponse) => {
          const { id } = record
          const quotationEditPath = backendPaths.quotationEdit({ routeParam: { quotationId: id } })
          return (
            <Link to={sanitizeUrl(quotationEditPath)}>
              <FaEye />
            </Link>
          )
        },
      },
    ],
    [currentPage, currentPageSize],
  )

  const createOptionColumns = useMemo(
    () => [
      {
        title: <span style={{ marginLeft: '32px' }}>Order No.</span>,
        width: '50px',
        render: (_: any, record: IQuotationSearchOrderResponse, index: number) => (
          <Radio value={record.id}>
            <span style={{ paddingLeft: '8px' }}>{record.orderNo}</span>
          </Radio>
        ),
      },
      {
        title: 'Order Datetime',
        width: '120px',
        dataIndex: 'orderDateTimeNm',
      },
      {
        title: 'Modify Date',
        width: '120px',
        dataIndex: 'modifydatenm',
      },
    ],
    [],
  )

  return (
    <>
      <ContentHeader title="Sale" subTitle="Quotation">
        <Space size={['large', 'small']} wrap style={{ justifyContent: 'start' }}>
          <SearchInput placeholder="ค้นหา Quotation" onSearch={handleSearch} />
          <Button type="primary" onClick={handleClickCreate}>
            สร้างใบ Quotation
          </Button>
        </Space>
      </ContentHeader>
      <ContentBody>
        <Table
          scroll={{ x: '100%' }}
          rowKey="id"
          dataSource={dataSource}
          columns={columns}
          loading={isFetching}
          pagination={{
            showSizeChanger: true,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
            onChange: handlePageChange,
            current: currentPage,
          }}
        />
      </ContentBody>
      <ModalSaleCreateOption<IQuotationSearchOrderResponse>
        title="เลือกเลข Order ที่คุณต้องการสร้างใบ Quotation"
        columns={createOptionColumns}
        dataSource={createOptionDataSource}
        visible={modalCreateOptionVisible}
        onDismiss={handleDismissCreateOptionModal}
        onSubmit={handleSubmitCreateOptionModal}
      />
    </>
  )
}
