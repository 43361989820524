import { Form, notification } from 'antd'
import { useForm } from 'antd/es/form/Form'
import dayjs, { Dayjs } from 'dayjs'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { ContentBody } from '_backend/components/layout/ContentBody'
import { ContentHeader } from '_backend/components/layout/ContentHeader'
import { apiDateFormat } from '_backend/constants/date-format'
import { useModalConfirm } from 'components/ModalConfirmProvider'
import { notifyFormValidateFailed } from 'helpers/form-helper'
import { backendPaths } from 'paths/backend-path-config'
import { IInvoiceSaveParams } from 'services/sale/invoice/invoice-param'
import { useInvoiceSaveMTT } from 'services/sale/invoice/invoice-service'
import { IGetQuotationResponse } from 'services/sale/quotation/quotation-response'
import { useGetQuotationDetailQRY } from 'services/sale/quotation/quotation-service'

import { SectionCustomerFields } from '../SectionCustomerFields'
import { SectionFormAction } from '../SectionFormAction'
import { SectionOrderFields } from '../SectionOrderFields'
import { DiscountTypeEnum, DiscountUnitEnum, VatTypeEnum } from '../sale-constants'
import { IRecordOrderItem, mapSaleDataItemToRecord } from '../sale-helper'

import { SectionInvoiceFields } from './SectionInvoiceFields'

export interface IFormInvoiceValues {
  id?: number
  dueDate?: Dayjs | string
  invoiceDate?: Dayjs | string
  tbServiceName?: string
  quotaNo?: string
  quodatedisplay?: string
  orderNo?: string
  orderDatedisplay?: string
  remark?: string

  attention?: string
  customerId?: number
  customerTaxId?: string
  customerAddress?: string

  vatType?: VatTypeEnum
  vatPercent?: number
  vat?: number
  vatAmount?: number
  discountType?: DiscountTypeEnum
  discountUnit?: DiscountUnitEnum
  discount?: number
  discountAmount?: number
  netPrice?: number
}

interface IMapInvoiceSaveParams {
  quotation: IGetQuotationResponse
  orderItems: IRecordOrderItem[]
  values: IFormInvoiceValues
}
export const mapInvoiceSaveParams = (params: IMapInvoiceSaveParams): IInvoiceSaveParams => {
  const { quotation, orderItems, values } = params
  return {
    id: values.id,
    tbQuotaId: quotation.id,
    dueDate: dayjs(values.dueDate).format(apiDateFormat),
    invoiceDate: dayjs(values.invoiceDate).format(apiDateFormat),
    vatType: values.vatType,
    vatPercent: values.vatPercent,
    vat: values.vatAmount,
    remark: values.remark,
    attention: values.attention,
    tbCustomerId: values.customerId,
    discountType: values.discountType,
    discountPercent: values.discountUnit === DiscountUnitEnum.PERCENT ? values.discount : 0,
    discount: values.discountAmount,
    total: values.netPrice,
    invoiceDts: orderItems.map((o) => ({
      id: o.id || 0,
      item: o.itemName,
      qty: o.quantity,
      unit: o.unit,
      unitPrice: o.unitPrice,
      total: o.unitPrice * o.quantity,
    })),
  }
}

export const PageInvoiceNew = () => {
  const [searchParams] = useSearchParams()
  const [form] = useForm()
  const quotationId = searchParams.get('quotationId')
  const modalConfirm = useModalConfirm()
  const navigate = useNavigate()

  const { data: quotation, isFetching } = useGetQuotationDetailQRY(Number(quotationId))
  const { mutateAsync: saveInvoice } = useInvoiceSaveMTT()

  const [orderItems, setOrderItems] = useState<IRecordOrderItem[]>([])
  const handleChangeItems = useCallback((newItems: IRecordOrderItem[]) => {
    setOrderItems(newItems)
  }, [])

  const exitingCustomerId = useMemo(() => quotation?.tbCustomerId, [quotation?.tbCustomerId])

  const initialValues = useMemo<IFormInvoiceValues>(() => {
    const discountUnit =
      quotation?.discountPercent === 0 && quotation?.discount > 0 ? DiscountUnitEnum.BATH : DiscountUnitEnum.PERCENT
    return {
      id: 0,
      vatType: VatTypeEnum.VAT_EXCLUDED,
      discountType: quotation?.discountType,
      quotaNo: quotation?.quotaNo,
      quodatedisplay: quotation?.quodatedisplay,
      tbServiceName: quotation?.tbServiceName,
      remark: quotation?.remark,

      attention: quotation?.attention,
      customerId: exitingCustomerId,

      orderNo: quotation?.orderNo,
      orderDatedisplay: quotation?.orderDatedisplay,
      discountUnit,
      discount: discountUnit === DiscountUnitEnum.PERCENT ? quotation?.discountPercent : quotation?.discount,
      vatPercent: quotation?.vatPercent,
    }
  }, [exitingCustomerId, quotation])

  const handleFinish = useCallback(
    (values: IFormInvoiceValues) => {
      modalConfirm.show({
        type: 'default',
        title: 'ยืนยันการสร้างใบวางบิล',
        content: 'คุณยืนยันที่จะสร้างใบวางบิลนี้หรือไม่',
        modalProps: {
          width: 700,
        },
        onOk: async () => {
          if (!quotation) return
          const saveParams = mapInvoiceSaveParams({ quotation, orderItems, values })
          await saveInvoice(saveParams)
          navigate(backendPaths.invoiceList())
          notification.success({ message: 'สำเร็จ', description: 'บันทึกข้อมูลใบวางบิลสำเร็จ', duration: 2 })
        },
      })
    },
    [modalConfirm, navigate, orderItems, quotation, saveInvoice],
  )

  useEffect(() => {
    setOrderItems(mapSaleDataItemToRecord(quotation?.dataItems, true))
  }, [quotation?.dataItems])

  useEffect(() => {
    form.setFieldsValue(initialValues)
  }, [form, initialValues])

  return (
    <>
      <ContentHeader title="Sale" subTitle="New Invoice" />
      <ContentBody>
        {!isFetching && (
          <Form<IFormInvoiceValues>
            layout="vertical"
            form={form}
            onFinish={handleFinish}
            onFinishFailed={notifyFormValidateFailed}
            scrollToFirstError
            initialValues={initialValues}
          >
            <SectionInvoiceFields />
            <SectionCustomerFields readonly form={form} exitingCustomerId={exitingCustomerId} />
            <SectionOrderFields form={form} orderItems={orderItems} onChangItems={handleChangeItems} />
            <SectionFormAction backLink={backendPaths.invoiceList()} />
          </Form>
        )}
      </ContentBody>
    </>
  )
}
