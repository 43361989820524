import { Button, Space } from 'antd'

import { useModalConfirm } from 'components/ModalConfirmProvider'

export const WikiModalConfirm = () => {
  const modalConfirm = useModalConfirm()
  return (
    <>
      <Space>
        <Button
          onClick={() =>
            modalConfirm.show({
              type: 'default',
              title: 'Title',
              content: <div>'Content...Default'</div>,
              onOk: () => {
                console.log('on ok trigger')
              },
              onCancel: () => {
                console.log('on cancel trigger')
              },
            })
          }
        >
          Default
        </Button>
        <Button
          onClick={() =>
            modalConfirm.show({
              type: 'info',
              title: 'Title',
              content: <div>'Content...default'</div>,
              onOk: () => {
                console.log('on ok trigger')
              },
            })
          }
        >
          Info
        </Button>
        <Button
          onClick={() =>
            modalConfirm.show({
              type: 'warning',
              title: 'Title',
              content: <div>'Content...Warning'</div>,
              onCancel: () => {
                console.log('on cancel trigger')
              },
            })
          }
          type="ghost"
          danger
        >
          Warning
        </Button>
        <Button
          onClick={() =>
            modalConfirm.show({
              type: 'danger',
              title: 'Title',
              content: <div>'Content...Danger'</div>,
            })
          }
          type="primary"
          danger
        >
          Danger
        </Button>
        <Button
          onClick={() =>
            modalConfirm.show({
              type: 'default',
              title: 'Title',
              content: <div>'Content...Default'</div>,
              modalProps: {
                width: 800,
              },
              onOk: () => {
                console.log('on ok trigger')
              },
              onCancel: () => {
                console.log('on cancel trigger')
              },
            })
          }
        >
          Set width 800px
        </Button>
      </Space>
      <div style={{ textAlign: 'start', marginTop: 16, whiteSpace: 'pre' }}>{codeText}</div>
    </>
  )
}

const codeText = `import { Button, Space } from 'antd'

import { useModalConfirm } from '_backend/components/ModalConfirmProvider'

export const WikiModalConfirm = () => {
  const modalConfirm = useModalConfirm()
  return (
    <>
      <Space>
        <Button
          onClick={() =>
            modalConfirm.show({
              type: 'default',
              title: 'Title',
              content: <div>'Content...Default'</div>,
              onOk: () => {
                console.log('on ok trigger')
              },
              onCancel: () => {
                console.log('on cancel trigger')
              },
            })
          }
        >
          Default
        </Button>
        <Button
          onClick={() =>
            modalConfirm.show({
              type: 'info',
              title: 'Title',
              content: <div>'Content...default'</div>,
              onOk: () => {
                console.log('on ok trigger')
              },
            })
          }
        >
          Info
        </Button>
        <Button
          onClick={() =>
            modalConfirm.show({
              type: 'warning',
              title: 'Title',
              content: <div>'Content...Warning'</div>,
              onCancel: () => {
                console.log('on cancel trigger')
              },
            })
          }
          type="ghost"
          danger
        >
          Warning
        </Button>
        <Button
          onClick={() =>
            modalConfirm.show({
              type: 'danger',
              title: 'Title',
              content: <div>'Content...Danger'</div>,
            })
          }
          type="primary"
          danger
        >
          Danger
        </Button>
      </Space>
      <div style={{ textAlign: 'start', marginTop: 16, whiteSpace: 'pre' }}>{codeText}</div>
    </>
  )
}`
