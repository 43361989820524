import { Tag } from 'antd'
import Table, { ColumnType } from 'antd/lib/table'
import { useCallback, useLayoutEffect, useMemo, useState } from 'react'

import { Txt } from 'components/Txt'
import { getColumnNumberByPage } from 'helpers/column-helper'
import { breakpoint } from 'helpers/responsive-helper'
import { IPromoDtDtosResponse } from 'services/promotion-code/promotion-code-response'

export interface ITableEmergencyContactsContext {
  openAddEmergencyContact: () => void
}

interface ITablePromotionCodeListProps {
  value?: IPromoDtDtosResponse[]
}
export const TablePromotionCodeList = (props: ITablePromotionCodeListProps) => {
  const { value: promoDtDtos = [] } = props

  const [currentPage, setCurrentPage] = useState(1)
  const [currentPageSize, setCurrentPageSize] = useState(10)

  const renderTagActive = useCallback((isActive: boolean) => {
    if (isActive) {
      return <Tag color="success">Active</Tag>
    }
    return <Tag color="default">Inactive</Tag>
  }, [])
  const handlePageChange = useCallback((page: number, pageSize: number) => {
    setCurrentPage(page)
    setCurrentPageSize(pageSize)
  }, [])

  const promoDtColumns = useMemo(
    (): ColumnType<IPromoDtDtosResponse>[] => [
      {
        title: 'ลำดับ',
        width: '50px',
        align: 'center',
        render: (_: any, record: IPromoDtDtosResponse, index: number) => (
          <Txt ag="body20">{getColumnNumberByPage(currentPage, index, currentPageSize)}</Txt>
        ),
      },
      {
        title: 'โปรโมชั่นโค้ด',
        dataIndex: 'promoCode',
      },
      {
        title: 'สถานะ',
        dataIndex: 'isUsed',
        render: renderTagActive,
      },

      { title: 'วันที่ใช้โค้ด', dataIndex: 'usedDatedisplay' },
      { title: 'ผู้ใช้โค้ด', dataIndex: 'useCodeNm' },
    ],
    [currentPage, renderTagActive, currentPageSize],
  )

  useLayoutEffect(() => {
    setCurrentPage(1)
  }, [promoDtDtos])

  return (
    <>
      <Table
        rowKey={'id'}
        columns={promoDtColumns}
        dataSource={promoDtDtos}
        scroll={{ x: breakpoint.smMax }}
        pagination={{
          current: currentPage,
          pageSize: currentPageSize,
          showSizeChanger: true,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
          onChange: handlePageChange,
        }}
      />
    </>
  )
}
