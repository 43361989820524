import { Col, Row } from 'antd'
import { styled } from 'goober'
import { PropsWithChildren, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { useLocation } from 'react-router-dom'

import { BACKEND_MENU_LIST } from '_backend/constants/backend-menu-config'
import { Txt } from 'components/Txt'
import { mediaQuery } from 'helpers/responsive-helper'

const Main = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 40px 24px;
  @media ${mediaQuery.xs} {
    padding: 24px 12px;
  }
`
const BreadCrumbWrapper = styled('div')`
  width: 100%;
`
const ActionWrapper = styled('div')`
  width: 100%;
  display: flex;
  justify-content: end;

  @media ${mediaQuery.xs} {
    justify-content: start;
  }
`
type ContentHeaderProps = {
  title?: string
  subTitle?: string
}

export const ContentHeader = (props: PropsWithChildren<ContentHeaderProps>) => {
  const { pathname } = useLocation()
  const menu = BACKEND_MENU_LIST.find((m) => m.path && pathname.includes(m.path))
  const { children, subTitle, title = menu?.name } = props
  const [contentHeaderPortalEl, setContentHeaderPortalEl] = useState<HTMLElement>()

  useEffect(() => {
    const el = document.getElementById('content_header_portal')
    if (el) setContentHeaderPortalEl(el)
  }, [])

  return contentHeaderPortalEl
    ? createPortal(
        <Main>
          <Row align="middle" justify="space-between" style={{ maxWidth: '1440px', width: '100%' }}>
            <Col span={24} sm={{ span: children ? 14 : 24 }} md={{ span: children ? 14 : 24 }}>
              <BreadCrumbWrapper>
                <Txt ag="header40" color="graySecondaryText">
                  {title}
                </Txt>
                {subTitle && (
                  <>
                    <Txt ag="header40" style={{ padding: '0 16px' }}>
                      -
                    </Txt>
                    <Txt ag="header40" color="lightBluePrimary">
                      {subTitle}
                    </Txt>
                  </>
                )}
              </BreadCrumbWrapper>
            </Col>
            {children && (
              <Col span={24} sm={{ span: 10 }} md={{ span: 10 }}>
                <ActionWrapper>{children}</ActionWrapper>
              </Col>
            )}
          </Row>
        </Main>,
        contentHeaderPortalEl,
      )
    : null
}
