import { Checkbox, Col, Row, Table } from 'antd'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { ColumnType } from 'antd/lib/table'
import { useCallback, useMemo } from 'react'

import { breakpoint } from 'helpers/responsive-helper'
import { IServiceLocation } from 'services/service-location/service-location-service-response'

interface IOzoneServiceLocationFieldProps {
  value?: IServiceLocation[]
  onChange?: (value: IServiceLocation[]) => void
  disabled?: boolean
}
const OzoneServiceLocationField = (props: IOzoneServiceLocationFieldProps) => {
  const { value: dataSource = [], onChange, disabled } = props

  const onCheckboxChange = useCallback(
    (record: IServiceLocation) => {
      return (event: CheckboxChangeEvent) => {
        const checked = event.target.checked
        const newServiceLocations = dataSource?.map((serviceLocation): IServiceLocation => {
          if (serviceLocation.serviceLocationId === record.serviceLocationId) {
            return {
              ...serviceLocation,
              isCheck: checked ? 1 : 0,
            }
          } else {
            return serviceLocation
          }
        })

        onChange?.(newServiceLocations)
      }
    },
    [dataSource, onChange],
  )

  const serviceLocationColumns = useMemo(
    (): ColumnType<IServiceLocation>[] => [
      {
        title: 'ลำดับ',
        width: '80px',
        align: 'center',
        render: (value, record, index) => index + 1,
      },
      {
        title: 'ชื่อสถานที่',
        dataIndex: 'locationName',
      },
      {
        title: 'กำหนดสิทธิ์',
        dataIndex: 'isCheck',
        width: '400px',
        fixed: 'right',
        render: (value, record) => {
          return (
            <Row>
              <Col>
                <Checkbox checked={!!value} onChange={onCheckboxChange(record)} disabled={disabled}>
                  เปิดสิทธิ์การใช้งาน
                </Checkbox>
              </Col>
            </Row>
          )
        },
      },
    ],
    [disabled, onCheckboxChange],
  )

  return (
    <Table
      rowKey={(record) => `${record.serviceLocationId}-${record.locationName}-${record.isCheck}`}
      columns={serviceLocationColumns}
      dataSource={dataSource}
      scroll={{ x: breakpoint.smMax }}
      pagination={false}
    />
  )
}

export default OzoneServiceLocationField
