import { useQuery } from '@tanstack/react-query'

import { encoClient } from 'services/http-client/enco-client'

import { ResponseType } from '../response-type'

import { IGetBannerParams } from './banner-param'
import { IBanner } from './banner-response'

const GET_BANNER = '/Banner'

export const useGetBannersQRY = (params: IGetBannerParams) => {
  return useQuery([GET_BANNER, params], async () => {
    const response = await encoClient.get<ResponseType<IBanner[]>>(GET_BANNER, {
      params,
    })
    return response.data.data
  })
}
