import { faCheckCircle, faCircle, faPen, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Card, Row } from 'antd'
import { styled } from 'goober'
import { isEmpty } from 'lodash'
import { useCallback } from 'react'

import { Txt } from 'components/Txt'
import { IGetAddrResponse } from 'services/address/address-response'
import theme from 'theme/goober'

const CustomCard = styled(Card)`
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  max-width: 500px;
  width: 100%;
  height: 150px;
  padding-right: 64px;
  margin-bottom: 16px;
  overflow: hidden;
  word-break: break-word;
  margin-left: auto;
  margin-right: auto;
`

const RemoveIcon = styled(FontAwesomeIcon)`
  position: absolute;
  right: 16px;
  bottom: 16px;
  &:hover {
    opacity: 0.5;
    cursor: pointer;
  }
`

const EditIconContainer = styled('div')`
  position: absolute;
  right: 40px;
  bottom: 16px;
  border-right: 1px solid ${theme.color.lightBluePrimary};
  display: flex;
  align-items: flex-end;
  padding-right: 8px;
  height: 28px;
`
const EditIcon = styled(FontAwesomeIcon)`
  &:hover {
    opacity: 0.5;
    cursor: pointer;
  }
`

const IconContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  right: 16px;
  top: 16px;
`

const SelectIcon = styled(FontAwesomeIcon)<{ selected: boolean; ['can-select']: string }>`
  color: ${(props) => (props.selected ? theme.color.lightBluePrimary : 'white')};
  border: 1px solid ${theme.color.lightBluePrimary};
  border-radius: 50px;
  cursor: ${(props) => (props.selected ? 'context' : 'pointer')};
  border-width: ${(props) => (props.selected ? 0 : 1)}px;
  &:hover {
    opacity: ${(props) => (props['can-select'] === 'yes' ? 0.5 : 1)};
  }
`

interface AddressItemProps extends IGetAddrResponse {
  label?: string
  onSelect: () => void
  isSelected?: boolean
  onRemove?: () => void
  canSelect: boolean
  isShowLabel?: boolean
  onEdit: () => void
}
const AddressItem = (props: AddressItemProps) => {
  const {
    isSelected,
    onSelect,
    onRemove,
    canSelect,
    fullname,
    address,
    tax,
    isShowLabel = false,
    label,
    onEdit,
  } = props

  const handleSelect = useCallback(() => {
    onSelect()
  }, [onSelect])

  const handleRemove = useCallback(() => {
    if (onRemove) onRemove()
  }, [onRemove])

  const handleClickEdit = useCallback(() => {
    onEdit()
  }, [onEdit])

  return (
    <CustomCard>
      <IconContainer>
        {isShowLabel && (
          <Txt ag="body18" color="lightBluePrimary">
            {label}
          </Txt>
        )}

        {isSelected ? (
          <SelectIcon selected={isSelected} can-select="no" icon={faCheckCircle} />
        ) : (
          <SelectIcon
            selected={false}
            can-select={canSelect ? 'yes' : 'no'}
            onClick={canSelect ? handleSelect : undefined}
            icon={faCircle}
          />
        )}
      </IconContainer>

      {onRemove && <RemoveIcon size="sm" onClick={handleRemove} icon={faTrash} />}

      <EditIconContainer>
        <EditIcon size="sm" onClick={handleClickEdit} color={theme.color.lightBluePrimary} icon={faPen} />
      </EditIconContainer>

      <Row style={{ minHeight: 30 }}>
        <Txt ag="header28" color="lightBluePrimary">
          {isEmpty(fullname) ? '-' : fullname}
        </Txt>
      </Row>
      <Row style={{ minHeight: 30 }}>
        <Txt ag="body20">{isEmpty(address) ? '-' : address}</Txt>
      </Row>
      <Row>
        <Txt ag="body20">เลขประจำตัวผู้เสียภาษี : {tax || '-'}</Txt>
      </Row>
    </CustomCard>
  )
}

const Container = styled('div')``

const AddressItemsContainer = styled('div')``

const ButtonContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
`

const CustomButton = styled(Button)`
  width: 100%;
  max-width: 343px;
  margin-bottom: 14px;
`

interface AddressSelectorProps {
  isShowLabel?: boolean
  canSelect?: boolean
  onSelect: (address: IGetAddrResponse) => void
  onClickAdd: () => void
  onClose: () => void
  onRemove?: (id: number) => void
  addresses: IGetAddrResponse[]
  currentSelected?: IGetAddrResponse
  checkboxLabel?: string
  onClickEdit: (address: IGetAddrResponse) => void
}

export const AddressSelector = (props: AddressSelectorProps) => {
  const {
    onSelect,
    onClose,
    onClickAdd,
    addresses,
    currentSelected,
    canSelect = true,
    onRemove,
    isShowLabel,
    checkboxLabel,
    onClickEdit,
  } = props

  const handleSelect = useCallback(
    (address: IGetAddrResponse) => {
      if (canSelect) {
        onSelect(address)
      }
    },
    [onSelect, canSelect],
  )

  const handleClickAdd = useCallback(() => {
    onClickAdd()
  }, [onClickAdd])

  const handleClickEdit = useCallback(
    (address: IGetAddrResponse) => {
      onClickEdit(address)
    },
    [onClickEdit],
  )

  const handleClose = useCallback(() => {
    onClose()
  }, [onClose])

  const handleRemove = useCallback(
    (id: number) => {
      if (onRemove) onRemove(id)
    },
    [onRemove],
  )

  return (
    <Container>
      <AddressItemsContainer>
        {addresses.map((address) => (
          <AddressItem
            key={address.id}
            label={checkboxLabel}
            isShowLabel={isShowLabel}
            onRemove={onRemove ? () => handleRemove(address.id) : undefined}
            canSelect={canSelect}
            isSelected={address.id === currentSelected?.id}
            onSelect={() => {
              handleSelect(address)
            }}
            onEdit={() => handleClickEdit(address)}
            {...address}
          />
        ))}
      </AddressItemsContainer>
      <ButtonContainer>
        <CustomButton onClick={handleClickAdd} type="primary">
          + Add
        </CustomButton>
        <CustomButton onClick={handleClose}>Close</CustomButton>
      </ButtonContainer>
    </Container>
  )
}
