import { useMutation, useQuery, useQueryClient, UseQueryOptions } from '@tanstack/react-query'

import { FileHelper } from 'helpers/file-helper'
import { GET_USER_INFO_PATH } from 'services/auth/auth-service'
import { ContentType } from 'services/http-client/base-http-client'
import { encoClient } from 'services/http-client/enco-client'
import { MY_ORDER_PATH } from 'services/order-enco-service/order-enco-service-service'

import { ResponseType } from '../response-type'

import {
  ICancelOrderParams,
  IDownloadOrderFullReceiptParams,
  IGetOrderCalendarParams,
  IGetOrderResponseParams,
  IMutationOtherServiceOrderParams,
  ISaveServiceOrderParams,
} from './order-response-service-param'
import {
  IMutationSetServiceRecNoResponse,
  IOrderReceiptResponse,
  ISearchOrderResponse,
  IServiceTimeResponse,
} from './order-response-service-response'

const SEARCH_ORDER_RESPONSE_PATH = '/SearchOrderResponse'
const ORDER_RESPONSE_PATH = '/OrderResponse'
const GET_ORDER_CALENDAR_PATH = '/OrderCalendar'
const GET_MASTER_SERVICE_TIME_PATH = '/GetMasterServiceTime'
const DOWNLOAD_ORDER_RECEIPT_PATH = '/GetReceipt'
const DOWNLOAD_ORDER_FULL_RECEIPT_PATH = '/GetFullReceipt'
const CANCEL_ORDER_PATH = '/CancelOrder'

export const useSearchOrderResponseQRY = (params: IGetOrderResponseParams) => {
  return useQuery(
    [SEARCH_ORDER_RESPONSE_PATH, params],
    async () => {
      const response = await encoClient.post<ResponseType<ISearchOrderResponse[]>>(
        `${SEARCH_ORDER_RESPONSE_PATH}`,
        params,
        {
          headers: {
            'Content-Type': ContentType.FORM_DATA,
          },
        },
      )
      return response.data.data
    },
    {
      enabled: !!params.ServiceId,
    },
  )
}

export const useGetServiceOrderQRY = (orderId?: number) => {
  return useQuery(
    [ORDER_RESPONSE_PATH, { orderId }],
    async () => {
      const response = await encoClient.get<ResponseType<ISearchOrderResponse>>(ORDER_RESPONSE_PATH, {
        params: {
          id: orderId,
        },
      })
      return response.data.data
    },
    {
      enabled: !!orderId,
    },
  )
}

export const useMutationOtherServiceOrderMTT = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async (params: IMutationOtherServiceOrderParams) => {
      const response = await encoClient.post<ResponseType<IMutationSetServiceRecNoResponse>>(
        ORDER_RESPONSE_PATH,
        params,
        {
          headers: {
            'Content-Type': ContentType.FORM_DATA,
          },
        },
      )

      return response.data.data
    },
    {
      onSuccess: (response, params) => {
        const { id: orderId } = params
        queryClient.invalidateQueries([ORDER_RESPONSE_PATH, { orderId }])
        queryClient.invalidateQueries([SEARCH_ORDER_RESPONSE_PATH])
      },
    },
  )
}

export const useGetOrderCalendarQRY = (
  params: IGetOrderCalendarParams,
  options?: UseQueryOptions<ISearchOrderResponse[]>,
) => {
  return useQuery<ISearchOrderResponse[]>(
    [GET_ORDER_CALENDAR_PATH, params],
    async () => {
      const res = await encoClient.post<ResponseType<ISearchOrderResponse[]>>(GET_ORDER_CALENDAR_PATH, params, {
        headers: {
          'Content-Type': ContentType.FORM_DATA,
        },
      })
      return res.data.data
    },
    options,
  )
}
export const useGetMasterServiceTimeQRY = () => {
  return useQuery([GET_MASTER_SERVICE_TIME_PATH], async () => {
    const res = await encoClient.get<ResponseType<IServiceTimeResponse[]>>(GET_MASTER_SERVICE_TIME_PATH)
    return res.data.data
  })
}

export const useSaveServiceOrderMTT = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async (params: ISaveServiceOrderParams) => {
      const response = await encoClient.post<ResponseType<ISearchOrderResponse>>(ORDER_RESPONSE_PATH, params, {
        headers: {
          'Content-Type': ContentType.FORM_DATA,
        },
      })

      return response.data.data
    },
    {
      onSuccess: (response, params) => {
        const { id: orderId } = params
        queryClient.removeQueries([ORDER_RESPONSE_PATH, { orderId }])
        queryClient.invalidateQueries([SEARCH_ORDER_RESPONSE_PATH])
      },
    },
  )
}

export const useCancelOrderMTT = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async (param: ICancelOrderParams) => {
      const res = await encoClient.post<ResponseType<ISearchOrderResponse>>(CANCEL_ORDER_PATH, param)
      return res.data.data
    },
    {
      onSuccess: (_, params) => {
        const { id: orderId } = params
        queryClient.invalidateQueries([ORDER_RESPONSE_PATH, { orderId: orderId }])
        queryClient.invalidateQueries([MY_ORDER_PATH])
        queryClient.invalidateQueries([GET_USER_INFO_PATH])
      },
    },
  )
}

export const useGetOrderReceiptInfoQry = (orderId?: number) => {
  return useQuery(
    [SEARCH_ORDER_RESPONSE_PATH, orderId],
    async () => {
      const response = await encoClient.post<ResponseType<IOrderReceiptResponse>>(DOWNLOAD_ORDER_RECEIPT_PATH, {
        tbOrderId: orderId,
      })
      return response.data.data
    },
    {
      enabled: !!orderId,
    },
  )
}

export const downloadReceiptFile = async (receiptData: IOrderReceiptResponse) => {
  let base64String = null
  let base64Response = null
  let fileName = ''

  if (receiptData.receiptFileImgBaseString) {
    base64String = receiptData.receiptFileImgBaseString
    base64Response = await fetch(`data:image/jpeg;base64,${base64String}`)
    fileName = `${receiptData.receiptNo}.jpeg`
  } else if (receiptData.receiptFilePDFBaseString) {
    base64String = receiptData.receiptFilePDFBaseString
    base64Response = await fetch(`data:application/pdf;base64,${base64String}`)
    fileName = `${receiptData.receiptNo}.pdf`
  }

  if (base64Response) {
    const blob = await base64Response.blob()
    const blobUrl = URL.createObjectURL(blob)
    FileHelper.download(blobUrl, fileName)
  }
}

export const useDownloadOrderReceiptMTT = () => {
  return useMutation(async (orderId: number) => {
    const res = await encoClient.post<ResponseType<IOrderReceiptResponse>>(DOWNLOAD_ORDER_RECEIPT_PATH, {
      tbOrderId: orderId,
    })
    const receiptData = res.data.data
    downloadReceiptFile(receiptData)
  })
}

export const useDownloadOrderFullReceiptMTT = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async (params: IDownloadOrderFullReceiptParams) => {
      const res = await encoClient.post<ResponseType<IOrderReceiptResponse>>(DOWNLOAD_ORDER_FULL_RECEIPT_PATH, params)
      const receiptData = res.data.data
      downloadReceiptFile(receiptData)
    },
    {
      onSuccess: (_, params) => {
        const { tbOrderId: orderId } = params
        queryClient.invalidateQueries([ORDER_RESPONSE_PATH, { orderId }])
      },
    },
  )
}

export const useGetOrderReceiptQRY = () => {
  return useMutation(async (orderId: number) => {
    const response = await encoClient.post<ResponseType<IOrderReceiptResponse>>(
      DOWNLOAD_ORDER_RECEIPT_PATH,
      {
        tbOrderId: orderId,
      },
      {
        headers: { 'Content-Type': ContentType.FORM_DATA },
      },
    )
    return response.data.data
  })
}
