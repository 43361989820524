import { Col, Form, Row } from 'antd'

import { Input } from 'components/Input'
import { Txt } from 'components/Txt'
import { ISearchOrderResponse } from 'services/order-response/order-response-service-response'

export const FieldsOzoneCustomerInfo = (props: { order: ISearchOrderResponse }) => {
  const { order } = props

  return (
    <Row gutter={24}>
      <Col span={24}>
        <Txt ag="header30">ข้อมูลลูกค้า</Txt>
      </Col>
      <Col span={24} sm={{ span: 12 }}>
        <Form.Item name="firstname" label="ชื่อลูกค้า">
          <Input disabled value={`${order.firstname} ${order.lastname}`} />
        </Form.Item>
      </Col>
      <Col span={24} sm={{ span: 12 }}>
        <Form.Item name="tel" label="เบอร์โทรศัพท์">
          <Input disabled />
        </Form.Item>
      </Col>
      <Col span={24} sm={{ span: 12 }}>
        <Form.Item name="email" label="อีเมล">
          <Input disabled />
        </Form.Item>
      </Col>
      <Col span={24} sm={{ span: 12 }}>
        <Form.Item name="company" label="ชื่อบริษัท">
          <Input disabled />
        </Form.Item>
      </Col>
    </Row>
  )
}
