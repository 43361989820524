import { Divider, Pagination, Spin } from 'antd'
import { styled } from 'goober'
import { chunk, get } from 'lodash'
import { Fragment, useCallback, useEffect, useMemo } from 'react'

import { Txt } from 'components/Txt'
import { LayoutCardPage } from 'components/layout/LayoutCardPage'
import { usePaginateState } from 'helpers/paginate-helper'
import { mediaQuery } from 'helpers/responsive-helper'
import { useGetMyOrderQRY } from 'services/order-enco-service/order-enco-service-service'
import theme from 'theme/goober'

import { MyOrder } from './MyOrder'

const ContentContainer = styled('div')`
  position: relative;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
  max-width: 1600px;
  min-height: 200px;
  flex-flow: column;

  border-radius: 10px;
  border: 1px solid ${theme.color.grayBackground};
  overflow-x: hidden;

  .empty-text {
    text-align: center;
  }

  > * {
    &.divider {
      border-bottom-width: 0px !important;
    }
    &.wrapper {
      padding: 18px 20px;
      padding-right: 16px;
      box-sizing: border-box;
    }
  }
`

const PaginationStyled = styled(Pagination)`
  text-align: right;
  padding: 0 8px 8px 0;

  @media ${mediaQuery.xsOnly} {
    text-align: center;
    padding: 0;
  }
`
const SpinBackdrop = styled('div')<{ show: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.2);
  pointer-events: none;
  pointer-events: ${({ show }) => (show ? 'painted' : 'none')};
  z-index: 100;
  opacity: ${({ show }) => (show ? '1' : '0')};

  transition-property: opacity;
  transition-duration: 0.6s;
  transition-delay: 0.1s;
`

const SpinOverlay = styled('div')<{ show: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 8px;
  opacity: ${({ show }) => (show ? '1' : '0')};

  transition-property: opacity;
  animation-duration: 0.7s;
`

export const PageMyOrder = () => {
  const { page, pageSize, onPageChange, onPageSizeChange } = usePaginateState(1, 10)
  const { data: myOrders = [], isLoading, isFetching } = useGetMyOrderQRY()
  const customMyOrderResponse = useMemo(() => {
    const pageNotLessThanOne = Math.max(page, 1)

    const items = chunk(myOrders, pageSize)

    const totalItem = myOrders.length
    const totalPage = items.length

    return {
      items: items[pageNotLessThanOne - 1],
      page,
      pageSize,
      totalPage,
      totalItem,
    }
  }, [myOrders, page, pageSize])

  const myOrdersPage = get(customMyOrderResponse, 'items', [])
  const totalItem = get(customMyOrderResponse, 'totalItem', 0)

  const handlePageChange = useCallback(
    (newPage: number, newPageSize: number) => {
      onPageChange(newPage)
      onPageSizeChange(newPageSize)
    },
    [onPageChange, onPageSizeChange],
  )

  const renderContent = useCallback(() => {
    if (isLoading) {
      return
    }
    if (!myOrdersPage?.length) {
      return (
        <Txt className="empty-text" ag="body24" color="grayBorder">
          ไม่มีข้อมูล
        </Txt>
      )
    }
    return myOrdersPage?.map((myOrder) => (
      <Fragment key={myOrder.id}>
        <div className="wrapper">
          <MyOrder data={myOrder} />
        </div>
        <Divider className="divider" />
      </Fragment>
    ))
  }, [isLoading, myOrdersPage])

  useEffect(() => {
    window.scroll({
      top: 0,
      behavior: 'smooth',
    })
  }, [page])

  return (
    <LayoutCardPage title="My Order">
      <SpinOverlay show={isFetching || isLoading}>
        <Spin />
      </SpinOverlay>
      <ContentContainer>
        <SpinBackdrop show={isFetching || isLoading} />
        {renderContent()}
        {!!totalItem && (
          <PaginationStyled
            total={totalItem}
            showSizeChanger={false}
            current={page}
            pageSize={pageSize}
            onChange={handlePageChange}
            responsive
          />
        )}
      </ContentContainer>
    </LayoutCardPage>
  )
}
