import { Col, Form, Row } from 'antd'

import DatePicker from 'components/DatePicker'

export const WikiDatePicker = () => {
  return (
    <>
      <DatePicker />
      <Form layout="vertical">
        <Row>
          <Col span={24}>
            <Form.Item label="date-picker" wrapperCol={{ span: 12 }} required>
              <DatePicker />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <div style={{ textAlign: 'start', marginTop: 16, whiteSpace: 'pre' }}>{codeText}</div>
    </>
  )
}

const codeText = `import { Col, Form, Row } from 'antd'

import DatePicker from 'components/DatePicker'

export const WikiDatePicker = () => {
  return (
    <>
      <DatePicker />
      <Form layout="vertical">
        <Row>
          <Col span={24}>
            <Form.Item label="date-picker" wrapperCol={{ span: 12 }} required>
              <DatePicker />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  )
}`
