import { sanitizeUrl } from '@braintree/sanitize-url'
import { Button, Radio, Space, Table } from 'antd'
import { ColumnType } from 'antd/lib/table'
import { useCallback, useMemo, useState } from 'react'
import { FaEye } from 'react-icons/fa'
import { Link, useNavigate } from 'react-router-dom'

import { SearchInput } from '_backend/components/SearchInput'
import { ContentBody } from '_backend/components/layout/ContentBody'
import { ContentHeader } from '_backend/components/layout/ContentHeader'
import { Txt } from 'components/Txt'
import { getColumnNumberByPage } from 'helpers/column-helper'
import { backendPaths } from 'paths/backend-path-config'
import { IInvoiceGetListResponse } from 'services/sale/invoice/invoice-response'
import { useInvoiceGetListQRY } from 'services/sale/invoice/invoice-service'
import { IQuotationGetListResponse } from 'services/sale/quotation/quotation-response'
import { useQuotationGetListQRY } from 'services/sale/quotation/quotation-service'

import { ModalSaleCreateOption } from '../ModalSaleCreateOption'
import { TooltipSaleNo } from '../TooltipSaleNo'

export const PageInvoiceList = () => {
  const [txtSearch, setTxtSearch] = useState('')
  const { data: dataSource = [], isFetching } = useInvoiceGetListQRY({ txtSearch })
  const [currentPage, setCurrentPage] = useState(1)
  const [currentPageSize, setCurrentPageSize] = useState(10)
  const [modalCreateOptionVisible, setModalCreateOptionVisible] = useState(false)
  const { data: createOptionDataSource = [] } = useQuotationGetListQRY(
    { mdQuotaSt: 1 },
    { enabled: modalCreateOptionVisible },
  )
  const navigate = useNavigate()

  const handlePageChange = useCallback((page: number, pageSize: number) => {
    setCurrentPage(page)
    setCurrentPageSize(pageSize)
  }, [])
  const handleSearch = useCallback((value: string) => {
    setTxtSearch(value)
  }, [])
  const handleClickCreate = useCallback(() => {
    setModalCreateOptionVisible(true)
  }, [])
  const handleDismissCreateOptionModal = useCallback(() => {
    setModalCreateOptionVisible(false)
  }, [])
  const handleSubmitCreateOptionModal = useCallback(
    (quotationId: number) => {
      navigate(backendPaths.invoiceNew({ queryParam: { quotationId } }))
    },
    [navigate],
  )

  const columns = useMemo(
    (): ColumnType<IInvoiceGetListResponse>[] => [
      {
        title: 'ลำดับ',
        width: '50px',
        align: 'center',
        render: (_: any, record: IInvoiceGetListResponse, index: number) => (
          <Txt ag="body20">{getColumnNumberByPage(currentPage, index, currentPageSize)}</Txt>
        ),
      },
      {
        title: 'Invoice No.',
        width: '120px',
        dataIndex: 'invoiceNo',
        render: (invoiceNo: string, record: IInvoiceGetListResponse, index: number) => (
          <TooltipSaleNo saleNo={invoiceNo} attention={record.quotaAttention} totalPrice={record.total} />
        ),
      },
      {
        title: 'Invoice Datetime',
        width: '120px',
        dataIndex: 'invoiceDatedisplay',
      },
      {
        title: 'Quotation No.',
        width: '120px',
        dataIndex: 'quotaNo',
      },
      {
        title: 'Quotation Datetime',
        width: '120px',
        dataIndex: 'quodatedisplay',
      },
      {
        title: 'Order No.',
        width: '120px',
        dataIndex: 'orderNo',
      },
      {
        title: 'Order Datetime',
        width: '120px',
        dataIndex: 'orderDatedisplay',
      },
      {
        title: 'Status',
        width: '100px',
        dataIndex: 'mdQuotaStName',
      },
      {
        title: 'Modify Date',
        width: '120px',
        dataIndex: 'modifydateNm',
      },
      {
        title: '',
        dataIndex: 'action',
        width: '100px',
        className: 'text-center',
        render: (_: any, record: IInvoiceGetListResponse) => {
          const { id } = record
          const invoiceEditPath = sanitizeUrl(backendPaths.invoiceEdit({ routeParam: { invoiceId: id } }))
          return (
            <Link to={invoiceEditPath}>
              <FaEye />
            </Link>
          )
        },
      },
    ],
    [currentPage, currentPageSize],
  )

  const createOptionColumns = useMemo(
    () => [
      {
        title: <span style={{ marginLeft: '32px' }}>Quotation No.</span>,
        width: '50px',
        render: (_: any, record: IQuotationGetListResponse, index: number) => (
          <Radio value={record.id}>
            <span style={{ paddingLeft: '8px' }}>{record.quotaNo}</span>
          </Radio>
        ),
      },
      {
        title: 'Quotation Datetime',
        width: '120px',
        dataIndex: 'quodatedisplay',
      },
    ],
    [],
  )
  return (
    <>
      <ContentHeader title="Sale" subTitle="Invoice">
        <Space size={['large', 'small']} wrap style={{ justifyContent: 'start' }}>
          <SearchInput placeholder="ค้นหา Invoice" onSearch={handleSearch} />
          <Button type="primary" onClick={handleClickCreate}>
            สร้างใบ Invoice
          </Button>
        </Space>
      </ContentHeader>
      <ContentBody>
        <Table
          scroll={{ x: '100%' }}
          rowKey="id"
          dataSource={dataSource}
          columns={columns}
          loading={isFetching}
          pagination={{
            showSizeChanger: true,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
            onChange: handlePageChange,
            current: currentPage,
          }}
        />
      </ContentBody>
      <ModalSaleCreateOption
        title="เลือกเลข Quotation ที่คุณต้องการสร้างใบ Invoice"
        columns={createOptionColumns}
        dataSource={createOptionDataSource}
        visible={modalCreateOptionVisible}
        onDismiss={handleDismissCreateOptionModal}
        onSubmit={handleSubmitCreateOptionModal}
      />
    </>
  )
}
