import { useMutation, useQuery, useQueryClient, UseQueryOptions } from '@tanstack/react-query'

import { ContentType } from 'services/http-client/base-http-client'
import { encoClient } from 'services/http-client/enco-client'
import { ResponseType } from 'services/response-type'

import { IQuotationGetListParams, IQuotationSaveParams, IQuotationSearchOrderParams } from './quotation-param'
import { IGetQuotationResponse, IQuotationGetListResponse, IQuotationSearchOrderResponse } from './quotation-response'

const QUOTATION_GET_LIST_PATH = '/SearchQuotation'
const QUOTATION_SEARCH_ORDER_PATH = '/SearchOrderQuotation'
const QUOTATION_SAVE_PATH = '/SaveQuotation'
const GET_QUOTATION_PATH = '/GetQuotation'

export const useQuotationGetListQRY = (
  param: IQuotationGetListParams,
  options?: UseQueryOptions<IQuotationGetListResponse[]>,
) => {
  return useQuery<IQuotationGetListResponse[]>(
    [QUOTATION_GET_LIST_PATH, param],
    async () => {
      const res = await encoClient.post<ResponseType<IQuotationGetListResponse[]>>(QUOTATION_GET_LIST_PATH, param)
      return res.data.data || []
    },
    options,
  )
}

export const useQuotationSearchOrderQRY = (
  params?: IQuotationSearchOrderParams,
  options?: UseQueryOptions<IQuotationSearchOrderResponse[]>,
) => {
  return useQuery<IQuotationSearchOrderResponse[]>(
    [QUOTATION_SEARCH_ORDER_PATH, params],
    async () => {
      const response = await encoClient.post<ResponseType<IQuotationSearchOrderResponse[]>>(
        QUOTATION_SEARCH_ORDER_PATH,
        params,
      )
      return response.data.data || []
    },
    options,
  )
}

export const useQuotationSaveMTT = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async (data: IQuotationSaveParams) => {
      const res = await encoClient.post<ResponseType<IQuotationGetListResponse>>(QUOTATION_SAVE_PATH, data, {
        headers: { 'Content-Type': ContentType.JSON },
      })
      return res.data.data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QUOTATION_GET_LIST_PATH])
      },
    },
  )
}

export const useGetQuotationDetailQRY = (id?: number) => {
  const params = { id }
  return useQuery(
    [GET_QUOTATION_PATH, params],
    async () => {
      const res = await encoClient.get<ResponseType<IGetQuotationResponse[]>>(GET_QUOTATION_PATH, { params })
      return res.data.data[0]
    },
    {
      enabled: !!id,
    },
  )
}

export const useQuotationDeleteMTT = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async (id: number) => {
      const res = await encoClient.delete<ResponseType<null>>(QUOTATION_SAVE_PATH, {
        data: { id },
      })
      return res.data.data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([QUOTATION_GET_LIST_PATH])
      },
    },
  )
}
