import { Card, Col, DatePicker, Form, Input, Radio, Row } from 'antd'

import { REQUIRED_RULES } from '_backend/constants/form-rules'
import { TextArea } from 'components/Input'
import NumberInput from 'components/NumberInput'
import { Txt } from 'components/Txt'

import { TextSaleNo } from '../TextSaleNo'
import { discountTypeOptions, vatTypeOptions } from '../sale-constants'

interface ISectionQuotationFieldsProps {
  readonly?: boolean
  quotationNo?: string
}

export const SectionQuotationFields = (props: ISectionQuotationFieldsProps) => {
  const { readonly = false, quotationNo = '-' } = props
  return (
    <Card style={{ marginBottom: 8 }}>
      <Row gutter={24}>
        <Col span={24}>
          <Txt ag="body20">เลขที่ใบเสนอราคา (Quotation No.)</Txt>
        </Col>
        <Col span={24} style={{ marginBottom: '16px' }}>
          <TextSaleNo no={quotationNo} />
          <Form.Item name="id" hidden>
            <NumberInput />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={24} md={{ span: 12 }}>
          <Form.Item name="quotaDate" label="วันที่ออกใบเสนอราคา (Quotation Datetime)" required rules={REQUIRED_RULES}>
            <DatePicker disabled={readonly} />
          </Form.Item>
        </Col>
        <Col span={24} md={{ span: 6 }}>
          <Form.Item name="vatType" label="การคำนวณภาษี (VAT)" required rules={REQUIRED_RULES}>
            <Radio.Group options={vatTypeOptions} disabled={readonly} />
          </Form.Item>
        </Col>
        <Col span={24} md={{ span: 6 }}>
          <Form.Item name="discountType" label="ส่วนลด (Discount)" required rules={REQUIRED_RULES}>
            <Radio.Group options={discountTypeOptions} disabled={readonly} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={24} sm={{ span: 12 }}>
          <Form.Item name="serviceNm" label="ชื่อบริการ (Service Name)">
            <Input disabled />
          </Form.Item>
        </Col>
        <Col span={24} sm={{ span: 12 }}>
          <Form.Item name="orderTypeNm" label="ประเภทคำสั่งซื้อ (Order Type)">
            <Input disabled />
          </Form.Item>
        </Col>
        <Col span={24} sm={{ span: 12 }}>
          <Form.Item name="orderNo" label="เลข Order (Order No.)">
            <Input disabled />
          </Form.Item>
        </Col>
        <Col span={24} sm={{ span: 12 }}>
          <Form.Item name="orderDateTimeNm" label="วันที่ออกเลข Order (Order Datetime)">
            <Input disabled />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item name="remark" label="หมายเหตุ">
            <TextArea rows={4} disabled={readonly} />
          </Form.Item>
        </Col>
      </Row>
    </Card>
  )
}
