import { Button, ButtonProps, Row } from 'antd'
import dayjs from 'dayjs'
import { styled } from 'goober'
import { groupBy } from 'lodash'
import { useCallback, useMemo } from 'react'
import { FaRegCheckCircle } from 'react-icons/fa'

import { Txt } from 'components/Txt'
import { useResponsive } from 'helpers/responsive-helper'
import { randomId } from 'helpers/string-helper'
import { IGetServiceSlotSelectResponse } from 'services/enco-service/enco-service-response'
import theme from 'theme/goober'

export interface IOzoneSlotItem extends IGetServiceSlotSelectResponse {}

interface IOzoneSlotSelectorProps {
  options?: IOzoneSlotItem[]
  value?: IOzoneSlotItem[]
  onChange?: (items: IOzoneSlotItem[]) => void
}

enum StateEnum {
  Selected = 'selected',
  Disabled = 'disabled',
  Unselected = 'unselected',
}

const ContentLayout = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: auto 1px auto;
  /* overflow: auto; */
  max-height: 300px;
  align-items: center;
  max-width: 400px;
`
const GridCol = styled.div`
  width: 100%;
  height: 100%;
  border-bottom: 1px solid #f5f5f5;
  padding: 6px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
`
const getStateColor = (state: StateEnum) => {
  switch (state) {
    case StateEnum.Selected:
      return `background: ${theme.color.lightBluePrimary};
      color: white;
      &&.ant-btn[disabled]:hover {
        background: ${theme.color.lightBluePrimary};
        border-color: ${theme.color.lightBluePrimary};
        opacity: 1;
        color: white;
      }
      `
    case StateEnum.Disabled:
      return `background: ${theme.color.gray};
      color: white;
      border-color: ${theme.color.gray};
      &&:hover {
        background: ${theme.color.gray};
        border-color: ${theme.color.gray};
        opacity: 1;
        color: white;
      }
    `
    case StateEnum.Unselected:
      return `&&:hover {
        background: ${theme.color.grayBackground};
        border-color: ${theme.color.lightBluePrimary};
        opacity: 1;
        color: ${theme.color.lightBluePrimary};
      }`
    default:
      return ''
  }
}
const ItemLayout = styled<{}, ButtonProps & { state: StateEnum }>(Button)`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 36px;
    border: 2px solid ${theme.color.lightBluePrimary};
    border-radius: 30px;
    color: ${theme.color.lightBluePrimary};
    margin: auto;
    min-width: 190px;
    &&:hover {
      opacity: 0.5;
    }
    ${({ state }) => getStateColor(state)}
  }
`
const VerticalLine = styled('div')`
  height: 40px;
  width: 1px;
  background-color: ${theme.color.lightBluePrimary};
`
const CheckedIcon = styled(FaRegCheckCircle)`
  left: 8px;
  position: absolute;
  color: ${theme.color.white};
  cursor: pointer;
  font-size: 18px;
  margin-right: 12px;
`
const DisabledContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background-color: ${theme.color.grayBackground};
  height: 300px;
`

export const OzoneSlotSelector = (props: IOzoneSlotSelectorProps) => {
  const { options, value = [], onChange } = props
  const { isXs } = useResponsive()

  const valueGroup = useMemo(() => {
    return groupBy(value, (v) => v.mdSlotId)
  }, [value])

  const handleOnChange = useCallback(
    (item: IOzoneSlotItem) => {
      const { useSlotQty: min = 0, slotQty: max = 0, mdSlotId } = item
      const itemCount = valueGroup[mdSlotId]?.length || 0
      if (itemCount + min < max) {
        onChange?.([...value, item])
      }
    },
    [onChange, value, valueGroup],
  )

  return options ? (
    <Row style={{ border: '1px solid #f5f5f5', overflow: 'auto' }}>
      <ContentLayout>
        {options?.map((item) => {
          const { useSlotQty: min = 0, slotQty: max = 0, mdSlotId, slotStatus } = item
          const { slotIdStart: time, txtDisplay } = item
          const itemCount = valueGroup[mdSlotId]?.length || 0
          const disabled = slotStatus === 0
          let state
          if (disabled) {
            state = StateEnum.Disabled
          } else if (itemCount > 0) {
            state = StateEnum.Selected
          } else {
            state = StateEnum.Unselected
          }

          return [
            <GridCol key={randomId()}>{dayjs(time, 'HH:mm:ss').format('HH:mm')}</GridCol>,
            <div key={randomId()}>
              <VerticalLine />
            </div>,
            <GridCol key={randomId()}>
              <ItemLayout
                state={state}
                onClick={handleOnChange.bind(null, item)}
                disabled={disabled || itemCount + min === max}
              >
                {state === StateEnum.Selected && !isXs && <CheckedIcon />}
                {txtDisplay}
              </ItemLayout>
            </GridCol>,
          ]
        })}
      </ContentLayout>
    </Row>
  ) : (
    <DisabledContainer>
      <Txt ag="header28" color="grayPlaceholder">
        กรุณาเลือกช่วงเวลา
      </Txt>
    </DisabledContainer>
  )
}
