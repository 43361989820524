import { Col, Form, notification, Row, Space } from 'antd'
import { styled } from 'goober'
import { PropsWithChildren, useCallback, useEffect, useState } from 'react'

import { EMAIL_FORMAT_RULES, REQUIRED_RULES } from '_backend/constants/form-rules'
import { AppButton } from 'components/AppButton'
import { FormModal } from 'components/FormModal'
import { Input } from 'components/Input'
import { ISaveServiceRequestParams } from 'services/enco-service/enco-service-param'
import { useSavServiceRequestMTT } from 'services/enco-service/enco-service-service'

interface IButtonServiceSubmitProps extends PropsWithChildren {
  modalTitle: string
  serviceId: number
  mdOrderTypeId?: number
}

const ModalBody = styled('div')`
  width: 100%;
`

const FieldsRow = styled(Row)``

export const ButtonServiceRequest = (props: IButtonServiceSubmitProps) => {
  const { children, modalTitle, serviceId, mdOrderTypeId } = props
  const [modalVisible, setModalVisible] = useState(false)
  const [form] = Form.useForm()
  const { mutateAsync: saveRequest } = useSavServiceRequestMTT()

  useEffect(() => {
    form.resetFields()
  }, [form, modalVisible])

  const handleCancel = useCallback(() => {
    setModalVisible(false)
  }, [])
  const handleClickButton = useCallback(() => {
    setModalVisible(true)
  }, [])
  const handleFinish = useCallback(
    async (values: ISaveServiceRequestParams) => {
      await saveRequest({ ...values, id: 0, tbServiceId: serviceId, mdOrderTypeId })
      setModalVisible(false)
      notification.success({ message: 'สำเร็จ', description: 'บันทึกข้อมูลสำเร็จ', duration: 2 })
    },
    [mdOrderTypeId, saveRequest, serviceId],
  )

  return (
    <>
      <AppButton type="primary" className="w-300" onClick={handleClickButton}>
        {children}
      </AppButton>
      <FormModal title={modalTitle} visible={modalVisible} onCancel={handleCancel} width={700}>
        <ModalBody>
          <Form className="w-100" layout="vertical" form={form} onFinish={handleFinish}>
            <FieldsRow>
              <Col span={21} offset={1} sm={{ span: 16, offset: 4 }}>
                <Form.Item name="firstname" label="First Name" required rules={REQUIRED_RULES}>
                  <Input placeholder="First Name" />
                </Form.Item>
              </Col>
              <Col span={21} offset={1} sm={{ span: 16, offset: 4 }}>
                <Form.Item name="lastname" label="Last Name" required rules={REQUIRED_RULES}>
                  <Input placeholder="Last Name" />
                </Form.Item>
              </Col>
              <Col span={21} offset={1} sm={{ span: 16, offset: 4 }}>
                <Form.Item name="email" label="Email" required rules={[...REQUIRED_RULES, ...EMAIL_FORMAT_RULES]}>
                  <Input placeholder="Email" />
                </Form.Item>
              </Col>
              <Col span={21} offset={1} sm={{ span: 16, offset: 4 }}>
                <Form.Item name="tel" label="Phone Number" required rules={REQUIRED_RULES}>
                  <Input placeholder="Phone Number" />
                </Form.Item>
              </Col>
              {mdOrderTypeId === 3 && (
                <Col span={21} offset={1} sm={{ span: 16, offset: 4 }}>
                  <Form.Item name="company" label="Company" required rules={REQUIRED_RULES}>
                    <Input placeholder="Company" />
                  </Form.Item>
                </Col>
              )}
            </FieldsRow>
            <Row style={{ marginTop: '40px' }}>
              <Col span={24}>
                <Space direction="vertical" align="center" size="middle" className="w-100">
                  <Form.Item noStyle required rules={REQUIRED_RULES}>
                    <AppButton htmlType="submit">Save</AppButton>
                  </Form.Item>
                  <AppButton type="default" onClick={() => setModalVisible(false)}>
                    Close
                  </AppButton>
                </Space>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </FormModal>
    </>
  )
}
