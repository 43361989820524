import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import { ContentType } from 'services/http-client/base-http-client'
import { encoClient } from 'services/http-client/enco-client'
import { ResponseType } from 'services/response-type'

import {
  IPostUserGrpMenuParams,
  IGetUserGrpParams,
  IPostUserGrpParams,
  IGetUserGrpMenuParams,
} from './permission-setting-param'
import {
  IGetMasterUserGrp,
  IGetUserGrpMenuResponse,
  IGetUserGrpResponse,
  IPostUserGrpResponse,
} from './permission-setting-response'

const USER_GRP = '/UserGrp'
const USER_GRP_MENU = '/UserGrpMenu'
const GET_MASTER_USER_GRP = '/GetListUserGrp'
const SAVE_USER_GRP_MENU = '/SaveUserGrpMenu'

export const useUserGrpQRY = (params: IGetUserGrpParams) => {
  return useQuery([USER_GRP, params], async () => {
    const res = await encoClient.get<ResponseType<IGetUserGrpResponse>>(USER_GRP, { params })
    return res.data.data
  })
}

export const useUserGrpMTT = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async (params: IPostUserGrpParams) => {
      const res = await encoClient.post<ResponseType<IPostUserGrpResponse>>(USER_GRP, params)
      return res.data.data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([USER_GRP])
        queryClient.invalidateQueries([USER_GRP_MENU])
      },
    },
  )
}

export const useUserGrpMenuQRY = (params: IGetUserGrpMenuParams) => {
  return useQuery([USER_GRP_MENU, params], async () => {
    const res = await encoClient.get<ResponseType<IGetUserGrpMenuResponse[]>>(USER_GRP_MENU, { params })
    return res.data.data
  })
}

export const useSaveUserGrpMenuMTT = () => {
  const queryClient = useQueryClient()
  return useMutation(
    (params: IPostUserGrpMenuParams) => {
      return encoClient.post(SAVE_USER_GRP_MENU, params, {
        headers: {
          'Content-Type': ContentType.JSON,
        },
      })
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([USER_GRP])
        queryClient.invalidateQueries([USER_GRP_MENU])
      },
    },
  )
}

export const useGetMasterUserGrp = () => {
  return useQuery([GET_MASTER_USER_GRP], async () => {
    const res = await encoClient.get<ResponseType<IGetMasterUserGrp[]>>(GET_MASTER_USER_GRP)
    return res.data.data
  })
}
