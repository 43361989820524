import { Input as AntdInput, InputProps } from 'antd'
import { TextAreaProps } from 'antd/lib/input'

const defaultLength = 500

export const Input = (props: InputProps) => {
  return <AntdInput maxLength={defaultLength} {...props} />
}

export const TextArea = (props: TextAreaProps) => {
  return <AntdInput.TextArea showCount maxLength={defaultLength} {...props} />
}
