import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import { FileHelper } from 'helpers/file-helper'
import { ContentType } from 'services/http-client/base-http-client'
import { encoClient } from 'services/http-client/enco-client'
import { ResponseType } from 'services/response-type'

import { IGeneratePromoParams, ISavePromoParams, ISearchPromotionCodeParams } from './promotion-code-param'
import {
  IExportPromoResponse,
  IGetMasterPromoType,
  IGetSearchPromotionCodeListResponse,
  IPromoDtDtosResponse,
} from './promotion-code-response'

const PROMOTION_CODE_PATH = '/Promo'

export const useGetPromotionCodeQRY = (tbServiceId?: number, txtSearch?: string) => {
  const params: ISearchPromotionCodeParams = {}
  if (tbServiceId) {
    params.tbServiceId = tbServiceId
  }
  if (txtSearch) {
    params.txtSearch = txtSearch
  }
  return useQuery(
    [PROMOTION_CODE_PATH, params],
    async () => {
      const response = await encoClient.post<ResponseType<IGetSearchPromotionCodeListResponse[]>>(
        `${PROMOTION_CODE_PATH}`,
        params,
      )
      return response.data.data
    },
    {
      enabled: !!tbServiceId,
    },
  )
}

const POST_SERVICE_SAVE_PROMO_PATH = '/SavePromo'
export const useSavePromoMTT = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async (param: ISavePromoParams) => {
      const res = await encoClient.post<ResponseType<IGetSearchPromotionCodeListResponse>>(
        POST_SERVICE_SAVE_PROMO_PATH,
        param,
        {
          headers: {
            'Content-Type': ContentType.JSON,
          },
        },
      )
      return res.data.data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([PROMOTION_CODE_PATH])
      },
    },
  )
}

const GET_MASTER_PROMO_TYPE = '/GetMasterPromoType'
export const useGetMasterPromoTypeQRY = () => {
  return useQuery([GET_MASTER_PROMO_TYPE], async () => {
    const res = await encoClient.get<ResponseType<IGetMasterPromoType[]>>(GET_MASTER_PROMO_TYPE)
    return res.data.data.filter((data) => data.active === 1)
  })
}

const POST_GENERATE_PROMO_DT_PATH = '/GeneratePromoDt'
export const useGeneratePromoDtMTT = () => {
  return useMutation(
    async (param: IGeneratePromoParams) => {
      const res = await encoClient.post<ResponseType<IPromoDtDtosResponse[]>>(POST_GENERATE_PROMO_DT_PATH, param)
      return res.data.data
    },
    {
      onSuccess: () => {},
    },
  )
}

const EXPORT_PROMO = '/ExportPromo'
export const useExportPromo = () => {
  return useMutation(async (id: number) => {
    const res = await encoClient.get<ResponseType<IExportPromoResponse>>(EXPORT_PROMO, { params: { id } })
    const data = res.data.data
    const base64Response = await fetch(
      `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${data.fileString}`,
    )
    const blob = await base64Response.blob()
    const blobUrl = URL.createObjectURL(blob)
    FileHelper.download(blobUrl, data.fileName)
  })
}
