/* eslint-disable no-template-curly-in-string */
import { Button, Card, CheckboxOptionType, Col, Form, notification, Radio, Row, Space } from 'antd'
import { useCallback, useMemo, useRef } from 'react'
import { Link } from 'react-router-dom'

import { TextEditor } from '_backend/components/TextEditor'
import { UploadBannerFormItem } from '_backend/components/upload/UploadBannerFormItem'
import { UploadImageFormItem } from '_backend/components/upload/UploadImageFormItem'
import { Input } from 'components/Input'
import { useModalConfirm } from 'components/ModalConfirmProvider'
import { Txt } from 'components/Txt'
import { notifyFormValidateFailed } from 'helpers/form-helper'
import { useResponsive } from 'helpers/responsive-helper'
import { backendPaths } from 'paths/backend-path-config'
import { ISubmitServiceEmergencyContact } from 'services/emergency-contact/emergency-contact-service-param'
import { ServiceActiveStatusEnum, ServiceShowOrHideEnum } from 'services/enco-service/enco-service-param'
import { ISubmitServiceLink } from 'services/service-link/service-link-service-param'

import { TableEmergencyContacts, ITableEmergencyContactsContext } from './TableEmergencyContacts'
import { ITableServiceLinkContext, TableServiceLink } from './TableServiceLink'

export interface OtherServiceFormValues {
  id: number
  active: ServiceActiveStatusEnum
  contactFunction: ServiceShowOrHideEnum

  serviceName: string
  modifydate: string // date string

  autolink?: string
  icon?: File | string

  bannerDesktop?: File | string
  bannerMobile?: File | string

  serviceContentId: number
  serviceContentDetail: string

  emergencyContacts: ISubmitServiceEmergencyContact[]
  serviceLinks: ISubmitServiceLink[]
}

const serviceStatusOptions = [
  {
    value: ServiceActiveStatusEnum.ACTIVE,
    label: 'Active',
  },
  {
    value: ServiceActiveStatusEnum.INACTIVE,
    label: 'Inactive',
  },
]

const serviceContactStatusOptions: CheckboxOptionType[] = [
  {
    value: ServiceShowOrHideEnum.SHOW,
    label: 'Show',
  },
  {
    value: ServiceShowOrHideEnum.HIDE,
    label: 'Hide',
  },
]

interface OtherServiceFormProps {
  initialValues?: Partial<OtherServiceFormValues>
  onSubmit: (values: OtherServiceFormValues) => Promise<void>
  onCancel: () => void
}
export const OtherServiceForm = (props: OtherServiceFormProps) => {
  const { onSubmit, onCancel, initialValues = {} } = props
  const { id: serviceId = 0 } = initialValues

  const { isXsOnly } = useResponsive()
  const modalConfirm = useModalConfirm()
  const emergencyContactContextRef = useRef<ITableEmergencyContactsContext>(null)
  const serviceLinkContextRef = useRef<ITableServiceLinkContext>(null)

  const [form] = Form.useForm<OtherServiceFormValues>()

  const isEditMode = useMemo(() => {
    return !!initialValues?.id
  }, [initialValues?.id])

  const onConfirmSaveClick = useCallback(async () => {
    const values = form.getFieldsValue()
    await onSubmit(values)
    notification.success({ message: 'สำเร็จ', description: 'บันทึกข้อมูลสำเร็จ', duration: 2 })
  }, [form, onSubmit])

  const handleSubmit = useCallback(
    (values: OtherServiceFormValues) => {
      const { serviceName = '' } = values

      switch (isEditMode) {
        case true:
          modalConfirm.show({
            type: 'default',
            title: 'ยืนยันการแก้ไขบริการ Other Service',
            content: `คุณยืนยันที่จะแก้ไขบริการ ${serviceName} นี้หรือไม่?`,
            onOk: onConfirmSaveClick,
          })
          break
        case false:
        default:
          modalConfirm.show({
            type: 'default',
            title: 'ยืนยันการเพิ่มบริการ Other Service',
            content: `คุณยืนยันที่จะเพิ่มบริการ ${serviceName} นี้หรือไม่?`,
            onOk: onConfirmSaveClick,
          })
          break
      }
    },
    [isEditMode, modalConfirm, onConfirmSaveClick],
  )

  const onAddEmergencyContactClick = useCallback(() => {
    emergencyContactContextRef.current?.openAddEmergencyContact?.()
  }, [])

  const onAddServiceLinkClick = useCallback(() => {
    serviceLinkContextRef.current?.openAddServiceLink?.()
  }, [])

  return (
    <Form
      layout="vertical"
      autoComplete="off"
      form={form}
      initialValues={initialValues}
      onFinish={handleSubmit}
      onFinishFailed={notifyFormValidateFailed}
      scrollToFirstError
    >
      <Row gutter={[8, 8]}>
        {/* service detail */}
        <Col span={24}>
          <Card>
            <Row gutter={[24, 24]}>
              <Col span={24}>
                <Row className="w-100" justify="space-between" align="middle" gutter={[12, 12]}>
                  <Col>
                    <Txt ag="header30">การตั้งค่าบริการ Other Service</Txt>
                  </Col>
                  <Col>
                    <Row justify={isXsOnly ? 'start' : 'end'}>
                      <Col>
                        <Link
                          to={backendPaths.serviceOtherOrder({
                            routeParam: {
                              serviceId,
                            },
                          })}
                        >
                          <Button disabled={!isEditMode}>รายการติดต่อกลับลูกค้า</Button>
                        </Link>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>

              <Col span={24}>
                <Row>
                  <Col sm={12} xs={24}>
                    <Form.Item label="สถานะบริการ (Status)" name="active">
                      <Radio.Group options={serviceStatusOptions} />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>

              <Col sm={12} xs={24}>
                <Row align="middle" gutter={[24, 16]}>
                  <Col span={24}>
                    <Txt ag="header30">รายละเอียดบริการ</Txt>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label="ชื่อบริการ (Service Name)"
                      name="serviceName"
                      rules={[
                        {
                          type: 'string',
                          required: true,
                          message: 'โปรดกรอกข้อมูล',
                        },
                      ]}
                    >
                      <Input placeholder="กรอกข้อมูล" maxLength={255} />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>

              <Col sm={12} xs={24}>
                <Row align="middle" gutter={[24, 16]}>
                  <Col span={24}>
                    <Txt ag="header30">การติดต่อกลับ</Txt>
                  </Col>
                  <Col span={24}>
                    <Form.Item label="เปิดให้ผู้ใช้ที่สนใจกรอกข้อมูลติดต่อกลับ" name="contactFunction">
                      <Radio.Group options={serviceContactStatusOptions} />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>

              {/* Auto Link */}
              <Col sm={12} xs={24}>
                <Row align="middle">
                  <Col span={24}>
                    <Form.Item
                      label="redirect link"
                      name="autolink"
                      rules={[
                        {
                          type: 'url',
                          required: false,
                          message: 'รูปแบบไม่ถูกต้อง',
                        },
                      ]}
                    >
                      <Input placeholder="กรอกข้อมูล" maxLength={500} />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>

              {/* Icon */}
              <Col span={24}>
                <Row align="middle" gutter={[24, 16]}>
                  <Col sm={12} xs={24}>
                    <Form.Item>
                      <UploadImageFormItem
                        form={form}
                        fieldName="icon"
                        label={<Txt ag="header30">ภาพไอคอน</Txt>}
                        title="ขนาดที่แนะนำ 150 x 150 หรืออัตราส่วน 1:1"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col span={24}>
                <Form.Item>
                  <UploadBannerFormItem form={form} desktopFieldName="bannerDesktop" mobileFieldName="bannerMobile" />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item name="serviceContentId" hidden>
                  <Input disabled />
                </Form.Item>
                <Form.Item label={<Txt ag="header30">เนื้อหาบริการ</Txt>} name="serviceContentDetail">
                  <TextEditor moduleName="other-service" />
                </Form.Item>
              </Col>
            </Row>
          </Card>
        </Col>
        {/* emergency contact table */}
        <Col span={24}>
          <Card className="w-100">
            <Row justify="space-between">
              <Txt ag="header30">ผู้ดูแล (Emergency Contacts)</Txt>
              <Button onClick={onAddEmergencyContactClick}>เพิ่มผู้ดูแล</Button>
            </Row>
            <Form.Item name="emergencyContacts" shouldUpdate>
              <TableEmergencyContacts ref={emergencyContactContextRef} />
            </Form.Item>
          </Card>
        </Col>

        {/* link table */}
        <Col span={24}>
          <Card className="w-100">
            <Row justify="space-between">
              <Txt ag="header30">ลิงก์ (LINK)</Txt>
              <Button onClick={onAddServiceLinkClick}>เพิ่มลิงก์</Button>
            </Row>
            <Form.Item name="serviceLinks" shouldUpdate>
              <TableServiceLink ref={serviceLinkContextRef} />
            </Form.Item>
          </Card>
        </Col>
      </Row>
      <Card style={{ marginTop: 8 }}>
        <Row>
          <Col span={12} className="text-left">
            <Button onClick={onCancel} htmlType="reset">
              กลับ
            </Button>
          </Col>
          <Col span={12} className="text-right">
            <Space>
              <Button type="primary" htmlType="submit">
                บันทึก
              </Button>
            </Space>
          </Col>
        </Row>
      </Card>
    </Form>
  )
}
