import { faEye } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Space, Table } from 'antd'
import { ColumnsType, ColumnType } from 'antd/lib/table'
import { styled } from 'goober'
import { useCallback, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'

import { SearchInput } from '_backend/components/SearchInput'
import { ContentBody } from '_backend/components/layout/ContentBody'
import { ContentHeader } from '_backend/components/layout/ContentHeader'
import { Txt } from 'components/Txt'
import { getColumnNumberByPage } from 'helpers/column-helper'
import { DashIfNull } from 'helpers/string-helper'
import { backendPaths } from 'paths/backend-path-config'
import { IGetUserResponse } from 'services/user/user-response'
import { useGetUserListQRY } from 'services/user/user-service'
import theme from 'theme/goober'

import { UserDetailModal } from './UserDetailModal'

const EyeIcon = styled(FontAwesomeIcon)`
  color: ${theme.color.lightBluePrimary};
  font-size: 24px;
  cursor: pointer;
`

export const PageUserList = () => {
  const [keyword, setKeyword] = useState('')
  const [visible, setVisible] = useState(false)
  const [currentUser, setCurrentUser] = useState<IGetUserResponse>()
  const [currentPage, setCurrentPage] = useState(1)
  const [currentPageSize, setCurrentPageSize] = useState(10)

  const { data: dataSource } = useGetUserListQRY({ txtSearch: keyword })

  const handleSearch = useCallback((value: string) => {
    setCurrentPage(1)
    setKeyword(value)
  }, [])

  const handleOpenDetailModal = useCallback(() => {
    setVisible(true)
  }, [setVisible])

  const handleCloseDetailModal = useCallback(() => {
    setVisible(false)
  }, [setVisible])

  const handleClickEye = useCallback(
    (user: IGetUserResponse) => {
      setCurrentUser(user)
      handleOpenDetailModal()
    },
    [handleOpenDetailModal],
  )

  const handleClickEditModal = useCallback(() => {
    handleCloseDetailModal()
  }, [handleCloseDetailModal])

  const handlePageChange = useCallback((page: number, pageSize: number) => {
    setCurrentPage(page)
    setCurrentPageSize(pageSize)
  }, [])

  const columns: ColumnsType<IGetUserResponse> = useMemo(
    (): ColumnType<IGetUserResponse>[] => [
      {
        title: 'ลำดับ',
        align: 'center',
        render: (_: any, record: IGetUserResponse, index: number) => (
          <Txt ag="body20">{getColumnNumberByPage(currentPage, index, currentPageSize)}</Txt>
        ),
      },
      {
        title: 'กลุ่มผู้ใช้งาน',
        dataIndex: 'userGrp',
        key: 'userGrp',
      },
      {
        title: 'ชื่อ-นามสกุล',
        render: (_: any, record: IGetUserResponse) => (
          <Txt ag="body20">
            {record.prefix}
            {record.firstname} {record.lastname}
          </Txt>
        ),
      },
      {
        title: 'ประเภทผู้ใช่้งาน',
        dataIndex: 'userType',
        key: 'userType',
      },
      {
        title: 'ชื่อโปรไฟล์',
        dataIndex: 'profile',
        key: 'profile',
        render: (_: any, record: IGetUserResponse) => <Txt ag="body20">{DashIfNull(record.profile)}</Txt>,
      },
      {
        title: 'อีเมล',
        dataIndex: 'email',
        key: 'email',
      },
      {
        title: 'Modify Date',
        dataIndex: 'lastdateDisplay',
        key: 'lastdateDisplay',
      },
      {
        title: '',
        key: 'action',
        render: (_: any, record: IGetUserResponse) => <EyeIcon icon={faEye} onClick={() => handleClickEye(record)} />,
      },
    ],
    [handleClickEye, currentPage, currentPageSize],
  )

  const userNewPath = backendPaths.userNew()

  return (
    <>
      <ContentHeader>
        <Space size={['large', 'small']} wrap style={{ justifyContent: 'start' }}>
          <SearchInput placeholder="ค้นหาผู้ใช้งาน" onSearch={handleSearch} />
          <Link to={userNewPath}>
            <Button type="primary">เพิ่มผู้ใช้งาน</Button>
          </Link>
        </Space>
      </ContentHeader>
      <ContentBody>
        <Table
          scroll={{ x: '100%' }}
          rowKey="userid"
          dataSource={dataSource}
          columns={columns}
          pagination={{
            showSizeChanger: true,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
            onChange: handlePageChange,
            current: currentPage,
          }}
        />
      </ContentBody>

      {currentUser && (
        <UserDetailModal
          user={currentUser}
          visible={visible}
          onClickEdit={handleClickEditModal}
          onCloseModal={handleCloseDetailModal}
        ></UserDetailModal>
      )}
    </>
  )
}
