import { Card, Divider, Space } from 'antd'
import { styled } from 'goober'
import { Link, useParams } from 'react-router-dom'

import { AppButton } from 'components/AppButton'
import { ContentHTML } from 'components/ContentHTML'
import { CoverPageDetail } from 'components/layout/CoverPageDetail'
import { EnumContentFunct } from 'constants/enum-content-funct'
import { mediaQuery } from 'helpers/responsive-helper'
import { appPaths } from 'paths/path-config'
import { ENCO_TYPE_OZONE_ID, useGetServiceQRY } from 'services/enco-service/enco-service-service'
import { useGetSearchServiceContentsQRY } from 'services/service-content/service-content-service-service'

import { ButtonServiceRequest } from './ButtonServiceRequest'
import { ServiceContactList } from './ServiceContactList'

const ContentCard = styled(Card)`
  width: 100%;
  max-width: 1124px;
  border-radius: 10px;
  background-color: white;
  min-height: 500px;
  margin: 0 auto;

  .ant-card-body {
    padding: 60px 50px;
    @media ${mediaQuery.xs} {
      padding: 30px 25px;
    }
  }
`
const PageContent = styled('div')`
  background-position: top right;
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
  padding-bottom: 40px;
`

export const PageEncoServiceDetail = () => {
  const query = useParams()
  const serviceId = Number(query.serviceId)
  const { data: service } = useGetServiceQRY(serviceId)
  const { data: serviceContents } = useGetSearchServiceContentsQRY(serviceId)

  const bookingContent = serviceContents?.find((content) => content.functnm === EnumContentFunct.Booking)
  const sponsorContent = serviceContents?.find((content) => content.functnm === EnumContentFunct.Sponsor)

  return (
    <>
      <CoverPageDetail title={service?.name} url={service?.coverImgWeb} />
      <PageContent>
        <ContentCard>
          {service?.bookingFunction === 1 && (
            <>
              <ContentHTML id="booking_content" htmlString={bookingContent?.contenttx} />
              <Space direction="vertical" align="center" className="w-100 py-24">
                {serviceId === ENCO_TYPE_OZONE_ID ? (
                  <Link to={appPaths.encoServiceOzoneOrder({ routeParam: { serviceId } })}>
                    <AppButton>{service?.bookingBtnName}</AppButton>
                  </Link>
                ) : (
                  <Link to={appPaths.encoServiceOrder({ routeParam: { serviceId } })}>
                    <AppButton>{service?.bookingBtnName}</AppButton>
                  </Link>
                )}
              </Space>
              <Divider />
            </>
          )}
          {service?.sponsorFunction === 1 && (
            <>
              <ContentHTML id="sponsor_content" htmlString={sponsorContent?.contenttx} />
              <Space direction="vertical" align="center" className="w-100 py-24">
                {serviceId === ENCO_TYPE_OZONE_ID ? (
                  <ButtonServiceRequest modalTitle="จองอบโอโซนพื้นที่" serviceId={serviceId} mdOrderTypeId={2}>
                    {service?.sponsorBtnName}
                  </ButtonServiceRequest>
                ) : (
                  <ButtonServiceRequest modalTitle="สมัครสปอนเซอร์แพ็คเกจ" serviceId={serviceId} mdOrderTypeId={3}>
                    {service?.sponsorBtnName}
                  </ButtonServiceRequest>
                )}
              </Space>
              <Divider />
            </>
          )}
          <ServiceContactList serviceId={serviceId} />
        </ContentCard>
      </PageContent>
    </>
  )
}
