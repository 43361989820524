import { Button, Col, notification, Row } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { styled } from 'goober'
import { useCallback, useEffect, useState } from 'react'

import { AppModal } from 'components/AppModal'
import { Txt } from 'components/Txt'
import { DashIfNull } from 'helpers/string-helper'
import { GetMemberAndSponsorEnum } from 'services/member-and-sponsor/member-and-sponsor-param'
import { IMemberAndSponsorResponse } from 'services/member-and-sponsor/member-and-sponsor-response'
import { useSaveRemarkMemberMTT } from 'services/member-and-sponsor/member-and-sponsor-service'

type ModalMemberAndSponsorType = {
  detail: IMemberAndSponsorResponse
  visible: boolean
  serviceType: GetMemberAndSponsorEnum
  onCloseModal: () => void
}

export const ModalMemberAndSponsor = (props: ModalMemberAndSponsorType) => {
  const { visible, onCloseModal, detail, serviceType } = props

  const [remark, setRemark] = useState(detail.remark || '')
  const { mutateAsync: saveRemarkMemberAndSponsor } = useSaveRemarkMemberMTT()
  const submitApi = useCallback(() => {
    saveRemarkMemberAndSponsor({ id: detail.tbOrderId, remark })
    notification.success({ message: 'สำเร็จ', description: 'บันทึกข้อมูลสำเร็จ', duration: 2 })
    setRemark('')
    onCloseModal()
  }, [detail.tbOrderId, onCloseModal, remark, saveRemarkMemberAndSponsor])

  const handleChange = useCallback((evt: React.ChangeEvent<HTMLTextAreaElement>) => {
    const val = evt.target?.value
    setRemark(val)
  }, [])

  const onCloseClickModal = useCallback(() => {
    setRemark('')
    onCloseModal()
  }, [onCloseModal])
  useEffect(() => {
    setRemark(detail.remark || '')
  }, [detail.remark])
  return (
    <>
      <AppModal visible={visible} width={800} onCancel={onCloseClickModal}>
        <AppModal.Header onCloseClick={onCloseClickModal}>
          {serviceType === 1 ? 'ข้อมูลสมาชิก' : 'ข้อมูล Sponsor'}
        </AppModal.Header>
        <AppModal.Body>
          <Row>
            <RowItem label="ชื่อ - นามสกุล" value={detail.memberName}></RowItem>
          </Row>
          <Row>
            <RowItem label="อีเมล" value={DashIfNull(detail.email)}></RowItem>
            <RowItem label="เบอร์โทรศัพท์" value={detail.tel.toString()}></RowItem>
          </Row>
          <Row>
            <RowItem label="วันที่เริ่มต้น" value={DashIfNull(detail.startDateNm)}></RowItem>
            <RowItem label="วันที่สิ้นสุด" value={DashIfNull(detail.endDateNm)}></RowItem>
          </Row>
          <Txt ag="body20">หมายเหตุ</Txt>
          <TextArea
            rows={4}
            autoSize={{ minRows: 4, maxRows: 5 }}
            placeholder="หมายเหตุ"
            value={remark}
            onChange={handleChange}
          />
        </AppModal.Body>
        <AppModal.Footer>
          <Button type="default" onClick={() => submitApi()}>
            บันทึก
          </Button>
        </AppModal.Footer>
      </AppModal>
    </>
  )
}

const LabelContainer = styled('div')`
  margin-bottom: 8px;
`

const ValueContainer = styled('div')`
  margin-bottom: 24px;
`

type RowItemType = {
  label: string
  value: string
}

export const RowItem = (props: RowItemType) => {
  return (
    <Col xs={24} md={12} style={{ paddingRight: '16px' }}>
      <LabelContainer>
        <Txt ag="body16">{props.label}</Txt>
      </LabelContainer>
      <ValueContainer>
        <Txt ag="header24">{props.value}</Txt>
      </ValueContainer>
    </Col>
  )
}
