import { Button, Col, Form, Row, Space } from 'antd'
import { useCallback, useEffect, useMemo } from 'react'

import { REQUIRED_RULES } from '_backend/constants/form-rules'
import { AppModal } from 'components/AppModal'
import { Input } from 'components/Input'
import NumberInput from 'components/NumberInput'

import { IRecordOrderItem } from './sale-helper'

interface IModalOrderItemFormProps {
  visible: boolean
  orderItems: IRecordOrderItem[]
  editOrderItem?: IRecordOrderItem
  editOrderIndex?: number
  onDismiss: () => void
  onSubmit: (orderItem: IRecordOrderItem, editOrderIndex?: number) => void
}

const mapTitle = (editOrderItem?: IRecordOrderItem) => (editOrderItem ? 'แก้ไขรายการ' : 'เพิ่มรายการ')

export const ModalOrderItemForm = (props: IModalOrderItemFormProps) => {
  const { visible, onDismiss, onSubmit, orderItems, editOrderItem, editOrderIndex } = props
  const [form] = Form.useForm()

  const title = useMemo(() => mapTitle(editOrderItem), [editOrderItem])

  const handleFinish = useCallback(
    (orderItem: IRecordOrderItem) => {
      onSubmit(orderItem, editOrderIndex)
    },
    [editOrderIndex, onSubmit],
  )

  const duplicateItemNameValidator = useCallback(
    () => ({
      validator(_: any, value: string) {
        if (editOrderItem?.itemName !== value && orderItems.some((o) => o.itemName === value)) {
          return Promise.reject(new Error('ชื่อสินค้าซ้ำ'))
        }
        return Promise.resolve()
      },
    }),
    [editOrderItem?.itemName, orderItems],
  )

  useEffect(() => {
    form.resetFields()
    if (editOrderItem) form.setFieldsValue(editOrderItem)
  }, [editOrderItem, form, visible])

  return (
    <AppModal visible={visible} width={700}>
      <AppModal.Header onCloseClick={onDismiss}>{title}</AppModal.Header>
      <AppModal.Body>
        <Form layout="vertical" form={form} onFinish={handleFinish} scrollToFirstError>
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item name="id" hidden>
                <Input />
              </Form.Item>
              <Form.Item
                name="itemName"
                label="ชื่อรายการ (Item Name)"
                required
                rules={[...REQUIRED_RULES, duplicateItemNameValidator]}
              >
                <Input placeholder="กรอกชื่อรายการ" />
              </Form.Item>
            </Col>
            <Col span={24} sm={{ span: 8 }}>
              <Form.Item name="quantity" label="จำนวน (Quantity)" required rules={REQUIRED_RULES}>
                <NumberInput placeholder="กรอกจำนวน" min={0} max={9999} />
              </Form.Item>
            </Col>
            <Col span={24} sm={{ span: 8 }}>
              <Form.Item name="unit" label="หน่วย (Unit)" required rules={REQUIRED_RULES}>
                <Input placeholder="กรอกหน่วย" />
              </Form.Item>
            </Col>
            <Col span={24} sm={{ span: 8 }}>
              <Form.Item name="unitPrice" label="ราคาต่อหน่วย (Unit Price)" required rules={REQUIRED_RULES}>
                <NumberInput placeholder="กรอกราคาต่อหน่วย" min={0} max={999999999} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </AppModal.Body>
      <AppModal.Footer>
        <Space size="middle">
          <Button onClick={onDismiss}>ยกเลิก</Button>
          <Button type="primary" onClick={form.submit}>
            บันทึก
          </Button>
        </Space>
      </AppModal.Footer>
    </AppModal>
  )
}
