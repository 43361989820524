import { Button } from 'antd'
import React, { createContext, Dispatch, PropsWithChildren, useCallback, useContext, useReducer } from 'react'

import { Txt } from 'components/Txt'

import { AppModal, IAppModalProps } from './AppModal'

type ModalType = 'default' | 'info' | 'warning' | 'danger'

type StateType = {
  type: ModalType
  visible: boolean
  title: string
  content?: string
  modalProps?: IAppModalProps
  onOk?: () => void
  onCancel?: () => void
}

type ActionType = {
  type: 'setState' | 'show' | 'dismiss'
  payload: Record<string, any>
}

type ModalContextType = {
  state: StateType
  dispatch: Dispatch<{ type: 'setState' | 'show' | 'dismiss'; payload?: any }>
}

export type UseModalConfirmParamType = {
  type: ModalType
  title: string | React.ReactElement
  content?: string | React.ReactElement
  modalProps?: IAppModalProps
  onOk?: () => void
  onCancel?: () => void
}

const reducer = (state: StateType, action: ActionType) => {
  switch (action.type) {
    case 'setState':
      return {
        ...state,
        ...(action.payload ?? {}),
      }
    case 'show':
      return { ...state, visible: true }
    case 'dismiss':
      return {
        ...state,
        visible: false,
        title: '',
        modalProps: undefined,
        content: undefined,
        onOk: undefined,
        onCancel: undefined,
      }
  }
}

const ModalConfirmCxt = createContext<ModalContextType>({} as any)

const ModalContent = () => {
  const { state, dispatch } = useContext(ModalConfirmCxt)
  const { visible, title, content, onOk, onCancel, type, modalProps } = state

  const onLocalCancel = useCallback(() => {
    dispatch({ type: 'dismiss' })
    onCancel?.()
  }, [dispatch, onCancel])

  const onLocalOk = useCallback(() => {
    dispatch({ type: 'dismiss' })
    onOk?.()
  }, [dispatch, onOk])

  return (
    <AppModal visible={visible} className="custom-confirm-modal" {...modalProps}>
      <AppModal.Header onCloseClick={onLocalCancel}>{title}</AppModal.Header>
      {content && (
        <AppModal.Body>
          <Txt ag="body20">{content}</Txt>
        </AppModal.Body>
      )}
      <AppModal.Footer>
        {type === 'default' && (
          <>
            <Button onClick={onLocalCancel}>ยกเลิก</Button>
            <Button onClick={onLocalOk} type="primary">
              ยืนยัน
            </Button>
          </>
        )}
        {type === 'info' && (
          <Button onClick={onLocalOk} type="primary">
            ยืนยัน
          </Button>
        )}
        {type === 'warning' && (
          <>
            <Button onClick={onLocalCancel} type="primary">
              ยกเลิก
            </Button>
            <Button onClick={onLocalOk}>ยืนยัน</Button>
          </>
        )}
        {type === 'danger' && (
          <>
            <Button onClick={onLocalCancel} type="primary">
              ยกเลิก
            </Button>
            <Button onClick={onLocalOk} danger>
              ยืนยัน
            </Button>
          </>
        )}
      </AppModal.Footer>
    </AppModal>
  )
}

const Provider = (props: React.PropsWithChildren) => {
  const [state, dispatch] = useReducer<typeof reducer>(reducer, {} as any)
  const value = {
    state,
    dispatch,
  }

  return <ModalConfirmCxt.Provider value={value as any}>{props.children}</ModalConfirmCxt.Provider>
}

export const ModalConfirmProvider = (props: PropsWithChildren) => {
  return (
    <Provider>
      <ModalContent />
      {props.children}
    </Provider>
  )
}

export const useModalConfirm = () => {
  const ctx = useContext(ModalConfirmCxt)

  const show = useCallback(
    (data: UseModalConfirmParamType) => {
      ctx.dispatch({
        type: 'setState',
        payload: data,
      })
      ctx.dispatch({ type: 'show' })
    },
    [ctx],
  )

  const ok = useCallback(() => {
    ctx.dispatch({ type: 'dismiss' })
    ctx.state.onOk?.()
  }, [ctx])

  const cancel = useCallback(() => {
    ctx.dispatch({ type: 'dismiss' })
    ctx.state.onCancel?.()
  }, [ctx])

  return { show, ok, cancel }
}
