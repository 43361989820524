import { Button, Col, Form, Row } from 'antd'
import { useForm } from 'antd/es/form/Form'

import { UploadImageFormItem } from '_backend/components/upload/UploadImageFormItem'

export const WikiUploadImageFormItem = () => {
  const [form] = useForm()
  return (
    <>
      <Form<{ mobileFile?: File | string; desktopFile?: File | string }>
        form={form}
        onFinish={(values) => {
          console.log(values)
        }}
        initialValues={
          {
            // imageFile: 'https://laz-img-sg.alicdn.com/p/6a78913c131cfcd539813bd4b7c42459.png',
          }
        }
      >
        <Row>
          <Col span={24} md={12}>
            <UploadImageFormItem
              style={{ width: '100%' }}
              form={form}
              fieldName="imageFile"
              required
              label="Label (Optional)"
              title="Title (Optional)"
              // subTitle="SubTitle (Optional)"
            />
          </Col>
        </Row>
        <Row justify="start">
          <Button htmlType="submit">Submit</Button>
        </Row>
      </Form>
      <div style={{ textAlign: 'start', marginTop: 16, whiteSpace: 'pre' }}>{codeText}</div>
    </>
  )
}

const codeText = `import { Button, Col, Form, Row } from 'antd'
import { useForm } from 'antd/es/form/Form'

import { UploadImageFormItem } from '_backend/components/upload/UploadImageFormItem'

export const WikiUploadImageFormItem = () => {
  const [form] = useForm()
  return (
    <>
      <Form<{ mobileFile?: File | string; desktopFile?: File | string }>
        form={form}
        onFinish={(values) => {
          console.log(values)
        }}
        initialValues={
          {
            // imageFile: 'https://laz-img-sg.alicdn.com/p/6a78913c131cfcd539813bd4b7c42459.png',
          }
        }
      >
        <Row>
          <Col span={24} md={12}>
            <UploadImageFormItem
              style={{ width: '100%' }}
              form={form}
              fieldName="imageFile"
              required
              label="Label (Optional)"
              title="Title (Optional)"
              subTitle="SubTitle (Optional)"
            />
          </Col>
        </Row>
        <Row justify="start">
          <Button htmlType="submit">Submit</Button>
        </Row>
      </Form>
    </>
  )
}
`
