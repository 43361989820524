import { Form, notification } from 'antd'
import { useForm } from 'antd/es/form/Form'
import dayjs from 'dayjs'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { ContentBody } from '_backend/components/layout/ContentBody'
import { ContentHeader } from '_backend/components/layout/ContentHeader'
import { useModalConfirm } from 'components/ModalConfirmProvider'
import { notifyFormValidateFailed } from 'helpers/form-helper'
import { backendPaths } from 'paths/backend-path-config'
import { useGetInvoiceQRY, useInvoiceDeleteMTT, useInvoiceSaveMTT } from 'services/sale/invoice/invoice-service'
import { useGetQuotationDetailQRY } from 'services/sale/quotation/quotation-service'

import { SaleButtonPrint } from '../ButtonPrint'
import { SectionCustomerFields } from '../SectionCustomerFields'
import { SectionFormAction } from '../SectionFormAction'
import { SectionOrderFields } from '../SectionOrderFields'
import { DiscountUnitEnum, InvoiceFormFields } from '../sale-constants'
import { IRecordOrderItem, mapSaleDataItemToRecord } from '../sale-helper'

import { IFormInvoiceValues, mapInvoiceSaveParams } from './PageInvoiceNew'
import { SectionInvoiceFields } from './SectionInvoiceFields'

export const PageInvoiceEdit = () => {
  const [form] = useForm()
  const { invoiceId } = useParams()
  const modalConfirm = useModalConfirm()
  const navigate = useNavigate()

  const { data: invoice, isFetching: isInvoiceFetching } = useGetInvoiceQRY(Number(invoiceId))
  const { data: quotation, isFetching: isQuotationFetching } = useGetQuotationDetailQRY(Number(invoice?.tbQuotaId))
  const { mutateAsync: saveInvoice } = useInvoiceSaveMTT()
  const { mutateAsync: deleteInvoice } = useInvoiceDeleteMTT()

  const [orderItems, setOrderItems] = useState<IRecordOrderItem[]>([])
  const handleChangeItems = useCallback((newItems: IRecordOrderItem[]) => {
    setOrderItems(newItems)
  }, [])

  const readonly = useMemo(() => invoice?.mdQuotaSt !== 2, [invoice?.mdQuotaSt])
  const exitingCustomerId = useMemo(() => quotation?.tbCustomerId, [quotation?.tbCustomerId])

  const initialValues = useMemo<IFormInvoiceValues>(() => {
    const discountUnit =
      invoice?.discountPercent === 0 && invoice?.discount > 0 ? DiscountUnitEnum.BATH : DiscountUnitEnum.PERCENT
    return {
      id: invoice?.id,
      firstdate: invoice?.firstdate ? dayjs(invoice?.firstdate) : undefined,
      dueDate: invoice?.dueDate ? dayjs(invoice?.dueDate) : undefined,
      vatType: invoice?.vatType,
      discountType: invoice?.discountType,
      tbServiceName: invoice?.tbServiceName,
      quotaNo: invoice?.quotaNo,
      quodatedisplay: invoice?.quodatedisplay,
      orderNo: invoice?.orderNo,
      orderDatedisplay: invoice?.orderDatedisplay,
      remark: invoice?.remark,
      attention: quotation?.attention,
      customerId: quotation?.tbCustomerId,
      vatPercent: invoice?.vatPercent,
      discountUnit,
      discount: discountUnit === DiscountUnitEnum.PERCENT ? invoice?.discountPercent : invoice?.discount,
    }
  }, [invoice, quotation])

  const handleFinish = useCallback(
    (values: InvoiceFormFields) => {
      if (!quotation || !invoice) return
      modalConfirm.show({
        type: 'default',
        title: 'ยืนยันการแก้ไขใบวางบิล',
        content: `คุณยืนยันที่จะแก้ไขใบวางบิล ${invoice.invoiceNo} นี้หรือไม่ ?`,
        modalProps: {
          width: 700,
        },
        onOk: async () => {
          const saveParams = mapInvoiceSaveParams({ quotation, orderItems, values })
          await saveInvoice(saveParams)
          navigate(backendPaths.invoiceList())
          notification.success({ message: 'สำเร็จ', description: 'บันทึกข้อมูลใบวางบิลสำเร็จ', duration: 2 })
        },
      })
    },
    [invoice, modalConfirm, navigate, orderItems, quotation, saveInvoice],
  )

  const handleReject = useCallback(() => {
    if (!invoice) return
    modalConfirm.show({
      type: 'danger',
      title: 'ยืนยันการยกเลิกใบวางบิล',
      content: `คุณยืนยันที่จะยกเลิกใบวางบิล ${invoice.invoiceNo} นี้หรือไม่?`,
      modalProps: {
        width: 700,
      },
      onOk: async () => {
        await deleteInvoice(invoice.id)
        navigate(backendPaths.invoiceList())
        notification.success({ message: 'สำเร็จ', description: 'ยกเลิกใบวางบิลสำเร็จ', duration: 2 })
      },
    })
  }, [deleteInvoice, modalConfirm, navigate, invoice])

  useEffect(() => {
    setOrderItems(mapSaleDataItemToRecord(invoice?.dataItems))
  }, [invoice?.dataItems])

  useEffect(() => {
    form.setFieldsValue(initialValues)
  }, [form, initialValues])

  return (
    <>
      <ContentHeader title="Sale" subTitle="Edit Invoice">
        {invoice?.invFilePDFBaseString && (
          <SaleButtonPrint fileName={`${invoice.invoiceNo}.pdf`} base64Data={invoice.invFilePDFBaseString} />
        )}
      </ContentHeader>
      <ContentBody>
        {!isInvoiceFetching && !isQuotationFetching && (
          <Form<InvoiceFormFields>
            layout="vertical"
            form={form}
            onFinish={handleFinish}
            onFinishFailed={notifyFormValidateFailed}
            scrollToFirstError
            initialValues={initialValues}
          >
            <SectionInvoiceFields readonly={readonly} invoiceNo={invoice?.invoiceNo} />
            <SectionCustomerFields readonly form={form} exitingCustomerId={exitingCustomerId} />
            <SectionOrderFields
              hideAddBtn
              readonly={readonly}
              form={form}
              orderItems={orderItems}
              onChangItems={handleChangeItems}
            />
            <SectionFormAction
              readonly={readonly}
              backLink={backendPaths.invoiceList()}
              rejectProps={{ title: 'ยกเลิกใบวางบิล', onReject: handleReject }}
            />
          </Form>
        )}
      </ContentBody>
    </>
  )
}
