import { Row, Divider, Col } from 'antd'
import dayjs from 'dayjs'
import { groupBy } from 'lodash'
import { useMemo, useCallback } from 'react'
import { MdDelete } from 'react-icons/md'

import { Txt } from 'components/Txt'
import { numberFormat } from 'helpers/number-helper'
import { ISearchServiceItemResponse } from 'services/enco-service/enco-service-response'

import { IOzoneSlotItem } from './OzoneSlotSelector'

interface IOzoneItemSummaryProps {
  value?: IOzoneSlotItem[]
  onChange?: (value: IOzoneSlotItem[] | undefined) => void
  ozoneItems: ISearchServiceItemResponse[]
}

export const OzoneItemSummary = (props: IOzoneItemSummaryProps) => {
  const { value, onChange, ozoneItems } = props
  const ozoneItemGroup = useMemo(() => {
    return groupBy(ozoneItems, (v) => v.id)
  }, [ozoneItems])

  const sumPrice = useMemo(() => {
    return value?.reduce((sum, t) => sum + t.price || 0, 0) || 0
  }, [value])

  const handleOnItemDelete = useCallback(
    (deletedIndex: number) => {
      value?.splice(deletedIndex, 1)
      onChange?.(value ? [...value] : undefined)
    },
    [onChange, value],
  )

  return (
    <>
      <Row>
        <Txt ag="header28">รายการที่เลือก</Txt>
      </Row>
      <Divider style={{ margin: '12px 0' }} />
      {value?.map((obj: IOzoneSlotItem, index: number) => {
        const itemName = ozoneItemGroup[obj.tbItemId]?.[0].name ?? ''
        const discountAmt = obj.discountAmt ? ` (ส่วนลด ${obj.discountAmt})` : ''
        return (
          <Row key={index}>
            <Col span={24}>
              <Row justify="space-between" align="middle">
                <Txt ag="body28">{`${itemName} (${obj.price} บาท) ${discountAmt}`}</Txt>
                <Col span={24} sm={7}>
                  <Row align="middle" justify="space-between">
                    <Col>
                      <Txt ag="body28" color="darkGray">{`${dayjs(obj.slotIdStart, 'HH:mm:ss').format(
                        'HH:mm',
                      )} - ${dayjs(obj.slotIdEnd, 'HH:mm:ss').format('HH:mm')}`}</Txt>
                    </Col>
                    <Col>
                      <MdDelete style={{ cursor: 'pointer' }} onClick={handleOnItemDelete.bind(null, index)} />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Divider style={{ margin: '12px 0' }} />
              </Row>
            </Col>
          </Row>
        )
      })}
      <Row justify="center">
        <Col>
          <Txt ag="header28">รวม {numberFormat(sumPrice)} บาท</Txt>
        </Col>
      </Row>
      <Divider style={{ margin: '12px 0' }} />
    </>
  )
}
