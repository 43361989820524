import { Row, Col, Button, Divider } from 'antd'

import { Txt } from 'components/Txt'

export const WikiButton = () => {
  return (
    <>
      <Row>
        <Txt ag="header24">Primary</Txt>
      </Row>
      <Row gutter={16}>
        <Col>
          <Button type="primary">Primary</Button>
        </Col>
        <Col>
          <Button type="primary">ไพมารี่</Button>
        </Col>
      </Row>
      <Row style={{ marginTop: 16 }} gutter={16}>
        <Col>
          <Button type="primary" disabled>
            Disabled
          </Button>
        </Col>
        <Col>
          <Button type="primary" disabled>
            ดีสเซเบิล
          </Button>
        </Col>
      </Row>

      <Divider />

      <Row>
        <Txt ag="header24">Default</Txt>
      </Row>
      <Row gutter={16}>
        <Col>
          <Button type="default">Default</Button>
        </Col>
        <Col>
          <Button type="default">ดีฟอล</Button>
        </Col>
      </Row>
      <Row style={{ marginTop: 16 }} gutter={16}>
        <Col>
          <Button type="default" disabled>
            Disabled
          </Button>
        </Col>
        <Col>
          <Button type="default" disabled>
            ดีสเซเบิล
          </Button>
        </Col>
      </Row>
      <div style={{ textAlign: 'start', marginTop: 16, textDecoration: 'underline' }}>
        <a href="https://ant.design/components/button/" target="_blank" rel="noreferrer">
          Antd Button Link
        </a>
      </div>
      {/* <div style={{ textAlign: 'start', marginTop: 16, whiteSpace: 'pre' }}>{codeText}</div> */}
    </>
  )
}
