import { useParams } from 'react-router-dom'

import { ContentBody } from '_backend/components/layout/ContentBody'
import { ContentHeader } from '_backend/components/layout/ContentHeader'
import { OrderType } from '_backend/constants/order-type'
import { useGetServiceQRY } from 'services/enco-service/enco-service-service'
import { ISearchOrderResponse } from 'services/order-response/order-response-service-response'
import { useGetServiceOrderQRY } from 'services/order-response/order-response-service-service'

import { FormOrderCancel } from './FormOrderCancel'
import { FormOrderRequest } from './FormOrderRequest'

const renderOrderForm = (order: ISearchOrderResponse) => {
  switch (order.mdOrderTypeId) {
    case OrderType.Booking:
      return <FormOrderCancel order={order} />
    case OrderType.MemberRegister:
      return <FormOrderCancel order={order} readonly />
    case OrderType.Request:
      return <FormOrderRequest order={order} />
    case OrderType.SponsorRequest:
      return <FormOrderRequest isSponsor order={order} />
    default:
      return <></>
  }
}

export const PageOrderDetail = () => {
  const { orderId, serviceId } = useParams()
  const { data: service } = useGetServiceQRY(Number(serviceId))
  const { data: order } = useGetServiceOrderQRY(Number(orderId))

  if (!service) return null
  if (!order) return null

  return (
    <>
      <ContentHeader title="Order Management" subTitle={`Order - ${order?.orderNo}`} />
      <ContentBody>{renderOrderForm(order)}</ContentBody>
    </>
  )
}
