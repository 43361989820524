import { faClipboard, faPencil, faUserCircle, IconDefinition } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, notification } from 'antd'
import { styled } from 'goober'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { UploadAvatar } from '_backend/components/upload/UploadAvatar'
import iconUser from 'assets/images/icon-user.svg'
import { AddressModal } from 'components/Address/AddressModal'
import { useModalConfirm } from 'components/ModalConfirmProvider'
import { Txt } from 'components/Txt'
import { LayoutCardPage } from 'components/layout/LayoutCardPage'
import { numberFormat } from 'helpers/number-helper'
import { ISaveAddressParams } from 'services/address/address-params'
import { IGetAddrResponse } from 'services/address/address-response'
import {
  useDeleteAddressMTT,
  useGetCurrentUserAddressQRY,
  useSaveAddressMTT,
  useSetDefaultAddressMTT,
} from 'services/address/address-service'
import { GetUserResponseType } from 'services/auth/auth-response'
import { useGetUserInfoQRY } from 'services/auth/auth-service'
import { useSaveUserInterestMTT, useUpdateProfileMTT, useUpdateUserProfileImgMTT } from 'services/user/user-service'
import theme from 'theme/goober'

import { EditProfileModal, UserProfileValues } from './EditProfileModal'

const MenuItemContainer = styled('div')`
  padding: 12px;
`

const MenuButton = styled(Button)<{ ['is-profile']: string }>`
  cursor: ${(props) => (props['is-profile'] === 'yes' ? 'context-menu' : 'pointer')};
`

const ButtonContent = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-around;
  min-width: 80px;
`

const MenuIcon = styled(FontAwesomeIcon)`
  margin-right: 16px;
`

const ButtonLinkTxt = styled(Txt)<{ ['is-profile']: string }>`
  cursor: ${(props) => (props['is-profile'] === 'yes' ? 'context-menu' : 'pointer')};
  color: ${(props) => (props['is-profile'] === 'yes' ? theme.color.lightBluePrimary : theme.color.grayPlaceholder)};
  &:hover {
    transition: ${(props) => (props['is-profile'] === 'yes' ? 'none' : 'color 0.2s')};
    color: ${theme.color.lightBluePrimary};
  }
`

interface MenuItemProps {
  txt: string
  icon: IconDefinition
  onClick?: () => void
  isProfile?: boolean
}
const MenuItem = (props: MenuItemProps) => {
  const { txt, icon, onClick, isProfile = false } = props

  const handleClickMenu = useCallback(() => {
    if (onClick) onClick()
  }, [onClick])

  const isStrProfile = useMemo(() => (isProfile ? 'yes' : 'no'), [isProfile])

  return (
    <MenuItemContainer>
      <MenuButton is-profile={isStrProfile} type="link" onClick={handleClickMenu}>
        <ButtonContent>
          <ButtonLinkTxt is-profile={isStrProfile} ag="header28">
            <MenuIcon icon={icon} />
            {txt}
          </ButtonLinkTxt>
        </ButtonContent>
      </MenuButton>
    </MenuItemContainer>
  )
}

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`

const TitleContainer = styled('div')`
  display: flex;
  justify-content: center;
  padding: clamp(4px, calc(2vw+2px), 32px);
`

const MenuContainer = styled('div')`
  margin-top: 64px;
  max-width: 600px;
  width: 100%;
  display: flex;
  flex-direction: column;
`

const AvatarContainer = styled('div')`
  height: 180px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
`

const LinkTextButton = styled(Txt)`
  &:hover {
    cursor: pointer;
    opacity: 0.5;
  }
`

const convertToProfileFormValues = (res: GetUserResponseType): UserProfileValues => {
  return {
    firstName: res.firstname,
    lastName: res.lastname,
    email: res.email,
    company: res.company,
    interestIds: res.userInterest.map((interest) => interest.mdInterestId),
  }
}

export const PageProfile = () => {
  const modalConfirm = useModalConfirm()

  const { data: currentUser, refetch: refetchUser } = useGetUserInfoQRY()
  const { data: addresses, refetch: refetchAddress } = useGetCurrentUserAddressQRY()
  const { mutateAsync: saveAddress } = useSaveAddressMTT()
  const { mutateAsync: removeAddress } = useDeleteAddressMTT()
  const { mutateAsync: setDefaultAddress } = useSetDefaultAddressMTT()
  const { mutateAsync: updateUserProfileImg } = useUpdateUserProfileImgMTT()
  const { mutateAsync: updateUserProfile } = useUpdateProfileMTT()
  const { mutateAsync: saveUserInterest } = useSaveUserInterestMTT()

  const defaultAddr = useMemo(() => addresses?.find((addr) => addr.isDefault === 1), [addresses])
  const userFormValues = useMemo(() => currentUser && convertToProfileFormValues(currentUser), [currentUser])

  const [image, setImage] = useState(currentUser?.profilePic || iconUser)
  const [isEditProfileVisible, setIsVisitProfileVisible] = useState(false)
  const [isAddressShow, setIsAddressShow] = useState(false)
  const [defaultAddrSelected, setDefaultAddrSelected] = useState<IGetAddrResponse | undefined>(defaultAddr)

  const handleProfileImgChange = useCallback(
    (file: File) => {
      updateUserProfileImg(
        { File: file },
        {
          onSuccess: (data) => {
            setImage(data.profilePic)
            notification.success({ message: 'สำเร็จ', description: 'แก้ไขรูปโปรไฟล์สำเร็จ', duration: 2 })
          },
        },
      )
    },
    [updateUserProfileImg],
  )

  const handleOpenEditProfile = useCallback(() => {
    setIsVisitProfileVisible(true)
  }, [])

  const handleCloseEditProfile = useCallback(() => {
    setIsVisitProfileVisible(false)
  }, [])

  const handleSaveUserProfile = useCallback(
    async (values: UserProfileValues) => {
      await updateUserProfile({
        Firstname: values.firstName,
        Lastname: values.lastName,
        Company: values.company,
        Email: values.email,
      })
      const interestIds = values.interestIds.map((id) => ({ mdInterestId: id }))
      await saveUserInterest(interestIds)
      await refetchUser()
      notification.success({ message: 'สำเร็จ', description: 'บันทึกข้อมูลโปรไฟล์สำเร็จ', duration: 2 })
      handleCloseEditProfile()
    },
    [handleCloseEditProfile, updateUserProfile, saveUserInterest, refetchUser],
  )

  const handleSaveAddress = useCallback(
    async (values: ISaveAddressParams) => {
      let isError: boolean = false
      await saveAddress(values, {
        onSuccess: () => {
          refetchAddress()
          notification.success({ message: 'สำเร็จ', description: 'บันทึกข้อมูลที่อยู่สำเร็จ', duration: 2 })
        },
        onError: () => {
          isError = true
        },
      })
      return isError
    },
    [saveAddress, refetchAddress],
  )

  const handleClickOpenAddressModal = useCallback(() => {
    refetchAddress()
    setIsAddressShow(true)
  }, [refetchAddress])

  const handleCloseAddressModal = useCallback(() => {
    setIsAddressShow(false)
  }, [])

  const handleConfirmSetDefaultAddress = useCallback(
    (addr: IGetAddrResponse) => {
      setDefaultAddress(
        { id: addr.id },
        {
          onSuccess: () => {
            setDefaultAddrSelected(addr)
            notification.success({ message: 'สำเร็จ', description: 'ตั้งค่าที่อยู่หลักสำเร็จ', duration: 2 })
            refetchAddress()
            setIsAddressShow(true)
          },
        },
      )
    },
    [setDefaultAddress, refetchAddress],
  )

  const handleCancelSetDefaultAddress = useCallback(() => {
    const previousDefault = addresses?.find((addr) => addr.isDefault === 1)
    setDefaultAddrSelected(previousDefault)
    handleClickOpenAddressModal()
  }, [addresses, handleClickOpenAddressModal])

  const handleSetDefaultAddress = useCallback(
    (addr: IGetAddrResponse) => {
      setIsAddressShow(false)
      modalConfirm.show({
        title: 'ตั้งค่าที่อยู่หลัก',
        content: 'คุณต้องการยืนยันที่จะใช้ที่อยู่นี้ เป็นที่อยู่หลักใช่ไหม ?',
        type: 'default',
        onOk: () => handleConfirmSetDefaultAddress(addr),
        onCancel: () => handleCancelSetDefaultAddress(),
      })
    },
    [modalConfirm, handleConfirmSetDefaultAddress, handleCancelSetDefaultAddress],
  )

  const handleConfirmRemove = useCallback(
    async (id: number) => {
      await removeAddress(
        { id },
        {
          onSuccess: () => {
            notification.success({ message: 'สำเร็จ', description: 'ลบข้อมูลที่อยู่สำเร็จ', duration: 2 })
            refetchAddress()
            setIsAddressShow(true)
          },
        },
      )
    },
    [removeAddress, refetchAddress],
  )

  const handleRemoveAddress = useCallback(
    (id: number) => {
      setIsAddressShow(false)
      modalConfirm.show({
        title: 'ลบที่อยู่',
        content: 'คุณต้องการยืนยันที่จะลบที่อยู่นี้ใช่ไหม ?',
        type: 'danger',
        onOk: () => handleConfirmRemove(id),
        onCancel: () => handleClickOpenAddressModal(),
      })
    },
    [modalConfirm, handleConfirmRemove, handleClickOpenAddressModal],
  )

  useEffect(() => {
    if (defaultAddr) setDefaultAddrSelected(defaultAddr)
  }, [defaultAddr])

  if (!currentUser)
    return (
      <LayoutCardPage>
        <TitleContainer>
          <Txt ag="body28">ไม่สามารถ load ข้อมูล user ได้</Txt>
        </TitleContainer>
      </LayoutCardPage>
    )

  return (
    <>
      {userFormValues && (
        <EditProfileModal
          value={userFormValues}
          onSave={handleSaveUserProfile}
          visible={isEditProfileVisible}
          onClose={handleCloseEditProfile}
        />
      )}

      <AddressModal
        checkboxLabel="Default"
        isShowLabel
        currentSelected={defaultAddrSelected}
        onSelectAddress={handleSetDefaultAddress}
        onSaveAddress={handleSaveAddress}
        onRemove={handleRemoveAddress}
        onClose={handleCloseAddressModal}
        addresses={addresses || []}
        visible={isAddressShow}
      />
      <LayoutCardPage title="">
        <Container>
          <TitleContainer>
            <Txt ag="header40" color="lightBluePrimary">
              Profile
            </Txt>
          </TitleContainer>
          <AvatarContainer>
            <UploadAvatar image={image} onChange={handleProfileImgChange} />
          </AvatarContainer>
          <LinkTextButton ag="body28" color="lightBluePrimary" onClick={handleOpenEditProfile}>
            <FontAwesomeIcon fontSize={16} style={{ marginRight: 8 }} icon={faPencil} />
            Edit Profile
          </LinkTextButton>
          <Txt ag="header40">
            {currentUser?.firstname} {currentUser?.lastname}
          </Txt>
          <Txt ag="header28" color="grayFlatButton">
            Credit {numberFormat(currentUser?.credit || 0, 2)}
          </Txt>
          <MenuContainer>
            <MenuItem isProfile txt="My Profile" icon={faUserCircle} />
            <MenuItem txt="ข้อมูลใช้เพื่อออกใบเสร็จแบบเต็ม" icon={faClipboard} onClick={handleClickOpenAddressModal} />
          </MenuContainer>
        </Container>
      </LayoutCardPage>
    </>
  )
}
