import { notification } from 'antd'
import dayjs from 'dayjs'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { ContentBody } from '_backend/components/layout/ContentBody'
import { ContentHeader } from '_backend/components/layout/ContentHeader'
import { backendPaths } from 'paths/backend-path-config'
import { IPrNewsCreateParam, IPrNewsUpdateParam } from 'services/pr-news/pr-news-param'
import { usePrNewsCreateMTT } from 'services/pr-news/pr-news-service'

import { PrNewsForm } from './PrNewsForm'

export const PagePrNewsNew = () => {
  const navigate = useNavigate()
  const { mutateAsync: prNewsCreate } = usePrNewsCreateMTT()

  const handleFormSubmit = useCallback(
    async (values: IPrNewsUpdateParam) => {
      const prNewsCreateParam: IPrNewsCreateParam = { ...values, id: '0' }
      await prNewsCreate(prNewsCreateParam)
      notification.success({ message: 'สำเร็จ', description: 'สร้างบทความสำเร็จ', duration: 2 })
      navigate(backendPaths.prNewsList())
    },
    [navigate, prNewsCreate],
  )

  const handleBack = useCallback(() => {
    navigate(backendPaths.prNewsList())
  }, [navigate])

  return (
    <>
      <ContentHeader subTitle="เพิ่มบทความ PR News" />
      <ContentBody>
        <PrNewsForm
          onSubmit={handleFormSubmit}
          onBack={handleBack}
          initialValues={{
            id: '0',
            publishDateRange: [dayjs(), dayjs()],
            contentTx: '',
          }}
        />
      </ContentBody>
    </>
  )
}
