import { Button, Card, Col, Form } from 'antd'
import { ValidateStatus } from 'antd/lib/form/FormItem'
import { styled } from 'goober'
import { isEmpty } from 'lodash'
import { useCallback, useEffect, useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'

import { FormModal } from 'components/FormModal'
import { Input } from 'components/Input'
import { Txt } from 'components/Txt'
import { IPromotionCodeResponse } from 'services/enco-service/enco-service-response'
import { useCheckPromotionCodeMTT, useGetEmployeePromotionCodeQRY } from 'services/enco-service/enco-service-service'

const ColFlex = styled(Col)`
  display: flex;
  flex-direction: column;
`

const CustomCard = styled(Card)`
  position: relative;
  min-width: 250px;
  margin-bottom: 12px;
`

const UseCodeButton = styled(Button)`
  position: absolute;
  top: 24px;
  right: 16px;
  border-radius: 50%;
`

interface PromotionCodeItemProps {
  promo: IPromotionCodeResponse
  onSelect: (promo: IPromotionCodeResponse) => void
}

const PromotionCodeItem = (props: PromotionCodeItemProps) => {
  const { onSelect, promo } = props

  const handleSelectCode = useCallback(() => {
    onSelect(promo)
  }, [onSelect, promo])

  return (
    <CustomCard>
      <ColFlex>
        <Txt ag="header18">{promo.promoCode}</Txt>
        <Txt ag="subtitle14">
          {promo.startdatedisplay} - {promo.expiredatedisplay}
        </Txt>
      </ColFlex>

      <UseCodeButton onClick={handleSelectCode} style={{ marginRight: '12px', height: '32px', borderRadius: '16px' }}>
        ใช้โค้ด
      </UseCodeButton>
    </CustomCard>
  )
}

const ContentContainer = styled('div')`
  width: 100%;
  max-width: 400px;
`

const CustomForm = styled(Form)`
  width: 100%;

  .ant-form-item-control-input-content {
    display: flex;
    input {
      margin-right: 4px;
    }
  }
`

const CustomButton = styled(Button)`
  height: 66px !important;
  margin-bottom: 1px;
`

const CodeItemContainer = styled('div')`
  margin-top: 32px;
  max-height: 400px;
  overflow-y: auto;
`

interface PromotionCodeModalProps {
  serviceId: number
  visible: boolean
  onClose: () => void
  onSelectCode: (promotion: IPromotionCodeResponse) => void
}

export const PromotionCodeModal = (props: PromotionCodeModalProps) => {
  const { serviceId, visible, onClose, onSelectCode } = props

  const [codeText, setCodeText] = useState('')

  const [currentCode, setCurrentCode] = useState<IPromotionCodeResponse | undefined>()
  const [errorHelper, setErrorHelper] = useState<string | undefined>()
  const [validateStatus, setValidateStatus] = useState<ValidateStatus | undefined>()

  const { data: employeePromotions, refetch } = useGetEmployeePromotionCodeQRY({ serviceId })
  const { mutate } = useCheckPromotionCodeMTT()

  const resetFieldError = useCallback(() => {
    setValidateStatus(undefined)
    setErrorHelper(undefined)
  }, [])

  const setCodeError = useCallback(() => {
    setValidateStatus('error')
    setErrorHelper('โค้ดไม่ถูกต้อง')
  }, [])

  const setCodeRequired = useCallback(() => {
    setValidateStatus('error')
    setErrorHelper('กรุณากรอกโค้ด')
  }, [])

  const handleCloseModal = useCallback(() => {
    onClose()
    setCodeText('')
    setCurrentCode(undefined)
    resetFieldError()
  }, [onClose, resetFieldError])

  const handleSearchSuccess = useCallback(
    (checkPromotion: IPromotionCodeResponse) => {
      if (checkPromotion.promoCode === null) {
        setCurrentCode(undefined)
        setCodeError()
      } else {
        setCurrentCode(checkPromotion)
        resetFieldError()
      }
    },
    [setCodeError, resetFieldError],
  )

  const onSearch = useCallback(() => {
    mutate({ promoCode: codeText, serviceId }, { onSuccess: (data) => handleSearchSuccess(data) })
  }, [mutate, codeText, serviceId, handleSearchSuccess])

  const debouncedHandleSearch = useDebouncedCallback(onSearch, 500)

  const handleChangeCode = useCallback(
    (value: string) => {
      setCodeText(value)
      if (isEmpty(value)) {
        setCodeRequired()
      } else {
        debouncedHandleSearch()
      }
    },
    [debouncedHandleSearch, setCodeRequired],
  )

  const handleSelectCode = useCallback(() => {
    if (currentCode) {
      onSelectCode(currentCode)
      handleCloseModal()
    } else {
      if (isEmpty(codeText)) {
        setCodeRequired()
      } else {
        setCodeError()
      }
    }
  }, [currentCode, onSelectCode, handleCloseModal, setCodeRequired, codeText, setCodeError])

  const handleClickUsePromo = useCallback(
    (promo: IPromotionCodeResponse) => {
      onSelectCode(promo)
      handleCloseModal()
      setCodeRequired()
    },
    [onSelectCode, handleCloseModal, setCodeRequired],
  )

  useEffect(() => {
    if (visible) refetch()
  }, [refetch, visible])

  return (
    <FormModal title="โค้ดส่วนลด" visible={visible} onCancel={handleCloseModal} width={800}>
      <ContentContainer>
        <CustomForm layout="vertical">
          <Form.Item label="โค้ดส่วนลด" validateStatus={validateStatus} help={errorHelper}>
            <Input
              value={codeText}
              onChange={(e) => handleChangeCode(e.target.value)}
              placeholder="กรอกโปรโมชั่นโค้ดของคุณที่นี่"
            />
            <CustomButton style={{ paddingBottom: '6px', paddingTop: '6px' }} type="primary" onClick={handleSelectCode}>
              ใช้โค้ด
            </CustomButton>
          </Form.Item>
        </CustomForm>

        <Txt ag="header28" color="grayPrimaryText">
          โค้ดส่วนลดที่สามารถใช้ได้
        </Txt>
        <CodeItemContainer>
          {employeePromotions?.map((promo) => (
            <PromotionCodeItem promo={promo} onSelect={handleClickUsePromo} />
          ))}
        </CodeItemContainer>
      </ContentContainer>
    </FormModal>
  )
}
