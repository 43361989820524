import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { notification } from 'antd'
import { AxiosError } from 'axios'
import React from 'react'

import { ModalConfirmProvider } from 'components/ModalConfirmProvider'
import { ResponsiveDebugger } from 'helpers/responsive-helper'
import { RootRoute } from 'routes/RootRoute'
import { removeToken } from 'services/auth/auth-service'

import 'App.less'

const showErrorNotification = (error: unknown) => {
  let message = String(error)
  let description = undefined
  if (error instanceof AxiosError) {
    if ([0, 401].includes(error.response?.status as number)) return
    message = error.message
    if (error.response?.data) {
      description = String(error.response?.data.status)
    }
  }
  notification.error({
    message,
    description,
  })
}

const handleError = (error: any) => {
  if (error instanceof AxiosError) {
    if ([0, 401].includes(error.response?.status as number)) {
      removeToken()
      queryClient.clear()
      window.location.reload()
    }
  }
}

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error: unknown) => {
      handleError(error)
      showErrorNotification(error)
    },
  }),
  mutationCache: new MutationCache({
    onError: (error: unknown) => {
      handleError(error)
      showErrorNotification(error)
    },
  }),
})
queryClient.setDefaultOptions({
  queries: {
    retry: 0,
    cacheTime: 1000 * 60 * 5, // 5 minute
    staleTime: 0,
    refetchOnWindowFocus: false,
  },
  mutations: {
    retry: 0,
  },
})

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ModalConfirmProvider>
        <React.Suspense fallback={<div>Loading...</div>}>
          <RootRoute />
        </React.Suspense>
        {process.env.REACT_APP_DEBUG && <ResponsiveDebugger />}
      </ModalConfirmProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  )
}

// trigger build 2022-12-12 4:58
export default App
