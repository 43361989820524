import { styled } from 'goober'

import { Txt } from 'components/Txt'
import { mediaQuery } from 'helpers/responsive-helper'

interface ICoverPageListProps {
  title?: string
  url: string
  insideUrl: string
}

const CoverContainer = styled('div')`
  position: relative;
`
const Img = styled('img')`
  display: block;
  margin: 0 auto;
  width: 100%;
  max-width: 1600px;
  height: calc(280px + 36vw);
  max-height: 768px;
  object-fit: cover;
  object-position: top center;
  @media ${mediaQuery.xs} {
    height: calc(350px);
  }
`
const InsideImgContainer = styled('div')`
  position: absolute;
  width: 100%;
  top: 120px;
  @media ${mediaQuery.xs} {
    top: 0;
  }
`
const InsideImg = styled<'img'>('img')`
  display: block;
  margin: 0 auto;
  width: 100%;
  max-width: 1600px;
  min-height: 200px;
  max-height: 530px;
  object-fit: cover;
`
const Title = styled('div')`
  display: flex;
  justify-content: center;
  padding-top: 24px;
  padding-bottom: 24px;
  .ant-typography {
    @media ${mediaQuery.xs} {
      font-size: calc(24px + 2vw);
    }
  }
`

export const CoverPageList = (props: ICoverPageListProps) => {
  const { title, url, insideUrl } = props
  return (
    <CoverContainer>
      <Img src={url} />
      <InsideImgContainer>
        <Title>
          <Txt ag="header40" color="white">
            {title}
          </Txt>
        </Title>
        <InsideImg src={insideUrl} max-width="1600px" max-height="530px" />
      </InsideImgContainer>
    </CoverContainer>
  )
}
