import { Button, Col, Form, Row, Select } from 'antd'
import { Rule, RuleObject } from 'antd/lib/form'
import { DefaultOptionType } from 'antd/lib/select'
import { styled } from 'goober'
import { chain } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { GetServiceTypeEnum } from 'services/enco-service/enco-service-param'
import { useGetServicesQRY } from 'services/enco-service/enco-service-service'
import { IGetSearchServicePropDayOffResponse } from 'services/property-management/property-management-response'
import { useGetSearchServicePropDayOffMTT } from 'services/property-management/property-management-service'
import { ISearchServicePropDayOffParams } from 'services/property-management/property-management.param'
import { useGetServicePropListQRY } from 'services/service-property/service-property-service'

import { SetDayOffDuplicateTable } from './SetDayOffDuplicateTable'

const Content = styled('div')``
export interface IDuplicateServicePropDayOffValue extends IGetSearchServicePropDayOffResponse {
  isCheck?: boolean
}
interface ISetDayOffDuplicateFormValues {
  serviceId: number
  propertyId: number
  duplicateDayOffList: IDuplicateServicePropDayOffValue[]
}

interface ISetDayOffDuplicateFormProps {
  isValidate?: boolean
}
export const SetDayOffDuplicateForm = (props: ISetDayOffDuplicateFormProps) => {
  const { isValidate } = props

  const form = Form.useFormInstance<ISetDayOffDuplicateFormValues>()
  const serviceId = Form.useWatch('serviceId', form)
  const propertyId = Form.useWatch('propertyId', form)

  const [searchServicePropDayOffParams, setSearchServicePropDayOffParams] = useState<ISearchServicePropDayOffParams>({
    ServiceId: 0,
    ServicePropId: 0,
  })
  const [currentPage, setCurrentPage] = useState(1)

  const { data: serviceList = [] } = useGetServicesQRY({
    serviceType: GetServiceTypeEnum.ENCO_SERVICE,
  })
  const { data: servicePropList = [], isLoading: isServicePropListLoading } = useGetServicePropListQRY({
    ServiceId: serviceId,
  })

  const {
    data: servicePropDayOffList = [],
    isLoading: isDayOffListLoading,
    mutate: getServicePropDayOffList,
  } = useGetSearchServicePropDayOffMTT({
    minimumAwaitTime: 250,
  })

  const serviceOptions = useMemo(
    () =>
      chain(serviceList)
        .filter((e) => !!e.active)
        .map(
          (e): DefaultOptionType => ({
            label: e.name,
            value: e.id,
          }),
        )
        .value(),
    [serviceList],
  )

  const servicePropOptions = useMemo(
    () =>
      chain(servicePropList)
        .filter((e) => !!e.active)
        .map(
          (e): DefaultOptionType => ({
            label: e.name,
            value: e.id,
          }),
        )
        .value(),
    [servicePropList],
  )

  const onPageChange = useCallback((page: number, pageSize: number) => {
    setCurrentPage(page)
  }, [])

  const onSearchServicePropDayOffClick = useCallback(() => {
    getServicePropDayOffList({
      ServiceId: serviceId,
      ServicePropId: propertyId,
    })
    setSearchServicePropDayOffParams({
      ServiceId: serviceId,
      ServicePropId: propertyId,
    })
    setCurrentPage(1)
  }, [getServicePropDayOffList, propertyId, serviceId])

  useEffect(() => {
    form.setFieldsValue({
      duplicateDayOffList: servicePropDayOffList,
    })
  }, [form, servicePropDayOffList])

  const validateDuplicateDayOffList = useMemo((): Rule => {
    const validator = (rule: RuleObject, value: IDuplicateServicePropDayOffValue[]) => {
      const valueChecked = value?.filter((e) => !!e.isCheck)
      let isValid: boolean = true
      if (!valueChecked?.length) {
        isValid = false
      }
      const errorMessage = 'โปรดเลือกวันหยุดอย่างน้อย 1 รายการ'
      if (isValid) {
        return Promise.resolve()
      }
      return Promise.reject(new Error(errorMessage))
    }
    return {
      validator,
    }
  }, [])

  const onServiceIdChange = useCallback(() => {
    form.setFieldsValue({
      propertyId: undefined,
    })
  }, [form])

  const duplicateDayOffListRules = useMemo(() => {
    if (isValidate) return [validateDuplicateDayOffList]
  }, [isValidate, validateDuplicateDayOffList])

  return (
    <>
      <Col span={24}>
        <Row className="w-100" justify="space-between" gutter={8} wrap={false}>
          <Col span={10}>
            <Form.Item label="Service" name="serviceId">
              <Select options={serviceOptions} allowClear={false} onChange={onServiceIdChange} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Property" name="propertyId">
              <Select
                options={servicePropOptions}
                allowClear={false}
                disabled={!serviceId || isServicePropListLoading}
                loading={isServicePropListLoading}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label=" ">
              <Button type="primary" onClick={onSearchServicePropDayOffClick} disabled={!serviceId || !propertyId}>
                ค้นหา
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Col>
      <Content>
        <Form.Item name="duplicateDayOffList" rules={duplicateDayOffListRules} shouldUpdate>
          <SetDayOffDuplicateTable
            currentPage={currentPage}
            onPageChange={onPageChange}
            isLoading={
              isDayOffListLoading &&
              !!searchServicePropDayOffParams.ServiceId &&
              !!searchServicePropDayOffParams.ServicePropId
            }
          />
        </Form.Item>
      </Content>
    </>
  )
}
