import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { css, styled } from 'goober'
import { ReactNode, useMemo } from 'react'
// Import Swiper React components
import { Virtual, Navigation } from 'swiper'
import { Swiper, SwiperSlide, SwiperProps } from 'swiper/react'

// Import Swiper styles
import 'swiper/less'
import 'swiper/less/navigation'
import { randomId } from 'helpers/string-helper'
import theme from 'theme/goober'

const colorGray20 = `${theme.color.graySecondaryText}20`
const colorGray59 = `${theme.color.graySecondaryText}59`

const SwiperLayoutCss = (props: Pick<IAppSwiperProps, 'withPadding'>) => css`
  padding: 0 ${props.withPadding ? '80px' : '0'};
  box-sizing: border-box;
  overflow: hidden;

  .swiper-button-prev {
    left: ${props.withPadding ? '0' : '15px'};
  }

  .swiper-button-next {
    right: ${props.withPadding ? '0' : '15px'};
  }
`

const SwiperButtonWrap = styled('div')`
  display: flex;
  width: 60px;
  height: 60px;
  padding: 24px;
  border-radius: 30px;

  box-sizing: border-box;
  background-color: ${colorGray20};

  &:hover {
    background-color: ${colorGray59};
  }

  &.swiper-button-disabled {
    cursor: default;
    pointer-events: all;
  }

  &:after {
    content: none;
  }
`
const SwiperButton = styled(FontAwesomeIcon)`
  color: ${theme.color.white};
  width: 28px;
  height: 28px;
`

export interface IAppSwiperItem {
  key: string | number
  content: ReactNode
}
interface IAppSwiperProps extends SwiperProps {
  items: IAppSwiperItem[]
  withPadding?: boolean
}
export const AppSwiper = (props: IAppSwiperProps) => {
  const { items, modules = [], withPadding = false, className, ...restProps } = props

  const buttonClassNames = useMemo(() => {
    const elmId = randomId('app-swiper')
    return {
      buttonNextClassName: ['button-next-ref', elmId].join('-'),
      buttonPrevClassName: ['button-prev-ref', elmId].join('-'),
    }
  }, [])

  return (
    <div
      className={[
        className,
        SwiperLayoutCss({
          withPadding,
        }),
      ].join(' ')}
    >
      <Swiper
        modules={[Virtual, Navigation, ...modules]}
        navigation={{
          nextEl: `.${buttonClassNames.buttonNextClassName}`,
          prevEl: `.${buttonClassNames.buttonPrevClassName}`,
        }}
        {...restProps}
      >
        {items?.map((item) => {
          return <SwiperSlide key={`${item.key}`}>{item.content}</SwiperSlide>
        })}
      </Swiper>
      <SwiperButtonWrap className={`swiper-button-prev ${buttonClassNames.buttonPrevClassName}`}>
        <SwiperButton icon={faChevronLeft} />
      </SwiperButtonWrap>
      <SwiperButtonWrap className={`swiper-button-next ${buttonClassNames.buttonNextClassName}`}>
        <SwiperButton icon={faChevronRight} />
      </SwiperButtonWrap>
    </div>
  )
}
