export interface IMyOrderItem {
  id: number
  recno: null | number
  tbOrderId: number
  tbServicePropId: number
  tbItemId: number
  itemTypeId: number
  itemtypenm: string
  itemnm: string
  unit: string
  slotType: string
  qty: number
  unitPrice: number
  bookingDate: string
  bookingDateNm: string
  mdSlotId: number
  slotStart: string
  slotEnd: string
  firstdate: string
  firststf: number
  modifydate: string
  modifystf: number
  canceldate: null
  cancelstf: null | number
}

export enum EnumMyOrderStatusNm {
  COMPLETED = 'ชำระเงินแล้ว',
  CANCELLED = 'ยกเลิก',
  WAITING = 'รอการติดต่อกลับ',
  WAITING_PAYMENT = 'รอชำระเงิน',
}

export enum EnumReceiptTypeNm {
  FULL = 'แบบเต็ม',
  MINI = 'แบบย่อ',
}
export interface IMyOrder {
  id: number
  userId: number
  tbServiceId: number
  tbMemberId: null | number
  mdReceiptTypeId: null | number
  receiptTypeNm: EnumReceiptTypeNm
  orderNo: string
  bookingdate: null | string
  bookingdatenm: null | string
  orderDateTime: string
  orderDateTimeNm: string
  mdOrderTypeId: number
  orderTypeNm: string
  firstname: string
  lastname: string
  email: string
  tel: string
  company: string
  tbOrderStId: null | number
  remark: string
  serviceDate: null | string
  serviceDateNm: string
  serviceTimeId: null | number
  mdServiceLocationId: null | number
  serviceLocationNm: string
  locationDesc: string
  areaSize: unknown
  distance: unknown
  tbPromoId: null | number
  tbPromoDtId: null | number
  credit: number
  discount: number
  total: number
  net: number
  firstdate: string
  firststf: number
  modifydate: string
  modifydatenm: string
  modifystf: number
  modifystfnm: string
  canceldate: null | string
  cancelstf: null | number
  allowCancel: number
  responseDate: null | string
  responseDateNm: string
  responseStf: null | number
  resDesc: string
  statusNm: EnumMyOrderStatusNm
  rejectUserDes: string
  rejectStaffDes: string
  tbInvoiceId: null | number
  invoiceDate: null | string
  invoiceDatenm: null | string
  tbQuotaId: null | number
  quotaDate: null | string
  quotaDatenm: null | string
  tbReceiptId: null | number
  receiveDate: null | string
  receiveDatenm: null | string
  invoiceNo: null | string
  quotaNo: string
  receiptNo: string
  canceldateNm: string
  cancelStfNm: string
  serviceNm: string
  servicePropNm: string
  bankOrderId: string
  bankOrderDate: string
  bankChargeId: string
  bankChargeDate: string
  tbUserAddrId: null | number
  rptFullName: string
  rptTax: null | string
  rptAddr: string
  orderItem: IMyOrderItem[]
  webViewHtmlPayKbank: string
  maxPostpone: number
}

export interface IMyOrderPaginate<T> {
  items: T[]
  totalItem: number
  totalPage: number
  pageSize: number
}

export interface IMyNoti {
  orderDateTimeNm: string
  orderNo: string
  remark: string
  servicenm: string
  statusnm: EnumMyOrderStatusNm
}

export interface IGetOrderType {
  id: number
  orderType: string
  active: number
}

export interface IGetOrderStatus {
  id: number
  status: string
  description: string
  active: number
}
