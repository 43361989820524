import { Txt } from 'components/Txt'

interface ITextSaleNoProps {
  no: string
}

export const TextSaleNo = (props: ITextSaleNoProps) => {
  const { no } = props
  return (
    <Txt ag="header40" color="lightGreenPrimary">
      {no}
    </Txt>
  )
}
