import { Tooltip } from 'antd'

import { numberFormat } from 'helpers/number-helper'

interface ITooltipSaleNoProps {
  saleNo: string
  attention: string
  totalPrice: number
}

export const TooltipSaleNo = (props: ITooltipSaleNoProps) => {
  const { saleNo, attention, totalPrice } = props
  return (
    <Tooltip
      title={
        <>
          <div>เรียน : {attention}</div>
          <div>ยอดรวม : {numberFormat(totalPrice)}</div>
        </>
      }
    >
      <span>{saleNo}</span>
    </Tooltip>
  )
}
