import { Space, Table, Tag } from 'antd'

import { Txt } from 'components/Txt'

import { DemoSortableTable } from './DemoSortableTable'

import type { ColumnsType } from 'antd/es/table'

interface DataType {
  key: string
  seq: number
  name: string
  age: number
  address: string
  tags: string[]
}

const columns: ColumnsType<DataType> = [
  {
    title: 'ลำดับ',
    dataIndex: 'seq',
    key: 'seq',
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: (text) => <a href="_blank">{text}</a>,
  },
  {
    title: 'Age',
    dataIndex: 'age',
    key: 'age',
  },
  {
    title: 'Address',
    dataIndex: 'address',
    key: 'address',
  },
  {
    title: 'Tags',
    key: 'tags',
    dataIndex: 'tags',
    render: (_, { tags }) => (
      <>
        {tags.map((tag) => {
          let color = tag.length > 5 ? 'default' : 'success'
          if (tag === 'loser') {
            color = 'processing'
          }
          return (
            <Tag color={color} key={tag}>
              {tag.toUpperCase()}
            </Tag>
          )
        })}
      </>
    ),
  },
  {
    title: 'Action',
    key: 'action',
    render: (_, record) => (
      <Space size="middle">
        <a href="_blank">Invite {record.name}</a>
        <a href="_blank">Delete</a>
      </Space>
    ),
  },
]

const data: DataType[] = [
  {
    key: '1',
    seq: 1,
    name: 'John Brown',
    age: 32,
    address: 'New York No. 1 Lake Park',
    tags: ['nice', 'developer'],
  },
  {
    key: '2',
    seq: 2,
    name: 'Jim Green',
    age: 42,
    address: 'London No. 1 Lake Park',
    tags: ['loser'],
  },
  {
    key: '3',
    seq: 3,
    name: 'Joe Black',
    age: 32,
    address: 'Sidney No. 1 Lake Park',
    tags: ['cool', 'teacher'],
  },
]

export const WikiTable = () => (
  <>
    <div style={{ textAlign: 'start' }}>
      <Txt ag="header24">SortableTable</Txt>
    </div>
    <DemoSortableTable />
    <div style={{ textAlign: 'start', marginTop: '100px' }}>
      <Txt ag="header24">Table</Txt>
    </div>
    <Table columns={columns} dataSource={data} pagination={false} />
    <div style={{ textAlign: 'start', marginTop: 16, textDecoration: 'underline' }}>
      <a href="https://ant.design/components/table/" target="_blank" rel="noreferrer">
        Antd Table Link
      </a>
    </div>
    <div style={{ textAlign: 'start', marginTop: 16, whiteSpace: 'pre' }}>{codeText}</div>
  </>
)

const codeText = `import { Space, Table, Tag } from 'antd'
import type { ColumnsType } from 'antd/es/table'

interface DataType {
  key: string
  name: string
  age: number
  address: string
  tags: string[]
}

const columns: ColumnsType<DataType> = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: (text) => <a href="_blank">{text}</a>,
  },
  {
    title: 'Age',
    dataIndex: 'age',
    key: 'age',
  },
  {
    title: 'Address',
    dataIndex: 'address',
    key: 'address',
  },
  {
    title: 'Tags',
    key: 'tags',
    dataIndex: 'tags',
    render: (_, { tags }) => (
      <>
        {tags.map((tag) => {
          let color = tag.length > 5 ? 'geekblue' : 'green'
          if (tag === 'loser') {
            color = 'volcano'
          }
          return (
            <Tag color={color} key={tag}>
              {tag.toUpperCase()}
            </Tag>
          )
        })}
      </>
    ),
  },
  {
    title: 'Action',
    key: 'action',
    render: (_, record) => (
      <Space size="middle">
        <a href="_blank">Invite {record.name}</a>
        <a href="_blank">Delete</a>
      </Space>
    ),
  },
]

const data: DataType[] = [
  {
    key: '1',
    name: 'John Brown',
    age: 32,
    address: 'New York No. 1 Lake Park',
    tags: ['nice', 'developer'],
  },
  {
    key: '2',
    name: 'Jim Green',
    age: 42,
    address: 'London No. 1 Lake Park',
    tags: ['loser'],
  },
  {
    key: '3',
    name: 'Joe Black',
    age: 32,
    address: 'Sidney No. 1 Lake Park',
    tags: ['cool', 'teacher'],
  },
]

export const WikiTable = () => (
  <>
    <Table columns={columns} dataSource={data} pagination={false} />
  </>
)`
