import { Row } from 'antd'
import { styled } from 'goober'

import { mediaQuery } from 'helpers/responsive-helper'

export const CardListLayout = styled(Row)`
  display: grid;
  width: 100%;
  justify-content: space-between;
  grid-column-gap: 32px;
  grid-row-gap: 64px;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  margin: 48px 12px;
  @media ${mediaQuery.lg} {
    grid-template-columns: repeat(auto-fill, minmax(370px, 1fr));
    margin: 114px 88px;
  }
`
