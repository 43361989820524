import { Pagination } from 'antd'

export const WikiPagination = () => (
  <>
    <Pagination defaultCurrent={1} total={50} />
    <div style={{ textAlign: 'start', marginTop: 16, textDecoration: 'underline' }}>
      <a href="https://ant.design/components/pagination/" target="_blank" rel="noreferrer">
        Antd Pagination Link
      </a>
    </div>
    <div style={{ textAlign: 'start', marginTop: 16, whiteSpace: 'pre' }}>{codeText}</div>
  </>
)

const codeText = `
import { Pagination } from 'antd'

export const WikiPagination = () => (
  <>
    <Pagination defaultCurrent={1} total={50} />
    <div style={{ textAlign: 'start', marginTop: 16, whiteSpace: 'pre' }}>{codeText}</div>
  </>
)`
