import { Button } from 'antd'
import { styled } from 'goober'
import { PropsWithChildren } from 'react'
import { Link } from 'react-router-dom'

const StyledButton = styled(Button)`
  position: absolute;
  bottom: 40px;
`
interface IBackButtonProps extends PropsWithChildren {
  to: string
  replace?: boolean
}

export const BackButton = (props: IBackButtonProps) => {
  const { children, to, replace } = props
  return (
    <Link to={to} replace={replace}>
      <StyledButton>{children || 'กลับ'}</StyledButton>
    </Link>
  )
}
