import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { InputNumber } from 'antd'
import { valueType } from 'antd/lib/statistic/utils'
import { styled } from 'goober'
import { useCallback, useState } from 'react'

import theme from 'theme/goober'

const CustomAddonButton = styled.button`
  width: 32px;
  height: 32px;
  padding: 4px;
  cursor: pointer;
  border: none;
  background: none;
`

const CustomNumberInput = styled(InputNumber)`
  width: 120px;
  .ant-input-number-handler-wrap {
    display: none;
    padding: 0px 4px;
  }
  .ant-input-number-group-addon:first-child {
    border: none;
    padding: 0;
  }
  .ant-input-number-group-addon:last-child {
    border: none;
    padding: 0;
  }
`
const Icon = styled(FontAwesomeIcon)`
  color: ${theme.color.grayPrimaryText};
  cursor: pointer;
  opacity: 0.2;
  font-size: 12px;
`

interface itemQtyInputProps {
  onChange: (value: number) => void
  value?: number
}

export const ItemQtyInput = (props: itemQtyInputProps) => {
  const { onChange, value: valueProp = 1 } = props
  const [value, setValue] = useState(valueProp)

  const handleValueChange = useCallback(
    (v: valueType) => {
      const val = Number(v)
      if (v === null) {
        setValue(1)
        onChange(1)
      } else {
        setValue(val)
        onChange(val)
      }
    },
    [onChange],
  )

  const onIncrease = () => {
    handleValueChange(value + 1)
  }

  const onDecrease = () => {
    if (value > 1) handleValueChange(value - 1)
  }

  return (
    <CustomNumberInput
      stringMode
      precision={0}
      min={1}
      max={999}
      addonBefore={
        <CustomAddonButton disabled={value < 2} onClick={onDecrease}>
          <Icon fontSize={8} icon={faMinus} opacity={value < 2 ? 0.2 : undefined} />
        </CustomAddonButton>
      }
      addonAfter={
        <CustomAddonButton onClick={onIncrease}>
          <Icon icon={faPlus} />
        </CustomAddonButton>
      }
      value={value}
      defaultValue={1}
      onChange={handleValueChange}
    />
  )
}
