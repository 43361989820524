import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import { encoClient } from 'services/http-client/enco-client'
import { ResponseType } from 'services/response-type'

import { IBannerGetListParam, IBannerUpdatePlatformParam, IBannerUpdateSeqParam } from './home-banner-param'
import { IBannerGetListResponse } from './home-banner-response'

const BANNER_LIST_PATH = '/Banner'

export const useBannersGetListQRY = (param: IBannerGetListParam) => {
  return useQuery([BANNER_LIST_PATH, param], async () => {
    const res = await encoClient.get<ResponseType<IBannerGetListResponse[]>>(BANNER_LIST_PATH, {
      params: {
        web: param.web,
        mobile: param.mobile,
      },
    })
    return res.data.data
  })
}

export const useBannerUpdatePlatformMTT = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async (param: IBannerUpdatePlatformParam) => {
      const res = await encoClient.post<ResponseType<IBannerGetListResponse>>(BANNER_LIST_PATH, param)
      return res.data.data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([BANNER_LIST_PATH])
      },
    },
  )
}

const PR_NEWS_UPDATE_SEQ_PATH = '/SetBannerRecno'
export const useBannerUpdateSeqMTT = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async (param: IBannerUpdateSeqParam) => {
      const res = await encoClient.post<ResponseType<IBannerGetListResponse[]>>(PR_NEWS_UPDATE_SEQ_PATH, param)
      return res.data.data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([BANNER_LIST_PATH])
      },
    },
  )
}
