import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import { ContentType } from 'services/http-client/base-http-client'
import { encoClient } from 'services/http-client/enco-client'
import { ResponseType } from 'services/response-type'

import { IReceiptGetListParam, IReceiptSaveParams } from './receipt-param'
import { IReceiptGetListResponse } from './receipt-response'

const RECEIPT_GET_LIST_PATH = '/SearchReceipt'
const RECEIPT_SAVE_PATH = '/SaveReceipt'
const RECEIPT_GET_DETAIL_PATH = '/GetReceipt'

export const useReceiptGetListQRY = (param: IReceiptGetListParam) => {
  return useQuery([RECEIPT_GET_LIST_PATH, param], async () => {
    const res = await encoClient.post<ResponseType<IReceiptGetListResponse[]>>(RECEIPT_GET_LIST_PATH, param)
    return res.data.data || []
  })
}

export const useReceiptSaveMTT = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async (data: IReceiptSaveParams) => {
      const res = await encoClient.post<ResponseType<IReceiptGetListResponse>>(RECEIPT_SAVE_PATH, data, {
        headers: { 'Content-Type': ContentType.JSON },
      })
      return res.data.data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([RECEIPT_GET_LIST_PATH])
      },
    },
  )
}

export const useGetReceiptQRY = (id: number) => {
  const params = { id }
  return useQuery(
    [RECEIPT_GET_DETAIL_PATH, params],
    async () => {
      const res = await encoClient.get<ResponseType<IReceiptGetListResponse>>(RECEIPT_GET_DETAIL_PATH, { params })
      return res.data.data
    },
    {
      enabled: !!id,
    },
  )
}

export const useReceiptDeleteMTT = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async (id: number) => {
      const res = await encoClient.delete<ResponseType<null>>(RECEIPT_SAVE_PATH, {
        data: { id },
      })
      return res.data.data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([RECEIPT_GET_LIST_PATH])
      },
    },
  )
}
