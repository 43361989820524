import { Button as AntdButton, Col, Row } from 'antd'
import { ButtonProps } from 'antd/es/button'
import { styled } from 'goober'
import { CSSProperties, useCallback } from 'react'

export interface IOzoneItem {
  label: string
  value: number
}

interface IOzoneItemSelectorProps {
  options: IOzoneItem[]
  value?: number
  onChange?: (selectedId: number) => void
  buttonStyle?: CSSProperties
  justifyCentered?: boolean
}

const Button = styled<{}, ButtonProps & { selected: boolean }>(AntdButton)`
  && {
    height: 50px;
    border-color: #2a2d81;
    color: ${({ selected }) => (selected ? 'white' : '#2A2D81')};
    ${({ selected }) => (selected ? 'background: #2A2D81;' : '')}
  }
`

export const OzoneItemSelector = (props: IOzoneItemSelectorProps) => {
  const { options = [], onChange, value, buttonStyle, justifyCentered } = props
  const handleButtonClick = useCallback(
    (v: IOzoneItem) => {
      onChange?.(v.value)
    },
    [onChange],
  )

  return (
    <Row gutter={[12, 12]} justify={justifyCentered ? 'center' : undefined}>
      {options.map((item) => {
        const selected = item.value === value
        return (
          <Col key={item.value}>
            <Button onClick={handleButtonClick.bind(null, item)} selected={selected} style={buttonStyle}>
              {item.label}
            </Button>
          </Col>
        )
      })}
    </Row>
  )
}
