import { Button, Col, Form, Radio, Row, Space } from 'antd'
import { useCallback, useEffect, useMemo } from 'react'

import { REQUIRED_RULES } from '_backend/constants/form-rules'
import { AppModal } from 'components/AppModal'
import { Input } from 'components/Input'
import { ISubmitKioskMasterProductParams } from 'services/kiosk-master/kiosk-master-params'

type ImdKioskSubProductDtos = ISubmitKioskMasterProductParams['mdKioskSubProductDtos'][number]

export interface IModalKioskMasterSubProductFormValues extends ImdKioskSubProductDtos {
  recordIndex: number
  isEditMode?: boolean
}

const statusOptions = [
  {
    value: 1,
    label: 'Active',
  },
  {
    value: 0,
    label: 'Inactive',
  },
]

interface IModalKioskMasterSubProductProps {
  visible: boolean
  initialValues?: Partial<IModalKioskMasterSubProductFormValues>
  onDismiss: () => void
  onConfirm: (values: IModalKioskMasterSubProductFormValues) => void
  onDelete: (recordIndex: number) => void
}
export const ModalKioskMasterSubProduct = (props: IModalKioskMasterSubProductProps) => {
  const { initialValues: preInitialValues = {}, visible, onDismiss, onConfirm, onDelete } = props

  const [form] = Form.useForm<IModalKioskMasterSubProductFormValues>()

  const isEditMode = useMemo(() => {
    if (preInitialValues?.id) return true
    if (preInitialValues?.id === 0) return true
    return false
  }, [preInitialValues?.id])

  const initialValues = useMemo((): Partial<IModalKioskMasterSubProductFormValues> => {
    const { id = 0, isActive = 1, ...restValues } = preInitialValues
    return {
      id,
      isActive,
      ...restValues,
    }
  }, [preInitialValues])

  const handleDismiss = useCallback(() => {
    form.resetFields()
    onDismiss()
  }, [form, onDismiss])

  const onSubmit = useCallback(
    (values: IModalKioskMasterSubProductFormValues) => {
      values.id ??= 0
      values.isEditMode = isEditMode
      onConfirm(values)
      form.resetFields()
    },
    [form, isEditMode, onConfirm],
  )

  const modalHeaderLabel = useMemo(() => {
    if (isEditMode) return 'แก้ไขประเภทสินค้าย่อย'
    return 'เพิ่มประเภทสินค้าย่อย'
  }, [isEditMode])

  const submitButtonLabel = useMemo(() => {
    if (isEditMode) return 'บันทึก'
    return 'เพิ่ม'
  }, [isEditMode])

  useEffect(() => {
    form.setFieldsValue(initialValues)
  }, [form, initialValues])

  return (
    <AppModal visible={visible} destroyOnClose forceRender>
      <Form form={form} onFinish={onSubmit} layout="vertical" autoComplete="off">
        <AppModal.Header onCloseClick={handleDismiss}>{modalHeaderLabel}</AppModal.Header>
        <AppModal.Body>
          {/* keep values */}
          <Form.Item name="id" hidden>
            <Input />
          </Form.Item>
          <Form.Item name="recordIndex" hidden>
            <Input />
          </Form.Item>
          <Form.Item name="isEditMode" hidden>
            <Input />
          </Form.Item>
          <Form.Item name="modifydate" hidden>
            <Input />
          </Form.Item>
          <Form.Item name="modifydateDisplay" hidden>
            <Input />
          </Form.Item>
          <Form.Item name="modifystf" hidden>
            <Input />
          </Form.Item>
          <Form.Item name="modifystfDisplay" hidden>
            <Input />
          </Form.Item>
          <Form.Item name="mdKioskProductId" hidden>
            <Input />
          </Form.Item>
          {/* keep values */}

          <Form.Item label="ประเภทสินค้าย่อย" name="subProductName" rules={REQUIRED_RULES} required>
            <Input placeholder="ประเภทสินค้าย่อย" maxLength={255} />
          </Form.Item>
          <Form.Item label="สถานะบริการ (Status)" name="isActive" rules={REQUIRED_RULES}>
            <Radio.Group options={statusOptions} />
          </Form.Item>
        </AppModal.Body>
        <Row className="custom-modal-footer" wrap={false} justify="space-between" style={{ width: '100%' }}>
          <Col>
            <Button onClick={handleDismiss}>ปิด</Button>
          </Col>
          <Col>
            <Space size={18} direction="horizontal">
              {isEditMode && preInitialValues?.recordIndex !== undefined && (
                <Button type="primary" onClick={onDelete.bind(null, preInitialValues?.recordIndex)} danger>
                  ลบรายการ
                </Button>
              )}
              <Button type="primary" htmlType="submit">
                {submitButtonLabel}
              </Button>
            </Space>
          </Col>
        </Row>
      </Form>
    </AppModal>
  )
}
