import { Button, notification, Radio, RadioChangeEvent, Table } from 'antd'
import { ColumnsType } from 'antd/es/table'
import { useCallback, useState } from 'react'

import { AppModal } from 'components/AppModal'

interface IModalSaleCreateOptionProps<T> {
  title: string
  dataSource: T[]
  columns: ColumnsType<T>
  visible: boolean
  onDismiss: () => void
  onSubmit: (value: number) => void
}

export const ModalSaleCreateOption = <RecordType extends object>(props: IModalSaleCreateOptionProps<RecordType>) => {
  const { visible, onDismiss, onSubmit, title, dataSource, columns } = props
  const [selectedValue, setSelectedValue] = useState<number>()

  const handleSelectOption = useCallback((e: RadioChangeEvent) => {
    setSelectedValue(e.target.value)
  }, [])

  const handleSubmit = useCallback(() => {
    if (selectedValue) {
      onSubmit(selectedValue)
    } else {
      notification.error({
        message: 'ผิดพลาด',
        description: 'กรุณาเลือก Order',
      })
    }
  }, [onSubmit, selectedValue])

  return (
    <AppModal visible={visible} width={700}>
      <AppModal.Header onCloseClick={onDismiss}>{title}</AppModal.Header>
      <AppModal.Body>
        <Radio.Group className="w-100" onChange={handleSelectOption}>
          <Table
            dataSource={dataSource}
            columns={columns}
            scroll={{ x: '100%' }}
            rowKey="id"
            pagination={
              dataSource.length > 10
                ? {
                    pageSize: 10,
                    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                  }
                : false
            }
          />
        </Radio.Group>
      </AppModal.Body>
      <AppModal.Footer>
        <Button onClick={onDismiss}>Cancel</Button>
        <Button type="primary" onClick={handleSubmit}>
          เลือก
        </Button>
      </AppModal.Footer>
    </AppModal>
  )
}
