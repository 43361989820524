import generatePicker from 'antd/es/date-picker/generatePicker'
import { PickerProps } from 'antd/lib/date-picker/generatePicker'
import dayjs, { Dayjs } from 'dayjs'
import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs'
import { ElementType } from 'react'

import { componentDisplayDateFormat } from '_backend/constants/date-format'

const NewDatePicker: ElementType = generatePicker<Dayjs>(dayjsGenerateConfig)

type DatePickerProps = PickerProps<Dayjs>

const DatePicker = (props: DatePickerProps) => {
  const { value, ...restProps } = props
  return <NewDatePicker format={componentDisplayDateFormat} value={value ? dayjs(value) : value} {...restProps} />
}

export default DatePicker
