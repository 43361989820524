import qs from 'qs'
import { useCallback, useEffect, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { ContentBody } from '_backend/components/layout/ContentBody'
import { ContentHeader } from '_backend/components/layout/ContentHeader'
import { backendPaths } from 'paths/backend-path-config'
import { ENCO_TYPE_OZONE_ID, useGetServiceQRY, useSubmitEncoService } from 'services/enco-service/enco-service-service'
import { useGetServiceLocationsQRY } from 'services/service-location/service-location-service-service'

import { EncoServiceForm, EncoServiceFormValues } from './EncoServiceForm'

export const PageEncoServiceEdit = () => {
  const navigate = useNavigate()
  const query = useParams()
  const serviceId = Number(query.serviceId)
  const isOzone = serviceId === ENCO_TYPE_OZONE_ID

  const { data: serviceResponse, isLoading: isServiceLoading } = useGetServiceQRY(serviceId)
  const { data: serviceLocations = [], isLoading: isServiceLocationsLoading } = useGetServiceLocationsQRY(isOzone)

  const { onSubmitEncoService } = useSubmitEncoService(
    serviceResponse?.id || 0,
    serviceResponse?.mdServiceTypeId || 0,
    isOzone,
    serviceResponse?.mdServiceCatId || 0,
  )

  const initialValues = useMemo(
    (): Partial<EncoServiceFormValues> => ({
      id: serviceResponse?.id,
      serviceName: serviceResponse?.name,
      active: serviceResponse?.active,
      propUnitName: serviceResponse?.propUnitName,
      quotationAbbr: serviceResponse?.quotationAbbr,

      icon: serviceResponse?.icon,

      bannerDesktop: serviceResponse?.coverImgWeb,
      bannerMobile: serviceResponse?.coverImgMobile,

      memberFunction: serviceResponse?.memberFunction,
      memberBtnName: serviceResponse?.memberBtnName,

      bookingFunction: serviceResponse?.bookingFunction,
      bookingBtnName: serviceResponse?.bookingBtnName,
      minDateBooking: serviceResponse?.minDateBooking,
      maxPostpone: serviceResponse?.maxPostpone,

      sponsorFunction: serviceResponse?.sponsorFunction,
      sponsorBtnName: serviceResponse?.sponsorBtnName,

      serviceLocationFunc: serviceResponse?.serviceLocationFunc,
      serviceLocations: serviceLocations,
    }),
    [
      serviceLocations,
      serviceResponse?.active,
      serviceResponse?.bookingBtnName,
      serviceResponse?.bookingFunction,
      serviceResponse?.coverImgMobile,
      serviceResponse?.coverImgWeb,
      serviceResponse?.icon,
      serviceResponse?.id,
      serviceResponse?.maxPostpone,
      serviceResponse?.memberBtnName,
      serviceResponse?.memberFunction,
      serviceResponse?.minDateBooking,
      serviceResponse?.name,
      serviceResponse?.propUnitName,
      serviceResponse?.quotationAbbr,
      serviceResponse?.serviceLocationFunc,
      serviceResponse?.sponsorBtnName,
      serviceResponse?.sponsorFunction,
    ],
  )

  const isLoading = useMemo(() => {
    return isServiceLoading || (isOzone && isServiceLocationsLoading)
  }, [isOzone, isServiceLoading, isServiceLocationsLoading])

  const onCancel = useCallback(() => {
    navigate(
      {
        pathname: backendPaths.serviceList(),
        search: qs.stringify({
          tab: serviceResponse?.mdServiceTypeId,
        }),
      },
      { replace: true },
    )
  }, [navigate, serviceResponse?.mdServiceTypeId])

  const onSubmit = useCallback(
    async (values: EncoServiceFormValues) => {
      try {
        await onSubmitEncoService(values)
        navigate(
          {
            pathname: backendPaths.serviceList(),
            search: qs.stringify({
              tab: serviceResponse?.mdServiceTypeId,
            }),
          },
          { replace: true },
        )
      } catch (error) {
        return Promise.reject(error)
      }
    },
    [navigate, onSubmitEncoService, serviceResponse?.mdServiceTypeId],
  )

  useEffect(() => {
    if (!serviceId || (!isLoading && !serviceResponse)) {
      navigate(
        {
          pathname: backendPaths.serviceList(),
          search: qs.stringify({
            tab: serviceResponse?.mdServiceTypeId,
          }),
        },
        { replace: true },
      )
    }
  }, [isLoading, navigate, serviceId, serviceResponse])

  return (
    <>
      <ContentHeader title={serviceResponse?.name} subTitle="Service Setting" />
      <ContentBody>
        {!isLoading && serviceResponse && (
          <EncoServiceForm initialValues={initialValues} onSubmit={onSubmit} onCancel={onCancel} isOzone={isOzone} />
        )}
      </ContentBody>
    </>
  )
}
