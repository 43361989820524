import { Button, Col, notification, Row, Space } from 'antd'
import { styled } from 'goober'
import { useCallback } from 'react'

import { FormModal } from 'components/FormModal'
import { Txt } from 'components/Txt'
import { mediaQuery } from 'helpers/responsive-helper'
import { useGetOrderReceiptQRY } from 'services/order-response/order-response-service-service'
import { useGetUserDetailQRY } from 'services/user/user-service'

const Layout = styled('div')`
  width: 500px;

  .button-layout {
    margin-top: 28px;
    button {
      max-width: 343px;
    }
  }

  @media ${mediaQuery.xsOnly} {
    width: 100%;
  }
`

const ButtonBlue = styled(Button)`
  color: #78c9f5;
  border-color: #78c9f5;
  background: transparent;
`

interface IModalViewOrderReceiptProps {
  visible: boolean
  orderId: number
  customerName: string
  customerId: number
  onDismissModalClick: () => void
}

export const ModalViewOrderReceipt = (props: IModalViewOrderReceiptProps) => {
  const { visible, orderId, customerName, customerId, onDismissModalClick } = props
  const { data: customerDetail } = useGetUserDetailQRY(customerId.toString(), !!customerId && visible)

  const { mutate: getOrderReceipt, isLoading } = useGetOrderReceiptQRY()

  const onViewReceiptClick = useCallback(() => {
    getOrderReceipt(orderId, {
      onSuccess: async (receiptData, variables, context) => {
        const base64String = receiptData.receiptFileImgBaseString
        if (base64String) {
          const base64Response = await fetch(`data:image/jpeg;base64,${base64String}`)
          const blob = await base64Response.blob()
          const blobUrl = URL.createObjectURL(blob)
          window.open(blobUrl, '_blank', 'noopener,noreferrer')
        } else {
          notification.warn({ message: 'แจ้งเตือน', description: 'ไม่พบ receipt ของ order นี้', duration: 2 })
        }
      },
    })
  }, [getOrderReceipt, orderId])

  return (
    <FormModal title="View Receipt" visible={visible} onCancel={onDismissModalClick} width={800}>
      <Layout>
        <Row gutter={[8, 8]}>
          <Col>
            <ButtonBlue
              type="ghost"
              size="small"
              shape="round"
              onClick={onViewReceiptClick}
              loading={isLoading}
              disabled={isLoading}
            >
              View Receipt
            </ButtonBlue>
          </Col>
          <Col>
            {/* <Button type="primary" size="small" onClick={onViewReceiptClick} shape="round">
            Send To Email
          </Button> */}
          </Col>
        </Row>
        <Row gutter={[8, 8]} style={{ marginTop: 18 }}>
          <Col span={24}>
            <Space size={9} direction="horizontal">
              <Txt ag="body18">Customer:</Txt>
              <Txt ag="header24">{customerName}</Txt>
            </Space>
          </Col>
          <Col span={24}>
            <Space size={9} direction="horizontal">
              <Txt ag="body18">Email:</Txt>
              <Txt ag="header24">{customerDetail?.email}</Txt>
            </Space>
          </Col>
        </Row>
        <Row className="button-layout" justify="center">
          <ButtonBlue type="ghost" size="small" block onClick={onDismissModalClick}>
            Back
          </ButtonBlue>
        </Row>
      </Layout>
    </FormModal>
  )
}
