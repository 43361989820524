import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import { GET_SERVICES } from 'services/enco-service/enco-service-service'
import { ContentType } from 'services/http-client/base-http-client'
import { encoClient } from 'services/http-client/enco-client'

import { ResponseType } from '../response-type'

import {
  IMutationServiceEmergencyContactParams,
  ISearchEmergencyContactParams,
} from './emergency-contact-service-param'
import { IMasterEmergencyContactPosition, IEmergencyContactData } from './emergency-contact-service-response'

const EMS_CONTACT = '/EmsContact'
const GET_MASTER_EMS_POS = '/GetMasterEmsPos'
const SEARCH_EMS_CONTACT = '/SearchEmsContact'

export const useGetMasterEmergencyContactPositionQRY = () => {
  return useQuery([GET_MASTER_EMS_POS], async () => {
    const response = await encoClient.get<ResponseType<IMasterEmergencyContactPosition[]>>(GET_MASTER_EMS_POS)
    return response.data.data
  })
}

export const useGetSearchEmergencyContactQRY = (
  serviceId?: number,
  params?: Omit<ISearchEmergencyContactParams, 'serviceId'>,
) => {
  return useQuery(
    [SEARCH_EMS_CONTACT, { serviceId }, params],
    async () => {
      const response = await encoClient.get<ResponseType<IEmergencyContactData[]>>(`${SEARCH_EMS_CONTACT}`, {
        params: {
          ...params,
          serviceId,
        },
      })
      return response.data.data
    },
    {
      enabled: !!serviceId,
    },
  )
}

export const useGetEmergencyContactQRY = (emergencyContactId?: number) => {
  return useQuery(
    [EMS_CONTACT, { emergencyContactId }],
    async () => {
      const response = await encoClient.get<ResponseType<IEmergencyContactData>>(`${EMS_CONTACT}`, {
        params: {
          id: emergencyContactId,
        },
      })
      return response.data.data
    },
    {
      enabled: !!emergencyContactId,
    },
  )
}

export const useMutationServiceEmergencyContactMTT = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async (params: IMutationServiceEmergencyContactParams) => {
      const response = await encoClient.post<ResponseType<IEmergencyContactData[]>>(EMS_CONTACT, params, {
        headers: {
          'Content-Type': ContentType.JSON,
        },
      })

      return response.data.data
    },
    {
      onSuccess: (response, params) => {
        const { ServiceId: serviceId } = params
        queryClient.invalidateQueries([GET_SERVICES])
        queryClient.invalidateQueries([SEARCH_EMS_CONTACT, { serviceId }])
        queryClient.invalidateQueries([EMS_CONTACT, { serviceId }])
      },
    },
  )
}
