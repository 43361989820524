export class FileHelper {
  static download(url: string, fileName: string) {
    const a = document.createElement('a')
    a.style.display = 'none'
    a.href = url
    a.download = fileName
    document.body.appendChild(a)
    a.click()
    a.remove()
  }

  static open(url: string) {
    window.open(url, '_blank')?.focus()
  }
}
