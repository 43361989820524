import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { useCallback } from 'react'
import secureLocalStorage from 'react-secure-storage'

import { encoClient } from 'services/http-client/enco-client'
import { ResponseType } from 'services/response-type'

import { LoginParamType } from './auth-param'
import { LoginResponseType, GetUserResponseType, IGetMenuWebResponse } from './auth-response'

export const GET_USER_INFO_PATH = '/GetUserInfo'
const LOGIN_PATH = '/login'
const GET_MENU_WEB = '/GetMenuWeb'

export const useLoginMTT = () => {
  return useMutation(
    async (params: LoginParamType) => {
      const response = await encoClient.post<ResponseType<LoginResponseType>>(LOGIN_PATH, params)
      return response.data.data
    },
    {
      onSuccess(data) {
        const { accessToken } = data
        setToken(accessToken)
      },
    },
  )
}

export const useGetUserInfoQRY = () => {
  return useQuery(
    [GET_USER_INFO_PATH],
    async () => {
      const response = await encoClient.get<ResponseType<GetUserResponseType>>(GET_USER_INFO_PATH)
      return response.data.data
    },
    {
      enabled: !!getToken(),
      staleTime: Infinity,
      cacheTime: Infinity,
      suspense: true,
    },
  )
}

export const useGetMenuWeb = () => {
  return useQuery(
    [GET_MENU_WEB],
    async () => {
      const response = await encoClient.get<ResponseType<IGetMenuWebResponse[]>>(GET_MENU_WEB)
      return response.data.data
    },
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      suspense: true,
      enabled: !!getToken(),
    },
  )
}

export const useLogout = () => {
  const queryClient = useQueryClient()
  const logout = useCallback(() => {
    removeToken()
    queryClient.invalidateQueries([GET_USER_INFO_PATH])
  }, [queryClient])

  return { logout }
}

export const setToken = (token: string) => {
  secureLocalStorage.setItem('AuthToken', token)
}

export const getToken = () => {
  return secureLocalStorage.getItem('AuthToken')
}

export const removeToken = () => {
  secureLocalStorage.removeItem('AuthToken')
}
