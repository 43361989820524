import FrameComponent from 'react-frame-component'

interface IContentHTMLProps {
  id?: string
  htmlString?: string
}

export const ContentHTML = ({ htmlString = '', id = 'content_html' }: IContentHTMLProps) => {
  const handleIframeContentLoaded = () => {
    setTimeout(() => {
      const iframe = document.getElementById(id)
      if (iframe) {
        //@ts-ignore
        iframe.height = iframe.contentWindow.document.body.offsetHeight + 30
      }
    }, 500)
  }
  return (
    <FrameComponent
      id={id}
      initialContent="<!DOCTYPE html><html><head></head><body style='margin: 0; overflow: hidden;'><div></div></body></html>"
      sandbox="allow-scripts allow-same-origin"
      style={{ border: 0, width: '100%' }}
      contentDidMount={handleIframeContentLoaded}
    >
      <link href="/css/content.css" rel="stylesheet" />
      <div dangerouslySetInnerHTML={{ __html: htmlString }} />
    </FrameComponent>
  )
}
