import { Button, Col, Row } from 'antd'
import { styled } from 'goober'
import { useCallback } from 'react'
import { FaAngleRight } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'

import theme from 'theme/goober'

import { Txt } from './Txt'

const ShortcutContainer = styled(Col)`
  display: flex;
  justify-content: center;
`

const ShortcutContent = styled('div')`
  position: relative;
  width: 370px;
  height: 360px;
  border-radius: 6px;
  background-color: ${theme.color.grayCard};
`

const Image = styled<'img', { ['cover-object-fit']: 'cover' | 'none' }>('img')`
  max-width: 370px;
  width: 100%;
  height: 200px;
  border-radius: 6px 6px 0px 0px;
  object-fit: ${(props) => props['cover-object-fit']};
`

const ContentContainer = styled('div')`
  padding: 0px 16px;
`

const TitleContainer = styled(Row)`
  height: 80px;
  align-items: center;
`

const Title = styled(Txt)`
  display: -webkit-box;
  height: 80px;
  max-width: 370px;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 28px;
`

const DateContainer = styled(Row)`
  margin: 8px 0px;
`

const MoreButton = styled(Button)`
  position: absolute;
  bottom: 16px;
  left: 14px;
  display: inline-flex;
  align-items: center;
  background-color: ${theme.color.grayBackground};
`

export interface ServiceShortcutProps {
  date?: string
  title: string
  cover: string
  linkTo: string
  onClickMore?: () => void
  coverObjectFit?: 'cover' | 'none'
}

const ServiceShortcut = (props: ServiceShortcutProps) => {
  const { title, date, onClickMore, cover, linkTo, coverObjectFit = 'cover' } = props
  const navigate = useNavigate()

  const handleClickMore = useCallback(() => {
    if (onClickMore) {
      onClickMore()
    } else {
      navigate(linkTo)
    }
  }, [linkTo, navigate, onClickMore])

  return (
    <ShortcutContainer>
      <ShortcutContent>
        <Image src={cover} cover-object-fit={coverObjectFit} />
        <ContentContainer>
          <DateContainer>
            <Txt ag="link18" color="lightBluePrimary">
              {date}
            </Txt>
          </DateContainer>
          <TitleContainer>
            <Title ag="header30">{title}</Title>
          </TitleContainer>
          <MoreButton type="default" shape="round" size="small" onClick={handleClickMore}>
            More
            <FaAngleRight size={16} />
          </MoreButton>
        </ContentContainer>
      </ShortcutContent>
    </ShortcutContainer>
  )
}

export default ServiceShortcut
