import { Button, Card, Col, Form, FormInstance, notification, Row, Select, Space } from 'antd'
import { BaseOptionType } from 'antd/lib/cascader'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { REQUIRED_RULES } from '_backend/constants/form-rules'
import { Input, TextArea } from 'components/Input'
import { useModalConfirm } from 'components/ModalConfirmProvider'
import { Txt } from 'components/Txt'
import { ICustomSaveParams } from 'services/customer/customer-param'
import { ICustomerGetListResponse } from 'services/customer/customer-response'
import { useCustomerDeleteMTT, useCustomerSaveMTT, useGetCustomerListQRY } from 'services/customer/customer-service'

import { ModalCustomerForm } from './ModalCustomerForm'
import { BranchTypeEnum, HQ_BRANCH_CODE, HQ_BRANCH_NAME } from './sale-constants'

interface ISectionCustomerFieldsProps {
  form: FormInstance
  readonly?: boolean
  exitingCustomerId?: number
}

export interface ICustomerFormValues extends ICustomSaveParams {
  branchType: number
}

const filterCustomers = (apiCustomers: ICustomerGetListResponse[] = [], customerId?: number) => {
  return apiCustomers.filter((c) => c.id === customerId || c.active === 1)
}

const prepareBranchValues = (values: ICustomerFormValues) => {
  const { branchType, branchCode, branchName } = values
  switch (branchType) {
    case BranchTypeEnum.HQ:
      return { branchCode: HQ_BRANCH_CODE, branchName: HQ_BRANCH_NAME }
    case BranchTypeEnum.INDIVIDUAL:
      return {}
    default:
      return { branchCode, branchName }
  }
}

export const SectionCustomerFields = (props: ISectionCustomerFieldsProps) => {
  const { form, readonly = false, exitingCustomerId } = props
  const [customerModalVisible, setCustomerModalVisible] = useState(false)
  const modalConfirm = useModalConfirm()

  const { data: apiCustomers } = useGetCustomerListQRY({ isActive: 0 })
  const { mutateAsync: saveCustomer } = useCustomerSaveMTT()
  const { mutateAsync: deleteCustomer } = useCustomerDeleteMTT()

  const [customers, setCustomers] = useState<ICustomerGetListResponse[]>([])
  const [lastCreatedCustomerId, setLastCreatedCustomerId] = useState<number>()
  const [deleteCustomerDisabled, setDeleteCustomerDisabled] = useState(true)

  const customerIdValue = Form.useWatch('customerId', form)

  const customerSelectOptions: BaseOptionType[] = useMemo(() => {
    return customers.map((c) => ({ label: c.name, value: c.id })) || []
  }, [customers])

  const selectedCustomer = useMemo(() => customers.find((c) => c.id === customerIdValue), [customerIdValue, customers])

  const handleClickAddCustomer = useCallback(() => {
    setCustomerModalVisible(true)
  }, [])

  const handleCreateCustomer = useCallback(
    async (values: ICustomerFormValues) => {
      const preparedBranchValues = prepareBranchValues(values)
      const customerResp = await saveCustomer({
        id: 0,
        name: values.name,
        taxid: values.taxid,
        address: values.address,
        branchCode: preparedBranchValues.branchCode,
        branchName: preparedBranchValues.branchName,
      })
      setLastCreatedCustomerId(customerResp.id)
      setCustomerModalVisible(false)
      form.setFieldsValue({ attention: values.name })
      notification.success({ message: 'สำเร็จ', description: 'เพิ่มข้อมูุลลูกค้าสำเร็จ', duration: 2 })
    },
    [form, saveCustomer],
  )

  const handleDeleteCustomer = () => {
    if (!selectedCustomer) return
    setCustomerModalVisible(false)
    modalConfirm.show({
      type: 'danger',
      title: 'ยืนยันการลบข้อมูลลูกค้า',
      content: `คุณยืนยันที่จะลบข้อมูลลูกค้า ${selectedCustomer.name} นี้หรือไม่?`,
      modalProps: {
        width: 700,
      },
      onOk: () => {
        deleteCustomer(selectedCustomer.id)
        form.setFieldsValue({ customerId: undefined, attention: undefined })
      },
      onCancel: () => {
        setCustomerModalVisible(false)
      },
    })
  }

  const handleChangeCustomer = useCallback(
    (customerId: number) => {
      const customerName = customers.find((c) => c.id === customerId)?.name
      if (customerName) {
        form.setFieldsValue({ attention: customerName })
      }
    },
    [customers, form],
  )

  useEffect(() => {
    setDeleteCustomerDisabled(!customerIdValue)
  }, [customerIdValue])

  useEffect(() => {
    setCustomers(filterCustomers(apiCustomers, exitingCustomerId))
  }, [apiCustomers, exitingCustomerId])

  useEffect(() => {
    if (lastCreatedCustomerId) {
      form.setFieldsValue({ customerId: lastCreatedCustomerId })
    }
  }, [form, lastCreatedCustomerId])

  useEffect(() => {
    form.setFieldsValue({
      customerTaxId: selectedCustomer?.taxid,
      customerBranchCode: selectedCustomer?.branchCode,
      customerBranchName: selectedCustomer?.branchName,
      customerAddress: selectedCustomer?.address,
    })
  }, [form, selectedCustomer])

  return (
    <Card style={{ marginBottom: 8 }}>
      <Row gutter={[24, 8]} style={{ marginBottom: 16 }}>
        <Col span={24} sm={{ span: 16 }}>
          <Txt ag="header30">รายละเอียดลูกค้า</Txt>
        </Col>
        {!readonly && (
          <Col span={24} sm={{ span: 8 }} className="text-right">
            <Space>
              <Button onClick={handleClickAddCustomer}>เพิ่มลูกค้า</Button>
              <Button danger disabled={deleteCustomerDisabled} onClick={handleDeleteCustomer}>
                ลบรายชื่อลูกค้า
              </Button>
            </Space>
          </Col>
        )}
      </Row>
      <Row gutter={24}>
        <Col span={24} sm={{ span: 12 }}>
          <Form.Item name="customerId" label="ลูกค้า (Customer)" required rules={REQUIRED_RULES}>
            <Select
              placeholder="กรุณาเลือกลูกค้า"
              showSearch
              optionFilterProp="label"
              options={customerSelectOptions}
              disabled={readonly}
              onChange={handleChangeCustomer}
            />
          </Form.Item>
        </Col>
        <Col span={24} sm={{ span: 12 }}>
          <Form.Item name="attention" label="เรียน (Attention)" required rules={readonly ? [] : REQUIRED_RULES}>
            <Input placeholder="กรุณากรอกชื่อ" disabled={readonly} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={24} sm={{ span: 12 }}>
          <Form.Item name="customerTaxId" label="เลขประจำตัวผู้เสียภาษี (Tax ID)">
            <Input disabled />
          </Form.Item>
        </Col>
        <Col span={24} sm={{ span: 12 }}>
          <Form.Item name="customerBranchCode" label="สำนักงานใหญ่ / สาขาเลขที่">
            <Input disabled />
          </Form.Item>
        </Col>
        <Col span={24} sm={{ span: 12 }}>
          <Form.Item name="customerBranchName" label="ชื่อสาขา">
            <Input disabled />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item name="customerAddress" label="ที่อยู่">
            <TextArea disabled autoSize={{ minRows: 3, maxRows: 3 }} />
          </Form.Item>
        </Col>
      </Row>
      <ModalCustomerForm
        visible={customerModalVisible}
        onDismiss={() => setCustomerModalVisible(false)}
        onCreateCustomer={handleCreateCustomer}
      />
    </Card>
  )
}
