import { Card, Row, Col, Form, Radio, Input } from 'antd'

import { REQUIRED_RULES } from '_backend/constants/form-rules'
import DatePicker from 'components/DatePicker'
import { TextArea } from 'components/Input'
import NumberInput from 'components/NumberInput'
import { Txt } from 'components/Txt'

import { TextSaleNo } from '../TextSaleNo'
import { discountTypeOptions, ReceiptFieldName, vatTypeOptions } from '../sale-constants'

interface ISectionReceiptFieldsProps {
  readonly?: boolean
  receiptNo?: string
}

export const SectionReceiptFields = (props: ISectionReceiptFieldsProps) => {
  const { receiptNo, readonly } = props

  return (
    <Card style={{ marginBottom: 8 }}>
      <Row gutter={24}>
        <Col span={24}>
          <Txt ag="body20">เลขที่ใบเสร็จรับเงิน (Receipt No.)</Txt>
        </Col>
        <Col span={24} style={{ marginBottom: '16px' }}>
          <TextSaleNo no={receiptNo || '-'} />
        </Col>
        <Form.Item name={ReceiptFieldName.ID} hidden>
          <NumberInput />
        </Form.Item>
      </Row>
      <Row gutter={24}>
        <Col span={24} md={{ span: 12 }}>
          <Form.Item
            name={ReceiptFieldName.RECEIPT_DATE}
            label="วันที่ออกใบเสร็จรับเงิน (Receipt Datetime)"
            required
            rules={REQUIRED_RULES}
          >
            <DatePicker disabled={readonly} />
          </Form.Item>
        </Col>

        <Col span={24} md={{ span: 6 }}>
          <Form.Item name={ReceiptFieldName.VAT_TYPE} label="การคำนวณภาษี (VAT)" required rules={REQUIRED_RULES}>
            <Radio.Group options={vatTypeOptions} disabled={readonly} />
          </Form.Item>
        </Col>
        <Col span={24} md={{ span: 6 }}>
          <Form.Item name={ReceiptFieldName.DISCOUNT_TYPE} label="ส่วนลด (Discount)" required rules={REQUIRED_RULES}>
            <Radio.Group options={discountTypeOptions} disabled={readonly} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={24} md={{ span: 12 }}>
          <Form.Item name={ReceiptFieldName.TB_SERVICE_NAME} label="ประเภทบริการ">
            <Input disabled />
          </Form.Item>
        </Col>
        <Col span={24} md={{ span: 12 }}>
          <Form.Item name={ReceiptFieldName.QUOTATION_STATUS_NAME} label="สถานะการชำระเงิน">
            <Input disabled />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={24} sm={{ span: 12 }}>
          <Form.Item name={ReceiptFieldName.INVOICE_NO} label="เลข Invoice (Invoice No.)">
            <Input disabled />
          </Form.Item>
        </Col>
        <Col span={24} sm={{ span: 12 }}>
          <Form.Item name={ReceiptFieldName.INVOICE_DATE_DISPLAY} label="วันที่ออกเลข Invoice (Invoice Datetime)">
            <Input disabled />
          </Form.Item>
        </Col>
        <Col span={24} sm={{ span: 12 }}>
          <Form.Item name={ReceiptFieldName.QUOTA_NO} label="เลข Quotation (Quotation No.)">
            <Input disabled />
          </Form.Item>
        </Col>
        <Col span={24} sm={{ span: 12 }}>
          <Form.Item name={ReceiptFieldName.QUO_DATE_DISPLAY} label="วันที่ออกเลข Quotation (Quotation Datetime)">
            <Input disabled />
          </Form.Item>
        </Col>
        <Col span={24} sm={{ span: 12 }}>
          <Form.Item name={ReceiptFieldName.ORDER_NO} label="เลข Order (Order No.)">
            <Input disabled />
          </Form.Item>
        </Col>
        <Col span={24} sm={{ span: 12 }}>
          <Form.Item name={ReceiptFieldName.ORDER_DATE_DISPLAY} label="วันที่ออกเลข Order (Order No.)">
            <Input disabled />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item name={ReceiptFieldName.REMARK} label="หมายเหตุ">
            <TextArea rows={4} disabled />
          </Form.Item>
        </Col>
      </Row>
    </Card>
  )
}
