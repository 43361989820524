import { Button, Card, Col, Divider, Image, Row } from 'antd'
import { styled } from 'goober'
import { useCallback } from 'react'
import { AiFillFile } from 'react-icons/ai'
import { Navigate, useNavigate, useParams } from 'react-router-dom'

import { ContentBody } from '_backend/components/layout/ContentBody'
import { Txt } from 'components/Txt'
import { FileHelper } from 'helpers/file-helper'
import { backendPaths } from 'paths/backend-path-config'
import { appPaths } from 'paths/path-config'
import { useGetSuggestionQTY } from 'services/suggestion/suggestion-service-service'
import theme from 'theme/goober'

const ImageLayout = styled('div')`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(290px, 318px));
  gap: 24px;
`
const FilePreviewLayout = styled('div')`
  display: flex;
  width: 100%;
  height: 200px;
  justify-content: center;
  align-items: center;
  background-color: ${theme.color.grayBackground};
  cursor: pointer;
`

interface FilePreviewProps {
  src: string
  name: string
}

const FilePreview = (props: FilePreviewProps) => {
  const { src, name } = props

  const handleClick = useCallback(() => {
    FileHelper.download(src, name)
  }, [name, src])

  return (
    <FilePreviewLayout onClick={handleClick}>
      <AiFillFile size={50} />
    </FilePreviewLayout>
  )
}

export const PageSuggestionImage = () => {
  const { suggestionId } = useParams()
  const navigate = useNavigate()
  const { data: suggestionData } = useGetSuggestionQTY(Number(suggestionId))

  const handleBackClick = useCallback(() => {
    navigate(backendPaths.suggestionList())
  }, [navigate])

  if (suggestionData === null) {
    return <Navigate to={appPaths.notFound()} />
  }

  return (
    <ContentBody>
      <Row style={{ padding: '40px 0' }}>
        <Col>
          <Txt ag="header40" color="graySecondaryText">
            Suggestion Monitor
          </Txt>
        </Col>
      </Row>
      <Card>
        <Row>
          <Col lg={8}>
            <Row>
              <Col span={24}>
                <Txt ag="body20">ชื่อผู้ให้ข้อมูล</Txt>
              </Col>
              <Col span={24}>
                <Txt ag="header40">{suggestionData?.usernm}</Txt>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col span={24}>
                <Txt ag="body20">ชื่อบริการ</Txt>
              </Col>
              <Col span={24}>
                <Txt ag="header40">{suggestionData?.servicenm}</Txt>
              </Col>
            </Row>
          </Col>
        </Row>
        <Divider />
        <ImageLayout>
          {suggestionData?.suggestFile.map((file) => {
            const isImage = ['.png', '.jpg', 'jpeg'].some((v) => file.path.includes(v))
            return (
              <div>
                <Row>
                  {isImage ? (
                    <Image height={200} src={file.path} />
                  ) : (
                    <FilePreview src={file.path} name={file.filename} />
                  )}
                </Row>
                <Row style={{ marginTop: 24 }}>
                  <Txt ag="subtitle20">{file.filename}</Txt>
                </Row>
              </div>
            )
          })}
        </ImageLayout>

        <Button onClick={handleBackClick} style={{ marginTop: 32 }}>
          กลับ
        </Button>
      </Card>
    </ContentBody>
  )
}
