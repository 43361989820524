import { UserOutlined } from '@ant-design/icons'
import { faRightFromBracket } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Avatar, Layout, Menu, MenuProps } from 'antd'
import { Content, Header } from 'antd/lib/layout/layout'
import { styled } from 'goober'
import { PropsWithChildren, useCallback, useLayoutEffect, useMemo } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'

import { BACKEND_MENU_CONFIGS, BACKEND_MENU_LIST, useMenuItems } from '_backend/constants/backend-menu-config'
import logoEnCo from 'assets/images/logo-enco.png'
import { useModalConfirm } from 'components/ModalConfirmProvider'
import { Txt } from 'components/Txt'
import { EnCoFooter } from 'components/layout/EnCoFooter'
import { mediaQuery } from 'helpers/responsive-helper'
import { useGetUserInfoQRY, useLogout } from 'services/auth/auth-service'
import theme from 'theme/goober'

import bodyStyle from './BackendLayout.module.less'

const HeaderContent = styled('div')`
  display: flex;
  width: 100%;
  max-width: 1440px;
`
const HeaderMenu = styled('div')`
  display: flex;
  width: 100%;
`
const LogoWrapper = styled('div')`
  display: flex;
  align-items: center;
  a,
  img {
    width: 60px;
  }
`
const ProfileMenuWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 12px;
  width: 100%;
  max-width: 220px;
`
const ProfileMenu = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 24px;
  width: 100%;
  padding-left: 24px;
  border-left: 1px solid ${theme.color.grayBorder};
`
const ProfileInfoWrapper = styled('div')`
  display: flex;
  align-items: center;
`
const ProfileInfoText = styled('div')`
  margin-left: 12px;
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  span {
    line-height: 60px;
  }
`

const LogoutIcon = styled(FontAwesomeIcon)`
  color: ${theme.color.grayPrimaryText};
  font-size: 24px;
  cursor: pointer;
`
const MainContent = styled('div')`
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 1484px;
  padding: 0 24px 40px;
  margin: 0 auto;

  @media ${mediaQuery.xs} {
    padding: 0 8px 24px 8px;
  }
`

export const BackendLayout = (props: PropsWithChildren<{}>) => {
  const navigate = useNavigate()
  const backendMenuItems = useMenuItems()
  const { pathname } = useLocation()
  const { data: currentUser } = useGetUserInfoQRY()
  const { logout } = useLogout()
  const modalConfirm = useModalConfirm()

  useLayoutEffect(() => {
    document.body.className = bodyStyle.body
  }, [])

  const selectedMenuKeys = useMemo(() => {
    const menu = BACKEND_MENU_LIST.find((m) => m.path && pathname.includes(m.path))
    const menuKey = menu?.code?.toString()
    const menuKeys = menuKey ? [menuKey] : []
    const subMenu = BACKEND_MENU_CONFIGS.find((config) => {
      if (config.children) {
        return config.children.some((c) => c.code.toString() === menuKey)
      } else {
        return false
      }
    })
    if (subMenu) menuKeys.push(subMenu.code.toString())
    return menuKeys
  }, [pathname])

  const handleClickMenu: MenuProps['onClick'] = useCallback(
    (e: { key: string }) => {
      const menu = BACKEND_MENU_LIST.find((m) => m.code.toString() === e.key)
      const path = menu?.path
      path && navigate(path)
    },
    [navigate],
  )

  const handleClickLogout = useCallback(() => {
    modalConfirm.show({
      type: 'default',
      title: 'ออกจากระบบ',
      content: 'คุณแน่ใจที่จะออกจากระบบหรือไม่?',
      modalProps: {
        width: 700,
      },
      onOk: () => {
        logout()
      },
    })
  }, [logout, modalConfirm])

  return (
    <Layout>
      <Header>
        <HeaderContent>
          <HeaderMenu>
            <LogoWrapper>
              <Link to="/home">
                <img src={logoEnCo} alt="enco-logo" />
              </Link>
            </LogoWrapper>
            <Menu
              theme="light"
              mode="horizontal"
              defaultSelectedKeys={['service']}
              selectedKeys={selectedMenuKeys}
              items={backendMenuItems}
              onClick={handleClickMenu}
            />
          </HeaderMenu>
          <ProfileMenuWrapper>
            <ProfileMenu>
              <ProfileInfoWrapper>
                <Avatar src={currentUser?.profilePic} icon={<UserOutlined />} size="small" />
                <ProfileInfoText>
                  <Txt ag="header24">{currentUser?.disPlayName}</Txt>
                </ProfileInfoText>
              </ProfileInfoWrapper>
              <LogoutIcon icon={faRightFromBracket} onClick={handleClickLogout} />
            </ProfileMenu>
          </ProfileMenuWrapper>
        </HeaderContent>
      </Header>
      <Content>
        <div id="content_header_portal" />
        <MainContent>{props.children}</MainContent>
      </Content>
      <EnCoFooter />
    </Layout>
  )
}
