import { useQuery } from '@tanstack/react-query'

import { ContentType } from 'services/http-client/base-http-client'
import { encoClient } from 'services/http-client/enco-client'
import { ResponseType } from 'services/response-type'

import { ISearchParams } from './service-property-param'
import { IServicePropertyResponse } from './service-property-response'

export const SEARCH_SERVICE_PROP_PATH = '/SearchServiceProp'

export const useGetServicePropListQRY = (params: ISearchParams) => {
  return useQuery(
    [SEARCH_SERVICE_PROP_PATH, params],
    async () => {
      const res = await encoClient.post<ResponseType<IServicePropertyResponse[]>>(SEARCH_SERVICE_PROP_PATH, params, {
        headers: { 'Content-Type': ContentType.FORM_DATA },
      })
      return res.data.data
    },
    {
      enabled: !!params.ServiceId,
    },
  )
}
