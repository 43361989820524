import { faEye } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Card, Tag } from 'antd'
import Table, { ColumnType } from 'antd/lib/table'
import { styled } from 'goober'
import { useCallback, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import { BackButton } from 'components/BackButton'
import { Txt } from 'components/Txt'
import { getColumnNumberByPage } from 'helpers/column-helper'
import { breakpoint } from 'helpers/responsive-helper'
import { backendPaths } from 'paths/backend-path-config'
import { GetMemberAndSponsorEnum } from 'services/member-and-sponsor/member-and-sponsor-param'
import { IMemberAndSponsorResponse } from 'services/member-and-sponsor/member-and-sponsor-response'
import { useGetSearchMemberPropQRY } from 'services/member-and-sponsor/member-and-sponsor-service'
import theme from 'theme/goober'

import { ModalMemberAndSponsor } from './ModalMemberAndSponsor'

export const GearIcon = styled(FontAwesomeIcon)`
  color: ${theme.color.lightBluePrimary};
  cursor: pointer;
`
const EyeIcon = styled(FontAwesomeIcon)`
  color: ${theme.color.lightBluePrimary};
  font-size: 24px;
  cursor: pointer;
`
interface ServiceTablePropsType {
  txtSearch?: string
  serviceType: GetMemberAndSponsorEnum
}
export const TableMemberAndSponsor = (props: ServiceTablePropsType) => {
  const { txtSearch, serviceType } = props
  const { serviceId } = useParams()
  const [visible, setVisible] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [currentPageSize, setCurrentPageSize] = useState(10)
  const [detail, setDetail] = useState<IMemberAndSponsorResponse>()

  const { data: dataSource, isLoading } = useGetSearchMemberPropQRY({
    memberType: serviceType,
    tbServiceId: Number(serviceId),
    txtSearch,
  })
  const renderTagActive = useCallback((isActive: boolean) => {
    if (isActive) {
      return <Tag color="success">Active</Tag>
    }
    return <Tag color="default">Inactive</Tag>
  }, [])

  const handlePageChange = useCallback((page: number, pageSize: number) => {
    setCurrentPage(page)
    setCurrentPageSize(pageSize)
  }, [])

  const handleOpenDetailModal = useCallback(() => {
    setVisible(true)
  }, [setVisible])

  const handleClickEye = useCallback(
    (record: IMemberAndSponsorResponse) => {
      setDetail(record)
      handleOpenDetailModal()
    },
    [handleOpenDetailModal],
  )
  const handleCloseModal = useCallback(() => {
    setVisible(false)
  }, [setVisible])

  const columns = useMemo(
    (): ColumnType<IMemberAndSponsorResponse>[] => [
      {
        title: 'ลำดับ',
        dataIndex: 'recno',
        align: 'center',
        width: '50px',
        render: (_: any, record: IMemberAndSponsorResponse, index: number) => (
          <Txt ag="body20">{getColumnNumberByPage(currentPage, index, currentPageSize)}</Txt>
        ),
      },
      {
        title: 'ชื่อสมาชิก',
        dataIndex: 'memberName',
      },
      {
        title: 'อีเมล',
        dataIndex: 'email',
      },
      {
        title: 'เบอร์โทรศัพท์',
        dataIndex: 'tel',
      },
      {
        title: 'บริษัท',
        dataIndex: 'company',
      },
      {
        title: 'สถานะ',
        dataIndex: 'active',
        render: renderTagActive,
      },
      {
        title: 'วันที่เริ่มต้น',
        dataIndex: 'startDateNm',
      },
      {
        title: 'วันที่สิ้นสุด',
        dataIndex: 'endDateNm',
      },
      {
        title: '',
        key: 'action',
        render: (_: any, record: IMemberAndSponsorResponse) => (
          <EyeIcon icon={faEye} onClick={() => handleClickEye(record)} />
        ),
      },
    ],
    [currentPage, handleClickEye, renderTagActive, currentPageSize],
  )

  return (
    <Card>
      <Table
        dataSource={dataSource}
        columns={columns}
        loading={isLoading}
        rowKey="id"
        scroll={{ x: breakpoint.smMax }}
        pagination={{
          showSizeChanger: true,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
          onChange: handlePageChange,
        }}
      />
      <BackButton to={backendPaths.serviceList()} />
      {detail && (
        <ModalMemberAndSponsor
          detail={detail}
          visible={visible}
          onCloseModal={handleCloseModal}
          serviceType={serviceType}
        />
      )}
    </Card>
  )
}
