import { styled } from 'goober'
import { CSSProperties } from 'react'

interface ContentBodyProps {
  'max-width'?: string
  style?: CSSProperties
}

export const ContentBody = styled<ContentBodyProps>('div')`
  flex: 1;
  max-width: ${(props) => props['max-width'] || '100%'};
`
