import { styled } from 'goober'
import { useCallback, useState } from 'react'
import { useParams } from 'react-router-dom'

import { Txt } from 'components/Txt'
import { LayoutCardPage } from 'components/layout/LayoutCardPage'
import { numberFormat } from 'helpers/number-helper'
import { useGetUserInfoQRY } from 'services/auth/auth-service'
import { IServiceData } from 'services/enco-service/enco-service-response'
import { useGetServiceQRY } from 'services/enco-service/enco-service-service'

import { OrderDetailForm, SummaryOrderProps } from './OrderDetailForm'
import { OrderSummary } from './OrderSummary'

const TitleContainer = styled('div')`
  position: relative;
  display: flex;
  justify-content: center;
  padding: 0px clamp(4px, calc(2vw+2px), 32px);
  padding-top: 64px;
  margin-bottom: 16px;
`
type OrderPageType = 'order' | 'summary'

const title = (type: OrderPageType, service: IServiceData) => {
  switch (type) {
    case 'order':
      return service.bookingBtnName
    case 'summary':
      return 'รายการสินค้าและบริการทั้งหมด'
    default:
      return ''
  }
}

const CreditContainer = styled('div')`
  position: absolute;
  top: 24px;
  right: 24px;
`

export const PageOrder = () => {
  const query = useParams()
  const serviceId = Number(query.serviceId)
  const { data: service } = useGetServiceQRY(serviceId)
  const { data: userInfo } = useGetUserInfoQRY()

  const [currentData, setCurrentData] = useState<SummaryOrderProps | undefined>()
  const [page, setPage] = useState<OrderPageType>('order')

  const handleClickOrderContinue = useCallback((props: SummaryOrderProps) => {
    setCurrentData(props)
    setPage('summary')
    document.documentElement.scrollTo(0, 0)
  }, [])

  const handleClickSummaryBack = useCallback((props: SummaryOrderProps) => {
    setCurrentData(props)
    setPage('order')
    document.documentElement.scrollTo(0, 0)
  }, [])

  const mappingPage = useCallback(
    (serviceProps: IServiceData) => {
      if (page === 'order')
        return (
          <OrderDetailForm values={currentData} service={serviceProps} onClickContinue={handleClickOrderContinue} />
        )
      if (currentData)
        return (
          <OrderSummary dataType="stadium" onBack={handleClickSummaryBack} service={serviceProps} data={currentData} />
        )
    },
    [page, handleClickOrderContinue, currentData, handleClickSummaryBack],
  )

  if (!service) return null
  if (service.bookingFunction !== 1)
    return (
      <LayoutCardPage>
        <TitleContainer>
          <Txt ag="header40" color="grayPrimaryText">
            การใช้งานส่วนนี้ ถูกปิดโดย admin
          </Txt>
        </TitleContainer>
      </LayoutCardPage>
    )
  return (
    <>
      <LayoutCardPage>
        <TitleContainer>
          <CreditContainer>
            <Txt ag="header28" color="grayPrimaryText">
              Your Credit : {numberFormat(userInfo?.credit || 0, 2)}
            </Txt>
          </CreditContainer>
          <Txt ag="header40" color="lightBluePrimary">
            {title(page, service)}
          </Txt>
        </TitleContainer>
        {mappingPage(service)}
      </LayoutCardPage>
    </>
  )
}
