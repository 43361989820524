/* eslint-disable no-template-curly-in-string */
import { Button, Card, Col, Form, notification, Radio, Row, Select } from 'antd'
import { BaseOptionType } from 'antd/lib/select'
import dayjs from 'dayjs'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import { SearchInput } from '_backend/components/SearchInput'
import { apiDateFormat } from '_backend/constants/date-format'
import {
  NUMBER_MORE_THAN_ZERO_AND_LESS_THAN_NINETY_NINE,
  requiredCustomMessage,
  REQUIRED_RULES,
} from '_backend/constants/form-rules'
import { Input, TextArea } from 'components/Input'
import { useModalConfirm } from 'components/ModalConfirmProvider'
import NumberInput from 'components/NumberInput'
import RangePicker from 'components/RangePicker'
import { Txt } from 'components/Txt'
import { notifyFormValidateFailed } from 'helpers/form-helper'
import { ServiceActiveStatusEnum } from 'services/enco-service/enco-service-param'
import { useGetServiceQRY } from 'services/enco-service/enco-service-service'
import { IGeneratePromoParams, ISavePromoParams } from 'services/promotion-code/promotion-code-param'
import { IPromoDtDtosResponse } from 'services/promotion-code/promotion-code-response'
import {
  useExportPromo,
  useGeneratePromoDtMTT,
  useGetMasterPromoTypeQRY,
} from 'services/promotion-code/promotion-code-service'
import { IQuotationGetListResponse } from 'services/sale/quotation/quotation-response'
import { useQuotationGetListQRY } from 'services/sale/quotation/quotation-service'

import { ModalCreatePromotionCode } from './ModalCreatePromotionCode'
import { TablePromotionCodeList } from './TablePromotionCodeList'

const serviceStatusOptions = [
  {
    value: ServiceActiveStatusEnum.ACTIVE,
    label: 'Active',
  },
  {
    value: ServiceActiveStatusEnum.INACTIVE,
    label: 'Inactive',
  },
]

interface OtherServiceFormProps {
  initialValues?: Partial<ISavePromoParams>
  onSubmit: (values: ISavePromoParams) => Promise<void>
  onCancel: () => void
  promoDetailData?: IPromoDtDtosResponse[]
  headerPromotionTxt: string
}

export const PromotionCodeForm = (props: OtherServiceFormProps) => {
  const { serviceId, promotionId } = useParams()

  const { onSubmit, onCancel, initialValues = {}, promoDetailData = [], headerPromotionTxt } = props
  const { data: masterPromoType } = useGetMasterPromoTypeQRY()
  const { data: service, isLoading } = useGetServiceQRY(Number(serviceId))
  const { data: apiQuotation } = useQuotationGetListQRY({ mdQuotaSt: 3 })
  const { mutateAsync: generatePromoDt } = useGeneratePromoDtMTT()
  const { mutateAsync: exportPromo } = useExportPromo()
  const modalConfirm = useModalConfirm()

  const [visible, setVisible] = useState(false)
  const onDismissClick = useCallback(() => {
    setVisible(false)
  }, [])
  const [codeQty, setCodeQty] = useState<number>()
  const [fixCode, setFixCode] = useState<string>()
  const [genDigit, setGenDigit] = useState<number>()
  const [promoDetail, setPromoDetail] = useState<IPromoDtDtosResponse[]>(promoDetailData)
  const [txtSearch, setTxtSearch] = useState('')
  const [quotation, setQuotation] = useState<IQuotationGetListResponse[]>([])

  const [form] = Form.useForm<ISavePromoParams>()

  const { mdServiceCatId = 0 } = service ?? {}

  const isKiosk = useMemo(() => {
    return +mdServiceCatId === 3
  }, [mdServiceCatId])

  const isEditMode = useMemo(() => {
    return !!initialValues?.id
  }, [initialValues?.id])

  const onConfirmSaveClick = useCallback(async () => {
    const values = form.getFieldsValue()

    await onSubmit({
      ...values,
      codeQty: codeQty || 0,
      fixCode: fixCode || '',
      genDigit: genDigit || 0,
      tbServiceId: Number(serviceId) || 0,
      startdate: values.rangeDate && dayjs(values.rangeDate[0]).format(apiDateFormat),
      expiredate: values.rangeDate && dayjs(values.rangeDate[1]).format(apiDateFormat),
      promoDtItems: promoDetail || [],
    })
    notification.success({ message: 'สำเร็จ', description: 'บันทึกข้อมูลสำเร็จ', duration: 2 })
  }, [codeQty, fixCode, form, genDigit, onSubmit, promoDetail, serviceId])

  const onGeneratePromotionCode = useCallback(
    async (values: IGeneratePromoParams) => {
      const response = await generatePromoDt(values)
      setPromoDetail(response)
      setGenDigit(values.GenDigit)
      setFixCode(values.FixCode)
      setCodeQty(values.CodeQty)
      onDismissClick()
    },
    [generatePromoDt, onDismissClick],
  )

  const handleSubmit = useCallback(
    (values: ISavePromoParams) => {
      switch (isEditMode) {
        case true:
          modalConfirm.show({
            type: 'default',
            title: `ยืนยันการแก้ไขโปรโมชั่นโค้ด`,
            content: `คุณยืนยันที่จะบันทึกการแก้ไขโปรโมชั่นโค้ดนี้หรือไม่?`,
            onOk: onConfirmSaveClick,
          })
          break
        case false:
        default:
          modalConfirm.show({
            type: 'default',
            title: `ยืนยันการสร้างโปรโมชั่นโค้ด`,
            content: `คุณยืนยันที่จะบันทึกการสร้างโปรโมชั่นโค้ดนี้หรือไม่?`,
            onOk: onConfirmSaveClick,
          })
          break
      }
    },
    [isEditMode, modalConfirm, onConfirmSaveClick],
  )

  const onCreatePromotionCode = useCallback(() => {
    setVisible(true)
  }, [])

  const optionPromoType = useMemo(() => {
    return masterPromoType?.map((item) => {
      const { id, name } = item
      return { label: name, value: id }
    })
  }, [masterPromoType])

  const customerSelectOptions: BaseOptionType[] = useMemo(() => {
    return quotation.map((c) => ({ label: c.quotaNo, value: c.id })) || []
  }, [quotation])

  useEffect(() => {
    form.setFieldsValue(initialValues)
    setQuotation(apiQuotation || [])
  }, [apiQuotation, form, initialValues])
  const handleSearch = useCallback((value: string) => {
    setTxtSearch(value)
  }, [])

  const handleExportPromoClick = useCallback(async () => {
    try {
      if (promotionId) {
        await exportPromo(+promotionId)
      }
    } catch {}
  }, [exportPromo, promotionId])

  return (
    <Form
      layout="vertical"
      autoComplete="off"
      form={form}
      initialValues={initialValues}
      onFinish={handleSubmit}
      onFinishFailed={notifyFormValidateFailed}
      scrollToFirstError
    >
      <Row gutter={[8, 8]}>
        {/* service detail */}
        <Col span={24}>
          <Card>
            <Row gutter={[24, 16]}>
              <Col span={24}>
                <Txt ag="header30">{headerPromotionTxt}</Txt>
              </Col>

              <Col span={24}>
                <Row>
                  <Col sm={12} xs={24}>
                    <Form.Item
                      name="active"
                      label="สถานะบริการโปรโมชั่นโค้ด"
                      required
                      rules={requiredCustomMessage('โปรดเลือกสถานะบริการโปรโมชั่นโค้ด')}
                    >
                      <Radio.Group options={serviceStatusOptions} />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>

              <Col sm={12} xs={24}>
                <Row align="middle" gutter={[24, 16]}>
                  <Col span={24}>
                    <Form.Item
                      label="บริการ"
                      name="serviceName"
                      rules={[
                        {
                          type: 'string',
                          message: 'โปรดกรอกข้อมูล',
                        },
                      ]}
                    >
                      {!isLoading && (
                        <Input placeholder="กรอกข้อมูล" maxLength={255} disabled={true} defaultValue={service?.name} />
                      )}
                    </Form.Item>
                  </Col>
                </Row>
              </Col>

              {!isKiosk && (
                <Col sm={12} xs={24}>
                  <Row align="middle" gutter={[24, 16]}>
                    <Col span={24}>
                      <Form.Item
                        label="ประเภทโปรโมชั่น"
                        name="mdPromoTypeId"
                        rules={[
                          {
                            required: true,
                            message: 'โปรดเลือกประเภทโปรโมชั่น',
                          },
                        ]}
                      >
                        <Select style={{ width: '100%', height: 38 }} options={optionPromoType} disabled={isEditMode} />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              )}

              <Col span={24}>
                <Row align="middle" gutter={[24, 16]}>
                  <Col span={24}>
                    <Form.Item label="คำอธิบาย(Description)" name="description" rules={REQUIRED_RULES}>
                      <TextArea
                        rows={4}
                        autoSize={{ minRows: 4, maxRows: 5 }}
                        placeholder="กรอกคำอธิบายเกี่ยวกับโปรโมชั่น code"
                        style={{ width: '100%' }}
                        disabled={isEditMode}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>

              <Col sm={12} xs={24}>
                <Row align="middle" gutter={[24, 16]}>
                  <Col span={24}>
                    <Form.Item label="ประเภทรายการ (Item Type)" name="">
                      <Input placeholder="ค่าจอง" maxLength={255} disabled={true} />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>

              <Col sm={12} xs={24}>
                <Row align="middle" gutter={[24, 16]}>
                  <Col span={24}>
                    <Form.Item
                      label="จำนวนหน่วยที่ลดต่อหนึ่งโค้ด"
                      name="discountUnit"
                      rules={[
                        ...requiredCustomMessage('กรุณากรอกจำนวนหน่วยที่ลดต่อหนึ่งโค้ด'),
                        ...NUMBER_MORE_THAN_ZERO_AND_LESS_THAN_NINETY_NINE,
                      ]}
                    >
                      <NumberInput placeholder="กรอกข้อมูล" disabled={isEditMode} />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>

              <Col span={24}>
                <Row align="middle" gutter={[24, 16]}>
                  <Col span={24}>
                    <Txt ag="header30">การใช้งานโค้ด</Txt>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="rangeDate"
                      label="วันที่เปิดใช้งานโค้ด - วันที่สิ้นสุดการใช้งานโค้ด"
                      rules={[
                        {
                          required: true,
                          message: 'กรุณาเลือกวันที่เปิดใช้งานโค้ด - วันที่สิ้นสุดการใช้งานโค้ด',
                        },
                      ]}
                    >
                      <RangePicker
                        disabled={isEditMode}
                        disabledDate={(current) => current.isBefore(dayjs().subtract(1, 'day'))}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>

              {!isKiosk && (
                <Col span={24}>
                  <Row align="middle" gutter={[24, 16]}>
                    <Col span={24}>
                      <Txt ag="header30">เอกสารที่เกี่ยวข้อง</Txt>
                    </Col>
                    <Col span={12}>
                      <Form.Item name="tbQuotaId" label="เลขที่ใบเสนอราคา">
                        <Select
                          placeholder="กรุณาเลือกใบเสนอราคา"
                          showSearch
                          optionFilterProp="label"
                          options={customerSelectOptions}
                          disabled={isEditMode}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              )}
            </Row>
          </Card>
        </Col>
        {/* emergency contact table */}
        <Col span={24}>
          <Card className="w-100">
            <Row justify="space-between">
              <Txt ag="header30">รายการโปรโมชั่นโค้ด (Promotion Code List)</Txt>

              <Row
                gutter={[
                  {
                    sm: 24,
                    xs: 16,
                  },
                  {
                    sm: 16,
                    xs: 16,
                  },
                ]}
                justify="end"
                align="middle"
              >
                {isEditMode && (
                  <Col>
                    <Button onClick={handleExportPromoClick}>Export</Button>
                  </Col>
                )}
                <Col>
                  <SearchInput placeholder="ค้นหาโปรโมชั่นโค้ดของคุณ" onSearch={handleSearch} />
                </Col>
                <Col>
                  <Button onClick={onCreatePromotionCode} disabled={isEditMode}>
                    สร้างโปรโมชั่นโค้ด
                  </Button>
                </Col>
              </Row>
            </Row>

            <TablePromotionCodeList
              value={promoDetail.filter((item) => {
                if (txtSearch !== '') {
                  return item.promoCode.includes(txtSearch)
                } else {
                  return true
                }
              })}
            />
          </Card>
        </Col>
        <ModalCreatePromotionCode visible={visible} onDismiss={onDismissClick} onSubmit={onGeneratePromotionCode} />
      </Row>

      <Card style={{ marginTop: 8 }}>
        <Row>
          <Col span={12} className="text-left">
            <Button onClick={onCancel} htmlType="reset">
              กลับ
            </Button>
          </Col>
          <Col span={12} className="text-right">
            <Button type="primary" htmlType="submit" disabled={promoDetail && promoDetail?.length > 0 ? false : true}>
              บันทึก
            </Button>
          </Col>
        </Row>
      </Card>
    </Form>
  )
}
