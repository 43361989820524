import { InputNumber, InputNumberProps } from 'antd'
import { styled } from 'goober'

type TextAlignType = 'left' | 'center' | 'right'

const CustomInputNumber = styled<{}, InputNumberProps & { align: TextAlignType }>(InputNumber)`
  width: 100%;
  input {
    text-align: ${(props) => props.align};
  }
  .ant-input-number-handler-wrap {
    display: none;
    padding: 0px 4px;
  }
`

interface INumberInputProps extends InputNumberProps {
  align?: TextAlignType
}

const NumberInput = (props: INumberInputProps) => {
  const { align = 'left', ...restProps } = props
  return (
    <CustomInputNumber
      formatter={(value) => (value ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : '')}
      parser={(value) => (value ? value.replace(/\$\s?|(,*)/g, '') : '')}
      align={align}
      {...restProps}
    />
  )
}

export default NumberInput
