import qs from 'qs'
import { useCallback, useEffect, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { ContentBody } from '_backend/components/layout/ContentBody'
import { ContentHeader } from '_backend/components/layout/ContentHeader'
import { backendPaths } from 'paths/backend-path-config'
import { useGetSearchEmergencyContactQRY } from 'services/emergency-contact/emergency-contact-service-service'
import { useGetServiceQRY, useSubmitOtherService } from 'services/enco-service/enco-service-service'
import { useGetLastServiceContentQRY } from 'services/service-content/service-content-service-service'
import { useGetSearchServiceLinkQRY } from 'services/service-link/service-link-service-service'

import { OtherServiceForm, OtherServiceFormValues } from './OtherServiceForm'

export const PageOtherServiceEdit = () => {
  const navigate = useNavigate()
  const query = useParams()
  const serviceId = Number(query.serviceId)

  const { data: serviceResponse, isLoading: isServiceLoading } = useGetServiceQRY(serviceId)
  const { data: serviceContentResponse, isLoading: isServiceContentLoading } = useGetLastServiceContentQRY(serviceId)
  const { data: emergencyContactResponse, isLoading: isEmergencyContactLoading } = useGetSearchEmergencyContactQRY(
    serviceId,
    {
      isActive: 0,
    },
  )
  const { data: serviceLinkResponse, isLoading: isServiceLinkLoading } = useGetSearchServiceLinkQRY(serviceId)

  const { onSubmitOtherService } = useSubmitOtherService(
    serviceResponse?.id || 0,
    serviceResponse?.mdServiceTypeId || 0,
  )

  const initialValues = useMemo((): Partial<OtherServiceFormValues> => {
    const { id: serviceContentId, contenttx } = serviceContentResponse || {}
    const emergencyContacts = emergencyContactResponse || []
    const serviceLinks = serviceLinkResponse || []
    return {
      id: serviceResponse?.id,
      serviceName: serviceResponse?.name,
      modifydate: serviceResponse?.modifydate,
      contactFunction: serviceResponse?.contactFunction,

      autolink: serviceResponse?.autoLink,
      icon: serviceResponse?.icon,

      bannerDesktop: serviceResponse?.coverImgWeb,
      bannerMobile: serviceResponse?.coverImgMobile,

      active: serviceResponse?.active,
      serviceContentDetail: contenttx,
      serviceContentId,

      emergencyContacts,
      serviceLinks,
    }
  }, [
    emergencyContactResponse,
    serviceContentResponse,
    serviceLinkResponse,
    serviceResponse?.active,
    serviceResponse?.autoLink,
    serviceResponse?.contactFunction,
    serviceResponse?.coverImgMobile,
    serviceResponse?.coverImgWeb,
    serviceResponse?.icon,
    serviceResponse?.id,
    serviceResponse?.modifydate,
    serviceResponse?.name,
  ])

  const isLoading = useMemo(() => {
    return isServiceLoading || isServiceContentLoading || isEmergencyContactLoading || isServiceLinkLoading
  }, [isEmergencyContactLoading, isServiceContentLoading, isServiceLinkLoading, isServiceLoading])

  const onCancel = useCallback(() => {
    navigate(
      {
        pathname: backendPaths.serviceList(),
        search: qs.stringify({
          tab: serviceResponse?.mdServiceTypeId,
        }),
      },
      { replace: true },
    )
  }, [navigate, serviceResponse?.mdServiceTypeId])

  const onSubmit = useCallback(
    async (values: OtherServiceFormValues) => {
      try {
        await onSubmitOtherService(values)
        navigate(
          {
            pathname: backendPaths.serviceList(),
            search: qs.stringify({
              tab: serviceResponse?.mdServiceTypeId,
            }),
          },
          { replace: true },
        )
      } catch (error) {
        return Promise.reject(error)
      }
    },
    [navigate, onSubmitOtherService, serviceResponse?.mdServiceTypeId],
  )

  useEffect(() => {
    if (!serviceId || (!isLoading && !serviceResponse)) {
      navigate(
        {
          pathname: backendPaths.serviceList(),
          search: qs.stringify({
            tab: serviceResponse?.mdServiceTypeId,
          }),
        },
        { replace: true },
      )
    }
  }, [isLoading, navigate, serviceId, serviceResponse])

  return (
    <>
      <ContentHeader title={serviceResponse?.name} subTitle="Service Setting" />
      <ContentBody>
        {!isLoading && serviceResponse && (
          <OtherServiceForm initialValues={initialValues} onSubmit={onSubmit} onCancel={onCancel} />
        )}
      </ContentBody>
    </>
  )
}
