import { Card, Divider, Space } from 'antd'
import { styled } from 'goober'
import { useParams } from 'react-router-dom'

import { ContentHTML } from 'components/ContentHTML'
import { Txt } from 'components/Txt'
import { CoverPageDetail } from 'components/layout/CoverPageDetail'
import { mediaQuery } from 'helpers/responsive-helper'
import { useGetServiceQRY } from 'services/enco-service/enco-service-service'
import { useGetSearchServiceContentsQRY } from 'services/service-content/service-content-service-service'
import { useGetSearchServiceLinkQRY } from 'services/service-link/service-link-service-service'

import { ButtonServiceRequest } from './ButtonServiceRequest'
import { ServiceContactList } from './ServiceContactList'

const ContentCard = styled(Card)`
  width: 100%;
  max-width: 1124px;
  border-radius: 10px;
  background-color: white;
  min-height: 500px;
  margin: 0 auto;

  .ant-card-body {
    padding: 60px 30px 60px 30px;
    @media ${mediaQuery.xs} {
      padding: 30px 15px 30px 15px;
    }
  }
`
const PageContent = styled('div')`
  background-position: top right;
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
  padding-bottom: 40px;
`
const ServiceLinks = styled('div')`
  margin-top: 24px;
  ol {
    padding-left: 30px;
  }
`

export const PageOtherServiceDetail = () => {
  const query = useParams()
  const serviceId = Number(query.serviceId)
  const { data: service } = useGetServiceQRY(serviceId)
  const { data: serviceContents } = useGetSearchServiceContentsQRY(serviceId)
  const { data: serviceLinks } = useGetSearchServiceLinkQRY(serviceId)

  const sponsorContent = serviceContents?.[0]

  return (
    <>
      <CoverPageDetail title={service?.name} url={service?.coverImgWeb} />
      <PageContent>
        <ContentCard>
          <ContentHTML htmlString={sponsorContent?.contenttx} />
          {serviceLinks && (
            <ServiceLinks>
              <Txt ag="header30">Link ที่เกี่ยวข้อง</Txt>
              <ol>
                {serviceLinks?.map((link) => (
                  <Txt ag="body24" key={link.id}>
                    <li key={link.description}>
                      <a target="_blank" href={link.link} rel="noreferrer">
                        {link.description}
                      </a>
                    </li>
                  </Txt>
                ))}
              </ol>
            </ServiceLinks>
          )}
          {service?.contactFunction === 1 && (
            <Space direction="vertical" align="center" className="w-100 py-24">
              <ButtonServiceRequest modalTitle="สนใจบริการ" serviceId={serviceId}>
                สนใจบริการของเรา คลิ๊ก
              </ButtonServiceRequest>
            </Space>
          )}
          <Divider />
          <ServiceContactList serviceId={serviceId} />
        </ContentCard>
      </PageContent>
    </>
  )
}
