import { Card, Row, Col, Form, Radio, Input } from 'antd'
import dayjs from 'dayjs'

import { REQUIRED_RULES } from '_backend/constants/form-rules'
import DatePicker from 'components/DatePicker'
import { TextArea } from 'components/Input'
import NumberInput from 'components/NumberInput'
import { Txt } from 'components/Txt'

import { TextSaleNo } from '../TextSaleNo'
import { discountTypeOptions, InvoiceFieldName, vatTypeOptions } from '../sale-constants'

interface ISectionInvoiceFieldsProps {
  readonly?: boolean
  invoiceNo?: string
}

export const SectionInvoiceFields = (props: ISectionInvoiceFieldsProps) => {
  const { invoiceNo, readonly } = props

  return (
    <Card style={{ marginBottom: 8 }}>
      <Row gutter={24}>
        <Col span={24}>
          <Txt ag="body20">เลขที่ใบวางบิล (Invoice No.)</Txt>
        </Col>
        <Col span={24} style={{ marginBottom: '16px' }}>
          <TextSaleNo no={invoiceNo || '-'} />
        </Col>
        <Form.Item name="id" hidden>
          <NumberInput />
        </Form.Item>
      </Row>
      <Row gutter={24}>
        <Col span={24} md={{ span: 6 }}>
          <Form.Item
            name={InvoiceFieldName.FIRST_DATE}
            label="วันที่ออกใบวางบิล (Invoice Datetime)"
            required
            rules={REQUIRED_RULES}
          >
            <DatePicker disabled={readonly} />
          </Form.Item>
        </Col>
        <Col span={24} md={{ span: 6 }}>
          <Form.Item
            name={InvoiceFieldName.DUE_DATE}
            label="วันที่ครบกำหนดชำระ (Due Date)"
            required
            rules={REQUIRED_RULES}
          >
            <DatePicker disabled={readonly} disabledDate={(current) => current.isBefore(dayjs().subtract(1, 'day'))} />
          </Form.Item>
        </Col>
        <Col span={24} md={{ span: 6 }}>
          <Form.Item name={InvoiceFieldName.VAT_TYPE} label="การคำนวณภาษี (VAT)" required rules={REQUIRED_RULES}>
            <Radio.Group options={vatTypeOptions} disabled={readonly} />
          </Form.Item>
        </Col>
        <Col span={24} md={{ span: 6 }}>
          <Form.Item name={InvoiceFieldName.DISCOUNT_TYPE} label="ส่วนลด (Discount)" required rules={REQUIRED_RULES}>
            <Radio.Group options={discountTypeOptions} disabled={readonly} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item name={InvoiceFieldName.TB_SERVICE_NAME} label="ประเภทบริการ">
            <Input disabled />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={24} sm={{ span: 12 }}>
          <Form.Item name={InvoiceFieldName.QUOTA_NO} label="เลข Quotation (Quotation No.)">
            <Input disabled />
          </Form.Item>
        </Col>
        <Col span={24} sm={{ span: 12 }}>
          <Form.Item name={InvoiceFieldName.QUO_DATE_DISPLAY} label="วันที่ออกเลข Quotation (Quotation Datetime)">
            <Input disabled />
          </Form.Item>
        </Col>
        <Col span={24} sm={{ span: 12 }}>
          <Form.Item name={InvoiceFieldName.ORDER_NO} label="เลข Order (Order No.)">
            <Input disabled />
          </Form.Item>
        </Col>
        <Col span={24} sm={{ span: 12 }}>
          <Form.Item name={InvoiceFieldName.ORDER_DATE_DISPLAY} label="วันที่ออกเลข Order (Order No.)">
            <Input disabled />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item name="remark" label="หมายเหตุ">
            <TextArea rows={4} disabled={readonly} />
          </Form.Item>
        </Col>
      </Row>
    </Card>
  )
}
