import { Button, Col, Row, Table, TableColumnsType } from 'antd'
import dayjs from 'dayjs'
import { useCallback, useMemo, useState } from 'react'
import { FaEye, FaFilter } from 'react-icons/fa'
import { Link } from 'react-router-dom'

import { SearchInput } from '_backend/components/SearchInput'
import { ContentBody } from '_backend/components/layout/ContentBody'
import { Txt } from 'components/Txt'
import { backendPaths } from 'paths/backend-path-config'
import { ISuggestion } from 'services/suggestion/suggestion-service-response'
import { useExportSuggestMTT, useSearchSuggestionQTY } from 'services/suggestion/suggestion-service-service'
import theme from 'theme/goober'

import { ModalAutoMessageSetting } from './ModalAutoMessageSetting'
import { ModalFilter, SubmitData } from './ModalFilter'

interface ITableData extends ISuggestion {
  recNo: number
}

const useModal = () => {
  const [visible, setVisible] = useState(false)
  const onOpen = useCallback(() => {
    setVisible(true)
  }, [])
  const onClose = useCallback(() => {
    setVisible(false)
  }, [])

  return {
    visible,
    onOpen,
    onClose,
  }
}

const columns: TableColumnsType<ITableData> = [
  {
    title: 'ลำดับ',
    align: 'center',
    dataIndex: 'recNo',
  },
  {
    title: 'คำแนะนำ',
    dataIndex: 'suggest',
  },
  {
    title: 'คะแนนความพอใจ',
    align: 'center',
    dataIndex: 'rating',
  },
  {
    title: 'ชื่อบริการ',
    dataIndex: 'servicenm',
  },
  {
    title: 'ชื่อผู้ให้ข้อมูล',
    dataIndex: 'usernm',
  },
  {
    title: 'วันที่บันทึก',
    dataIndex: 'firstdatedisplay',
  },
  {
    title: 'ไฟล์แนบ',
    align: 'center',
    render: (_: undefined, record: ITableData) => {
      const disabled = record.suggestFile?.length <= 0
      return (
        <Link
          style={{ pointerEvents: disabled ? 'none' : undefined }}
          to={backendPaths.suggestionImage({
            routeParam: {
              suggestionId: record.id,
            },
          })}
        >
          <FaEye color={disabled ? theme.color.softGray : theme.color.lightBluePrimary} />
        </Link>
      )
    },
  },
]

export const PageSuggestionList = () => {
  const filterModal = useModal()
  const autoMessageSettingModal = useModal()

  const [txtSearch, setTxtSearch] = useState<string | undefined>()
  const [filterData, setFilterData] = useState<SubmitData | undefined>()
  const handleTxtSearch = useCallback((value: string) => {
    setTxtSearch(value)
  }, [])

  const handleFilterModalSubmit = useCallback((value: SubmitData) => {
    setFilterData(value)
  }, [])

  const { data: searchSuggestionData } = useSearchSuggestionQTY({ txtSearch, ...filterData })
  const tableData = useMemo(() => {
    return searchSuggestionData
      ?.sort((a, b) => dayjs(b.firstdate).diff(dayjs(a.firstdate)))
      .map<ITableData>((v, index) => ({
        recNo: index + 1,
        ...v,
      }))
  }, [searchSuggestionData])

  const { mutateAsync: downloadFile } = useExportSuggestMTT()
  const handleDownloadFile = useCallback(async () => {
    try {
      await downloadFile({
        ...filterData,
        txtSearch,
      })
    } catch {}
  }, [downloadFile, filterData, txtSearch])

  return (
    <ContentBody>
      <Row style={{ padding: '40px 0' }} align="middle" gutter={[0, 12]} justify="space-between">
        <Col>
          <Txt ag="header40" color="graySecondaryText">
            Suggestion Monitor
          </Txt>
        </Col>
        <Col>
          <Row align="middle" gutter={[24, 12]}>
            <Col>
              <FaFilter
                color={theme.color.lightBluePrimary}
                style={{ cursor: 'pointer' }}
                onClick={filterModal.onOpen}
              />
              <ModalFilter
                onSubmit={handleFilterModalSubmit}
                visible={filterModal.visible}
                onCloseClick={filterModal.onClose}
              />
            </Col>
            <Col>
              <SearchInput placeholder="ค้นหาคำแนะนำ, ผู้ให้ข้อมูล" onSearch={handleTxtSearch} />
            </Col>
            <Col>
              <Button onClick={handleDownloadFile}>ดาวน์โหลดข้อมูล</Button>
            </Col>
            <Col>
              <Button type="primary" onClick={autoMessageSettingModal.onOpen}>
                ตั้งค่าข้อความตอบกลับอัตโนมัติ
              </Button>
              <ModalAutoMessageSetting
                visible={autoMessageSettingModal.visible}
                onCloseClick={autoMessageSettingModal.onClose}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Table columns={columns} dataSource={tableData} scroll={{ x: '100%' }} />
      </Row>
    </ContentBody>
  )
}
