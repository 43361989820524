import { Button, Col, Form, Radio, RadioChangeEvent, Row } from 'antd'
import { useCallback, useEffect } from 'react'

import { REQUIRED_RULES, TAX_ID_RULES } from '_backend/constants/form-rules'
import { BranchTypeEnum } from '_backend/modules/sale/sale-constants'
import { AppModal } from 'components/AppModal'
import { Input, TextArea } from 'components/Input'

import { ICustomerFormValues } from './SectionCustomerFields'

interface IModalCustomerFormProps {
  visible: boolean
  onDismiss: () => void
  onCreateCustomer: (values: ICustomerFormValues) => void
}

export const ModalCustomerForm = (props: IModalCustomerFormProps) => {
  const { visible, onDismiss, onCreateCustomer } = props
  const [form] = Form.useForm()
  const branchTypeValue = Form.useWatch('branchType', form)

  const handleBranchTypeChange = useCallback(
    (e: RadioChangeEvent) => {
      form.setFieldsValue({ branchCode: '', branchName: '' })
    },
    [form],
  )

  useEffect(() => {
    if (!visible) {
      form.resetFields()
    }
  }, [form, visible])

  return (
    <AppModal visible={visible} width={700} centered>
      <AppModal.Header onCloseClick={onDismiss}>เพิ่มรายชื่อลูกค้า</AppModal.Header>
      <AppModal.Body>
        <Form
          layout="vertical"
          form={form}
          onFinish={onCreateCustomer}
          scrollToFirstError
          initialValues={{ branchType: BranchTypeEnum.HQ }}
        >
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item name="name" label="ลูกค้า (Customer)" required rules={REQUIRED_RULES}>
                <Input placeholder="กรอกชื่อบริษัทหรือชื่อลูกค้าของคุณ" />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="address" label="ที่อยู่ (Address)" required rules={REQUIRED_RULES}>
                <TextArea placeholder="กรอกที่อยู่" rows={4} />
              </Form.Item>
            </Col>
            <Col span={24} sm={{ span: 12 }}>
              <Form.Item
                name="taxid"
                label="เลขประจำตัวผู้เสียภาษี"
                required
                rules={[...REQUIRED_RULES, ...TAX_ID_RULES]}
              >
                <Input placeholder="กรอกเลขประจำตัวผู้เสียภาษี" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Form.Item name="branchType" label="สำนักงาน / สาขาเลขที่" required rules={REQUIRED_RULES}>
                <Radio.Group onChange={handleBranchTypeChange}>
                  <Radio value={1}>สำนักงานใหญ่</Radio>
                  <Row style={{ marginTop: 8 }}>
                    <Col span={24}>
                      <Radio value={2}>
                        สาขา
                        <Form.Item name="branchCode" noStyle>
                          <Input
                            placeholder="สาขาเลขที่"
                            style={{ width: 200, marginLeft: 8, marginRight: 8, marginBottom: 8 }}
                            disabled={branchTypeValue !== BranchTypeEnum.NON_HQ}
                          />
                        </Form.Item>
                        <Form.Item name="branchName" noStyle>
                          <Input
                            placeholder="ชื่อสาขา"
                            style={{ width: 200 }}
                            disabled={branchTypeValue !== BranchTypeEnum.NON_HQ}
                          />
                        </Form.Item>
                      </Radio>
                    </Col>
                  </Row>
                  <Radio value={3}>บุคคลทั่วไป</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </AppModal.Body>
      <AppModal.Footer>
        <Button onClick={onDismiss}>ยกเลิก</Button>
        <Button type="primary" onClick={form.submit}>
          บันทึก
        </Button>
      </AppModal.Footer>
    </AppModal>
  )
}
