import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Card, Space, Tag } from 'antd'
import Table, { ColumnType } from 'antd/lib/table'
import { styled } from 'goober'
import { useCallback, useMemo, useState } from 'react'
import { Link, useParams } from 'react-router-dom'

import { SearchInput } from '_backend/components/SearchInput'
import { ContentBody } from '_backend/components/layout/ContentBody'
import { ContentHeader } from '_backend/components/layout/ContentHeader'
import { BackButton } from 'components/BackButton'
import { Txt } from 'components/Txt'
import { getColumnNumberByPage } from 'helpers/column-helper'
import { backendPaths } from 'paths/backend-path-config'
import { useGetServiceQRY } from 'services/enco-service/enco-service-service'
import { IGetServiceItemResponse } from 'services/item-management/item-management-response'
import { useGetServiceItemListQRY } from 'services/item-management/item-management-service'
import theme from 'theme/goober'

const EditIcon = styled(FontAwesomeIcon)`
  color: ${theme.color.lightBluePrimary};
  font-size: 24px;
  cursor: pointer;
`

export const PageServiceItemList = () => {
  const { serviceId } = useParams()

  const [txtSearch, setTxtSearch] = useState('')
  const [currentPage, setCurrentPage] = useState(1)

  const { data: dataSource } = useGetServiceItemListQRY({ txtSearch, ServiceId: Number(serviceId) })
  const { data: service } = useGetServiceQRY(Number(serviceId))

  const handleSearch = useCallback((value: string) => {
    setTxtSearch(value)
  }, [])

  const handlePageChange = useCallback((page: number, pageSize: number) => {
    setCurrentPage(page)
  }, [])

  const columns = useMemo(
    (): ColumnType<IGetServiceItemResponse>[] => [
      {
        title: 'ลำดับ',
        width: '50px',
        align: 'center',
        render: (_: any, record: IGetServiceItemResponse, index: number) => (
          <Txt ag="body20">{getColumnNumberByPage(currentPage, index)}</Txt>
        ),
      },
      {
        title: 'ชื่อไอเทม',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'ประเภทไอเทม',
        dataIndex: 'itemtypenm',
        key: 'itemtypenm',
      },
      {
        title: 'สถานะ',
        render: (_: any, record: IGetServiceItemResponse) => (
          <Tag color={record.active === 1 ? 'success' : 'default'}>{record.active === 1 ? 'Active' : 'Inactive'}</Tag>
        ),
      },
      {
        title: 'หน่วย',
        dataIndex: 'unit',
        key: 'unit',
      },
      {
        title: 'ส่วนลด',
        dataIndex: 'discount',
        key: 'discount',
        render: (_: any, record: IGetServiceItemResponse) => (
          <Txt ag="body20">{record.discount ? `${record.discount}%` : 0}</Txt>
        ),
      },
      {
        title: 'Modify By',
        dataIndex: 'modifystfnm',
        key: 'modifystfnm',
      },
      {
        title: 'Modify Date',
        dataIndex: 'modifydatenm',
        key: 'modifydatenm',
      },
      {
        title: '',
        key: 'edit',
        render: (_: any, record: IGetServiceItemResponse) => {
          const itemEditPath = backendPaths.itemManagementEdit({
            routeParam: { serviceId: Number(serviceId), itemId: record.id },
          })
          return (
            serviceId && (
              <Link to={itemEditPath}>
                <EditIcon icon={faEdit} />
              </Link>
            )
          )
        },
      },
    ],
    [currentPage, serviceId],
  )

  return (
    <>
      <ContentHeader title={service?.name} subTitle="Item Management">
        <Space size={['large', 'small']} wrap style={{ justifyContent: 'start' }}>
          <SearchInput placeholder="ค้นหาไอเทมของคุณ" onSearch={handleSearch} />
          <Link to={backendPaths.itemManagementNew({ routeParam: { serviceId: Number(serviceId) } })}>
            <Button type="primary">เพิ่มไอเทม</Button>
          </Link>
        </Space>
      </ContentHeader>
      <ContentBody>
        <Card>
          <Table
            scroll={{ x: '100%' }}
            rowKey="id"
            dataSource={dataSource}
            columns={columns}
            pagination={{
              showSizeChanger: true,
              showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
              onChange: handlePageChange,
            }}
          />
          <BackButton to={backendPaths.serviceList()} />
        </Card>
      </ContentBody>
    </>
  )
}
