import { Col, Row, Typography } from 'antd'
import { styled } from 'goober'
import { useMemo } from 'react'

import { Txt } from 'components/Txt'
import { numberFormat } from 'helpers/number-helper'
import { IMyOrderItem } from 'services/order-enco-service/order-enco-service-response'

const Layout = styled('div')`
  display: flex;
  flex-flow: column;
`

const OrderItemSummaryLayout = styled('div')`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-column-gap: 20px;
  > span {
    &:nth-child(even) {
      text-align: right;
    }
  }

  .net {
    grid-column: 1 / span 2;
    text-align: right;
  }
`

interface IMyOrderItemProps {
  data: IMyOrderItem
  showCancelButton: boolean
  onCancelOrderClick: () => void
}
export const MyOrderItem = (props: IMyOrderItemProps) => {
  const { data, showCancelButton, onCancelOrderClick } = props
  const { itemTypeId, itemnm, itemtypenm, qty, bookingDateNm, unitPrice } = data

  const itemNameLabel = useMemo(() => {
    const withPrefix = (prefix: string, label: string) => {
      if (!label.startsWith(prefix)) {
        return prefix + label
      }
      return label
    }

    switch (itemTypeId) {
      case 1:
        return withPrefix('จอง', itemtypenm)
      case 2:
        return withPrefix('จอง', itemtypenm + itemnm)
      case 3:
      default:
        return itemnm
    }
  }, [itemTypeId, itemnm, itemtypenm])

  const bookingDateLabel = useMemo(() => {
    switch (itemTypeId) {
      case 1:
      case 2:
        return bookingDateNm
      default:
        return ''
    }
  }, [bookingDateNm, itemTypeId])

  const cancelButton = useMemo(() => {
    if (!showCancelButton) {
      return
    }
    if (![1, 2].includes(itemTypeId)) {
      return
    }
    return (
      <Typography.Link onClick={onCancelOrderClick} underline>
        ยกเลิกการจอง
      </Typography.Link>
    )
  }, [showCancelButton, itemTypeId, onCancelOrderClick])

  return (
    <Layout>
      <OrderItemSummaryLayout>
        <Txt ag="body24">{itemNameLabel}</Txt>
        <div>{cancelButton}</div>
        <Txt ag="body18" color="grayPlaceholder">
          {bookingDateLabel}
        </Txt>
        <Txt ag="body18" color="grayPlaceholder">
          Quantity: {qty}
        </Txt>
        <Row className="net">
          <Col span={12}>
            <Txt ag="header24">Total</Txt>
          </Col>
          <Col span={12}>
            <Txt ag="header24" className="net-price">{`${numberFormat(unitPrice, 0)} บาท`}</Txt>
          </Col>
        </Row>
      </OrderItemSummaryLayout>
    </Layout>
  )
}
