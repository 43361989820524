import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import { GET_SERVICES } from 'services/enco-service/enco-service-service'
import { ContentType } from 'services/http-client/base-http-client'
import { encoClient } from 'services/http-client/enco-client'

import { ResponseType } from '../response-type'

import { IMutationServiceLinkParams } from './service-link-service-param'
import { IServiceLinkData } from './service-link-service-response'

const SERVICE_LINK = '/ServiceLink'
const SEARCH_SERVICE_LINK = '/SearchServiceLink'

export const useGetSearchServiceLinkQRY = (serviceId?: number) => {
  return useQuery(
    [SEARCH_SERVICE_LINK, { serviceId }],
    async () => {
      const response = await encoClient.get<ResponseType<IServiceLinkData[]>>(`${SEARCH_SERVICE_LINK}`, {
        params: {
          serviceId,
        },
      })
      return response.data.data
    },
    {
      enabled: !!serviceId,
    },
  )
}

export const useGetServiceLinkQRY = (serviceLinkId?: number) => {
  return useQuery(
    [SERVICE_LINK, { serviceLinkId }],
    async () => {
      const response = await encoClient.get<ResponseType<IServiceLinkData>>(`${SERVICE_LINK}`, {
        params: {
          id: serviceLinkId,
        },
      })
      return response.data.data
    },
    {
      enabled: !!serviceLinkId,
    },
  )
}

export const useMutationServiceLinkMTT = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async (params: IMutationServiceLinkParams) => {
      const response = await encoClient.post<ResponseType<IServiceLinkData[]>>(SERVICE_LINK, params, {
        headers: {
          'Content-Type': ContentType.JSON,
        },
      })

      return response.data.data
    },
    {
      onSuccess: (response, params) => {
        const { ServiceId: serviceId } = params
        queryClient.invalidateQueries([GET_SERVICES])
        queryClient.invalidateQueries([SEARCH_SERVICE_LINK, { serviceId }])
        queryClient.invalidateQueries([SERVICE_LINK, { serviceId }])
      },
    },
  )
}
