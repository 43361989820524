import { Button, Col, Form, Row, Select } from 'antd'
import dayjs from 'dayjs'
import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { componentDisplayDateFormat } from '_backend/constants/date-format'
import { AppModal } from 'components/AppModal'
import RangePicker from 'components/RangePicker'
import { backendPaths } from 'paths/backend-path-config'
import { GetServiceTypeEnum } from 'services/enco-service/enco-service-param'
import { IServiceData } from 'services/enco-service/enco-service-response'
import { useGetServicesQRY } from 'services/enco-service/enco-service-service'
import { IGetReportListResponse } from 'services/report/report-response'

interface ReportFilterModalProps {
  report: IGetReportListResponse
  visible: boolean
  onCloseModal: () => void
}

export interface ReportFilterFormValue {
  serviceId: number
  rangeDate: [dayjs.Dayjs, dayjs.Dayjs]
}

const getFilterValue = (index: number, values?: [dayjs.Dayjs, dayjs.Dayjs]) => {
  if (!values) return ''
  if (values.length < 1) return ''
  const value = values[index]
  if (value) return value.format(componentDisplayDateFormat)
  return ''
}

export const ReportFilterModal = (props: ReportFilterModalProps) => {
  const { visible, onCloseModal, report } = props
  const { data: services } = useGetServicesQRY({ serviceType: GetServiceTypeEnum.ALL })

  const navigate = useNavigate()

  const [form] = Form.useForm()

  const handleCloseModal = useCallback(() => {
    onCloseModal()
    form.resetFields()
  }, [onCloseModal, form])

  const handleFinishForm = useCallback(
    (values: ReportFilterFormValue) => {
      handleCloseModal()
      console.log('== ', {
        serviceId: values.serviceId,
        start: getFilterValue(0, values.rangeDate),
        end: getFilterValue(1, values.rangeDate),
      })

      navigate(
        backendPaths.report({
          routeParam: { reportId: report?.id },
          queryParam: {
            serviceId: values.serviceId,
            start: getFilterValue(0, values.rangeDate),
            end: getFilterValue(1, values.rangeDate),
          },
        }),
      )
    },
    [handleCloseModal, navigate, report?.id],
  )

  return (
    <AppModal visible={visible}>
      <AppModal.Header onCloseClick={handleCloseModal}>{report.reportName}</AppModal.Header>
      <Form labelCol={{ span: 24 }} form={form} onFinish={handleFinishForm}>
        <AppModal.Body>
          <Row gutter={24}>
            <Col sm={{ span: 12 }} span={24}>
              <Form.Item name="serviceId" label="ชื่อบริการ">
                <Select placeholder="เลือกบริการ">
                  {services &&
                    services
                      .filter((service) => Boolean(service.active))
                      .map((data: IServiceData) => (
                        <Select.Option key={data.id} value={data.id}>
                          {data.name}
                        </Select.Option>
                      ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item name="rangeDate" label="ช่วงเดือนที่ต้องการเรียกดูข้อมูล">
            <RangePicker />
          </Form.Item>
        </AppModal.Body>
        <AppModal.Footer>
          <Button onClick={handleCloseModal}>ยกเลิก</Button>

          <Button htmlType="submit">แสดงข้อมูล</Button>
        </AppModal.Footer>
      </Form>
    </AppModal>
  )
}
