import { Txt } from 'components/Txt'
import { EnumOrderStatus } from 'constants/enum-order-status'
import { ISearchOrderResponse } from 'services/order-response/order-response-service-response'
import { AppColor } from 'theme/goober'

export const getOrderStatusTagColor = (order: ISearchOrderResponse) => {
  switch (order.mdOrderStId) {
    case EnumOrderStatus.WAITING_PAYMENT:
    case EnumOrderStatus.WAITING_CONTACT:
    case EnumOrderStatus.QUOTATION_CREATED:
    case EnumOrderStatus.INVOICE_CREATED:
      return 'warning'
    case EnumOrderStatus.CONTACTED:
    case EnumOrderStatus.PAYMENT_COMPLETED:
      return 'success'
    case EnumOrderStatus.CANCELLED:
      return 'error'
    default:
      return 'blue'
  }
}

export const getOrderStatusColor = (order: ISearchOrderResponse): keyof AppColor => {
  switch (order.mdOrderStId) {
    case EnumOrderStatus.WAITING_PAYMENT:
    case EnumOrderStatus.WAITING_CONTACT:
    case EnumOrderStatus.QUOTATION_CREATED:
    case EnumOrderStatus.INVOICE_CREATED:
      return 'tagWarning'
    case EnumOrderStatus.CONTACTED:
    case EnumOrderStatus.PAYMENT_COMPLETED:
      return 'tagSuccess'
    case EnumOrderStatus.CANCELLED:
      return 'tagError'
    default:
      return 'blue'
  }
}

interface ITextOrderStatusProps {
  order: ISearchOrderResponse
}

export const TextOrderStatus = (props: ITextOrderStatusProps) => {
  const { order } = props
  return (
    <Txt ag="header40" color={getOrderStatusColor(order)}>
      {order.statusNm}
    </Txt>
  )
}
