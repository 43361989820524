type EnvKeyType =
  | 'encoApiHost'
  | 'tinyApiKey'
  | 'sentryDsn'
  | 'sentryEnvironment'
  | 'kBankScriptSrc'
  | 'kBankApiKey'
  | 'googleRecaptchaClientKey'

export const getEnv = (key: EnvKeyType) => {
  return (window as any).env[key]
}
