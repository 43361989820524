import { Space } from 'antd'
import { ColumnsType } from 'antd/es/table'
import Tag from 'antd/es/tag'
import { useState } from 'react'

import { SortableTable } from '_backend/components/SortableTable'

interface DataType {
  key: string
  seq: number
  name: string
  age: number
  address: string
  tags: string[]
}

const columns: ColumnsType<DataType> = [
  {
    title: 'ลำดับ',
    dataIndex: 'seq',
    key: 'seq',
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    render: (text) => <a href="_blank">{text}</a>,
  },
  {
    title: 'Age',
    dataIndex: 'age',
    key: 'age',
  },
  {
    title: 'Address',
    dataIndex: 'address',
    key: 'address',
  },
  {
    title: 'Tags',
    key: 'tags',
    dataIndex: 'tags',
    render: (_, { tags }) => (
      <>
        {tags.map((tag) => {
          let color = tag.length > 5 ? 'default' : 'success'
          if (tag === 'loser') {
            color = 'processing'
          }
          return (
            <Tag color={color} key={tag}>
              {tag.toUpperCase()}
            </Tag>
          )
        })}
      </>
    ),
  },
  {
    title: 'Action',
    key: 'action',
    render: (_, record) => (
      <Space size="middle">
        <a href="_blank">Invite {record.name}</a>
        <a href="_blank">Delete</a>
      </Space>
    ),
  },
]

const data: DataType[] = [
  {
    key: '1',
    seq: 1,
    name: 'John Brown',
    age: 32,
    address: 'New York No. 1 Lake Park',
    tags: ['nice', 'developer'],
  },
  {
    key: '2',
    seq: 2,
    name: 'Jim Green',
    age: 42,
    address: 'London No. 1 Lake Park',
    tags: ['loser'],
  },
  {
    key: '3',
    seq: 3,
    name: 'Joe Black',
    age: 32,
    address: 'Sidney No. 1 Lake Park',
    tags: ['cool', 'teacher'],
  },
]

export const DemoSortableTable = () => {
  const [dataSource, setDataSource] = useState<readonly DataType[]>(data)
  const [loading, setLoading] = useState(false)
  const handleSortEnd = (seq: number, item: any, newDataSource: readonly DataType[]) => {
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
      setDataSource(newDataSource)
    }, 500)
  }
  return <SortableTable columns={columns} dataSource={dataSource} loading={loading} onSortEnd={handleSortEnd} />
}
