import { Navigate, useLocation } from 'react-router-dom'

import { getToken } from 'services/auth/auth-service'

interface RequireAuthProps {
  children: JSX.Element
}

export const RequireAuth = (props: RequireAuthProps) => {
  const { children } = props
  const isLogin = getToken()
  const location = useLocation()

  if (!isLogin) {
    return <Navigate replace to="/login" state={{ from: location }} />
  }
  return children
}
