import { Col, Image, Row } from 'antd'
import { css, styled } from 'goober'
import { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { AppSwiper, IAppSwiperItem } from 'components/AppSwiper'
import { Txt } from 'components/Txt'
import { mediaQuery } from 'helpers/responsive-helper'
import { appPaths } from 'paths/path-config'
import { GetServiceContactStatusFilterEnum, GetServiceTypeEnum } from 'services/enco-service/enco-service-param'
import { useGetServicesQRY } from 'services/enco-service/enco-service-service'
import theme from 'theme/goober'

import stadiumLogo from '../../assets/images/stadium-logo.png'

const Layout = styled('div')`
  position: relative;
  height: 200px;
  background: #737791 0% 0% no-repeat padding-box;

  @media ${mediaQuery.smLte} {
    height: unset;
  }
`

const GridLayout = styled('div')`
  display: grid;
  grid-template-columns: 55% 45%;

  @media ${mediaQuery.mdLte} {
    grid-template-columns: 45% 55%;
  }

  @media ${mediaQuery.smLte} {
    grid-template-columns: 100%;
  }
`

const TextLayout = styled(Row)`
  padding: 32px;
  padding-left: 134px;
  box-sizing: border-box;

  @media ${mediaQuery.mdLte} {
    padding: 32px 60px;
  }

  @media ${mediaQuery.smLte} {
    padding: 28px 38px;
  }
`

const SwiperLayout = styled('div')`
  position: relative;
  margin: -36px 0;
  z-index: 2;

  @media ${mediaQuery.mdLte} {
    margin: -22px 0;
  }

  @media ${mediaQuery.smLte} {
    margin: 0;
  }
`

const ServiceSwiperItemCss = (props: { bgColor?: string }) => css`
  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  background-color: ${props?.bgColor || 'grey'};

  height: 274px;
  padding: 48px;
  grid-row-gap: 16px;

  box-sizing: border-box;

  cursor: pointer;

  @media ${mediaQuery.mdLte} {
    height: 243px;
    padding: 36px;
  }

  @media ${mediaQuery.smLte} {
    height: 243px;
    padding: 24px;
  }
`

const ServiceLogoImage = styled(Image)`
  width: 105px;
  height: 105px;
  object-fit: contain;
`

export const ShortCutEnCoService = () => {
  const navigate = useNavigate()

  const { data: services = [], isLoading } = useGetServicesQRY({
    serviceType: GetServiceTypeEnum.ENCO_SERVICE,
    isActive: GetServiceContactStatusFilterEnum.ACTIVE,
  })

  const onItemClick = useCallback(
    (serviceId: number) => {
      navigate(`${appPaths.encoService()}/${serviceId}`)
    },
    [navigate],
  )

  const swiperItems = useMemo(() => {
    const getBgColor = (index: number) => {
      const modIndex = index % 3
      switch (modIndex) {
        case 0:
          return theme.color.greenPrimary
        case 1:
          return theme.color.lightBluePrimary
        case 2:
        default:
          return theme.color.orangePrimary
      }
    }

    return services.map(
      (service, index): IAppSwiperItem => ({
        key: service.id,
        content: (
          <div
            className={ServiceSwiperItemCss({
              bgColor: getBgColor(index),
            })}
            onClick={onItemClick.bind(null, service.id)}
          >
            <ServiceLogoImage preview={false} src={service.icon} fallback={stadiumLogo} />
            <Txt
              ag="header40"
              color="white"
              ellipsis={{
                tooltip: service.name,
              }}
            >
              {service.name}
            </Txt>
          </div>
        ),
      }),
    )
  }, [onItemClick, services])

  return (
    <Layout>
      <GridLayout>
        <TextLayout gutter={[13, 13]}>
          <Col xs={24}>
            <Txt ag="header40" color="white">
              EnCo Service
            </Txt>
          </Col>
          <Col xs={24}>
            <Txt ag="header24" color="white">
              บริการต่าง ๆ ของเรา ที่ใช้ในการจองเพื่อรับบริการ
            </Txt>
          </Col>
        </TextLayout>
        <SwiperLayout>
          {!isLoading && (
            <AppSwiper
              items={swiperItems}
              breakpoints={{
                1: {
                  slidesPerView: Math.min(swiperItems.length || 1, 1),
                },
                576: {
                  slidesPerView: Math.min(swiperItems.length || 1, 2),
                },
              }}
            />
          )}
        </SwiperLayout>
      </GridLayout>
    </Layout>
  )
}
