import { faEye } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Card, Col, Row, Table, Tag } from 'antd'
import { ColumnType } from 'antd/lib/table'
import { styled } from 'goober'
import { useCallback, useMemo, useState } from 'react'
import { Link, useParams } from 'react-router-dom'

import { SearchInput } from '_backend/components/SearchInput'
import { ContentHeader } from '_backend/components/layout/ContentHeader'
import { BackButton } from 'components/BackButton'
import { Txt } from 'components/Txt'
import { getColumnNumberByPage } from 'helpers/column-helper'
import { truncateString } from 'helpers/string-helper'
import { backendPaths } from 'paths/backend-path-config'
import { useGetServiceQRY } from 'services/enco-service/enco-service-service'
import { IGetSearchPromotionCodeListResponse } from 'services/promotion-code/promotion-code-response'
import { useGetPromotionCodeQRY } from 'services/promotion-code/promotion-code-service'
import theme from 'theme/goober'

const EyeIcon = styled(FontAwesomeIcon)`
  min-width: 40px;
  color: ${theme.color.lightBluePrimary};
  cursor: pointer;
`
export const PagePromotionCodeList = () => {
  const { serviceId } = useParams()

  const [txtSearch, setTxtSearch] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [currentPageSize, setCurrentPageSize] = useState(10)

  const { data: service } = useGetServiceQRY(Number(serviceId))
  const { data: dataSource, isFetching } = useGetPromotionCodeQRY(Number(serviceId), txtSearch)

  const handlePageChange = useCallback((page: number, pageSize: number) => {
    setCurrentPage(page)
    setCurrentPageSize(pageSize)
  }, [])

  const renderTagActive = useCallback((isActive: boolean) => {
    if (isActive) {
      return <Tag color="success">Active</Tag>
    }
    return <Tag color="default">Inactive</Tag>
  }, [])

  const columns = useMemo(
    (): ColumnType<IGetSearchPromotionCodeListResponse>[] => [
      {
        title: 'ลำดับ',
        width: '50px',
        align: 'center',
        render: (_: any, record: IGetSearchPromotionCodeListResponse, index: number) => (
          <Txt ag="body20">{getColumnNumberByPage(currentPage, index, currentPageSize)}</Txt>
        ),
      },
      {
        title: 'วันที่เริ่มต้น',
        dataIndex: 'startdatedisplay',
      },
      {
        title: 'วันที่สิ้นสุด',
        dataIndex: 'expiredatedisplay',
      },

      {
        title: 'ประเภทโปรโมชั่น',
        dataIndex: 'promoTypeNm',
      },
      {
        title: 'คำอธิบาย',
        dataIndex: 'description',
        render: (_: any, record: IGetSearchPromotionCodeListResponse) => {
          const { description } = record
          return <span>{truncateString(description, 50)}</span>
        },
      },
      {
        title: 'สถานะ',
        dataIndex: 'active',
        render: renderTagActive,
      },
      {
        title: 'Modify Date',
        dataIndex: 'modifydatedisplay',
      },
      {
        title: 'Modify By',
        dataIndex: 'modifystfNm',
      },
      {
        dataIndex: 'action',
        width: '80px',
        render: (_: any, record: IGetSearchPromotionCodeListResponse) => (
          <Link
            to={backendPaths.serviceEncoPromotionEdit({
              routeParam: { serviceId: Number(serviceId), promotionId: record.id },
            })}
          >
            <EyeIcon icon={faEye} />
          </Link>
        ),
      },
    ],
    [currentPage, renderTagActive, serviceId, currentPageSize],
  )

  const onSearchService = useCallback((value?: string) => {
    if (value) {
      setTxtSearch(value)
    }
  }, [])
  const onShowAddServiceModal = useCallback(() => {
    //change page
  }, [])

  return (
    <>
      <ContentHeader title={service?.name} subTitle="Promotion Code Management">
        <Row
          gutter={[
            {
              sm: 24,
              xs: 16,
            },
            {
              sm: 16,
              xs: 16,
            },
          ]}
          justify="end"
          align="middle"
        >
          <Col>
            <SearchInput placeholder="ค้นหาโปรโมชั่นโค้ดของคุณ" onSearch={onSearchService} />
          </Col>
          <Col>
            <Link
              to={backendPaths.serviceEncoPromotionNew({
                routeParam: { serviceId: Number(serviceId) },
              })}
            >
              <Button type="primary" onClick={onShowAddServiceModal}>
                สร้างโปรโมชั่นโค้ด
              </Button>
            </Link>
          </Col>
        </Row>
      </ContentHeader>
      <Card style={{ width: '100%' }}>
        <Table
          scroll={{ x: '100%' }}
          dataSource={dataSource}
          columns={columns}
          loading={isFetching}
          rowKey={'id'}
          pagination={{
            showSizeChanger: true,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
            onChange: handlePageChange,
          }}
        />
        <BackButton to={backendPaths.serviceList()} />
      </Card>
    </>
  )
}
