import { faEye } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Card, Col, notification, Row, Tag } from 'antd'
import Table, { ColumnType } from 'antd/lib/table'
import { styled } from 'goober'
import { compact } from 'lodash'
import { useCallback, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import { SearchInput } from '_backend/components/SearchInput'
import { ContentBody } from '_backend/components/layout/ContentBody'
import { ContentHeader } from '_backend/components/layout/ContentHeader'
import { Txt } from 'components/Txt'
import { breakpoint } from 'helpers/responsive-helper'
import { useGetServiceQRY } from 'services/enco-service/enco-service-service'
import { ISearchOrderResponse } from 'services/order-response/order-response-service-response'
import {
  useSearchOrderResponseQRY,
  useMutationOtherServiceOrderMTT,
} from 'services/order-response/order-response-service-service'
import theme from 'theme/goober'

import { ModalEditOrderResponse, ModalEditOrderResponseFormValues } from './ModalEditOrderResponse'

const ContentHeaderLayout = styled(Col)`
  background-color: ${theme.color.white};
  padding: 24px 0;
`

const EyeIcon = styled(FontAwesomeIcon)`
  min-width: 40px;
  color: ${theme.color.lightBluePrimary};
  cursor: pointer;
`

export const PageOrderResponseList = () => {
  const query = useParams()
  const serviceId = Number(query.serviceId)

  const [visible, setVisible] = useState(false)
  const [editOrderValues, setEditOrderValues] = useState<ModalEditOrderResponseFormValues>()
  const [txtSearch, setTxtSearch] = useState('')

  const { data: service, isLoading: isServiceLoading } = useGetServiceQRY(serviceId)
  const { data: orderList, isLoading: isOrderListLoading } = useSearchOrderResponseQRY({
    ServiceId: serviceId,
    txtSearch,
  })
  const { mutate: mutateOtherServiceOrder } = useMutationOtherServiceOrderMTT()

  const isLoading = useMemo(() => {
    return isServiceLoading || isOrderListLoading
  }, [isOrderListLoading, isServiceLoading])

  const onOrderClick = useCallback((order: ISearchOrderResponse) => {
    setEditOrderValues({
      id: order.id,
      description: order.resDesc,
      email: order.email,
      firstname: order.firstname,
      lastname: order.lastname,
      tel: order.tel,
    })
    setVisible(true)
  }, [])

  const columns = useMemo(
    (): ColumnType<ISearchOrderResponse>[] => [
      {
        title: 'ลำดับ',
        width: '80px',
        align: 'center',
        render(value, record, index) {
          return index + 1
        },
      },
      {
        title: 'ชื่อผู้ติดต่อ',
        dataIndex: 'name',
        render: (value, record) => {
          return compact([record.firstname, record.lastname]).join(' ')
        },
      },
      {
        title: 'เบอร์โทรศัพท์',
        dataIndex: 'tel',
      },
      {
        title: 'อีเมล',
        dataIndex: 'email',
      },
      {
        title: 'สถานะ',
        width: '100px',
        dataIndex: 'statusNm',
        render(value) {
          return <Tag color={value ? 'success' : 'default'}>{value}</Tag>
        },
      },
      {
        title: 'รายละเอียดการติดต่อลูกค้า',
        dataIndex: 'resDesc',
        ellipsis: true,
      },
      {
        title: 'วันที่ติดต่อลูกค้า',
        dataIndex: 'responseDateNm',
      },
      {
        dataIndex: 'action',
        width: '80px',
        render: (value, record) => (
          <div style={{ width: '40px' }}>
            <EyeIcon icon={faEye} onClick={onOrderClick.bind(this, record)} />
          </div>
        ),
      },
    ],
    [onOrderClick],
  )

  const onModalDismissClick = useCallback(() => {
    setEditOrderValues(undefined)
    setVisible(false)
  }, [])

  const onModalSubmitClick = useCallback(
    (values: ModalEditOrderResponseFormValues) => {
      mutateOtherServiceOrder(
        {
          id: values.id,
          ResDesc: values.description,
        },
        {
          onSuccess: () => {
            notification.success({ message: 'สำเร็จ', description: 'บันทึกข้อความสำเร็จ', duration: 2 })
          },
        },
      )
      setEditOrderValues(undefined)
      setVisible(false)
    },
    [mutateOtherServiceOrder],
  )

  const onSearchService = useCallback((value: string) => {
    setTxtSearch(value)
  }, [])

  return (
    <>
      <ContentHeader title={service?.name} subTitle="รายการติดต่อกลับลูกค้า">
        <Row gutter={24}>
          <Col>
            <SearchInput placeholder="ค้นหาบริการของคุณ" onSearch={onSearchService} />
          </Col>
        </Row>
      </ContentHeader>

      <ContentBody>
        <Card>
          <Row>
            <ContentHeaderLayout span={24}>
              <Row gutter={[4, 4]}>
                <Col span={24}>
                  <Txt ag="body20">ชื่อบริการ (Service Name)</Txt>
                </Col>
                <Col span={24}>
                  <Txt ag="header40">{service?.name || '-'}</Txt>
                </Col>
              </Row>
            </ContentHeaderLayout>
            <Col span={24}>
              <Table
                dataSource={orderList || []}
                columns={columns}
                loading={isLoading}
                rowKey={(e) => e.id}
                scroll={{ x: breakpoint.smMax }}
                pagination={{
                  showSizeChanger: true,
                  showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                }}
              />
            </Col>
          </Row>
        </Card>
      </ContentBody>
      {visible && editOrderValues && (
        <ModalEditOrderResponse
          visible={visible}
          initialValues={editOrderValues}
          onDismiss={onModalDismissClick}
          onConfirm={onModalSubmitClick}
        />
      )}
    </>
  )
}
