import { Divider, Popover, Tag } from 'antd'
import { styled } from 'goober'
import { Fragment, useCallback, useMemo } from 'react'

import iconBell from 'assets/images/bell.svg'
import { Txt } from 'components/Txt'
import { mediaQuery } from 'helpers/responsive-helper'
import { myOrderStatusMapLabel, myOrderStatusMapTagColor } from 'modules/order/MyOrder'
import { IMyNoti } from 'services/order-enco-service/order-enco-service-response'
import { useGetMyNotiQTY } from 'services/order-enco-service/order-enco-service-service'

const Layout = styled('div')`
  display: flex;
  align-items: center;
`

const ContentLayout = styled('div')`
  width: 516px;
  max-width: 100vw;
  max-height: 360px;
  overflow-y: auto;

  .content-empty {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const NotificationWrapper = styled('div')`
  display: grid;
  grid-template-columns: 80px max-content max-content minmax(80px, auto);
  grid-column-gap: 30px;

  > * {
    &.divider {
      border-bottom-width: 0px !important;
      grid-column: 1 / -1;
      margin: 0;
      margin-top: 16px;
      margin-bottom: 8px;
      max-width: 100vw;
      min-width: unset;

      &:last-of-type {
        display: none;
      }
    }
  }

  .service-name {
    grid-column: 1 / -1;
    padding-left: 17px;
    max-width: 100vw;
  }
  .order-no,
  .order-date,
  .order-status,
  .order-remark {
    display: flex;
    flex-flow: column;
  }

  .order-no {
    padding-left: 17px;
  }
  .order-date {
  }
  .order-status {
    .status-tag {
      font: normal normal normal 20px/22px DB Heavent;
      height: 24px;
      text-align: center;
      border-radius: 40px;
      margin-right: 0;
      background-color: transparent;
      border: unset;
      padding: 0;
      text-align: left;
    }
  }
  .order-remark {
    padding-right: 17px;
  }

  @media ${mediaQuery.xsOnly} {
    max-width: 100vw;
    .order-remark {
      padding: 0 17px;
      grid-column: 1 / -1;
      max-width: 100vw;
    }
  }
`

const NotificationIcon = styled('img')`
  cursor: pointer;
`

interface INotificationMenuProps {}
export const NotificationMenu = (props: INotificationMenuProps) => {
  const { data: myNoti } = useGetMyNotiQTY()

  const renderNotificationItem = useCallback((notiItem: IMyNoti) => {
    const { servicenm, orderNo, orderDateTimeNm, statusnm, remark } = notiItem
    return (
      <Fragment key={[orderNo, orderDateTimeNm, statusnm].join('-')}>
        <Txt className="service-name" ag="header18">
          {servicenm}
        </Txt>
        <div className="order-no">
          <Txt ag="body18" color="grayBorder">
            Order No.
          </Txt>
          <Txt ag="body20" color="grayBorder">
            {orderNo}
          </Txt>
        </div>
        <div className="order-date">
          <Txt ag="body18" color="grayBorder">
            Order Date
          </Txt>
          <Txt ag="body20" color="grayBorder">
            {orderDateTimeNm}
          </Txt>
        </div>
        <div className="order-status">
          <Txt ag="body18" color="grayBorder">
            Order Status
          </Txt>
          <Tag className="status-tag" color={myOrderStatusMapTagColor[statusnm]}>
            {myOrderStatusMapLabel[statusnm]}
          </Tag>
        </div>
        <div className="order-remark">
          {!!remark && (
            <>
              <Txt ag="body18" color="grayBorder">
                Remark
              </Txt>
              <Txt ag="body20" color="redPrimary" ellipsis>
                {remark}
              </Txt>
            </>
          )}
        </div>
        <Divider className="divider" />
      </Fragment>
    )
  }, [])

  const content = useMemo(() => {
    if (!myNoti?.length) {
      return (
        <ContentLayout>
          <div className="content-empty">
            <Txt ag="body18">ไม่มีข้อมูล</Txt>
          </div>
        </ContentLayout>
      )
    }

    return (
      <ContentLayout>
        <NotificationWrapper>{myNoti?.map(renderNotificationItem)}</NotificationWrapper>
      </ContentLayout>
    )
  }, [myNoti, renderNotificationItem])

  return (
    <Layout>
      <Popover
        overlayClassName={'noti-menu-popover'}
        openClassName="test-popover"
        trigger="click"
        placement="bottomRight"
        content={content}
        arrowPointAtCenter
      >
        <NotificationIcon src={iconBell} alt="Bell Icon" />
      </Popover>
    </Layout>
  )
}
