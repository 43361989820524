import { Tag } from 'antd'
import { styled } from 'goober'
import { useMediaQuery } from 'react-responsive'

import { Txt } from 'components/Txt'

export const breakpoint = {
  smMin: '576px',
  mdMin: '768px',
  lgMin: '992px',
  xlMin: '1200px',
  xxlMin: '1600px',

  xsMax: '575.98px',
  smMax: '767.98px',
  mdMax: '991.98px',
  lgMax: '1199.98px',
  xlMax: '1599.98px',
}

export const mediaQuery = {
  xs: `screen and (max-width: ${breakpoint.xsMax})`,
  sm: `screen and (min-width: ${breakpoint.smMin})`,
  md: `screen and (min-width: ${breakpoint.mdMin})`,
  lg: `screen and (min-width: ${breakpoint.lgMin})`,
  xl: `screen and (min-width: ${breakpoint.xlMin})`,
  xxl: `screen and (min-width: ${breakpoint.xxlMin})`,

  xsOnly: `screen and (max-width: ${breakpoint.xsMax})`,
  smOnly: `screen and (min-width: ${breakpoint.smMin}) and (max-width: ${breakpoint.smMax})`,
  mdOnly: `screen and (min-width: ${breakpoint.mdMin}) and (max-width: ${breakpoint.mdMax})`,
  lgOnly: `screen and (min-width: ${breakpoint.lgMin}) and (max-width: ${breakpoint.lgMax})`,
  xlOnly: `screen and (min-width: ${breakpoint.xlMin}) and (max-width: ${breakpoint.xlMax})`,
  xxlOnly: `screen and (min-width: ${breakpoint.xxlMin})`,

  smGte: `screen and (min-width: ${breakpoint.smMin})`,
  mdGte: `screen and (min-width: ${breakpoint.mdMin})`,
  lgGte: `screen and (min-width: ${breakpoint.lgMin})`,
  xlGte: `screen and (min-width: ${breakpoint.xlMin})`,
  xxlGte: `screen and (min-width: ${breakpoint.xxlMin})`,

  smLte: `screen and (max-width: ${breakpoint.smMax})`,
  mdLte: `screen and (max-width: ${breakpoint.mdMax})`,
  lgLte: `screen and (max-width: ${breakpoint.lgMax})`,
  xlLte: `screen and (max-width: ${breakpoint.xlMax})`,
}

type ResponsiveType = {
  isXs: boolean
  isSm: boolean
  isMd: boolean
  isLg: boolean
  isXl: boolean
  isXxl: boolean
  isXsOnly: boolean
  isSmOnly: boolean
  isMdOnly: boolean
  isLgOnly: boolean
  isXlOnly: boolean
  isXxlOnly: boolean
}

export type PropsWithResponsive<P = unknown> = P & ResponsiveType

export const useResponsive = () => {
  const isXs = useMediaQuery({ query: mediaQuery.xs })
  const isSm = useMediaQuery({ query: mediaQuery.sm })
  const isMd = useMediaQuery({ query: mediaQuery.md })
  const isLg = useMediaQuery({ query: mediaQuery.lg })
  const isXl = useMediaQuery({ query: mediaQuery.xl })
  const isXxl = useMediaQuery({ query: mediaQuery.xxl })

  const isXsOnly = useMediaQuery({ query: mediaQuery.xsOnly })
  const isSmOnly = useMediaQuery({ query: mediaQuery.smOnly })
  const isMdOnly = useMediaQuery({ query: mediaQuery.mdOnly })
  const isLgOnly = useMediaQuery({ query: mediaQuery.lgOnly })
  const isXlOnly = useMediaQuery({ query: mediaQuery.xlOnly })
  const isXxlOnly = useMediaQuery({ query: mediaQuery.xxlOnly })

  return {
    isXs,
    isSm,
    isMd,
    isLg,
    isXl,
    isXxl,
    isXsOnly,
    isSmOnly,
    isMdOnly,
    isLgOnly,
    isXlOnly,
    isXxlOnly,
  }
}

const getCurrentBreakpoint = ({ isXsOnly, isSmOnly, isMdOnly, isLgOnly, isXlOnly, isXxlOnly }: ResponsiveType) => {
  if (isXsOnly) return 'xs'
  if (isSmOnly) return 'sm'
  if (isMdOnly) return 'md'
  if (isLgOnly) return 'lg'
  if (isXlOnly) return 'xl'
  if (isXxlOnly) return 'xxl'
}

const getGridBreakpoints = ({ isXs, isSm, isMd, isLg, isXl, isXxl }: ResponsiveType) => {
  const breakpoints = []
  if (isXs) breakpoints.push('xs')
  if (isSm) breakpoints.push('sm')
  if (isMd) breakpoints.push('md')
  if (isLg) breakpoints.push('lg')
  if (isXl) breakpoints.push('xl')
  if (isXxl) breakpoints.push('xxl')
  return breakpoints
}

const ResponsiveLabel = styled('div')`
  position: fixed;
  z-index: 99999;
  bottom: 16px;
  right: 16px;
  .ant-tag {
    font-size: 24px;
  }
`

export const ResponsiveDebugger = () => {
  const responsive = useResponsive()
  const gridBreakpoints = getGridBreakpoints(responsive)
  const currentBreakpoint = getCurrentBreakpoint(responsive)

  return (
    <ResponsiveLabel>
      <Txt ag="body24">Grid: </Txt>
      {gridBreakpoints.map((bp) => (
        <Tag color="success" key={bp}>
          {bp}
        </Tag>
      ))}
      <Txt ag="body24">Only: </Txt>
      <Tag color="processing">{currentBreakpoint}</Tag>
    </ResponsiveLabel>
  )
}
