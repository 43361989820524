import { Button, Col, DatePicker, Form, Radio, Row, Space } from 'antd'
import dayjs from 'dayjs'
import { useCallback, useEffect, useMemo } from 'react'

import { componentDisplayDateFormat } from '_backend/constants/date-format'
import { REQUIRED_RULES } from '_backend/constants/form-rules'
import { AppModal } from 'components/AppModal'
import { Input } from 'components/Input'
import { ISubmitKioskPeriodParams } from 'services/kiosk-period/kiosk-period-params'

export interface IModalKioskPeriodFormValues extends Omit<ISubmitKioskPeriodParams, 'tbServiceId'> {}

const statusOptions = [
  {
    value: 1,
    label: 'Active',
  },
  {
    value: 0,
    label: 'Inactive',
  },
]

interface IModalKioskPeriodProps {
  visible: boolean
  initialValues?: Partial<IModalKioskPeriodFormValues>
  onDismiss: () => void
  onConfirm: (values: IModalKioskPeriodFormValues) => void
  onCancelKioskPeriodClick: (kioskPeriodId: number) => void
}
export const ModalKioskPeriod = (props: IModalKioskPeriodProps) => {
  const { initialValues: preInitialValues = {}, visible, onDismiss, onConfirm, onCancelKioskPeriodClick } = props

  const [form] = Form.useForm<IModalKioskPeriodFormValues>()

  const isEditMode = useMemo(() => {
    if (preInitialValues?.id) return true
    if (preInitialValues?.id === 0) return true
    return false
  }, [preInitialValues?.id])

  const initialValues = useMemo((): Partial<IModalKioskPeriodFormValues> => {
    const {
      id,
      description = '',
      isActive = 1,
      startdate,
      enddate,
      startBookingDate,
      endBookingDate,
      ...restValues
    } = preInitialValues
    return {
      id,
      description,
      isActive,
      startdate: startdate ? dayjs(startdate) : undefined,
      enddate: enddate ? dayjs(enddate) : undefined,
      startBookingDate: startBookingDate ? dayjs(startBookingDate) : undefined,
      endBookingDate: endBookingDate ? dayjs(endBookingDate) : undefined,
      ...restValues,
    }
  }, [preInitialValues])

  const handleDismiss = useCallback(() => {
    form.resetFields()
    onDismiss()
  }, [form, onDismiss])

  const onSubmit = useCallback(
    (values: IModalKioskPeriodFormValues) => {
      onConfirm({
        ...values,
        id: preInitialValues?.id ?? 0,
        startdate: dayjs(values.startdate).format(componentDisplayDateFormat),
        enddate: dayjs(values.enddate).format(componentDisplayDateFormat),
        startBookingDate: dayjs(values.startBookingDate).format(componentDisplayDateFormat),
        endBookingDate: values.endBookingDate ? dayjs(values.endBookingDate).format(componentDisplayDateFormat) : null,
      })
      form.resetFields()
    },
    [form, onConfirm, preInitialValues],
  )

  const modalHeaderLabel = useMemo(() => {
    if (isEditMode) return 'แก้ไขรอบการขาย'
    return 'เพิ่มรอบการขาย'
  }, [isEditMode])

  const submitButtonLabel = useMemo(() => {
    return 'บันทึก'
  }, [])

  useEffect(() => {
    form.setFieldsValue(initialValues)
  }, [form, initialValues])

  return (
    <AppModal visible={visible} destroyOnClose forceRender>
      <Form form={form} onFinish={onSubmit} layout="vertical" autoComplete="off">
        <AppModal.Header onCloseClick={handleDismiss}>{modalHeaderLabel}</AppModal.Header>
        <AppModal.Body>
          <Form.Item label="รอบการขาย" name="description" rules={REQUIRED_RULES} required>
            <Input placeholder="รอบการขาย" maxLength={255} />
          </Form.Item>
          <Form.Item label="วันที่เริ่มรอบ" name="startdate" rules={REQUIRED_RULES} required>
            <DatePicker />
          </Form.Item>
          <Form.Item label="วันที่สิ้นสุดรอบ" name="enddate" rules={REQUIRED_RULES} required>
            <DatePicker />
          </Form.Item>
          <Form.Item label="สถานะบริการ (Status)" name="isActive" rules={REQUIRED_RULES}>
            <Radio.Group options={statusOptions} />
          </Form.Item>
          <Form.Item label="วันที่เริ่มจองได้" name="startBookingDate" rules={REQUIRED_RULES} required>
            <DatePicker />
          </Form.Item>
          <Form.Item label="วันที่สิ้นสุดการจอง" name="endBookingDate" rules={REQUIRED_RULES} required>
            <DatePicker />
          </Form.Item>
        </AppModal.Body>
        <Row className="custom-modal-footer" wrap={false} justify="space-between" style={{ width: '100%' }}>
          <Col>
            <Button onClick={handleDismiss}>ยกเลิก</Button>
          </Col>
          <Col>
            <Space size={18} direction="horizontal">
              {preInitialValues.id && (
                <Button type="primary" onClick={onCancelKioskPeriodClick.bind(null, preInitialValues.id)} danger>
                  ยกเลิกรอบการขาย
                </Button>
              )}
              <Button type="primary" htmlType="submit">
                {submitButtonLabel}
              </Button>
            </Space>
          </Col>
        </Row>
      </Form>
    </AppModal>
  )
}
