import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Card, Col, notification, Row, Table, Tag } from 'antd'
import { ColumnType } from 'antd/lib/table'
import { styled } from 'goober'
import { useCallback, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import { ContentHeader } from '_backend/components/layout/ContentHeader'
import { BackButton } from 'components/BackButton'
import { Txt } from 'components/Txt'
import { getColumnNumberByPage } from 'helpers/column-helper'
import { useVisible } from 'helpers/modal-helper'
import { backendPaths } from 'paths/backend-path-config'
import { useGetServiceQRY } from 'services/enco-service/enco-service-service'
import { IGetKioskPeriodListResponse } from 'services/kiosk-period/kiosk-period-response'
import {
  useDeleteKioskPeriodMTT,
  useGetKioskPeriodListQRY,
  useSaveKioskPeriodMTT,
} from 'services/kiosk-period/kiosk-period-service'
import theme from 'theme/goober'

import { IModalKioskPeriodFormValues, ModalKioskPeriod } from './ModalKioskPeriod'

const EyeIcon = styled(FontAwesomeIcon)`
  min-width: 40px;
  color: ${theme.color.lightBluePrimary};
  cursor: pointer;
`
export const PageKioskPeriodList = () => {
  const { serviceId } = useParams()

  const [txtSearch /* setTxtSearch */] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const [currentPageSize, setCurrentPageSize] = useState(10)

  const { data: service } = useGetServiceQRY(Number(serviceId))
  const { data: dataSource, isFetching } = useGetKioskPeriodListQRY(Number(serviceId), txtSearch)
  const { mutate: saveKioskPeriod } = useSaveKioskPeriodMTT()
  const { mutate: deleteKioskPeriod } = useDeleteKioskPeriodMTT()

  const modalKioskPeriodVisible = useVisible()
  const [modalKioskPeriodFormValues, setModalKioskPeriodFormValues] = useState<Partial<IModalKioskPeriodFormValues>>({})

  const handlePageChange = useCallback((page: number, pageSize: number) => {
    setCurrentPage(page)
    setCurrentPageSize(pageSize)
  }, [])

  const renderTagActive = useCallback((isActive: boolean) => {
    if (isActive) {
      return <Tag color="success">Active</Tag>
    }
    return <Tag color="default">Inactive</Tag>
  }, [])

  const onModalKioskPeriodDismissClick = useCallback(() => {
    modalKioskPeriodVisible.close()
    setModalKioskPeriodFormValues({})
  }, [modalKioskPeriodVisible])

  const onModalKioskPeriodConfirmClick = useCallback(
    (data: IModalKioskPeriodFormValues) => {
      if (serviceId) {
        saveKioskPeriod(
          { ...data, tbServiceId: +serviceId },
          {
            onSuccess() {
              notification.success({ message: 'สำเร็จ', description: 'บันทึกรอบการขายสำเร็จ', duration: 2 })
            },
            onSettled: () => {
              setModalKioskPeriodFormValues({})
            },
          },
        )
        modalKioskPeriodVisible.close()
      }
    },
    [modalKioskPeriodVisible, saveKioskPeriod, serviceId],
  )

  const onKioskPeriodEditClick = useCallback(
    (d: IGetKioskPeriodListResponse) => {
      setModalKioskPeriodFormValues({
        id: d.id ?? 0,
        description: d.description,
        isActive: d.isActive,
        startdate: d.startdate,
        enddate: d.enddate,
        startBookingDate: d.startBookingDate,
        endBookingDate: d.endBookingDate,
      })
      modalKioskPeriodVisible.open()
    },
    [modalKioskPeriodVisible],
  )

  const onModalKioskPeriodDeleteClick = useCallback(
    (kioskPeriodId: number) => {
      if (kioskPeriodId) {
        deleteKioskPeriod(kioskPeriodId, {
          onSuccess() {
            notification.success({ message: 'สำเร็จ', description: 'ยกเลิกรอบการขายสำเร็จ', duration: 2 })
          },
          onSettled: () => {
            setModalKioskPeriodFormValues({})
          },
        })
        modalKioskPeriodVisible.close()
      }
    },
    [deleteKioskPeriod, modalKioskPeriodVisible],
  )

  const columns = useMemo(
    (): ColumnType<IGetKioskPeriodListResponse>[] => [
      {
        title: 'ลำดับ',
        width: '50px',
        align: 'center',
        render: (_: any, record: IGetKioskPeriodListResponse, index: number) => (
          <Txt ag="body20">{getColumnNumberByPage(currentPage, index, currentPageSize)}</Txt>
        ),
      },
      {
        title: 'รอบการขาย',
        dataIndex: 'description',
      },
      {
        title: 'เริ่มรอบ',
        dataIndex: 'startdateDisplay',
      },
      {
        title: 'จบรอบ',
        dataIndex: 'enddateDisplay',
      },
      {
        title: 'เริ่มจอง',
        dataIndex: 'startBookingDateDisplay',
      },
      {
        title: 'สถานะ',
        dataIndex: 'isActive',
        render: renderTagActive,
      },
      {
        title: 'Modify Date',
        dataIndex: 'modifydateDisplay',
      },
      {
        title: 'Modify By',
        dataIndex: 'modifystfDisplay',
      },
      {
        dataIndex: 'action',
        width: '80px',
        render: (_: any, record: IGetKioskPeriodListResponse) => (
          <EyeIcon icon={faEdit} onClick={onKioskPeriodEditClick.bind(null, record)} />
        ),
      },
    ],
    [renderTagActive, currentPage, currentPageSize, onKioskPeriodEditClick],
  )

  // const onSearchService = useCallback((value?: string) => {
  //   if (value) {
  //     setTxtSearch(value)
  //   }
  // }, [])

  return (
    <>
      <ContentHeader title={service?.name} subTitle="รอบการขาย">
        <Row
          gutter={[
            {
              sm: 24,
              xs: 16,
            },
            {
              sm: 16,
              xs: 16,
            },
          ]}
          justify="end"
          align="middle"
        >
          {/* <Col>
            <SearchInput placeholder="ค้นหารอบการขายของคุณ" onSearch={onSearchService} />
          </Col> */}
          <Col>
            <Button type="primary" onClick={modalKioskPeriodVisible.open}>
              เพิ่มรอบการขาย
            </Button>
          </Col>
        </Row>
      </ContentHeader>
      <Card style={{ width: '100%' }}>
        <Table
          scroll={{ x: '100%' }}
          dataSource={dataSource}
          columns={columns}
          loading={isFetching}
          rowKey={'id'}
          pagination={{
            showSizeChanger: true,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
            onChange: handlePageChange,
          }}
        />
        <BackButton to={backendPaths.serviceList()} />
      </Card>

      <ModalKioskPeriod
        visible={modalKioskPeriodVisible.visible}
        initialValues={modalKioskPeriodFormValues}
        onDismiss={onModalKioskPeriodDismissClick}
        onConfirm={onModalKioskPeriodConfirmClick}
        onCancelKioskPeriodClick={onModalKioskPeriodDeleteClick}
      />
    </>
  )
}
