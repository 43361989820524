import { Radio } from 'antd'
import { useState } from 'react'

import type { RadioChangeEvent } from 'antd'

const plainOptions = ['Apple', 'Pear', 'Orange']
const options = [
  { label: 'Apple', value: 'Apple' },
  { label: 'Pear', value: 'Pear' },
  { label: 'Orange', value: 'Orange' },
]
const optionsWithDisabled = [
  { label: 'Apple', value: 'Apple' },
  { label: 'Pear', value: 'Pear' },
  { label: 'Orange', value: 'Orange', disabled: true },
]

export const WikiRadio = () => {
  const [value1, setValue1] = useState('Apple')
  const [value2, setValue2] = useState('Apple')
  const [value3, setValue3] = useState('Apple')
  const [value4, setValue4] = useState('Apple')

  const onChange1 = ({ target: { value } }: RadioChangeEvent) => {
    console.log('radio1 checked', value)
    setValue1(value)
  }

  const onChange2 = ({ target: { value } }: RadioChangeEvent) => {
    console.log('radio2 checked', value)
    setValue2(value)
  }

  const onChange3 = ({ target: { value } }: RadioChangeEvent) => {
    console.log('radio3 checked', value)
    setValue3(value)
  }

  const onChange4 = ({ target: { value } }: RadioChangeEvent) => {
    console.log('radio4 checked', value)
    setValue4(value)
  }

  return (
    <>
      <Radio.Group options={plainOptions} onChange={onChange1} value={value1} />
      <br />
      <Radio.Group options={optionsWithDisabled} onChange={onChange2} value={value2} />
      <br />
      <br />
      <Radio.Group options={options} onChange={onChange3} value={value3} optionType="button" buttonStyle="solid" />
      <br />
      <br />
      <Radio.Group
        options={optionsWithDisabled}
        onChange={onChange4}
        value={value4}
        optionType="button"
        buttonStyle="solid"
      />
      <div style={{ textAlign: 'start', marginTop: 16, textDecoration: 'underline' }}>
        <a href="https://ant.design/components/radio/" target="_blank" rel="noreferrer">
          Antd Radio Link
        </a>
      </div>
      <div style={{ textAlign: 'start', marginTop: 16, whiteSpace: 'pre' }}>{codeText}</div>
    </>
  )
}

const codeText = `import type { RadioChangeEvent } from 'antd'
import { Radio } from 'antd'
import { useState } from 'react'

const plainOptions = ['Apple', 'Pear', 'Orange']
const options = [
  { label: 'Apple', value: 'Apple' },
  { label: 'Pear', value: 'Pear' },
  { label: 'Orange', value: 'Orange' },
]
const optionsWithDisabled = [
  { label: 'Apple', value: 'Apple' },
  { label: 'Pear', value: 'Pear' },
  { label: 'Orange', value: 'Orange', disabled: true },
]

export const WikiRadio = () => {
  const [value1, setValue1] = useState('Apple')
  const [value2, setValue2] = useState('Apple')
  const [value3, setValue3] = useState('Apple')
  const [value4, setValue4] = useState('Apple')

  const onChange1 = ({ target: { value } }: RadioChangeEvent) => {
    console.log('radio1 checked', value)
    setValue1(value)
  }

  const onChange2 = ({ target: { value } }: RadioChangeEvent) => {
    console.log('radio2 checked', value)
    setValue2(value)
  }

  const onChange3 = ({ target: { value } }: RadioChangeEvent) => {
    console.log('radio3 checked', value)
    setValue3(value)
  }

  const onChange4 = ({ target: { value } }: RadioChangeEvent) => {
    console.log('radio4 checked', value)
    setValue4(value)
  }

  return (
    <>
      <Radio.Group options={plainOptions} onChange={onChange1} value={value1} />
      <br />
      <Radio.Group options={optionsWithDisabled} onChange={onChange2} value={value2} />
      <br />
      <br />
      <Radio.Group options={options} onChange={onChange3} value={value3} optionType="button" buttonStyle="solid" />
      <br />
      <br />
      <Radio.Group
        options={optionsWithDisabled}
        onChange={onChange4}
        value={value4}
        optionType="button"
        buttonStyle="solid"
      />
    </>
  )
}
`
