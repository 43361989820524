import { Button, Form, Select, Space } from 'antd'
import { BaseOptionType } from 'antd/lib/select'
import { map } from 'lodash'
import { useCallback, useMemo } from 'react'

import { AppModal } from 'components/AppModal'
import { GetServiceTypeEnum } from 'services/enco-service/enco-service-param'
import { useGetMasterServiceTypeQRY } from 'services/enco-service/enco-service-service'

interface ModalServiceTypeFormValues {
  serviceType: GetServiceTypeEnum
}

interface IModalServiceTypeProps {
  visible: boolean
  onDismiss: () => void
  onConfirm: (serviceType: GetServiceTypeEnum) => void
}

export const ModalServiceType = (props: IModalServiceTypeProps) => {
  const { visible, onDismiss, onConfirm } = props

  const [form] = Form.useForm<ModalServiceTypeFormValues>()
  const serviceType = Form.useWatch('serviceType', form)

  const { data: masterServiceTypes } = useGetMasterServiceTypeQRY()

  const serviceOptions = useMemo(
    () =>
      map(masterServiceTypes || [], (v): BaseOptionType => {
        return {
          value: v.id,
          label: v.serviceType,
        }
      }),
    [masterServiceTypes],
  )

  const onSubmit = useCallback(
    (values: ModalServiceTypeFormValues) => {
      onConfirm(values.serviceType)
    },
    [onConfirm],
  )

  return (
    <AppModal visible={visible} destroyOnClose forceRender>
      <Form form={form} onFinish={onSubmit} layout="vertical" autoComplete="off">
        <AppModal.Header onCloseClick={onDismiss}>ประเภทบริการ</AppModal.Header>
        <AppModal.Body>
          <Form.Item label="ประเภทบริการ" name="serviceType" required>
            <Select<GetServiceTypeEnum> placeholder="โปรดเลือกประเภทบริการ" style={{ width: '100%' }}>
              {serviceOptions.map((option) => (
                <Select.Option key={option.value} value={option.value}>
                  {option.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </AppModal.Body>
        <AppModal.Footer>
          <Form.Item shouldUpdate>
            <Space size={24} direction="horizontal">
              <Button onClick={onDismiss}>ยกเลิก</Button>
              <Button type="primary" htmlType="submit" disabled={!serviceType}>
                สร้างบริการ
              </Button>
            </Space>
          </Form.Item>
        </AppModal.Footer>
      </Form>
    </AppModal>
  )
}
