import { useMemo } from 'react'

import { IDay, PropertySlotForm } from './PropertySlotForm'

export const PagePropertyNew = () => {
  const initialValuesServiceProp = useMemo(
    () => ({
      active: 1,
      servicePropRelates: [],
    }),
    [],
  )

  const initialValuesSlot = useMemo(
    () => ({
      sun: 1,
      mon: 1,
      tue: 1,
      wed: 1,
      thu: 1,
      fri: 1,
      sat: 1,
      [IDay.mon]: [
        { mdSlotIdStart: 1, mdSlotIdEnd: 22, slotType: 'Normal' },
        { mdSlotIdStart: 23, mdSlotIdEnd: 32, slotType: 'Peak' },
      ],
      [IDay.fri]: [
        { mdSlotIdStart: 1, mdSlotIdEnd: 22, slotType: 'Normal' },
        { mdSlotIdStart: 23, mdSlotIdEnd: 32, slotType: 'Peak' },
      ],
      [IDay.sat]: [
        { mdSlotIdStart: 1, mdSlotIdEnd: 22, slotType: 'Normal' },
        { mdSlotIdStart: 23, mdSlotIdEnd: 32, slotType: 'Peak' },
      ],
      [IDay.sun]: [
        { mdSlotIdStart: 1, mdSlotIdEnd: 22, slotType: 'Normal' },
        { mdSlotIdStart: 23, mdSlotIdEnd: 32, slotType: 'Peak' },
      ],
      [IDay.thu]: [
        { mdSlotIdStart: 1, mdSlotIdEnd: 22, slotType: 'Normal' },
        { mdSlotIdStart: 23, mdSlotIdEnd: 32, slotType: 'Peak' },
      ],
      [IDay.wed]: [
        { mdSlotIdStart: 1, mdSlotIdEnd: 22, slotType: 'Normal' },
        { mdSlotIdStart: 23, mdSlotIdEnd: 32, slotType: 'Peak' },
      ],
      [IDay.tue]: [
        { mdSlotIdStart: 1, mdSlotIdEnd: 22, slotType: 'Normal' },
        { mdSlotIdStart: 23, mdSlotIdEnd: 32, slotType: 'Peak' },
      ],
    }),
    [],
  )

  return <PropertySlotForm initialValuesSlot={initialValuesSlot} initialValuesServiceProp={initialValuesServiceProp} />
}
