import { Button, Form, Input } from 'antd'
import { useCallback, useEffect } from 'react'

import { PASSWORD_RULE, REQUIRED_RULES } from '_backend/constants/form-rules'
import { AppModal } from 'components/AppModal'
import { Txt } from 'components/Txt'

export interface IUserResetPasswordFormValues {
  password: string
  confirmPassword: string
}

interface IUserResetPasswordModalType {
  visible: boolean
  onClickConfirm: (values: IUserResetPasswordFormValues) => void
  onCloseModal: () => void
}

const DEFAULT_RESET_PASSWORD_RULES = [...REQUIRED_RULES, ...PASSWORD_RULE]

export const UserResetPasswordModal = (props: IUserResetPasswordModalType) => {
  const { visible, onCloseModal, onClickConfirm } = props

  const [form] = Form.useForm<IUserResetPasswordFormValues>()

  const handleSubmitForm = useCallback(
    (values: IUserResetPasswordFormValues) => {
      onClickConfirm(values)
    },
    [onClickConfirm],
  )

  const handleClickConfirm = useCallback(async () => {
    try {
      await form.validateFields()
      form.submit()
    } catch (error) {
      //
    }
  }, [form])

  useEffect(() => {
    if (visible) {
      form.resetFields()
    }
  }, [form, visible])

  return (
    <AppModal visible={visible} width={500}>
      <AppModal.Header onCloseClick={onCloseModal}>Reset Password</AppModal.Header>
      <AppModal.Body>
        <Form form={form} layout="vertical" onFinish={handleSubmitForm}>
          <Form.Item label="New Password" name="password" rules={DEFAULT_RESET_PASSWORD_RULES}>
            <Input.Password placeholder="Password" maxLength={16} />
          </Form.Item>
          <Form.Item
            label="Confirm New Password"
            name="confirmPassword"
            dependencies={['password']}
            rules={[
              ...DEFAULT_RESET_PASSWORD_RULES,
              ({ getFieldValue }) => ({
                validator(_, confirmPassword) {
                  const password = getFieldValue('password')
                  if (password && confirmPassword && password !== confirmPassword) {
                    return Promise.reject(new Error('Confirm New Password not matching Password'))
                  }

                  return Promise.resolve()
                },
              }),
            ]}
          >
            <Input.Password placeholder="Confirm New Password" maxLength={16} />
          </Form.Item>
          <Form.Item noStyle>
            <Txt ag="body18">
              {`*** ต้องประกอบด้วย a-z, A-Z และอักษระ ~\`!@#$%^&*()_-+={[}]|\:;"'<,>.?/ อย่างละ 1 ตัว
              ทั้งหมด 8-16 ตัวอักษร`}
            </Txt>
            <Txt ag="body16"></Txt>
          </Form.Item>
        </Form>
      </AppModal.Body>
      <AppModal.Footer>
        <Button onClick={onCloseModal}>ยกเลิก</Button>
        <Button type="primary" onClick={handleClickConfirm}>
          บันทึก
        </Button>
      </AppModal.Footer>
    </AppModal>
  )
}
