import { faTrashCan } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Image, Progress } from 'antd'
import { styled } from 'goober'
import { useMemo, useState } from 'react'

import { Txt } from 'components/Txt'
import { ISubmitSuggestionFile } from 'services/suggestion/suggestion-service-param'
import { useGetSuggestionFileQTY } from 'services/suggestion/suggestion-service-service'
import theme from 'theme/goober'

import defaultFileImage from '../../assets/images/default-file.svg'

const Layout = styled<{ border: boolean }>('div')(
  ({ border }) => `
box-shadow: ${border ? '0px 3px 6px #00000029' : 'unset'};
position: relative;
display: grid;
grid-template-rows: 1fr max-content;
height: 90px;
width: 100px;

border-radius: 6px;
padding: 8px 12px;
box-sizing: border-box;

overflow: hidden;

.ant-image {
  display: flex;
  justify-content: center;
  overflow: hidden;
  img {
    max-height: 60px;
    height: 100%;
    width: auto;
  }
}
`,
)
const DefaultFileImage = styled(Image)`
  object-fit: scale-down;
`

const FileImage = styled(Image)`
  object-fit: cover;
`

const RemoveHover = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  background-color: #00000011;

  .trash-icon {
    position: absolute;
    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);

    color: ${theme.color.grayBorder};
    opacity: 0.65;
  }

  &:hover {
    opacity: 1;
    cursor: pointer;
  }
`

interface IUploadSuggestionFileItemProps {
  data: ISubmitSuggestionFile
  disabled?: boolean
  onRemoveClick?: () => void
}
export const UploadSuggestionFileItem = (props: IUploadSuggestionFileItemProps) => {
  const { data, disabled, onRemoveClick } = props
  const { id: suggestionFileId, FilePath: suggestionFilePath = '' } = data
  const [imageError, setImageError] = useState(false)

  const { isLoading } = useGetSuggestionFileQTY(suggestionFileId)

  const fileName = useMemo(() => {
    return suggestionFilePath?.split('/')?.pop()?.slice(14)
  }, [suggestionFilePath])

  const render = useMemo(() => {
    if (suggestionFileId && isLoading) {
      return <Progress />
    }

    if (imageError) {
      return <DefaultFileImage preview={false} src={defaultFileImage} />
    }

    return (
      <FileImage
        preview={false}
        src={suggestionFilePath}
        onError={setImageError.bind(null, true)}
        fallback={defaultFileImage}
      />
    )
  }, [imageError, isLoading, suggestionFileId, suggestionFilePath])

  return (
    <Layout border={imageError}>
      {render}
      <Txt
        ag="body16"
        color="grayBorder"
        ellipsis={{
          tooltip: true,
        }}
      >
        {fileName}
      </Txt>
      {!disabled && (
        <RemoveHover onClick={onRemoveClick}>
          <FontAwesomeIcon className="trash-icon" icon={faTrashCan} />
        </RemoveHover>
      )}
    </Layout>
  )
}
