import { Col, Image, Row } from 'antd'
import { styled } from 'goober'
import { CSSProperties, useCallback, useEffect, useState } from 'react'

import { Txt } from 'components/Txt'
import theme from 'theme/goober'

import { UploadButton } from './UploadButton'

const LayoutStyled = styled.div`
  padding: 16px;
  background-color: ${theme.color.grayBackground};
  border: 1px solid ${theme.color.grayBorder};
  border-radius: 2px;
`
const ImageStyled = styled(Image)`
  object-fit: contain;
`

type UploadImagePanelPropsType = {
  style?: CSSProperties
  className?: string
  title?: string
  subTitle?: string
  value?: File | string
  onChange?: (file?: File) => void
}

export const UploadImagePanel = (props: UploadImagePanelPropsType) => {
  const { title, subTitle, onChange: externalOnSelect, style, className, value } = props
  const [url, setUrl] = useState<string>()

  const onSelect = useCallback(
    (file: File) => {
      url && URL.revokeObjectURL(url)
      externalOnSelect?.(file)
    },
    [externalOnSelect, url],
  )

  useEffect(() => {
    if (value instanceof File) {
      setUrl(URL.createObjectURL(value))
    } else if (typeof value === 'string') {
      setUrl(value)
    }
  }, [externalOnSelect, value])

  useEffect(() => {
    return () => {
      url && URL.revokeObjectURL(url)
    }
  }, [url])

  return (
    <LayoutStyled style={style} className={className}>
      <Row justify="space-between" align="middle">
        <Col>
          <Row align="middle" gutter={8}>
            <Col>
              <Txt ag="body24">{title}</Txt>
            </Col>
            <Col>
              <Txt ag="body16">{subTitle}</Txt>
            </Col>
          </Row>
        </Col>
        <Col>
          <UploadButton onSelect={onSelect} accept="image/png, image/jpeg" />
        </Col>
      </Row>
      {url && (
        <Row style={{ marginTop: 16 }}>
          <Col flex={1}>
            <ImageStyled src={url} />
          </Col>
        </Row>
      )}
    </LayoutStyled>
  )
}
