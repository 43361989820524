import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { styled } from 'goober'
import { KeyboardEventHandler, useCallback } from 'react'
import { useDebouncedCallback } from 'use-debounce'

import { Input } from 'components/Input'
import theme from 'theme/goober'

interface ISearchInputProps {
  placeholder?: string
  delay?: number
  width?: string
  onSearch: (value: string) => void
}

const SearchIcon = styled(FontAwesomeIcon)`
  color: ${theme.color.grayPlaceholder};
  font-size: 14px;
  cursor: pointer;
`

export const SearchInput = (props: ISearchInputProps) => {
  const { placeholder = 'ค้นหา', delay = 300, width = '250px', onSearch } = props
  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value
      onSearch(value)
    },
    [onSearch],
  )
  const debouncedHandleChange = useDebouncedCallback(handleChange, delay)

  const disableEnterKey = useCallback<KeyboardEventHandler>((e) => {
    if (e.code === 'Enter') {
      e.preventDefault()
    }
  }, [])

  return (
    <Input
      placeholder={placeholder}
      style={{ width }}
      allowClear
      onChange={debouncedHandleChange}
      suffix={<SearchIcon icon={faSearch} />}
      onKeyDown={disableEnterKey}
    />
  )
}
