import { faEye } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Checkbox } from 'antd'
import { CheckboxChangeEvent } from 'antd/es/checkbox'
import { ColumnType } from 'antd/lib/table'
import { useCallback, useMemo } from 'react'
import { Link } from 'react-router-dom'

import { SortableTable } from '_backend/components/SortableTable'
import { ContentHeader } from '_backend/components/layout/ContentHeader'
import { useModalConfirm } from 'components/ModalConfirmProvider'
import { truncateString } from 'helpers/string-helper'
import { IBannerGetListParam, TypeNameEnum } from 'services/home-banner/home-banner-param'
import { IBannerGetListResponse } from 'services/home-banner/home-banner-response'
import {
  useBannersGetListQRY as useBannerGetListQRY,
  useBannerUpdatePlatformMTT,
  useBannerUpdateSeqMTT,
} from 'services/home-banner/home-banner-service'

type PlatformType = 'mobile' | 'website'

export const PageHomeBanner = () => {
  const param: IBannerGetListParam = {
    web: 0,
    mobile: 0,
  }

  const { data: dataSource, isFetching } = useBannerGetListQRY(param)
  const withKeyDataSource = dataSource?.map((banner) => ({ ...banner, key: `${banner.typeBanner}_${banner.sourceId}` }))

  const { mutateAsync: updateBannerPlatform } = useBannerUpdatePlatformMTT()

  const { mutateAsync: updateBannerSeq } = useBannerUpdateSeqMTT()

  const modalConfirm = useModalConfirm()

  const handleSortEnd = useCallback(
    (sortedSeq: number, sortedData: IBannerGetListResponse) => {
      updateBannerSeq({ id: sortedData.bannerid, newRow: sortedSeq })
    },
    [updateBannerSeq],
  )

  const onClickLink = useCallback((sourceId: number, typeName: TypeNameEnum) => {
    if (typeName === TypeNameEnum.prNews) {
      return `/pr-news/${sourceId}`
    } else if (typeName === TypeNameEnum.encoService) {
      return `/enco-services/${sourceId}`
    } else {
      return `/other-services/${sourceId}`
    }
  }, [])

  const handlePublishedPlatformChange = useCallback(
    (item: IBannerGetListResponse, checkingPlatform: PlatformType, isChecked: boolean) => {
      const isMobile = checkingPlatform === 'mobile' ? isChecked : item.mobile === 1
      const isWebSite = checkingPlatform === 'website' ? isChecked : item.web === 1
      const mobile = isMobile ? 1 : 0
      const website = isWebSite ? 1 : 0
      modalConfirm.show({
        type: 'default',
        title: 'ยืนยันการปรับเปลี่ยนแพลตฟอร์มที่เผยแพร่บทความ',
        content: `คุณยืนยันที่จะปรับเปลี่ยนแพลตฟอร์มที่เผยแพร่บทความ ${item.name} นี้หรือไม่?`,
        modalProps: {
          width: 700,
        },
        onOk: () => {
          updateBannerPlatform({ sourceId: item.sourceId, mobile: mobile, web: website, typeBanner: item.typeBanner })
        },
      })
    },
    [modalConfirm, updateBannerPlatform],
  )

  const columns = useMemo(
    (): ColumnType<IBannerGetListResponse>[] => [
      {
        title: 'ลำดับ',
        dataIndex: 'recNo',
        width: '50px',
        align: 'center',
        render: (_: any, bannerItem: IBannerGetListResponse) => {
          const { recno } = bannerItem
          return <span>{recno}</span>
        },
      },
      {
        title: 'ที่มา',
        dataIndex: 'typeName',
        width: '200px',
        render: (_: any, bannerItem: IBannerGetListResponse) => {
          const { typeName } = bannerItem
          return <span>{typeName}</span>
        },
      },
      {
        title: 'ชื่อบทความ',
        dataIndex: 'name',
        width: '600px',
        render: (_: any, bannerItem: IBannerGetListResponse) => {
          const { name } = bannerItem
          return <span>{truncateString(name, 100)}</span>
        },
      },

      {
        title: 'แพลตฟอร์มที่เผยแพร่',
        dataIndex: 'publishedPlatform',
        width: '200px',
        render: (_: any, bannerItem: IBannerGetListResponse) => {
          const { mobile, web } = bannerItem
          return (
            <>
              <div>
                <Checkbox
                  checked={mobile === 1}
                  onChange={(event: CheckboxChangeEvent) =>
                    handlePublishedPlatformChange(bannerItem, 'mobile', event.target.checked)
                  }
                >
                  Mobile App
                </Checkbox>
              </div>
              <div>
                <Checkbox
                  checked={web === 1}
                  onChange={(event: CheckboxChangeEvent) =>
                    handlePublishedPlatformChange(bannerItem, 'website', event.target.checked)
                  }
                >
                  Website
                </Checkbox>
              </div>
            </>
          )
        },
      },
      {
        title: '',
        dataIndex: 'action',
        width: '100px',
        render: (_: any, bannerItem: IBannerGetListResponse) => {
          const { sourceId, typeName } = bannerItem
          return (
            <Link to={onClickLink(sourceId, typeName)} target="_blank">
              <FontAwesomeIcon icon={faEye} />
            </Link>
          )
        },
      },
    ],
    [handlePublishedPlatformChange, onClickLink],
  )

  return (
    <>
      <ContentHeader />
      <SortableTable dataSource={withKeyDataSource} columns={columns} onSortEnd={handleSortEnd} loading={isFetching} />
    </>
  )
}
