import { styled } from 'goober'

import { Txt } from 'components/Txt'
import { mediaQuery } from 'helpers/responsive-helper'
import { useGetSearchEmergencyContactQRY } from 'services/emergency-contact/emergency-contact-service-service'

interface IContactListProps {
  serviceId: number
}

const ContactTitle = styled('div')`
  padding: 40px 0;
  .ant-typography {
    display: flex;
    justify-content: center;
  }
`

const ContactList = styled('div')`
  display: grid;
  width: 100%;
  justify-content: space-between;
  grid-row-gap: 24px;
  grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
`
const ContactItem = styled('div')`
  padding-left: 24px;

  .ant-typography {
    word-break: break-all;
  }

  @media ${mediaQuery.xs} {
    padding: 0;
    max-width: 350px;
  }
`

export const ServiceContactList = (props: IContactListProps) => {
  const { serviceId } = props
  const { data: serviceContacts } = useGetSearchEmergencyContactQRY(serviceId)

  if (!serviceContacts) return null

  return (
    <>
      <ContactTitle>
        <Txt ag="subtitle40" color="lightBluePrimary">
          ติดต่อเรา
        </Txt>
      </ContactTitle>
      <ContactList>
        {serviceContacts?.map((contact) => (
          <ContactItem key={contact.id}>
            <div>
              <Txt ag="header28">{contact.emPosition}</Txt>
            </div>
            <div>
              <Txt ag="body28">{contact.contactname}</Txt>
            </div>
            <div>
              <Txt ag="body28">โทร. {contact.tel}</Txt>
            </div>
            <div>
              <Txt ag="body28">อีเมล: {contact.email}</Txt>
            </div>
          </ContactItem>
        ))}
      </ContactList>
    </>
  )
}
