import { CheckboxOptionType } from 'antd'
import { Dayjs } from 'dayjs'

export const HQ_BRANCH_CODE = '00000'
export const HQ_BRANCH_NAME = 'สำนักงานใหญ่'

export enum BranchTypeEnum {
  HQ = 1,
  NON_HQ = 2,
  INDIVIDUAL = 3,
}

export enum VatTypeEnum {
  VAT_INCLUDED = 1,
  VAT_EXCLUDED = 2,
}

export enum DiscountTypeEnum {
  NO_DISCOUNT = 0,
  BEFORE_VAT = 1,
  AFTER_VAT = 2,
}

export enum DiscountUnitEnum {
  PERCENT = 1,
  BATH = 2,
}

export enum InvoiceFieldName {
  FIRST_DATE = 'firstdate',
  DUE_DATE = 'dueDate',
  VAT_TYPE = 'vatType',
  DISCOUNT_TYPE = 'discountType',
  TB_SERVICE_NAME = 'tbServiceName',
  QUOTA_NO = 'quotaNo',
  QUO_DATE_DISPLAY = 'quodatedisplay',
  ORDER_NO = 'orderNo',
  ORDER_DATE_DISPLAY = 'orderDatedisplay',
}

export interface InvoiceFormFields {
  [InvoiceFieldName.FIRST_DATE]?: Dayjs
  [InvoiceFieldName.DUE_DATE]?: Dayjs
  [InvoiceFieldName.VAT_TYPE]?: number
  [InvoiceFieldName.DISCOUNT_TYPE]?: number
  [InvoiceFieldName.TB_SERVICE_NAME]?: string
  [InvoiceFieldName.QUOTA_NO]?: string
  [InvoiceFieldName.QUO_DATE_DISPLAY]?: string
  [InvoiceFieldName.ORDER_NO]?: string
  [InvoiceFieldName.ORDER_DATE_DISPLAY]?: string
}

export enum ReceiptFieldName {
  ID = 'id',
  RECEIPT_DATE = 'receiveDate',
  FIRST_DATE_DISPLAY = 'firstdatedisplay',
  QUOTATION_STATUS_NAME = 'mdQuotaStName',
  TB_SERVICE_NAME = 'tbServiceName',
  INVOICE_NO = 'invoiceNo',
  INVOICE_DATE_DISPLAY = 'invoiceDatedisplay',
  QUOTA_NO = 'quotaNo',
  QUO_DATE_DISPLAY = 'quodatedisplay',
  ORDER_NO = 'orderNo',
  ORDER_DATE_DISPLAY = 'orderDatedisplay',
  VAT_TYPE = 'vatType',
  DISCOUNT_TYPE = 'discountType',
  REMARK = 'remark',
}
export interface IReceiptFormFields {
  [ReceiptFieldName.ID]: number
  [ReceiptFieldName.RECEIPT_DATE]: Dayjs | string
  [ReceiptFieldName.FIRST_DATE_DISPLAY]: string
  [ReceiptFieldName.QUOTATION_STATUS_NAME]: string
  [ReceiptFieldName.TB_SERVICE_NAME]: string
  [ReceiptFieldName.INVOICE_NO]: string
  [ReceiptFieldName.INVOICE_DATE_DISPLAY]: string
  [ReceiptFieldName.QUOTA_NO]: string
  [ReceiptFieldName.QUO_DATE_DISPLAY]: string
  [ReceiptFieldName.ORDER_NO]: string
  [ReceiptFieldName.ORDER_DATE_DISPLAY]: string
  [ReceiptFieldName.VAT_TYPE]: VatTypeEnum
  [ReceiptFieldName.DISCOUNT_TYPE]: DiscountTypeEnum
  [ReceiptFieldName.REMARK]: string
}

export const vatTypeOptions: CheckboxOptionType[] = [
  {
    label: 'รวมภาษี',
    value: VatTypeEnum.VAT_INCLUDED,
  },
  {
    label: 'ไม่รวมภาษี',
    value: VatTypeEnum.VAT_EXCLUDED,
  },
]

export const discountTypeOptions: CheckboxOptionType[] = [
  {
    label: 'ไม่มีส่วนลด',
    value: DiscountTypeEnum.NO_DISCOUNT,
  },
  {
    label: 'ก่อนรวมภาษี',
    value: DiscountTypeEnum.BEFORE_VAT,
  },
  {
    label: 'หลังรวมภาษี',
    value: DiscountTypeEnum.AFTER_VAT,
  },
]
