import { faClose } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Col, Divider, notification, Row } from 'antd'
import { styled } from 'goober'
import { groupBy } from 'lodash'
import { useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { AddressModal } from 'components/Address/AddressModal'
import { useModalConfirm } from 'components/ModalConfirmProvider'
import { Txt } from 'components/Txt'
import { numberFormat } from 'helpers/number-helper'
import { randomId } from 'helpers/string-helper'
import { appPaths } from 'paths/path-config'
import { ISaveAddressParams } from 'services/address/address-params'
import { IGetAddrResponse } from 'services/address/address-response'
import { useDeleteAddressMTT, useGetCurrentUserAddressQRY, useSaveAddressMTT } from 'services/address/address-service'
import { useGetUserInfoQRY } from 'services/auth/auth-service'
import { IOrderItemParams } from 'services/enco-service/enco-service-param'
import {
  IGetServiceSlotSelectResponse,
  IPromotionCodeResponse,
  IServiceData,
} from 'services/enco-service/enco-service-response'
import { useSaveOrderBookingMTT, useSearchServiceItemQRY } from 'services/enco-service/enco-service-service'
import theme from 'theme/goober'

import { ItemQty, ItemWithQty } from './ItemSelector'
import { SummaryOrderProps } from './OrderDetailForm'
import { PromotionCodeModal } from './PromotionCodeModal'
import { OzoneItemSelector } from './ozone/OzoneItemSelector'

const calculateItemTotalPrice = (items: IOrderItemParams[]) => {
  let total = 0
  items.forEach((item) => (total += item.unitPrice * item.qty))
  return total
}

const PromoCodeItemContainer = styled('div')`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 32px;
  background-color: #ebf5fc;
  padding: 4px 40px 4px 24px;
`

const CloseButton = styled(FontAwesomeIcon)`
  position: absolute;
  right: 12px;
  top: 10px;
  &:hover {
    opacity: 0.5;
    cursor: pointer;
  }
`
interface PromoCodeItemProps {
  promoCode: string
  onRemove: () => void
}

const PromoCodeItem = (props: PromoCodeItemProps) => {
  const { onRemove, promoCode } = props
  const handleRemove = useCallback(() => {
    onRemove()
  }, [onRemove])

  return (
    <PromoCodeItemContainer>
      <CloseButton size="sm" onClick={handleRemove} icon={faClose} />
      <Txt ag="body24" style={{ color: '#737791' }}>
        {promoCode}
      </Txt>
    </PromoCodeItemContainer>
  )
}

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const ItemsContainer = styled('div')`
  min-width: 300px;
  max-width: 600px;
  width: 100%;
`

const RowContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0;
  width: 100%;
  max-width: 600px;
  word-wrap: break-word;
  border-bottom: 1px solid ${theme.color.grayBackground};
`

const TotalItemContainer = styled('div')`
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-bottom: 1px solid ${theme.color.grayBackground};
  padding: 12px 0;
  .summary-title {
    text-align: left;
    min-width: 100px;
    margin-right: 16px;
  }
  .summary-value {
    text-align: right;
  }
`

const ButtonContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  width: 100%;
`

const CustomButton = styled(Button)`
  width: 100%;
  max-width: 343px;
  margin: 14px;
`

const LinkTextButton = styled(Txt)`
  margin-left: 12px;
  text-decoration: underline;
  &:hover {
    cursor: pointer;
    opacity: 0.5;
  }
`

const ReceiptTitleContainer = styled('div')`
  margin-top: 32px;
`
const AddressContainer = styled('div')`
  padding: 0px 16px;
  min-width: 300px;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  overflow: hidden;
  word-break: break-word;
  margin-top: 16px;
`

interface OrderSummaryProps {
  data: SummaryOrderProps
  service: IServiceData
  mdServiceLocationId?: number
  onBack: (props: SummaryOrderProps) => void
  dataType?: 'stadium' | 'ozone'
}

const covertItemWithQtyToOrder = (item: ItemWithQty): IOrderItemParams => {
  return {
    tbItemId: item.item.id,
    qty: item.quantity,
    unitPrice: item.item.encoPrice,
    discount: item.item.discount * item.quantity,
  }
}

const convertSlotToOrder = (slot: IGetServiceSlotSelectResponse): IOrderItemParams => {
  return {
    tbItemId: slot.tbItemId,
    qty: 1,
    unitPrice: slot.price,
    mdSlotId: slot.mdSlotId,
    discount: slot.discountAmt,
  }
}

const orderSlotDesc = (slots: IGetServiceSlotSelectResponse[]) => {
  return slots.sort((a, b) => b.price - a.price)
}

const calculateDiscountPercent = (percent: number, price: number, amount: number) => {
  const totalPrice = price * amount
  const discount = totalPrice * (percent / 100)
  return discount
}

export const OrderSummary = (props: OrderSummaryProps) => {
  const { data, service, onBack, mdServiceLocationId, dataType } = props
  const { date, items, property, slots } = data
  const navigate = useNavigate()
  const modalConfirm = useModalConfirm()
  const [selectedItems, setSelectedItems] = useState<ItemWithQty[]>(items)
  const [selectedItemsDisplay, setSelectedItemsDisplay] = useState<ItemWithQty[]>(items)
  const [isPromotionCodeVisible, setIsPromotionCodeVisible] = useState(false)
  const [selectedPromoCode, setSelectedPromoCode] = useState<IPromotionCodeResponse | undefined>()
  const [isAddressVisible, setIsAddressVisible] = useState(false)

  const [receiptType, setReceiptType] = useState(1)

  const { data: userInfo } = useGetUserInfoQRY()
  const { data: Addresses, refetch: refetchAddress } = useGetCurrentUserAddressQRY()
  const { mutateAsync: saveOrder } = useSaveOrderBookingMTT()
  const { mutateAsync: saveAddress } = useSaveAddressMTT()
  const { data: ozoneItems } = useSearchServiceItemQRY({ enabled: dataType === 'ozone' })
  const { mutateAsync: removeAddress } = useDeleteAddressMTT()
  const ozoneItemGroup = useMemo(() => {
    return groupBy(ozoneItems, (v) => v.id)
  }, [ozoneItems])

  const defaultAddress = Addresses?.find((address) => address.isDefault === 1)

  const [addressSelected, setAddressSelected] = useState<IGetAddrResponse | undefined>(defaultAddress)
  const [isConfirmingOrder, setIsConfirmingOrder] = useState(false)

  const handleDeleteItem = useCallback(
    (item: ItemWithQty) => {
      const newSelectedList = selectedItems.filter((selected) => item.item.id !== selected.item.id)
      setSelectedItems(newSelectedList)
      setSelectedItemsDisplay(newSelectedList)
    },
    [selectedItems],
  )

  const handleQuantityChange = useCallback(
    (item: ItemWithQty) => {
      let newSelectedList = selectedItems.filter((selected) => item.item.id !== selected.item.id)
      newSelectedList = [...newSelectedList, item]
      setSelectedItems(newSelectedList)
    },
    [selectedItems],
  )

  const summaryOrderItems = useMemo(() => {
    return [
      ...selectedItems.map((item) => covertItemWithQtyToOrder(item)),
      ...slots.map((slot) => convertSlotToOrder(slot)),
    ]
  }, [selectedItems, slots])

  const sumPrice = useMemo(() => {
    return calculateItemTotalPrice(summaryOrderItems)
  }, [summaryOrderItems])

  const orderItemDiscount = useMemo(() => {
    const itemDiscount = selectedItems.reduce(
      (sum, v) => sum + calculateDiscountPercent(v.item.discount, v.item.encoPrice, v.quantity),
      0,
    )
    const slotDiscount = orderSlotDesc(slots)
      .filter((slot, ind) => ind >= (selectedPromoCode?.discountUnit || 0))
      .reduce((sum, v) => sum + v.discountAmt, 0)
    return itemDiscount + slotDiscount
  }, [selectedItems, slots, selectedPromoCode?.discountUnit])

  const promoDiscount = useMemo(() => {
    let discount = 0
    const orderPriceSlots = orderSlotDesc(slots)
    orderPriceSlots.forEach((slot, ind) => {
      if (ind < (selectedPromoCode?.discountUnit || 0)) discount += slot.price
    })

    return discount
  }, [selectedPromoCode?.discountUnit, slots])

  const credit = useMemo(() => {
    if (userInfo?.credit) {
      const priceDiffPromotion = sumPrice - orderItemDiscount - promoDiscount
      const userCredit = userInfo?.credit < 0 ? 0 : userInfo?.credit
      if (priceDiffPromotion < 0) return 0
      return userCredit <= priceDiffPromotion ? userCredit : priceDiffPromotion
    }
    return 0
  }, [promoDiscount, sumPrice, userInfo?.credit, orderItemDiscount])

  const net = useMemo(() => {
    const returnNet = sumPrice - credit - promoDiscount - orderItemDiscount
    return returnNet < 0 ? 0 : returnNet
  }, [credit, sumPrice, promoDiscount, orderItemDiscount])

  const discountDisplay = useMemo(() => {
    if (promoDiscount >= sumPrice) return sumPrice
    if (orderItemDiscount >= sumPrice) return sumPrice
    if (promoDiscount + orderItemDiscount >= sumPrice) return sumPrice
    return promoDiscount + orderItemDiscount
  }, [promoDiscount, sumPrice, orderItemDiscount])

  const handleClickConfirm = useCallback(async () => {
    setIsConfirmingOrder(true)
    const order = await saveOrder(
      {
        id: 0,
        mdOrderTypeId: 1,
        tbServiceId: service.id,
        TbServicePropId: property.id,
        bookingdate: date,
        MdReceiptTypeId: Number(receiptType),
        mdServiceLocationId,
        credit: credit,
        discount: promoDiscount + orderItemDiscount,
        total: sumPrice,
        orderItem: summaryOrderItems,
        tbPromoDtId: selectedPromoCode?.tbPromoDtId,
        net,
        TbUserAddrId: addressSelected?.id,
      },
      {
        onError: () => {
          setIsConfirmingOrder(false)
        },
      },
    )

    if (Number(order.net) > 0) {
      window.location.replace(appPaths.orderCheckout({ routeParam: { orderId: order.id } }))
    } else {
      navigate(appPaths.orderPaymentCompleted({ routeParam: { orderId: order.id } }))
    }
  }, [
    saveOrder,
    service.id,
    property.id,
    date,
    receiptType,
    mdServiceLocationId,
    credit,
    promoDiscount,
    orderItemDiscount,
    sumPrice,
    summaryOrderItems,
    selectedPromoCode?.tbPromoDtId,
    net,
    addressSelected?.id,
    navigate,
  ])

  const handleClickBack = useCallback(() => {
    onBack({
      date: props.data.date,
      items: selectedItems,
      property: props.data.property,
      slots: props.data.slots,
    })
  }, [onBack, selectedItems, props])

  const handleClickPromotion = useCallback(() => {
    setIsPromotionCodeVisible(true)
  }, [])
  const handleClosePromotionModal = useCallback(() => {
    setIsPromotionCodeVisible(false)
  }, [])
  const handleSelectPromoCode = useCallback((code?: IPromotionCodeResponse) => {
    setSelectedPromoCode(code)
  }, [])

  const handleSelectAddress = useCallback((address: IGetAddrResponse) => {
    setAddressSelected(address)
  }, [])
  const handleClickOpenAddressModal = useCallback(() => {
    refetchAddress()
    setIsAddressVisible(true)
  }, [refetchAddress])
  const handleCloseAddressModal = useCallback(() => {
    setIsAddressVisible(false)
  }, [])

  const handleSaveAddress = useCallback(
    async (values: ISaveAddressParams) => {
      let isError: boolean = false
      await saveAddress(values, {
        onSuccess: () => {
          refetchAddress()
          notification.success({ message: 'สำเร็จ', description: 'บันทึกข้อมูลที่อยู่สำเร็จ', duration: 2 })
        },
        onError: () => {
          isError = true
        },
      })
      return isError
    },
    [saveAddress, refetchAddress],
  )

  const handleConfirmRemove = useCallback(
    async (id: number) => {
      await removeAddress(
        { id },
        {
          onSuccess: () => {
            notification.success({ message: 'สำเร็จ', description: 'ลบข้อมูลที่อยู่สำเร็จ', duration: 2 })
            refetchAddress()
            setIsAddressVisible(true)
            setAddressSelected(undefined)
          },
        },
      )
    },
    [removeAddress, refetchAddress],
  )

  const handleRemoveAddress = useCallback(
    (id: number) => {
      setIsAddressVisible(false)
      modalConfirm.show({
        title: 'ลบที่อยู่',
        content: 'คุณต้องการยืนยันที่จะลบที่อยู่นี้ใช่ไหม ?',
        type: 'danger',
        onOk: () => handleConfirmRemove(id),
        onCancel: () => handleClickOpenAddressModal(),
      })
    },
    [modalConfirm, handleConfirmRemove, handleClickOpenAddressModal],
  )

  const handleChangeReceiptType = useCallback((value: number) => {
    setReceiptType(value)
  }, [])

  const isConfirmButtonDisabled = useMemo(() => {
    if (receiptType === 1) return false
    return !addressSelected
  }, [receiptType, addressSelected])

  const ozoneSlotGroupByTime = useMemo(() => {
    const grouped = groupBy(slots, (slot) => slot.mdSlotId)
    const ozoneSlots: { obj: IGetServiceSlotSelectResponse; qty: number }[] = []
    Object.keys(grouped).forEach((key) => {
      const slot = grouped[key]
      const ozoneSlot = {
        obj: slot[0],
        qty: slot.length,
      }
      ozoneSlots.push(ozoneSlot)
    })
    return ozoneSlots
  }, [slots])

  return (
    <Container>
      <PromotionCodeModal
        onSelectCode={handleSelectPromoCode}
        serviceId={service.id}
        visible={isPromotionCodeVisible}
        onClose={handleClosePromotionModal}
      />
      <AddressModal
        checkboxLabel="เลือก"
        isShowLabel
        currentSelected={addressSelected}
        onSaveAddress={handleSaveAddress}
        onSelectAddress={handleSelectAddress}
        onClose={handleCloseAddressModal}
        onRemove={handleRemoveAddress}
        addresses={Addresses || []}
        visible={isAddressVisible}
      />
      <RowContainer>
        <Txt ag="header30">โค้ดส่วนลด</Txt>

        {selectedPromoCode && (
          <PromoCodeItem promoCode={selectedPromoCode.promoCode} onRemove={handleSelectPromoCode}></PromoCodeItem>
        )}

        <LinkTextButton ag="body24" onClick={handleClickPromotion} color="lightBluePrimary">
          กรอกโค้ดส่วนลด
        </LinkTextButton>
      </RowContainer>

      {dataType === 'stadium' && (
        <div style={{ width: '100%', maxWidth: 600, marginTop: 12 }}>
          {slots?.map((slot) => {
            const startSplit = slot.slotIdStart.split(':')
            const endSplit = slot.slotIdEnd.split(':')
            const start = `${startSplit[0]}:${startSplit[1]}`
            const end = `${endSplit[0]}:${endSplit[1]}`
            const timeDisplay = `${start} - ${end}`
            const itemName = `${slot.itemNm} ${timeDisplay}`
            const discountText = slot.discountAmt > 0 ? `(ส่วนลด ${slot.discountAmt})` : ''

            return (
              <Row key={randomId()}>
                <Col span={24}>
                  <Row justify="space-between" align="middle">
                    <Col>
                      <Txt ag="body24">
                        {itemName} {discountText}
                      </Txt>
                    </Col>
                    <Col>
                      <Txt ag="body24">1 * {slot.price} บาท</Txt>
                    </Col>
                  </Row>
                  <Row>
                    <Divider
                      style={{ margin: '12px 0', border: 'none', borderTop: `1px solid ${theme.color.grayBackground}` }}
                    />
                  </Row>
                </Col>
              </Row>
            )
          })}
        </div>
      )}

      {dataType === 'ozone' && (
        <div style={{ width: '100%', maxWidth: 600, marginTop: 12 }}>
          {ozoneSlotGroupByTime?.map((item) => {
            const timeSplit = item.obj.slotIdStart.split(':')
            const timeDisplay = `${timeSplit[0]}:${timeSplit[1]}`
            const itemName = ozoneItemGroup[item.obj.tbItemId]?.[0].name ?? ''
            const discountText = item.obj.discountAmt ? ` (ส่วนลด ${item.obj.discountAmt})` : ''
            return (
              <Row key={randomId()}>
                <Col span={24}>
                  <Row justify="space-between" align="middle">
                    <Col>
                      <Txt ag="body24">{`${itemName} ${timeDisplay} ${discountText}`}</Txt>
                    </Col>
                    <Col>
                      <Txt ag="body24">{`${item.qty} * ${item.obj.price} บาท`}</Txt>
                    </Col>
                  </Row>
                  <Row>
                    <Divider style={{ margin: '12px 0' }} />
                  </Row>
                </Col>
              </Row>
            )
          })}
        </div>
      )}

      {selectedItemsDisplay.map((item) => (
        <ItemsContainer key={item.item.id}>
          <ItemQty itemQty={item} onChange={handleQuantityChange} onDelete={handleDeleteItem} isShowTotalPrice />
        </ItemsContainer>
      ))}

      <TotalItemContainer>
        <Txt ag="header30" color="grayPrimaryText" className="summary-title">
          Total
        </Txt>
        <Txt ag="header30" color="grayPrimaryText" className="summary-value">
          {numberFormat(sumPrice)} บาท
        </Txt>
        <Txt ag="header30" color="grayPrimaryText" className="summary-title">
          Discount
        </Txt>
        <Txt ag="header30" color="grayPrimaryText" className="summary-value">
          {numberFormat(discountDisplay)} บาท
        </Txt>
        <Txt ag="header30" color="grayPrimaryText" className="summary-title">
          Credit
        </Txt>
        <Txt ag="header30" color="grayPrimaryText" className="summary-value">
          {numberFormat(credit)} บาท
        </Txt>
        <Txt ag="header30" color="grayPrimaryText" className="summary-title">
          Net
        </Txt>
        <Txt ag="header30" color="grayPrimaryText" className="summary-value">
          {numberFormat(net)} บาท
        </Txt>
      </TotalItemContainer>
      <ReceiptTitleContainer>
        <Txt ag="header24">เลือกประเภทรูปแบบใบเสร็จ</Txt>
        <LinkTextButton ag="header24" color="lightBluePrimary" onClick={handleClickOpenAddressModal}>
          เพิ่มเติมชื่อ ที่อยู่
        </LinkTextButton>
      </ReceiptTitleContainer>
      <Txt ag="body24">(รอการติดต่อกลับจากเจ้าหน้าที่)</Txt>

      {addressSelected && (
        <AddressContainer>
          <Txt ag="header24" color="lightBluePrimary">
            {addressSelected.fullname || '-'}
          </Txt>
          <Txt ag="body20">{addressSelected.address || '-'}</Txt>
          <Txt ag="body20">เลขประจำตัวผู้เสียภาษี : {addressSelected.tax || '-'}</Txt>
        </AddressContainer>
      )}
      <ButtonContainer>
        <OzoneItemSelector
          options={[
            { label: 'แบบย่อ', value: 1 },
            { label: 'แบบเต็ม', value: 2 },
          ]}
          onChange={(value) => handleChangeReceiptType(value)}
          value={receiptType}
          buttonStyle={{ width: 180, height: 80, fontSize: 30 }}
          justifyCentered
        />
      </ButtonContainer>
      <ButtonContainer>
        <CustomButton
          disabled={isConfirmButtonDisabled || isConfirmingOrder}
          onClick={handleClickConfirm}
          type="primary"
        >
          Confirm
        </CustomButton>
        <CustomButton onClick={handleClickBack}>Back</CustomButton>
      </ButtonContainer>
    </Container>
  )
}
