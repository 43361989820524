import { Footer } from 'antd/lib/layout/layout'
import { styled } from 'goober'
import { FaFacebookF, FaTwitter } from 'react-icons/fa'

import { mediaQuery } from 'helpers/responsive-helper'

import styles from './EnCoFooter.module.less'

const FooterContent = styled('div')`
  display: flex;
  justify-content: space-between;
  color: #ffffff;
  width: 100%;
  max-width: 1600px;
  @media ${mediaQuery.xs} {
    flex-direction: column;
  }
`
const FooterText = styled('div')`
  font-size: 24px;
  @media ${mediaQuery.xs} {
    padding-left: 8px;
  }
`
const FooterSocials = styled('div')`
  display: flex;
  justify-content: space-between;
  width: 48px;
  a {
    color: #ffffff;
    text-decoration: none;
    @media ${mediaQuery.xs} {
      padding-top: 8px;
      padding-left: 8px;
    }
  }
`

export const EnCoFooter = () => {
  return (
    <Footer className={styles.body}>
      <FooterContent>
        <FooterText>Copyright © 2022 EnCo All rights reserved.</FooterText>
        <FooterSocials>
          <a target="_blank" href="https://www.facebook.com/EnergyComplexEnCo" rel="noreferrer">
            <FaFacebookF size={16} />
          </a>
          <a target="_blank" href="https://twitter.com/energycomplex_" rel="noreferrer">
            <FaTwitter size={16} />
          </a>
        </FooterSocials>
      </FooterContent>
    </Footer>
  )
}
