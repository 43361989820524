import { faPenToSquare } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Space, Table, TableColumnsType, Tag } from 'antd'
import { useMemo } from 'react'
import { Link } from 'react-router-dom'

import { ContentBody } from '_backend/components/layout/ContentBody'
import { ContentHeader } from '_backend/components/layout/ContentHeader'
import { truncateString } from 'helpers/string-helper'
import { backendPaths } from 'paths/backend-path-config'
import { useGetMasterUserGrp } from 'services/permission-setting/permission-setting-service'

interface ITableData {
  id: number
  recNo: number
  group: string
  active: boolean
}

export const PagePermissionSettingList = () => {
  const { data: masterUserGrpData } = useGetMasterUserGrp()
  const dataSource = useMemo<ITableData[]>(() => {
    return (
      masterUserGrpData?.map((data, index) => ({
        recNo: index + 1,
        id: data.id,
        group: data.userGrp,
        active: data.active === 1,
      })) || []
    )
  }, [masterUserGrpData])

  const columns: TableColumnsType<ITableData> = useMemo(
    () => [
      {
        title: 'ลำดับ',
        width: 50,
        dataIndex: 'recNo',
        render: (value: number) => {
          return <div style={{ textAlign: 'center' }}>{value}</div>
        },
      },
      {
        title: 'กลุ่มผู้ใช้งาน',
        dataIndex: 'group',
        render: (value: string) => {
          return <span>{truncateString(value, 100)}</span>
        },
      },
      {
        title: 'สถานะ',
        dataIndex: 'active',
        width: 200,
        render: (value: boolean) => {
          return <Tag color={value ? 'success' : 'default'}>{value ? 'Active' : 'Inactive'}</Tag>
        },
      },
      {
        title: '',
        dataIndex: 'action',
        width: '16px',
        render: (_: undefined, record: ITableData) => {
          const permissionEditPath = backendPaths.permissionSettingEdit({ routeParam: { groupId: record.id } })
          return (
            <Link to={permissionEditPath}>
              <FontAwesomeIcon icon={faPenToSquare} />
            </Link>
          )
        },
      },
    ],
    [],
  )

  const permissionNewPath = useMemo(() => backendPaths.permissionSettingNew(), [])

  return (
    <>
      <ContentHeader title="กลุ่มผุ้ใช้งานระบบ (Permission)">
        <Space size={['large', 'small']} wrap style={{ justifyContent: 'start' }}>
          {/* <SearchInput placeholder="ค้นหากลุ่มผู้ใช้งาน" onSearch={handleSearch} /> */}
          <Link to={permissionNewPath}>
            <Button type="primary">เพิ่มกลุ่มผู้ใช้งาน</Button>
          </Link>
        </Space>
      </ContentHeader>
      <ContentBody>
        <Table scroll={{ x: '100%' }} dataSource={dataSource} columns={columns} />
      </ContentBody>
    </>
  )
}
