import { Card } from 'antd'
import { styled } from 'goober'
import { PropsWithChildren } from 'react'

import bgBlur from 'assets/images/cover-blue.png'
import { Txt } from 'components/Txt'
import { mediaQuery } from 'helpers/responsive-helper'

const LayoutCover = styled('div')`
  position: relative;
`
const CoverImage = styled('div')`
  position: absolute;
  max-width: 1600px;
  left: 50%;
  transform: translate(-50%);
  background-image: url(${bgBlur});
  width: 100%;
  height: 768px;
  max-height: calc(100vh - 120px);
`
const LayoutContent = styled('div')`
  width: 100%;
`
const Title = styled('div')`
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 136px;
  @media ${mediaQuery.xs} {
    margin-top: 24px;
  }
`
const ContentCard = styled(Card)`
  width: 100%;
  max-width: 1200px;
  min-height: calc(100vh - 284px);
  margin: 200px auto 24px auto;
  @media ${mediaQuery.xs} {
    margin-top: 90px;
  }
`

interface ILayoutCardPageProps {
  title?: string
}

export const LayoutCardPage = (props: PropsWithChildren<ILayoutCardPageProps>) => {
  const { title = 'EnCo Service', children } = props
  return (
    <>
      <LayoutCover>
        <CoverImage />
      </LayoutCover>
      <Title>
        <Txt ag="header40" color="white">
          {title}
        </Txt>
      </Title>
      <LayoutContent>
        <ContentCard>{children}</ContentCard>
      </LayoutContent>
    </>
  )
}
