import { Button, Col, Form, Row, Space } from 'antd'
import { styled } from 'goober'
import { compact } from 'lodash'
import { useCallback } from 'react'

import { AppModal } from 'components/AppModal'
import { TextArea } from 'components/Input'
import { Txt } from 'components/Txt'

const InputDescription = styled(TextArea)`
  height: 106px !important;
  min-height: 106px !important;
  max-height: 106px !important;
  textarea {
    resize: none;
  }
`

export type ModalEditOrderResponseFormValues = {
  id: number
  description: string
  firstname: string
  lastname: string
  email: string
  tel: string
}

interface IModalEditOrderResponseProps {
  visible: boolean
  initialValues: ModalEditOrderResponseFormValues
  onDismiss: () => void
  onConfirm: (values: ModalEditOrderResponseFormValues) => void
}

export const ModalEditOrderResponse = (props: IModalEditOrderResponseProps) => {
  const { initialValues, visible, onDismiss, onConfirm } = props

  const [form] = Form.useForm<ModalEditOrderResponseFormValues>()

  const handleDismiss = useCallback(() => {
    onDismiss()
  }, [onDismiss])

  const onSubmit = useCallback(
    (values: ModalEditOrderResponseFormValues) => {
      onConfirm({ ...values, id: initialValues?.id })
    },
    [onConfirm, initialValues],
  )

  return (
    <AppModal visible={visible} width={684} destroyOnClose forceRender>
      <Form form={form} initialValues={initialValues} onFinish={onSubmit} layout="vertical" autoComplete="off">
        <AppModal.Header onCloseClick={handleDismiss}>การติดต่อกลับลูกค้า</AppModal.Header>
        <AppModal.Body>
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item label="ชื่อ - นามสกุล">
                <Txt ag="header24">{compact([initialValues?.firstname, initialValues?.lastname]).join(' ')} </Txt>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="อีเมล">
                <Txt ag="header24">{initialValues?.email || '-'} </Txt>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="เบอร์โทรศัพท์">
                <Txt ag="header24">{initialValues?.tel || '-'} </Txt>
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item
                label="รายละเอียดการติดต่อกลับ"
                name="description"
                required
                rules={[
                  {
                    type: 'string',
                    required: true,
                    message: 'โปรดกรอกรายละเอียดการติดต่อกลับ',
                  },
                ]}
              >
                <InputDescription placeholder="กรอกรายละเอียดการติดต่อกลับ" maxLength={1000} showCount />
              </Form.Item>
            </Col>
          </Row>
        </AppModal.Body>
        <AppModal.Footer>
          <Form.Item shouldUpdate>
            {({ getFieldsError }) => {
              const isSomeFieldError = getFieldsError().some((e) => e.errors?.length)
              return (
                <Space size={24} direction="horizontal">
                  <Button onClick={handleDismiss}>ยกเลิก</Button>
                  <Button type="primary" htmlType="submit" disabled={isSomeFieldError}>
                    บันทึก
                  </Button>
                </Space>
              )
            }}
          </Form.Item>
        </AppModal.Footer>
      </Form>
    </AppModal>
  )
}
