import { MenuProps } from 'antd'

import { appPaths } from 'paths/path-config'

interface IMenuConfigType {
  code: number
  children?: IMenuConfigType[]
}

interface IMenuItem {
  code: number
  name: string
  path?: string
}

export const MENU_LIST: IMenuItem[] = [
  {
    code: 1,
    name: 'Home',
    path: appPaths.home(),
  },
  {
    code: 2,
    name: 'EnCo Service',
    path: appPaths.encoService(),
  },
  {
    code: 3,
    name: 'Other Service',
    path: appPaths.otherService(),
  },
  {
    code: 4,
    name: 'PR News',
    path: appPaths.prNewsList(),
  },
]

export const MENU_CONFIGS: IMenuConfigType[] = [
  {
    code: 1,
  },
  {
    code: 2,
  },
  {
    code: 3,
  },
  {
    code: 4,
  },
]

export const menuItems: MenuProps['items'] = MENU_CONFIGS.map((config) => {
  const menu = MENU_LIST.find((c) => c.code === config.code)
  return {
    key: menu?.code.toString() || '',
    label: menu?.name || '',
    path: menu?.path,
    children: config.children?.map((cd) => {
      const childMenu = MENU_LIST.find((c) => c.code === cd.code)
      return { key: childMenu?.code.toString(), label: childMenu?.name, path: childMenu?.path }
    }),
  }
})
