export interface IBannerGetListParam {
  web: 0 | 1
  mobile: 0 | 1
}
export enum TypeBannerEnum {
  service = 'service',
  pr = 'pr',
}

export enum TypeNameEnum {
  encoService = 'EnCo Service',
  prNews = 'PR News',
  otherService = 'Other Service',
}

export interface IBannerUpdateSeqParam {
  id: number
  newRow: number
}

export interface IBannerUpdatePlatformParam {
  sourceId: number
  typeBanner: TypeBannerEnum
  mobile?: 0 | 1
  web?: 0 | 1
}
