import { Card } from 'antd'
import { styled } from 'goober'
import { useParams } from 'react-router-dom'

import { ContentHTML } from 'components/ContentHTML'
import { CoverPageDetail } from 'components/layout/CoverPageDetail'
import { mediaQuery } from 'helpers/responsive-helper'
import { usePrNewsGetDetailQRY } from 'services/pr-news/pr-news-service'

const ContentCard = styled(Card)`
  width: 100%;
  max-width: 1124px;
  border-radius: 10px;
  background-color: white;
  min-height: 500px;
  margin: 0 auto;

  .ant-card-body {
    padding: 60px 50px;
    @media ${mediaQuery.xs} {
      padding: 30px 25px;
    }
  }
`
const PageContent = styled('div')`
  background-position: top right;
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
  padding-bottom: 40px;
`

export const PagePrNewsDetail = () => {
  const { prNewsId } = useParams()
  const { data: prNewsItem } = usePrNewsGetDetailQRY({ id: String(prNewsId) })

  return (
    <>
      <CoverPageDetail title={prNewsItem?.name} url={prNewsItem?.coverImgWeb} />
      <PageContent>
        <ContentCard>
          <ContentHTML htmlString={prNewsItem?.contentTx} />
        </ContentCard>
      </PageContent>
    </>
  )
}
