import { sanitizeUrl } from '@braintree/sanitize-url'
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Checkbox, Space, Tag } from 'antd'
import { CheckboxChangeEvent } from 'antd/es/checkbox'
import { ColumnType } from 'antd/lib/table'
import { useCallback, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'

import { SearchInput } from '_backend/components/SearchInput'
import { SortableTable } from '_backend/components/SortableTable'
import { ContentBody } from '_backend/components/layout/ContentBody'
import { ContentHeader } from '_backend/components/layout/ContentHeader'
import { useModalConfirm } from 'components/ModalConfirmProvider'
import { truncateString } from 'helpers/string-helper'
import { backendPaths } from 'paths/backend-path-config'
import { IPrNewsGetListResponse } from 'services/pr-news/pr-news-response'
import {
  usePrNewsGetListQRY,
  usePrNewsUpdatePlatformMTT,
  usePrNewsUpdateSeqMTT,
} from 'services/pr-news/pr-news-service'

type PlatformType = 'mobile' | 'website'

export const PagePrNewsList = () => {
  const [txtSearch, setTxtSearch] = useState('')
  const { data: dataSource, isFetching } = usePrNewsGetListQRY({ txtSearch })
  const { mutateAsync: updatePrNewsSeq } = usePrNewsUpdateSeqMTT()
  const { mutateAsync: updatePrNewsPlatform } = usePrNewsUpdatePlatformMTT()
  const modalConfirm = useModalConfirm()

  const handleSortEnd = useCallback(
    (sortedSeq: number, sortedData: IPrNewsGetListResponse) => {
      updatePrNewsSeq({ id: sortedData.id, newRow: sortedSeq })
    },
    [updatePrNewsSeq],
  )

  const handlePublishedPlatformChange = useCallback(
    (item: IPrNewsGetListResponse, checkingPlatform: PlatformType, isChecked: boolean) => {
      const isMobile = checkingPlatform === 'mobile' ? isChecked : item.mobile === 1
      const isWebSite = checkingPlatform === 'website' ? isChecked : item.website === 1
      const mobile = isMobile ? 1 : 0
      const website = isWebSite ? 1 : 0
      modalConfirm.show({
        type: 'default',
        title: 'ยืนยันการปรับเปลี่ยนแพลตฟอร์มที่เผยแพร่บทความ',
        content: `คุณยืนยันที่จะปรับเปลี่ยนแพลตฟอร์มที่เผยแพร่บทความ ${item.name} นี้หรือไม่?`,
        modalProps: {
          width: 700,
        },
        onOk: () => {
          updatePrNewsPlatform({ id: item.id, mobile, website })
        },
      })
    },
    [modalConfirm, updatePrNewsPlatform],
  )

  const handleSearch = useCallback((value: string) => {
    setTxtSearch(value)
  }, [])

  const columns = useMemo(
    (): ColumnType<IPrNewsGetListResponse>[] => [
      {
        title: 'ลำดับ',
        width: '50px',
        align: 'center',
        dataIndex: 'recNo',
      },
      {
        title: 'ชื่อบทความ',
        dataIndex: 'name',
        render: (_: any, prNewsItem: IPrNewsGetListResponse) => {
          const { name } = prNewsItem
          return <span>{truncateString(name, 100)}</span>
        },
      },
      {
        title: 'สถานะ',
        width: '100px',
        dataIndex: 'active',
        render: (_: any, prNewsItem: IPrNewsGetListResponse) => {
          const { id, active, statusnm } = prNewsItem
          const isActive = active === 1
          return (
            <Tag color={isActive ? 'success' : 'default'} key={id}>
              {statusnm}
            </Tag>
          )
        },
      },
      {
        title: 'วันที่แสดง',
        width: '120px',
        dataIndex: 'startdateDisplay',
      },
      {
        title: 'วันที่สิ้นสุด',
        width: '120px',
        dataIndex: 'enddateDisplay',
      },
      {
        title: 'แพลตฟอร์มที่เผยแพร่',
        dataIndex: 'publishedPlatform',
        width: '200px',
        render: (_: any, prNewsItem: IPrNewsGetListResponse) => {
          const { mobile, website } = prNewsItem
          return (
            <>
              <div>
                <Checkbox
                  checked={mobile === 1}
                  onChange={(event: CheckboxChangeEvent) =>
                    handlePublishedPlatformChange(prNewsItem, 'mobile', event.target.checked)
                  }
                >
                  Mobile App
                </Checkbox>
              </div>
              <div>
                <Checkbox
                  checked={website === 1}
                  onChange={(event: CheckboxChangeEvent) =>
                    handlePublishedPlatformChange(prNewsItem, 'website', event.target.checked)
                  }
                >
                  Website
                </Checkbox>
              </div>
            </>
          )
        },
      },
      {
        title: '',
        dataIndex: 'action',
        render: (_: any, prNewsItem: IPrNewsGetListResponse) => {
          const prNewsEditPath = sanitizeUrl(backendPaths.prNewsEdit({ routeParam: { prNewsId: prNewsItem.id } }))
          return (
            <Link to={sanitizeUrl(prNewsEditPath)}>
              <FontAwesomeIcon icon={faPenToSquare} />
            </Link>
          )
        },
      },
    ],
    [handlePublishedPlatformChange],
  )

  const prNewsNewPath = useMemo(() => backendPaths.prNewsNew(), [])

  return (
    <>
      <ContentHeader title="PR & News">
        <Space size={['large', 'small']} wrap style={{ justifyContent: 'start' }}>
          <SearchInput placeholder="ค้นหาข่าวประชาสัมพันธ์" onSearch={handleSearch} />
          <Link to={prNewsNewPath}>
            <Button type="primary">เพิ่มบทความข่าว</Button>
          </Link>
        </Space>
      </ContentHeader>
      <ContentBody>
        <SortableTable
          rowKey="id"
          dataSource={dataSource}
          columns={columns}
          onSortEnd={handleSortEnd}
          loading={isFetching}
          isSortable={txtSearch === ''}
        />
      </ContentBody>
    </>
  )
}
