import { Col, Row } from 'antd'
import { useCallback, useState } from 'react'
import { useParams } from 'react-router-dom'

import { SearchInput } from '_backend/components/SearchInput'
import { Tabs } from '_backend/components/Tabs'
import { ContentBody } from '_backend/components/layout/ContentBody'
import { ContentHeader } from '_backend/components/layout/ContentHeader'
import { GetServiceTypeEnum } from 'services/enco-service/enco-service-param'
import { useGetServiceQRY } from 'services/enco-service/enco-service-service'
import { GetMemberAndSponsorEnum } from 'services/member-and-sponsor/member-and-sponsor-param'

import { TableMemberAndSponsor } from './TableMemberAndSponsor'

export const PageMemberAndSponsorList = () => {
  const { serviceId } = useParams()

  const { data: service } = useGetServiceQRY(Number(serviceId))

  const [tabActive, setTabActive] = useState<number>(GetServiceTypeEnum.ENCO_SERVICE)
  const [txtSearchHash, setTxtSearchHash] = useState<Record<string, string | undefined>>({})
  const onSearchService = useCallback(
    (value?: string) => {
      setTxtSearchHash((prev) => ({ ...prev, [tabActive.toString()]: value }))
    },
    [tabActive],
  )

  const onTabChange = useCallback((value: string) => {
    setTabActive(Number(value))
  }, [])

  return (
    <>
      <ContentHeader title={service?.name} subTitle="Member&Sponsor Package Customer">
        <Row
          gutter={[
            {
              sm: 24,
              xs: 16,
            },
            {
              sm: 16,
              xs: 16,
            },
          ]}
          justify="end"
          align="middle"
        >
          <Col>
            <SearchInput placeholder="ค้นหาชื่อสมาชิก/Sponsor" onSearch={onSearchService} />
          </Col>
        </Row>
      </ContentHeader>

      <ContentBody>
        <Tabs type="card" defaultActiveKey={tabActive?.toString()} onChange={onTabChange}>
          <Tabs.TabPane tab={'Member'} key={GetMemberAndSponsorEnum.MEMBER}>
            <TableMemberAndSponsor
              txtSearch={txtSearchHash[GetMemberAndSponsorEnum.MEMBER]}
              serviceType={GetMemberAndSponsorEnum.MEMBER}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab={'Sponsor'} key={GetMemberAndSponsorEnum.SPONSOR}>
            <TableMemberAndSponsor
              txtSearch={txtSearchHash[GetMemberAndSponsorEnum.SPONSOR]}
              serviceType={GetMemberAndSponsorEnum.SPONSOR}
            />
          </Tabs.TabPane>
        </Tabs>
      </ContentBody>
    </>
  )
}
