import generatePicker from 'antd/es/date-picker/generatePicker'
import { RangePickerProps } from 'antd/lib/date-picker/generatePicker'
import dayjs, { Dayjs } from 'dayjs'
import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs'

import { componentDisplayDateFormat } from '_backend/constants/date-format'

const { RangePicker: NewRangePicker } = generatePicker<Dayjs>(dayjsGenerateConfig)

type DatePickerProps = RangePickerProps<Dayjs>

const RangePicker = (props: DatePickerProps) => {
  const { value, ...restProps } = props
  return (
    <NewRangePicker
      format={componentDisplayDateFormat}
      value={value ? [dayjs(value[0]), dayjs(value[1])] : value}
      {...restProps}
    />
  )
}

export default RangePicker
