import { Button, Card, Checkbox, Col, Form, Row, Space } from 'antd'
import dayjs, { Dayjs } from 'dayjs'
import { PropsWithChildren, useCallback, useEffect } from 'react'

import { TextEditor } from '_backend/components/TextEditor'
import { UploadBannerFormItem } from '_backend/components/upload/UploadBannerFormItem'
import { apiDateFormat } from '_backend/constants/date-format'
import { REQUIRED_RULES } from '_backend/constants/form-rules'
import { TextArea } from 'components/Input'
import { useModalConfirm } from 'components/ModalConfirmProvider'
import RangePicker from 'components/RangePicker'
import { Txt } from 'components/Txt'
import { notifyFormValidateFailed } from 'helpers/form-helper'
import { IPrNewsUpdateParam } from 'services/pr-news/pr-news-param'

interface IPrNewsFormProps extends PropsWithChildren {
  isEdit?: boolean
  initialValues?: Partial<IPrNewsFormValues>
  isLoading?: boolean
  onSubmit: (values: IPrNewsUpdateParam) => void
  onBack: () => void
}

type IPrNewsFormPlatform = Array<'mobile' | 'website'>

interface IPrNewsFormValues extends IPrNewsUpdateParam {
  platform: IPrNewsFormPlatform
  publishDateRange: Dayjs[]
}

const preparePlatformValues = (values: IPrNewsFormValues) => {
  const { platform, publishDateRange, ...restValues } = values
  let mobile = 0
  let website = 0
  if (platform?.includes('mobile')) mobile = 1
  if (platform?.includes('website')) website = 1

  const startdate = dayjs(publishDateRange[0]).format(apiDateFormat)
  const enddate = dayjs(publishDateRange[1]).format(apiDateFormat)

  return { ...restValues, mobile, website, startdate, enddate }
}

export const PrNewsForm = (props: IPrNewsFormProps) => {
  const [form] = Form.useForm()
  const { onSubmit, onBack, initialValues, isLoading, isEdit } = props
  const modalConfirm = useModalConfirm()

  const handleFinish = useCallback(
    (values: IPrNewsFormValues) => {
      const preparedValues = preparePlatformValues(values)
      modalConfirm.show({
        type: 'default',
        title: isEdit ? 'ยืนยันการแก้ไขบทความ' : 'ยืนยันการสร้างบทความ',
        content: isEdit
          ? `คุณยืนยันที่จะแก้ไขบทความ ${preparedValues.name} นี้หรือไม่?`
          : `คุณยืนยันที่จะสร้างบทความ ${preparedValues.name} นี้หรือไม่?`,
        modalProps: {
          width: 700,
        },
        onOk: () => {
          onSubmit(preparedValues)
        },
      })
    },
    [isEdit, modalConfirm, onSubmit],
  )

  useEffect(() => {
    const platform: IPrNewsFormPlatform = []
    if (initialValues?.mobile === 1) platform.push('mobile')
    if (initialValues?.website === 1) platform.push('website')
    form.setFieldsValue({ ...initialValues, platform })
  }, [form, initialValues])

  return (
    <Form<IPrNewsFormValues>
      layout="vertical"
      form={form}
      onFinish={handleFinish}
      onFinishFailed={notifyFormValidateFailed}
      scrollToFirstError
    >
      <Row>
        <Col span={24}>
          <Card loading={isLoading}>
            <Txt ag="header30">ตั้งค่าการแสดงบทความ</Txt>
            <Row gutter={24}>
              <Col span={24} md={{ span: 16 }} lg={{ span: 12 }}>
                <Form.Item
                  name="publishDateRange"
                  label="วันที่เริ่มแสดง - สิ้นสุดการแสดง"
                  rules={[{ required: true, message: 'กรุณาเลือกวันที่เริ่มแสดง - สิ้นสุดการแสดง' }]}
                >
                  <RangePicker />
                </Form.Item>
              </Col>
              <Col span={24} md={{ span: 8 }} lg={{ span: 12 }}>
                <Form.Item name="platform" label="แพลตฟอร์มที่เผยแพร่">
                  <Checkbox.Group style={{ paddingTop: '8px' }}>
                    <Checkbox value="mobile">Mobile App</Checkbox>
                    <Checkbox value="website">Website</Checkbox>
                  </Checkbox.Group>
                </Form.Item>
              </Col>
            </Row>
            <div style={{ marginTop: '8px' }}>
              <Txt ag="header30">รายละเอียดบทความ</Txt>
            </div>
            <Form.Item name="name" label="ชื่อบทความ" required rules={REQUIRED_RULES}>
              <TextArea placeholder="กรอกชื่อบทความ" autoSize={{ minRows: 2, maxRows: 2 }} />
            </Form.Item>
            <UploadBannerFormItem
              form={form}
              className="label-header30"
              mobileFieldName="coverImgMobile"
              desktopFieldName="coverImgWeb"
            />
            <div style={{ marginTop: '32px' }}>
              <Form.Item className="label-header30" label="เนื้อหาบทความ" name="contentTx">
                <TextEditor moduleName="pr-news" />
              </Form.Item>
            </div>
          </Card>
        </Col>
      </Row>
      <Row style={{ marginTop: '8px' }}>
        <Col span={24}>
          <Card loading={isLoading}>
            <Space wrap style={{ justifyContent: 'space-between', width: '100%' }}>
              <Button onClick={onBack}>กลับ</Button>
              <Form.Item noStyle>
                <Button type="primary" htmlType="submit">
                  บันทึก
                </Button>
              </Form.Item>
            </Space>
          </Card>
        </Col>
      </Row>
    </Form>
  )
}
