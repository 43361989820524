import { notification } from 'antd'
import Form, { useForm } from 'antd/lib/form/Form'
import dayjs, { Dayjs } from 'dayjs'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { ContentBody } from '_backend/components/layout/ContentBody'
import { ContentHeader } from '_backend/components/layout/ContentHeader'
import { apiDateFormat } from '_backend/constants/date-format'
import { DiscountTypeEnum, DiscountUnitEnum, VatTypeEnum } from '_backend/modules/sale/sale-constants'
import { useModalConfirm } from 'components/ModalConfirmProvider'
import { notifyFormValidateFailed } from 'helpers/form-helper'
import { backendPaths } from 'paths/backend-path-config'
import { ISearchOrderResponse } from 'services/order-response/order-response-service-response'
import { useGetServiceOrderQRY } from 'services/order-response/order-response-service-service'
import { IQuotationSaveParams } from 'services/sale/quotation/quotation-param'
import { useQuotationSaveMTT } from 'services/sale/quotation/quotation-service'

import { SectionCustomerFields } from '../SectionCustomerFields'
import { SectionFormAction } from '../SectionFormAction'
import { SectionOrderFields } from '../SectionOrderFields'
import { IRecordOrderItem } from '../sale-helper'

import { SectionQuotationFields } from './SectionQuotationFields'

export interface IFormQuotationValues {
  id: number
  quotaDate: Dayjs | string
  remark: string

  attention: string
  customerId: number
  customerTaxId: string
  customerAddress: string

  vatType: VatTypeEnum
  vatPercent: number
  vatAmount: number

  discountType: DiscountTypeEnum
  discountUnit: DiscountUnitEnum
  discount: number
  discountAmount: number
  netPrice: number
}

interface IMapQuotationSaveParams {
  order: ISearchOrderResponse
  orderItems: IRecordOrderItem[]
  values: IFormQuotationValues
}
export const mapQuotationSaveParams = (params: IMapQuotationSaveParams): IQuotationSaveParams => {
  const { order, orderItems, values } = params
  return {
    id: values.id,
    tbServiceId: order.tbServiceId,
    tbOrderId: order.id,
    mdQuotaSt: 1,
    quotaDate: dayjs(values.quotaDate).format(apiDateFormat),
    remark: values.remark,
    attention: values.attention,
    tbCustomerId: values.customerId,
    taxId: values.customerTaxId,
    address: values.customerAddress,
    vatType: values.vatType,
    vatPercent: values.vatPercent,
    vat: values.vatAmount,
    discountType: values.discountType,
    discountPercent: values.discountUnit === DiscountUnitEnum.PERCENT ? values.discount : 0,
    discount: values.discountAmount,
    totalPrice: values.netPrice,
    quotaDts: orderItems.map((o) => {
      return {
        id: o.id || 0,
        item: o.itemName,
        qty: o.quantity,
        totalPrice: o.unitPrice * o.quantity,
        unit: o.unit,
        unitPrice: o.unitPrice,
      }
    }),
  }
}

export const PageQuotationNew = () => {
  const [searchParams] = useSearchParams()
  const orderId = Number(searchParams.get('orderId'))
  const modalConfirm = useModalConfirm()
  const navigate = useNavigate()

  const { data: order } = useGetServiceOrderQRY(orderId)
  const { mutateAsync: saveQuotation } = useQuotationSaveMTT()

  const [orderItems, setOrderItems] = useState<IRecordOrderItem[]>([])
  const handleChangeItems = useCallback((newItems: IRecordOrderItem[]) => {
    setOrderItems(newItems)
  }, [])

  const [form] = useForm()
  const initialValues = useMemo(
    () => ({
      id: 0,
      vatType: VatTypeEnum.VAT_EXCLUDED,
      vatPercent: 7,
      discountType: DiscountTypeEnum.NO_DISCOUNT,
      discountUnit: DiscountUnitEnum.PERCENT,
      discount: 0,

      orderTypeNm: order?.orderTypeNm,
      serviceNm: order?.serviceNm,
      orderNo: order?.orderNo,
      orderDateTimeNm: order?.orderDateTimeNm,
    }),
    [order],
  )

  const handleFinish = useCallback(
    (values: IFormQuotationValues) => {
      modalConfirm.show({
        type: 'default',
        title: 'ยืนยันการสร้างใบเสนอราคา',
        content: 'คุณยืนยันที่จะสร้างใบเสนอราคานี้หรือไม่?',
        modalProps: {
          width: 700,
        },
        onOk: async () => {
          if (!order) return
          const saveParams = mapQuotationSaveParams({ order, orderItems, values })
          await saveQuotation(saveParams)
          navigate(backendPaths.quotationList())
          notification.success({ message: 'สำเร็จ', description: 'บันทึกข้อมูลใบเสนอราคาสำเร็จ', duration: 2 })
        },
      })
    },
    [modalConfirm, navigate, order, orderItems, saveQuotation],
  )

  useEffect(() => {
    form.setFieldsValue(initialValues)
  }, [form, initialValues])

  return (
    <>
      <ContentHeader title="Sale" subTitle="New Quotation" />
      <ContentBody>
        <Form
          layout="vertical"
          form={form}
          initialValues={initialValues}
          onFinish={handleFinish}
          onFinishFailed={notifyFormValidateFailed}
          scrollToFirstError
        >
          <SectionQuotationFields />
          <SectionCustomerFields form={form} />
          <SectionOrderFields form={form} orderItems={orderItems} onChangItems={handleChangeItems} />
          <SectionFormAction backLink={backendPaths.quotationList()} />
        </Form>
      </ContentBody>
    </>
  )
}
