import { Col, Divider, Row } from 'antd'

import { Txt } from 'components/Txt'

export const WikiTypography = () => {
  return (
    <Col>
      <Row>
        <Txt ag="header40">ag=header40 -- Be-Live ใส่ใจทุกไลฟ์สไตล์</Txt>
      </Row>
      <Row>
        <Txt ag="header30">ag=header30 -- Be-Live ใส่ใจทุกไลฟ์สไตล์</Txt>
      </Row>
      <Row>
        <Txt ag="header24">ag=header24 -- Be-Live ใส่ใจทุกไลฟ์สไตล์</Txt>
      </Row>
      <Row>
        <Txt ag="header18">ag=header18 -- Be-Live ใส่ใจทุกไลฟ์สไตล์</Txt>
      </Row>

      <Divider />

      <Row>
        <Txt ag="body24">ag=body24 -- Be-Live ใส่ใจทุกไลฟ์สไตล์</Txt>
      </Row>
      <Row>
        <Txt ag="body20">ag=body20 -- Be-Live ใส่ใจทุกไลฟ์สไตล์</Txt>
      </Row>
      <Row>
        <Txt ag="body16">ag=body16 -- Be-Live ใส่ใจทุกไลฟ์สไตล์</Txt>
      </Row>
      <Row>
        <Txt ag="body10">ag=body10 -- Be-Live ใส่ใจทุกไลฟ์สไตล์</Txt>
      </Row>

      <Divider />

      <Row>
        <Txt ag="subtitle20">ag=subtitle20 -- Be-Live ใส่ใจทุกไลฟ์สไตล์</Txt>
      </Row>
      <Row>
        <Txt ag="subtitle14">ag=subtitle14 -- Be-Live ใส่ใจทุกไลฟ์สไตล์</Txt>
      </Row>
      <Row>
        <Txt ag="subtitle10">ag=subtitle10 -- Be-Live ใส่ใจทุกไลฟ์สไตล์</Txt>
      </Row>
      <Row></Row>
    </Col>
  )
}
