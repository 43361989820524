import qs from 'qs'
import { useCallback, useEffect, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { ContentBody } from '_backend/components/layout/ContentBody'
import { ContentHeader } from '_backend/components/layout/ContentHeader'
import { backendPaths } from 'paths/backend-path-config'
import { useGetServiceQRY, useSubmitKioskService } from 'services/enco-service/enco-service-service'

import { KioskServiceForm, IKioskServiceFormValues } from './KioskServiceForm'

export const PageKioskServiceEdit = () => {
  const navigate = useNavigate()
  const query = useParams()
  const serviceId = Number(query.serviceId)

  const { data: serviceResponse, isLoading: isServiceLoading } = useGetServiceQRY(serviceId)

  const { onSubmitKioskService } = useSubmitKioskService(
    serviceResponse?.id || 0,
    serviceResponse?.mdServiceTypeId || 0,
    serviceResponse?.mdServiceCatId || 0,
  )

  const initialValues = useMemo(
    (): Partial<IKioskServiceFormValues> => ({
      id: serviceResponse?.id,
      serviceName: serviceResponse?.name,
      active: serviceResponse?.active,
      propUnitName: serviceResponse?.propUnitName,

      icon: serviceResponse?.icon,

      coverImgMobile: serviceResponse?.coverImgMobile,

      bookingFunction: serviceResponse?.bookingFunction,
      bookingBtnName: serviceResponse?.bookingBtnName,
      minDateBooking: serviceResponse?.minDateBooking,
      maxBookingPerDay: serviceResponse?.maxBookingPerDay,
      maxBookingPerWeek: serviceResponse?.maxBookingPerWeek,
    }),
    [
      serviceResponse?.active,
      serviceResponse?.bookingBtnName,
      serviceResponse?.bookingFunction,
      serviceResponse?.coverImgMobile,
      serviceResponse?.icon,
      serviceResponse?.id,
      serviceResponse?.maxBookingPerDay,
      serviceResponse?.maxBookingPerWeek,
      serviceResponse?.minDateBooking,
      serviceResponse?.name,
      serviceResponse?.propUnitName,
    ],
  )

  const isLoading = useMemo(() => {
    return isServiceLoading
  }, [isServiceLoading])

  const onCancel = useCallback(() => {
    navigate(
      {
        pathname: backendPaths.serviceList(),
        search: qs.stringify({
          tab: serviceResponse?.mdServiceTypeId,
        }),
      },
      { replace: true },
    )
  }, [navigate, serviceResponse?.mdServiceTypeId])

  const onSubmit = useCallback(
    async (values: IKioskServiceFormValues) => {
      try {
        await onSubmitKioskService(values)
        navigate(
          {
            pathname: backendPaths.serviceList(),
            search: qs.stringify({
              tab: serviceResponse?.mdServiceTypeId,
            }),
          },
          { replace: true },
        )
      } catch (error) {
        return Promise.reject(error)
      }
    },
    [navigate, onSubmitKioskService, serviceResponse?.mdServiceTypeId],
  )

  useEffect(() => {
    if (!serviceId || (!isLoading && !serviceResponse)) {
      navigate(
        {
          pathname: backendPaths.serviceList(),
          search: qs.stringify({
            tab: serviceResponse?.mdServiceTypeId,
          }),
        },
        { replace: true },
      )
    }
  }, [isLoading, navigate, serviceId, serviceResponse])

  return (
    <>
      <ContentHeader title={serviceResponse?.name} subTitle="Service Setting" />
      <ContentBody>
        {!isLoading && serviceResponse && (
          <KioskServiceForm initialValues={initialValues} onSubmit={onSubmit} onCancel={onCancel} />
        )}
      </ContentBody>
    </>
  )
}
