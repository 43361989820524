import { useCallback, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { ContentBody } from '_backend/components/layout/ContentBody'
import { ContentHeader } from '_backend/components/layout/ContentHeader'
import { backendPaths } from 'paths/backend-path-config'
import { ServiceActiveStatusEnum } from 'services/enco-service/enco-service-param'
import { useGetServiceQRY } from 'services/enco-service/enco-service-service'
import { ISavePromoParams } from 'services/promotion-code/promotion-code-param'
import { useSavePromoMTT } from 'services/promotion-code/promotion-code-service'

import { PromotionCodeForm } from './PromotionCodeForm'

export const PagePromotionCodeNew = () => {
  const navigate = useNavigate()

  const query = useParams()
  const serviceId = Number(query.serviceId)
  const { data: service } = useGetServiceQRY(Number(serviceId))

  const { mutateAsync: mutateSaveServicePromo } = useSavePromoMTT()

  const initialValues = useMemo(
    (): Partial<ISavePromoParams> => ({
      active: ServiceActiveStatusEnum.ACTIVE,
    }),
    [],
  )

  const onCancel = useCallback(() => {
    navigate(
      backendPaths.serviceEncoPromotionCodeList({
        routeParam: {
          serviceId: Number(serviceId),
        },
      }),
    )
  }, [navigate, serviceId])

  const onSubmit = useCallback(
    async (values: ISavePromoParams) => {
      const newPromoCodeDetail = values.promoDtItems.map((promoDtItem) => {
        return { recNo: promoDtItem.recNo, promoCode: promoDtItem.promoCode }
      })

      try {
        mutateSaveServicePromo({
          id: 0,
          tbServiceId: values.tbServiceId,
          mdPromoTypeId: values.mdPromoTypeId,
          startdate: values.startdate,
          expiredate: values.expiredate,
          description: values.description,
          tbQuotaId: values.tbQuotaId || undefined,
          discountUnit: values.discountUnit,
          active: values.active,
          codeQty: values.codeQty,
          fixCode: values.fixCode,
          genDigit: values.genDigit,
          promoDtItems: newPromoCodeDetail || [],
        })
        navigate(
          backendPaths.serviceEncoPromotionCodeList({
            routeParam: {
              serviceId: Number(serviceId),
            },
          }),
        )
      } catch (error) {
        return Promise.reject(error)
      }
    },
    [mutateSaveServicePromo, navigate, serviceId],
  )

  return (
    <>
      <ContentHeader title={service?.name} subTitle="สร้างโปรโมชั่นโค้ด" />
      <ContentBody>
        <PromotionCodeForm
          initialValues={initialValues}
          onSubmit={onSubmit}
          onCancel={onCancel}
          headerPromotionTxt={'สร้างโปรโมชั่นโค้ด'}
        />
      </ContentBody>
    </>
  )
}
