import { Button, Card, Col, notification, Row, Tag, TagProps } from 'antd'
import dayjs from 'dayjs'
import { styled } from 'goober'
import { chain } from 'lodash'
import { useCallback, useMemo, useState } from 'react'

import { Txt } from 'components/Txt'
import { numberFormat } from 'helpers/number-helper'
import { mediaQuery } from 'helpers/responsive-helper'
import { useGetAppConfigQRY } from 'services/enco-service/enco-service-service'
import {
  EnumMyOrderStatusNm,
  EnumReceiptTypeNm,
  IMyOrder,
} from 'services/order-enco-service/order-enco-service-response'
import { useCancelOrderMTT } from 'services/order-response/order-response-service-service'
import { useSaveOrderSuggestionMTT } from 'services/suggestion/suggestion-service-service'

import { ModalCancelOrder } from './ModalCancelOrder'
import { IModalOrderSuggestionFormValues, ModalOrderSuggestion } from './ModalOrderSuggestion'
import { ModalViewOrderReceipt } from './ModalViewOrderReceipt'
import { MyOrderItem } from './MyOrderItem'

const Layout = styled('div')`
  display: flex;
  flex-flow: column;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
  max-width: 1600px;

  grid-row-gap: 23px;
`

const OrderDetailLayout = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 7px;

  .header-label {
    grid-column: 1 / 3;
    padding-left: 24px;
    display: grid;
    grid-template-columns: 1fr max-content max-content;
    grid-column-gap: 23px;

    align-items: center;
  }

  .header-button {
    box-sizing: border-box;
    padding-right: 24px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    grid-column-gap: 24px;
    .order-status-tag {
      min-width: 103px;
      height: 24px;
      text-align: center;
      border-radius: 40px;
      margin-right: 0;
      font: normal normal normal 20px/22px DB Heavent;
    }
  }

  @media ${mediaQuery.mdLte} {
    grid-template-columns: 1fr 1fr;

    .header-button {
      grid-column: 1 / 3;
    }
  }

  @media ${mediaQuery.xsOnly} {
    grid-template-columns: 100%;
    grid-row-gap: 10px;

    .header-label {
      grid-column: 1;
      grid-template-columns: 100%;
    }
    .header-button {
      grid-column: 1;
      padding-right: 0;
      justify-content: space-between;
    }
  }
`

const CustomCard = styled(Card)`
  height: 300px;
`

const MyOrderItemLayout = styled(CustomCard)`
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 13px;

  overflow-y: auto;
`

const OrderSummaryLayout = styled('div')`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-column-gap: 20px;

  > span {
    &:nth-child(even) {
      text-align: right;
    }
  }

  .net {
    margin-top: 42px;
    grid-column: 1 / span 2;
    text-align: right;
  }
`

export const myOrderStatusMapLabel = {
  [EnumMyOrderStatusNm.COMPLETED]: 'Completed',
  [EnumMyOrderStatusNm.CANCELLED]: 'Cancelled',
  [EnumMyOrderStatusNm.WAITING]: 'Waiting',
  [EnumMyOrderStatusNm.WAITING_PAYMENT]: 'Waiting Payment',
}

export const myOrderStatusMapTagColor: Record<EnumMyOrderStatusNm, TagProps['color']> = {
  [EnumMyOrderStatusNm.COMPLETED]: 'success',
  [EnumMyOrderStatusNm.CANCELLED]: 'default',
  [EnumMyOrderStatusNm.WAITING]: 'yellow',
  [EnumMyOrderStatusNm.WAITING_PAYMENT]: 'warning',
}

const OrderReceiptLayout = styled('div')`
  display: grid;
  grid-template-columns: 1fr max-content;
`

const ButtonBlue = styled(Button)`
  color: #78c9f5;
  border-color: #78c9f5;
  background: transparent;
`

interface IMyOrderProps {
  data: IMyOrder
}
export const MyOrder = (props: IMyOrderProps) => {
  const { data } = props
  const {
    id: orderId,
    serviceNm,
    orderDateTimeNm,
    orderNo,
    orderItem = [],
    orderTypeNm,
    statusNm,
    total,
    discount,
    net,
    receiptTypeNm,
    modifystfnm,
    firststf,
    allowCancel,
    bookingdate,
    credit = 0,
  } = data

  const { data: appConfig = 'บันทึกข้อความสำเร็จ' } = useGetAppConfigQRY()
  const { mutateAsync: cancelOrder } = useCancelOrderMTT()
  const { mutateAsync: orderSuggestion } = useSaveOrderSuggestionMTT()
  const [cancelModalVisible, setCancelModalVisible] = useState(false)
  const [viewReceiptModalVisible, setViewReceiptModalVisible] = useState(false)
  const [orderSuggestionModalVisible, setOrderSuggestionModalVisible] = useState(false)

  const myOrderItems = useMemo(() => {
    return chain(orderItem)
      .filter((item) => !!item.itemnm)
      .orderBy(['itemTypeId'], ['asc'])
      .value()
  }, [orderItem])

  const onConfirmOrderSuggestionClick = useCallback(
    async (values: IModalOrderSuggestionFormValues) => {
      const { id = 0, rating, suggest, suggestFile } = values

      setOrderSuggestionModalVisible(false)
      await orderSuggestion({
        id,
        tbOrderId: orderId,
        rating,
        suggest,
        suggestFile,
      })
        .then(() => {
          notification.success({ message: 'สำเร็จ', description: appConfig, duration: 2 })
        })
        .catch((error) => {
          console.error(error)
        })
    },
    [appConfig, orderId, orderSuggestion],
  )

  const onConfirmCancelOrderClick = useCallback(
    async (reason: string) => {
      setCancelModalVisible(false)
      await cancelOrder({
        id: orderId,
        rejectUserDes: reason,
      })
        .then(() => {
          notification.success({ message: 'สำเร็จ', description: 'ทำรายการสำเร็จ', duration: 2 })
        })
        .catch((error) => {
          console.error(error)
        })
    },
    [cancelOrder, orderId],
  )

  const cancelable = useMemo(() => !!allowCancel, [allowCancel])

  const suggestionButton = useMemo(() => {
    const today = dayjs()
    const bookingDate = dayjs(bookingdate)
    const isBookingDateAfterToday = dayjs(bookingDate).isAfter(today, 'day')
    const isNotCompleted = statusNm !== EnumMyOrderStatusNm.COMPLETED
    if (isNotCompleted || isBookingDateAfterToday) return <div />

    return (
      <ButtonBlue
        type="ghost"
        shape="round"
        size="small"
        color="primary"
        onClick={setOrderSuggestionModalVisible.bind(null, true)}
      >
        ให้คะแนน / ข้อเสนอแนะ
      </ButtonBlue>
    )
  }, [bookingdate, statusNm])

  const viewReceiptButton = useMemo(() => {
    const isComplete = statusNm === EnumMyOrderStatusNm.COMPLETED
    const isFullReceipt = receiptTypeNm === EnumReceiptTypeNm.FULL
    if (isComplete && !isFullReceipt) {
      return (
        <ButtonBlue type="ghost" shape="round" size="small" onClick={setViewReceiptModalVisible.bind(null, true)}>
          View Receipt
        </ButtonBlue>
      )
    }
  }, [receiptTypeNm, statusNm])

  const renderMyOrderItems = useCallback(
    () =>
      myOrderItems?.map((item, index) => (
        <MyOrderItem
          key={item.id}
          data={item}
          showCancelButton={cancelable && index === 0}
          onCancelOrderClick={setCancelModalVisible.bind(null, true)}
        />
      )),
    [cancelable, myOrderItems],
  )

  const renderContent = useMemo(() => {
    if (['Request', 'Request (Sponsor Package)'].includes(orderTypeNm?.trim())) {
      return
    }
    return (
      <>
        {/* order items */}
        <MyOrderItemLayout>{renderMyOrderItems()}</MyOrderItemLayout>
        {/* order summary */}
        <CustomCard>
          <OrderSummaryLayout>
            <Txt ag="header24">ราคารวม</Txt>
            <Txt ag="header24">{`${numberFormat(total, 2)} บาท`}</Txt>
            <Txt ag="body24">ส่วนลด</Txt>
            <Txt ag="body24">{`-${numberFormat(discount, 2)} บาท`}</Txt>
            <Txt ag="body24">Credit</Txt>
            <Txt ag="body24">{`-${numberFormat(credit, 2)} บาท`}</Txt>
            <Row className="net">
              <Col span={12}>
                <Txt ag="header24" className="net-label">
                  ยอดสุทธิ
                </Txt>
              </Col>
              <Col span={12}>
                <Txt ag="header24" className="net-price">{`${numberFormat(net, 2)} บาท`}</Txt>
              </Col>
            </Row>
          </OrderSummaryLayout>
        </CustomCard>
        {/* order receipt */}
        <CustomCard>
          <OrderReceiptLayout>
            <div>
              <Row align="middle" gutter={7}>
                <Col>
                  <Txt ag="body18">Receipt Type: </Txt>
                </Col>
                <Col>
                  <Txt ag="header24">{receiptTypeNm}</Txt>
                </Col>
              </Row>
              <Row align="middle" gutter={7}>
                <Col>
                  <Txt ag="body18">Customer: </Txt>
                </Col>
                <Col>
                  <Txt ag="header24">{modifystfnm}</Txt>
                </Col>
              </Row>
            </div>
            <div>{viewReceiptButton}</div>
          </OrderReceiptLayout>
        </CustomCard>
      </>
    )
  }, [credit, discount, modifystfnm, net, orderTypeNm, receiptTypeNm, renderMyOrderItems, total, viewReceiptButton])

  return (
    <Layout>
      <OrderDetailLayout>
        <div className="header-label">
          <Txt ag="header28">{serviceNm}</Txt>
          <Txt ag="body24">Order Date: {orderDateTimeNm}</Txt>
          <Txt ag="body24">Order No: {orderNo}</Txt>
        </div>
        <div className="header-button">
          {suggestionButton}
          <Tag className="order-status-tag" color={myOrderStatusMapTagColor[statusNm]}>
            {myOrderStatusMapLabel[statusNm] || statusNm}
          </Tag>
        </div>
        {renderContent}
      </OrderDetailLayout>
      {cancelModalVisible && (
        <ModalCancelOrder
          visible={cancelModalVisible}
          onConfirmClick={onConfirmCancelOrderClick}
          onCancelClick={setCancelModalVisible.bind(null, false)}
        />
      )}
      {viewReceiptModalVisible && (
        <ModalViewOrderReceipt
          visible={viewReceiptModalVisible}
          orderId={orderId}
          customerName={modifystfnm}
          customerId={firststf}
          onDismissModalClick={setViewReceiptModalVisible.bind(null, false)}
        />
      )}
      {orderSuggestionModalVisible && (
        <ModalOrderSuggestion
          visible={orderSuggestionModalVisible}
          orderId={orderId}
          onConfirmClick={onConfirmOrderSuggestionClick}
          onCancelClick={setOrderSuggestionModalVisible.bind(null, false)}
        />
      )}
    </Layout>
  )
}
