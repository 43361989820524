import { useMemo } from 'react'
import { Navigate, useLocation } from 'react-router-dom'

import { BACKEND_MENU_LIST } from '_backend/constants/backend-menu-config'
import { backendPaths } from 'paths/backend-path-config'
import { appPaths } from 'paths/path-config'
import { useGetMenuWeb } from 'services/auth/auth-service'

interface RequireAuthProps {
  children: JSX.Element
}

export const RequirePermission = (props: RequireAuthProps) => {
  const { children } = props
  const location = useLocation()
  const { data: authMenu } = useGetMenuWeb()

  const authorized = useMemo(() => {
    // ignore root path
    if (/^\/backend\/?$/.test(location.pathname)) return true

    // ignore Sale sub menu path
    const subMenuPaths = [backendPaths.quotationList(), backendPaths.invoiceList(), backendPaths.receiptList()]
    for (const path of subMenuPaths) {
      if (location.pathname.includes(path)) {
        return authMenu?.some((m) => m.menucode === 60 /* Sale menu */)
      }
    }

    // default
    return BACKEND_MENU_LIST.some((v) => {
      return v.path && location.pathname.includes(v.path) && authMenu?.find((m) => m.menucode === v.code)
    })
  }, [authMenu, location.pathname])

  if (!authorized) {
    return <Navigate to={appPaths.notFound()} state={{ from: location }} />
  }
  return children
}
