import { v4 as uuidV4 } from 'uuid'

export const truncateString = (str: string, num: number) => {
  if (str.length > num) {
    return str.slice(0, num) + '...'
  } else {
    return str
  }
}

export const DashIfNull = (txt?: string) => {
  if (txt && txt !== 'null') return txt
  return '-'
}

export const randomId = (prefix?: string) => {
  const uuid = uuidV4().replaceAll('-', '')
  return prefix ? `${prefix}${uuid}` : uuid
}
