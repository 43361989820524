import { get } from 'lodash'
import qs from 'qs'
import { useCallback, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { ContentBody } from '_backend/components/layout/ContentBody'
import { ContentHeader } from '_backend/components/layout/ContentHeader'
import { backendPaths } from 'paths/backend-path-config'
import { ServiceShowOrHideEnum, ServiceActiveStatusEnum } from 'services/enco-service/enco-service-param'
import { useSubmitOtherService } from 'services/enco-service/enco-service-service'

import { OtherServiceForm, OtherServiceFormValues } from './OtherServiceForm'

export const PageOtherServiceNew = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const serviceType = get(location.state, 'serviceType', null)

  const { onSubmitOtherService } = useSubmitOtherService(0, serviceType)

  const initialValues = useMemo(
    (): Partial<OtherServiceFormValues> => ({
      contactFunction: ServiceShowOrHideEnum.SHOW,
      active: ServiceActiveStatusEnum.ACTIVE,
      emergencyContacts: [],
      serviceLinks: [],
    }),
    [],
  )

  const onCancel = useCallback(() => {
    navigate(
      {
        pathname: backendPaths.serviceList(),
        search: qs.stringify({
          tab: serviceType,
        }),
      },
      { replace: true },
    )
  }, [navigate, serviceType])

  const onSubmit = useCallback(
    async (values: OtherServiceFormValues) => {
      try {
        await onSubmitOtherService(values)
        navigate(
          {
            pathname: backendPaths.serviceList(),
            search: qs.stringify({
              tab: serviceType,
            }),
          },
          { replace: true },
        )
      } catch (error) {
        return Promise.reject(error)
      }
    },
    [navigate, onSubmitOtherService, serviceType],
  )

  return (
    <>
      <ContentHeader title="Service Management" subTitle="เพิ่มบริการ Other Service" />
      <ContentBody>
        <OtherServiceForm initialValues={initialValues} onSubmit={onSubmit} onCancel={onCancel} />
      </ContentBody>
    </>
  )
}
