import { useMutation, useQuery } from '@tanstack/react-query'

import { ContentType } from 'services/http-client/base-http-client'
import { encoClient } from 'services/http-client/enco-client'
import { ResponseType } from 'services/response-type'

import { IDeleteAddressParams, ISaveAddressParams, ISetDefaultAddressParams } from './address-params'
import { IGetAddrResponse } from './address-response'

const GET_CURRENT_USER_ADDRESS_PATH = '/GetMyAddr'
const SAVE_USER_ADDRESS_PATH = '/SaveUserAddr'
const SET_DEFAULT_ADDRESS_PATH = '/SetDefaultUserAddr'
const SEARCH_USER_ADDRESS_PATH = '/SearchUserAddr'
const BACKEND_GET_USER_ADDRESS_LIST = '/GetListUserAddrBack'

export const useGetCurrentUserAddressQRY = () => {
  return useQuery([GET_CURRENT_USER_ADDRESS_PATH], async () => {
    const res = await encoClient.get<ResponseType<IGetAddrResponse[]>>(GET_CURRENT_USER_ADDRESS_PATH)
    return res.data.data
  })
}

export const useSearchUserAddressMTT = () => {
  return useMutation<IGetAddrResponse[], unknown, { id: number }>(async (data) => {
    const res = await encoClient.post<ResponseType<IGetAddrResponse[]>>(`${SEARCH_USER_ADDRESS_PATH}`, data, {
      headers: { 'Content-Type': ContentType.FORM_DATA },
    })
    return res.data.data
  })
}

export const useSaveAddressMTT = () => {
  return useMutation<IGetAddrResponse, unknown, ISaveAddressParams>(async (data) => {
    const res = await encoClient.post<ResponseType<IGetAddrResponse>>(`${SAVE_USER_ADDRESS_PATH}`, data, {
      headers: { 'Content-Type': ContentType.FORM_DATA },
    })
    return res.data.data
  })
}

export const useDeleteAddressMTT = () => {
  return useMutation<null, unknown, IDeleteAddressParams>(async (data) => {
    const res = await encoClient.delete<ResponseType<null>>(`${SAVE_USER_ADDRESS_PATH}`, {
      data,
      headers: { 'Content-Type': ContentType.FORM_DATA },
    })
    return res.data.data
  })
}

export const useSetDefaultAddressMTT = () => {
  return useMutation<IGetAddrResponse, unknown, ISetDefaultAddressParams>(async (data) => {
    const res = await encoClient.post<ResponseType<IGetAddrResponse>>(`${SET_DEFAULT_ADDRESS_PATH}`, data, {
      headers: { 'Content-Type': ContentType.FORM_DATA },
    })
    return res.data.data
  })
}

export const useBackendGetAddressQry = (userId: number) => {
  return useQuery([BACKEND_GET_USER_ADDRESS_LIST, userId], async () => {
    const res = await encoClient.get<ResponseType<IGetAddrResponse[]>>(BACKEND_GET_USER_ADDRESS_LIST, {
      params: {
        userId,
      },
    })
    return res.data.data
  })
}
