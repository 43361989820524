import { Button, Card, Col, Row, Space } from 'antd'
import { Link } from 'react-router-dom'

interface ISectionFormActionProps {
  readonly?: boolean
  backLink: string
  rejectProps?: {
    title: string
    onReject?: () => void
  }
  hideSubmit?: boolean
}

export const SectionFormAction = (props: ISectionFormActionProps) => {
  const { backLink, rejectProps, readonly = false, hideSubmit = false } = props

  return (
    <Card>
      <Row gutter={[24, 8]}>
        <Col span={8} className="text-left">
          <Link to={backLink}>
            <Button>กลับ</Button>
          </Link>
        </Col>
        {!readonly && (
          <Col span={16} className="text-right">
            <Space>
              {rejectProps && (
                <Button danger onClick={rejectProps.onReject}>
                  {rejectProps.title}
                </Button>
              )}
              {!hideSubmit && (
                <Button type="primary" htmlType="submit">
                  บันทึก
                </Button>
              )}
            </Space>
          </Col>
        )}
      </Row>
    </Card>
  )
}
