import { useParams } from 'react-router-dom'

import { ContentBody } from '_backend/components/layout/ContentBody'
import { useUserGrpQRY } from 'services/permission-setting/permission-setting-service'

import { FormPermissionSetting } from './FormPermissionSetting'

export const PagePermissionSettingEdit = () => {
  const { groupId } = useParams()
  const { data: userGrpData } = useUserGrpQRY({ id: Number(groupId) })

  return (
    <ContentBody max-width="920px" style={{ marginTop: 32 }}>
      <FormPermissionSetting userGroupData={userGrpData} mode="edit" />
    </ContentBody>
  )
}
