import * as icons from '@ant-design/icons'
import { Layout, Menu } from 'antd'
import Sider from 'antd/lib/layout/Sider'
import { Content, Footer, Header } from 'antd/lib/layout/layout'
import { styled } from 'goober'
import React, { useLayoutEffect, useState } from 'react'

import { Txt } from 'components/Txt'

import bodyStyle from './PageFrontendWiki.module.less'
import { WikiAppModal } from './WikiAppModal'
import { WikiButton } from './WikiButton'
import { WikiCard } from './WikiCard'
import { WikiColor } from './WikiColor'
import { WikiDatePicker } from './WikiDatePicker'
import { WikiFormModal } from './WikiFormModal'
import { WikiModalConfirm } from './WikiModalConfirm'
import { WikiTextField } from './WikiTextField'
import { WikiTypography } from './WikiTypography'

const pages = [
  {
    page: WikiColor,
    name: 'Color',
  },
  {
    page: WikiTypography,
    name: 'Txt',
  },
  {
    page: WikiButton,
    name: 'Button',
  },
  {
    page: WikiDatePicker,
    name: 'DatePicker',
  },
  {
    page: WikiTextField,
    name: 'TextField',
  },
  {
    page: WikiCard,
    name: 'Card',
  },
  { page: WikiAppModal, name: 'App Modal' },
  {
    page: WikiModalConfirm,
    name: 'ConfirmModal',
  },
  {
    page: WikiFormModal,
    name: 'FormModal',
  },
]

const iconList = Object.values(icons) as React.ElementType[]

const items = pages.map((page, index) => ({
  key: String(index),
  icon: React.createElement(iconList[index * 3]),
  label: page.name,
}))

const Logo = styled.div`
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.2);
`

export const PageFrontendWiki = () => {
  const [selectedKey, setSelectedKey] = useState('0')

  useLayoutEffect(() => {
    document.body.className = bodyStyle.body
  }, [])

  return (
    <Layout hasSider style={{ background: '#f0f2f5' }}>
      <Sider
        style={{
          overflow: 'auto',
          height: '100vh',
          position: 'fixed',
          left: 0,
          top: 0,
          bottom: 0,
        }}
      >
        <Logo />
        <Menu
          theme="dark"
          mode="inline"
          selectedKeys={[selectedKey]}
          onSelect={(info) => setSelectedKey(info.key)}
          items={items}
        />
      </Sider>
      <Layout className="site-layout" style={{ marginLeft: 200 }}>
        <Header
          className="site-layout-background"
          style={{ padding: '0 16px', background: 'white', position: 'fixed', zIndex: 1, width: '100%' }}
        >
          <Txt ag="header30">{pages[+selectedKey].name}</Txt>
        </Header>
        <Content style={{ margin: '24px 16px 0', overflow: 'initial', marginTop: 80 }}>
          <div
            className="site-layout-background"
            style={{
              padding: 24,
              textAlign: 'center',
              background: 'white',
              minHeight: 'calc(100vh - 150px)',
            }}
          >
            {React.createElement(pages[+selectedKey].page)}
          </div>
        </Content>
        <Footer style={{ textAlign: 'center' }}>Ant Design ©2018 Created by Ant UED</Footer>
      </Layout>
    </Layout>
  )
}
