
import { generate } from './path-tools'

// export const example = generate<[route param type], [query param type]>([pathname])

const notFound = generate<{}, {}>('/404')
const login = generate<{}, {}>('/login')
const home = generate<{}, {}>('/home')
const encoService = generate<{}, {}>('/enco-services')
const encoServiceOrder = generate<{ serviceId: number }, {}>(`${encoService()}/:serviceId/order`)
const encoServiceDetail = generate<{}, {}>(`${encoService()}/:serviceId`)
const otherService = generate<{}, {}>('/other-services')
const otherServiceDetail = generate<{}, {}>(`${otherService()}/:serviceId`)
const prNewsList = generate<{}, {}>('/pr-news')
const prNewsDetail = generate<{ prNewsId: number }, {}>(`${prNewsList()}/:prNewsId`)
const profile = generate<{}, {}>('/profile')
const notification = generate<{}, {}>('/notification')
const orderList = generate<{}, {}>('/orders')
const orderCheckout = generate<{ orderId: number }, {}>(`${orderList()}/:orderId/checkout`)
const orderPaymentCompleted = generate<{ orderId: number }, {}>(`${orderList()}/:orderId/payment-completed`)
const encoServiceOzoneOrder = generate<{ serviceId: number }, {}>(`${encoServiceDetail()}/ozone-order`)


export const appPaths = {
  notFound,
  login,
  home,
  encoService,
  orderList,
  orderCheckout,
  orderPaymentCompleted,
  encoServiceDetail,
  otherService,
  otherServiceDetail,
  prNewsList,
  prNewsDetail,
  profile,
  notification,
  encoServiceOrder,
  encoServiceOzoneOrder,
}
