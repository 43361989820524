import { styled } from 'goober'
import { useMemo } from 'react'

import coverBlueUrl from 'assets/images/cover-blue.png'
import coverEncoService from 'assets/images/cover-enco-service.png'
import { CardListLayout } from 'components/CardListLayout'
import ServiceShortcut from 'components/ServiceShortcut'
import { CoverPageList } from 'components/layout/CoverPageList'
import { appPaths } from 'paths/path-config'
import { useGetServicesQRY } from 'services/enco-service/enco-service-service'

const ContentContainer = styled('div')`
  display: flex;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
  max-width: 1600px;
  min-height: 200px;
`

export const PageEncoServiceList = () => {
  const { data: services } = useGetServicesQRY({ serviceType: 1, isActive: 1 })
  const sortedService = useMemo(() => services?.sort((a) => a.recno), [services])
  return (
    <>
      <CoverPageList title="EnCo Service" url={coverBlueUrl} insideUrl={coverEncoService} />
      <ContentContainer>
        <CardListLayout>
          {sortedService &&
            sortedService.map((service) => {
              const { name, coverImgWeb, id } = service
              return (
                <ServiceShortcut key={id} cover={coverImgWeb} title={name} linkTo={`${appPaths.encoService()}/${id}`} />
              )
            })}
        </CardListLayout>
      </ContentContainer>
    </>
  )
}
