import { faEye } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { styled } from 'goober'
import { useCallback, useMemo, useState } from 'react'

import { ContentBody } from '_backend/components/layout/ContentBody'
import { ContentHeader } from '_backend/components/layout/ContentHeader'
import { IGetReportListResponse } from 'services/report/report-response'
import { useGetReportListQRY } from 'services/report/report-service'
import theme from 'theme/goober'

import { ReportFilterModal } from './ReportFilterModal'

const EyeIcon = styled(FontAwesomeIcon)`
  color: ${theme.color.lightBluePrimary};
  font-size: 24px;
  cursor: pointer;
`

export const PageReportList = () => {
  const { data: reports } = useGetReportListQRY()
  const [visible, setVisible] = useState(false)
  const [selectedReport, setSelectedReport] = useState<IGetReportListResponse>()

  const handleOpenModal = useCallback(() => {
    setVisible(true)
  }, [setVisible])

  const handleCloseModal = useCallback(() => {
    setVisible(false)
  }, [setVisible])

  const handleClickEye = useCallback(
    (record: IGetReportListResponse) => {
      handleOpenModal()
      setSelectedReport(record)
    },
    [handleOpenModal],
  )

  const columns = useMemo(
    (): ColumnsType<IGetReportListResponse> => [
      {
        title: 'ลำดับ',
        dataIndex: 'recno',
        width: '50px',
        align: 'center',
        key: 'reportName',
      },
      {
        title: 'ชื่อรายงาน',
        dataIndex: 'reportName',
        key: 'reportName',
      },
      {
        title: 'รายงาน',
        key: 'action',
        width: '100px',
        render: (_: any, record: IGetReportListResponse) => (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <EyeIcon icon={faEye} onClick={() => handleClickEye(record)} />
          </div>
        ),
      },
    ],
    [handleClickEye],
  )

  if (!reports) return null

  return (
    <>
      <ContentHeader />
      <ContentBody>
        <Table scroll={{ x: '100%' }} rowKey="id" dataSource={reports} columns={columns} />
      </ContentBody>
      {selectedReport && (
        <ReportFilterModal report={selectedReport} onCloseModal={handleCloseModal} visible={visible} />
      )}
    </>
  )
}
