import { Button, Col, Form, Radio, Row, Space } from 'antd'
import { RuleObject, RuleRender } from 'antd/lib/form'
import Select, { BaseOptionType } from 'antd/lib/select'
import { compact, keyBy, map } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { ISearchAutoCompleteOption, SearchAutoComplete } from '_backend/components/SearchAutocomplete'
import { AppModal } from 'components/AppModal'
import { Input } from 'components/Input'
import { ruleStringRequired } from 'helpers/form-helper'
import { ISubmitServiceEmergencyContact } from 'services/emergency-contact/emergency-contact-service-param'
import { useGetMasterEmergencyContactPositionQRY } from 'services/emergency-contact/emergency-contact-service-service'
import { useGetEncoEmployeeQRY } from 'services/user/user-service'

enum ModalEmergencyContactFormModeEnum {
  ENCO = 'พนักงาน EnCo',
  MANUAL = 'Manual',
}

export interface IModalEmergencyContactFormValues extends ISubmitServiceEmergencyContact {
  formMode?: ModalEmergencyContactFormModeEnum
  profile: string
  searchEmployee: string
  employeeId: number

  recordIndex: number
  isEditMode?: boolean
}

interface IModalEmergencyContactProps {
  visible: boolean
  onDismiss: () => void
  initialValues?: Partial<IModalEmergencyContactFormValues>
  onConfirm: (values: IModalEmergencyContactFormValues) => void
  emergencyContactEmpCodesSelected?: ISubmitServiceEmergencyContact[]
}

export const ModalEmergencyContact = (props: IModalEmergencyContactProps) => {
  const {
    initialValues: preInitialValues = {},
    visible,
    emergencyContactEmpCodesSelected = [],
    onDismiss,
    onConfirm,
  } = props
  const [form] = Form.useForm<IModalEmergencyContactFormValues>()
  const formMode = Form.useWatch('formMode', form)

  const [txtSearch, setTxtSearch] = useState<string>('')

  const { data: emergencyContactPositionList = [], isLoading: isEmergencyContactPositionListLoading } =
    useGetMasterEmergencyContactPositionQRY()
  const { data: encoEmployeeList = [], isLoading: isEncoEmployeeListLoading } = useGetEncoEmployeeQRY({
    txtSearch,
  })

  const isEditMode = useMemo(() => {
    if (!!preInitialValues?.id) return true
    if (preInitialValues?.id === 0) return true
    return false
  }, [preInitialValues?.id])

  const isLoading = useMemo(() => isEmergencyContactPositionListLoading, [isEmergencyContactPositionListLoading])

  const userGroupOptions = useMemo(
    () =>
      map(
        emergencyContactPositionList,
        (e): BaseOptionType => ({
          value: e.id,
          label: e.emPosition,
        }),
      ),
    [emergencyContactPositionList],
  )

  const employeeOptions = useMemo(
    () =>
      map(
        encoEmployeeList,
        (e): ISearchAutoCompleteOption => ({
          value: e.empcode,
          text: compact([e.firstname, e.lastname, e.empcode]).join(' '),
        }),
      ),
    [encoEmployeeList],
  )

  const initialValues = useMemo((): Partial<IModalEmergencyContactFormValues> => {
    const {
      empcode,
      prefix,
      firstname,
      lastname,
      mdEmsPosId = userGroupOptions?.[0]?.value,
      emPosition,
      tel,
      email,
      active = 1,
    } = preInitialValues
    let initialFormMode = ModalEmergencyContactFormModeEnum.ENCO
    if (isEditMode) {
      initialFormMode = ModalEmergencyContactFormModeEnum.MANUAL
    }
    return {
      formMode: initialFormMode,
      empcode,
      prefix,
      firstname,
      lastname,
      mdEmsPosId,
      emPosition,
      tel,
      email,
      active,
    }
  }, [isEditMode, preInitialValues, userGroupOptions])

  const onSearchEmployee = useCallback((value: string) => {
    setTxtSearch(value)
  }, [])

  const onSelectEmployee = useCallback(
    (empcode: string) => {
      const employeeHash = keyBy(encoEmployeeList, 'empcode')
      const employee = employeeHash[empcode]
      if (employee) {
        form.setFieldsValue({
          prefix: employee.prefix,
          firstname: employee.firstname,
          lastname: employee.lastname,
          empcode: employee.empcode,
          email: employee.email,
          tel: employee.tel,
          profile: employee.profile,
          searchEmployee: '',
        })
      }
      setTxtSearch('')
    },
    [encoEmployeeList, form],
  )

  const onSubmit = useCallback(
    (values: IModalEmergencyContactFormValues) => {
      const { id = 0, recordIndex = 0 } = preInitialValues
      const contactPositionHash = keyBy(emergencyContactPositionList, 'id')
      const emPosition = contactPositionHash[values.mdEmsPosId]?.emPosition
      const submitValues: IModalEmergencyContactFormValues = { ...values, emPosition, id, recordIndex, isEditMode }
      onConfirm(submitValues)
    },
    [preInitialValues, emergencyContactPositionList, isEditMode, onConfirm],
  )

  const validateDuplicate = useCallback<RuleRender>(
    ({ getFieldValue }) => {
      const validator = (rule: RuleObject) => {
        const { firstname: initialFirstname, lastname: initialLastname } = preInitialValues
        const firstname = getFieldValue('firstname')
        const lastname = getFieldValue('lastname')
        const isValueChange = initialFirstname !== firstname || lastname !== initialLastname
        let isDuplicate = false
        for (const e of emergencyContactEmpCodesSelected) {
          if (e.firstname === firstname && e.lastname === lastname) {
            isDuplicate = true
            break
          }
        }

        if (isValueChange && isDuplicate) {
          return Promise.reject(new Error('ไม่สามารถเลือกผู้ดูแลซ้ำได้'))
        }
        return Promise.resolve()
      }
      return {
        validator,
      }
    },
    [preInitialValues, emergencyContactEmpCodesSelected],
  )

  const modalHeaderLabel = useMemo(() => {
    if (isEditMode) return 'แก้ไขข้อมูลผู้ดูแล (Emergency Contacts)'
    return 'เพิ่มผู้ดูแล (Emergency Contacts)'
  }, [isEditMode])

  const submitButtonLabel = useMemo(() => {
    if (isEditMode) return 'บันทึก'
    return 'เพิ่มผู้ดูแล'
  }, [isEditMode])

  const isEncoMode = useMemo(() => formMode === ModalEmergencyContactFormModeEnum.ENCO, [formMode])

  const ruleRequiredByFormMode = useMemo(() => {
    if (!isEncoMode) {
      return [ruleStringRequired]
    }
  }, [isEncoMode])

  const prefixField = useMemo(() => {
    if (isEncoMode) {
      return (
        <Form.Item label="คำนำหน้าชื่อ" name="prefix">
          <Select className="w-100" placeholder="คำนำหน้าชื่อ" disabled />
        </Form.Item>
      )
    }

    return (
      <Form.Item label="คำนำหน้าชื่อ" name="prefix">
        <Input placeholder="กรอกคำนำหน้า" />
      </Form.Item>
    )
  }, [isEncoMode])

  useEffect(() => {
    if (!isEditMode) {
      form.resetFields([
        'empcode',
        'prefix',
        'firstname',
        'lastname',
        'mdEmsPosId',
        'emPosition',
        'tel',
        'email',
        'active',
      ])
    }
  }, [form, formMode, isEditMode])

  return (
    <AppModal visible={visible} width={684} destroyOnClose forceRender>
      <AppModal.Header onCloseClick={onDismiss}>{modalHeaderLabel}</AppModal.Header>
      {!isLoading && (
        <Form
          form={form}
          initialValues={initialValues}
          onFinish={onSubmit}
          layout="vertical"
          autoComplete="off"
          disabled={isLoading}
        >
          <AppModal.Body>
            <Row gutter={24}>
              {!isEditMode && (
                <Col span={24}>
                  <Form.Item name="formMode">
                    <Radio.Group buttonStyle="solid" disabled={isEditMode}>
                      <Radio.Button value={ModalEmergencyContactFormModeEnum.ENCO} disabled={isEditMode}>
                        {ModalEmergencyContactFormModeEnum.ENCO}
                      </Radio.Button>
                      <Radio.Button value={ModalEmergencyContactFormModeEnum.MANUAL} disabled={isEditMode}>
                        {ModalEmergencyContactFormModeEnum.MANUAL}
                      </Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              )}
              {isEncoMode && (
                <Col span={24}>
                  <Form.Item
                    name="empcode"
                    rules={[
                      {
                        required: true,
                        message: 'กรุณาค้นหาและเลือกพนักงาน',
                      },
                    ]}
                  >
                    <SearchAutoComplete
                      placeholder="ค้นหาจากรหัสพนักงาน / ชื่อ - นามสกุล"
                      onSearch={onSearchEmployee}
                      onChange={onSelectEmployee}
                      options={employeeOptions}
                      isLoading={isEncoEmployeeListLoading}
                      width="100%"
                    />
                  </Form.Item>
                </Col>
              )}
              <Col sm={4} xs={12}>
                {prefixField}
              </Col>
              <Col sm={10} xs={12}>
                <Form.Item label="ชื่อ" name="firstname" rules={ruleRequiredByFormMode}>
                  <Input placeholder="กรอกชื่อ" disabled={isEncoMode} />
                </Form.Item>
              </Col>
              <Col sm={10} xs={12}>
                <Form.Item label="นามสกุล" name="lastname">
                  <Input placeholder="กรอกนามสกุล" disabled={isEncoMode} />
                </Form.Item>
              </Col>

              <Col sm={12} xs={12}>
                <Form.Item
                  label="อีเมล"
                  name="email"
                  rules={[
                    {
                      type: 'string',
                      required: true,
                      message: 'โปรดกรอกอีเมล',
                    },
                    {
                      type: 'email',
                      message: 'รูปแบบอีเมลไม่ถูกต้อง',
                    },
                  ]}
                >
                  <Input placeholder="กรอกอีเมล" maxLength={255} />
                </Form.Item>
              </Col>
              <Col sm={12} xs={12}>
                <Form.Item
                  label="เบอร์โทรศัพท์"
                  name="tel"
                  rules={[
                    {
                      type: 'string',
                      required: true,
                      message: 'โปรดกรอกเบอร์โทรศัพท์',
                    },
                  ]}
                >
                  <Input placeholder="กรอกเบอร์โทรศัพท์" />
                </Form.Item>
              </Col>

              <Col sm={12} xs={12}>
                <Form.Item label="ตำแหน่ง" name="mdEmsPosId">
                  <Select
                    className="w-100"
                    placeholder="เลือกตำแหน่ง"
                    options={userGroupOptions}
                    defaultActiveFirstOption
                  />
                </Form.Item>
              </Col>
              <Col sm={12} xs={12}>
                {isEncoMode && (
                  <Form.Item label="ชื่อโปรไฟล์" name="profile">
                    <Input placeholder="กรอกชื่อโปรไฟล์" disabled />
                  </Form.Item>
                )}
              </Col>

              <Col>
                <Form.Item label="การแสดงผล (หมายเหตุ ระบบจะส่งอีเมลแจ้งเตือนผู้ดูแลทั้งหมด)" name="active">
                  <Radio.Group>
                    <Radio value={1}>Show</Radio>
                    <Radio value={0}>Hide</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
            </Row>
          </AppModal.Body>
          <AppModal.Footer>
            <Form.Item name="firstname" rules={[validateDuplicate]} shouldUpdate>
              <div />
            </Form.Item>
            <Form.Item shouldUpdate>
              <Space size={24} direction="horizontal">
                <Button onClick={onDismiss}>ยกเลิก</Button>
                <Button type="primary" htmlType="submit">
                  {submitButtonLabel}
                </Button>
              </Space>
            </Form.Item>
          </AppModal.Footer>
        </Form>
      )}
    </AppModal>
  )
}
