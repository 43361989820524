import { Button, Col, Divider, Form, notification, Row, Select } from 'antd'
import { ValidateStatus } from 'antd/lib/form/FormItem'
import dayjs from 'dayjs'
import { styled } from 'goober'
import { useCallback, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'

import { apiDateFormat } from '_backend/constants/date-format'
import DatePicker from 'components/DatePicker'
import { Txt } from 'components/Txt'
import { appPaths } from 'paths/path-config'
import { IGetServiceSlotSelectResponse, IServiceData } from 'services/enco-service/enco-service-response'
import { useGetServiceSlotSelectQRY } from 'services/enco-service/enco-service-service'
import { useGetServiceItemListQRY } from 'services/item-management/item-management-service'
import { IGetSearchServicePropResponse } from 'services/property-management/property-management-response'
import { useGetSearchServicePropQRY } from 'services/property-management/property-management-service'

import { ItemSelector, ItemWithQty } from './ItemSelector'
import { SlotSelector } from './SlotSelector'

const SectionTitleContainer = styled.div<{ centered: string }>`
  text-align: ${(props) => (props.centered.toLocaleLowerCase() === 'true' ? 'center' : 'left')};
  margin-bottom: 16px;
`

export const SectionTitle = (props: { txt: string; centered?: boolean }) => {
  const { txt, centered = false } = props
  return (
    <SectionTitleContainer centered={String(centered)}>
      <Txt ag="header30" color="grayPlaceholder">
        {txt}
      </Txt>
    </SectionTitleContainer>
  )
}

const CustomSelector = styled(Select)`
  width: 100%;
  text-align: left;
  .ant-select-selector {
    height: 66px !important;
    width: 100%;
    text-align: left;
    align-items: center;
    font-size: 28px;
  }
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
`

const CustomButton = styled(Button)`
  width: 343px;
  margin: 14px;
`

export interface SummaryOrderProps {
  property: IGetSearchServicePropResponse
  date: string // YYYY/MM/DD
  slots: IGetServiceSlotSelectResponse[]
  items: ItemWithQty[]
}

interface OrderDetailFormProps {
  service: IServiceData
  onClickContinue: (values: SummaryOrderProps) => void
  values?: SummaryOrderProps
}

export const OrderDetailForm = (props: OrderDetailFormProps) => {
  const { service, onClickContinue, values } = props

  const [property, setProperty] = useState<IGetSearchServicePropResponse | undefined>(values?.property || undefined)
  const [selectedDate, setSelectedDate] = useState<string | null>(values?.date || null)
  const [selectedSlots, setSelectedSlots] = useState<IGetServiceSlotSelectResponse[]>(values?.slots || [])
  const [selectedItems, setSelectedItems] = useState<ItemWithQty[]>(values?.items || [])
  const [isSubmitting, setIsSubmitting] = useState(false)

  const canReserveIn = useCallback(() => dayjs().add((service.minDateBookingDay || 0) - 1, 'day'), [service])

  const { data: properties } = useGetSearchServicePropQRY({ ServiceId: service.id })
  const { data: slots } = useGetServiceSlotSelectQRY({
    bookdate: selectedDate || canReserveIn().format(apiDateFormat),
    servicePropId: property?.id,
  })

  const { data: itemOther } = useGetServiceItemListQRY({ ServiceId: service.id, IsActive: 1, itemTypeId: 3 })

  const propertiesMap = useMemo(() => {
    let returnItems: { [id: number]: IGetSearchServicePropResponse } = {}
    properties?.forEach((prop) => (returnItems[prop.id] = prop))
    return returnItems
  }, [properties])

  const handleDateChange = useCallback((value: dayjs.Dayjs | null) => {
    if (value) {
      const date = value.format(apiDateFormat)
      setSelectedDate(date)
    } else {
      setSelectedDate(value)
    }
    handleSelectSlot([])
  }, [])

  const handlePropertiesChange = useCallback(
    (value: unknown) => {
      const selected = propertiesMap[value as number]
      setProperty(selected)
      handleDateChange(null)
    },
    [propertiesMap, handleDateChange],
  )

  const handleSelectSlot = (value: IGetServiceSlotSelectResponse[]) => {
    setSelectedSlots(value)
  }

  const disabledDate = useCallback(
    (date: dayjs.Dayjs) => {
      if (!service) return true
      return date.diff(canReserveIn()) < 0
    },
    [canReserveIn, service],
  )

  const handleChangeSelectItem = useCallback((items: ItemWithQty[]) => {
    setSelectedItems(items)
  }, [])

  const handleClickContinue = useCallback(() => {
    setIsSubmitting(true)
    if (selectedSlots.length < 2)
      notification.error({ message: 'ผิดพลาด', description: 'กรุุณาเลือกช่วงเวลาอย่างน้อย 2 รายการ' })
    if (selectedDate && property && selectedSlots.length > 1) {
      const summary: SummaryOrderProps = {
        date: selectedDate,
        property: property,
        slots: selectedSlots,
        items: selectedItems,
      }
      onClickContinue(summary)
    }
  }, [onClickContinue, selectedDate, property, selectedSlots, selectedItems])

  const isPropertiesError = (): { status: ValidateStatus; help?: string } => {
    if (isSubmitting) {
      if (property) return { status: '', help: undefined }
      return { status: 'error', help: `กรุุณาเลือก${service.propUnitName}` }
    } else {
      return { status: '', help: undefined }
    }
  }

  const isSelectedDateError = (): { status: ValidateStatus; help?: string } => {
    if (isSubmitting) {
      if (selectedDate) return { status: '', help: undefined }
      return { status: 'error', help: 'กรุณาเลือกวันที่' }
    } else {
      return { status: '', help: undefined }
    }
  }

  const encoServiceDetailPath = useMemo(
    () =>
      appPaths.encoServiceDetail({
        routeParam: {
          serviceId: service.id,
        },
      }),
    [service.id],
  )

  return (
    <>
      <Row gutter={24}>
        <Col span={24} xl={{ span: 8 }} style={{ marginBottom: 16 }}>
          <SectionTitle txt={`เลือก${service.propUnitName}`} />
          <Form.Item validateStatus={isPropertiesError().status} help={isPropertiesError().help}>
            <CustomSelector
              value={property?.id}
              onChange={handlePropertiesChange}
              placeholder={`กรุณาเลือก${service.propUnitName}`}
              dropdownClassName="big-selector"
            >
              {properties
                ?.filter((p) => p.active === 1)
                ?.map((prop) => (
                  <Select.Option key={prop.id} value={prop.id}>
                    {prop.name}
                  </Select.Option>
                ))}
            </CustomSelector>
          </Form.Item>
        </Col>
        <Col span={24} xl={{ span: 8 }} style={{ marginBottom: 16 }}>
          <SectionTitle txt="เลือกวันที่" />
          <Form.Item validateStatus={isSelectedDateError().status} help={isSelectedDateError().help}>
            <DatePicker
              disabled={!property}
              placeholder="กรุณาเลือกวันที่"
              value={selectedDate ? dayjs(selectedDate) : undefined}
              onChange={handleDateChange}
              disabledDate={disabledDate}
              allowClear={false}
            />
          </Form.Item>
        </Col>
        <Col span={24} xl={{ span: 8 }} style={{ marginBottom: 16 }}>
          <SectionTitle txt={`ตารางเวลา${service.propUnitName}`} />
          <SlotSelector
            service={service}
            values={selectedSlots}
            onSelect={handleSelectSlot}
            slots={slots || []}
            disabled={selectedDate === null}
            isError={isSubmitting && selectedSlots.length < 1}
          />
        </Col>
      </Row>
      <Divider />
      <ItemSelector
        selectedSlots={selectedSlots}
        values={selectedItems}
        masterItems={[...(itemOther || [])]}
        onChange={handleChangeSelectItem}
      >
        <ButtonContainer>
          <CustomButton onClick={handleClickContinue} type="primary">
            Continue
          </CustomButton>
          <Link to={encoServiceDetailPath}>
            <CustomButton>Back</CustomButton>
          </Link>
        </ButtonContainer>
      </ItemSelector>
    </>
  )
}
