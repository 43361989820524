import dayjs from 'dayjs'
import { every, keyBy, sortBy, values } from 'lodash'
import qs from 'qs'
import { useCallback, useEffect, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { ContentBody } from '_backend/components/layout/ContentBody'
import { ContentHeader } from '_backend/components/layout/ContentHeader'
import { backendPaths } from 'paths/backend-path-config'
import { useGetSearchEmergencyContactQRY } from 'services/emergency-contact/emergency-contact-service-service'
import {
  ENCO_TYPE_OZONE_ID,
  useGetServiceQRY,
  useSubmitEncoServiceContentsMTT,
} from 'services/enco-service/enco-service-service'
import { IServiceContentData } from 'services/service-content/service-content-service-response'
import {
  EnumMasterServiceContentFunc,
  useGetMasterServiceContentFuncQRY,
  useGetSearchServiceContentsQRY,
} from 'services/service-content/service-content-service-service'

import { EncoServiceContentForm, EncoServiceContentFormValues } from './EncoServiceContentForm'

export const PageEncoServiceContent = () => {
  const navigate = useNavigate()
  const query = useParams()
  const serviceId = Number(query.serviceId)
  const isOzone = serviceId === ENCO_TYPE_OZONE_ID

  const { data: serviceResponse, isLoading: isServiceLoading } = useGetServiceQRY(serviceId)
  const { data: serviceContentsResponse, isLoading: isServiceContentLoading } =
    useGetSearchServiceContentsQRY(serviceId)
  const { data: emergencyContactResponse, isLoading: isEmergencyContactLoading } = useGetSearchEmergencyContactQRY(
    serviceId,
    {
      isActive: 0,
    },
  )
  const { data: masterServiceContentFuncResponse, isLoading: isMasterServiceContentFuncResponseLoading } =
    useGetMasterServiceContentFuncQRY()
  const { onSubmitEncoServiceContents } = useSubmitEncoServiceContentsMTT(serviceId)

  const serviceContentFuncEnable = useMemo(() => {
    const serviceContentFuncEnableResult: string[] = []
    const { memberFunction, sponsorFunction, bookingFunction, mdServiceCatId = 0 } = serviceResponse || {}
    const isKiosk = +mdServiceCatId === 3

    if (isKiosk) {
      if (bookingFunction) {
        serviceContentFuncEnableResult.push(EnumMasterServiceContentFunc.BOOKING)
      }

      serviceContentFuncEnableResult.push(EnumMasterServiceContentFunc.KIOSK_TERM)
      serviceContentFuncEnableResult.push(EnumMasterServiceContentFunc.KIOSK_NOTICE)
      return serviceContentFuncEnableResult
    }

    if (memberFunction) {
      serviceContentFuncEnableResult.push(EnumMasterServiceContentFunc.MEMBER)
    }
    if (sponsorFunction) {
      serviceContentFuncEnableResult.push(EnumMasterServiceContentFunc.SPONSOR)
    }
    if (bookingFunction) {
      serviceContentFuncEnableResult.push(EnumMasterServiceContentFunc.BOOKING)
    }
    return serviceContentFuncEnableResult
  }, [serviceResponse])

  const defaultServiceContentsHash = useMemo(() => {
    return (masterServiceContentFuncResponse || []).reduce((acc: Record<string, IServiceContentData>, cur) => {
      const isEnable = serviceContentFuncEnable.includes(cur.funcName)
      if (isEnable && cur.funcName) {
        acc[cur.funcName] = {
          contenttx: '',
          funct: cur.id,
          functnm: cur.funcName,
          topic: '',
          tbServiceId: serviceId,
          modifystf: 0,
          firstdate: null,
          id: 0,
          modifydate: '',
          firststf: 0,
        }
      }
      return acc
    }, {})
  }, [masterServiceContentFuncResponse, serviceContentFuncEnable, serviceId])

  const serviceContents = useMemo(() => {
    const masterServiceContentFuncHash = keyBy(masterServiceContentFuncResponse, 'funcname')
    const dataSorted = sortBy(serviceContentsResponse, (e) => -dayjs(e.modifydate).unix())

    for (const d of dataSorted) {
      const isEnable = serviceContentFuncEnable.includes(d.functnm)
      const isExistsInHash = !!defaultServiceContentsHash[d.functnm]?.id
      const isKeyEnableFilled = every(serviceContentFuncEnable, (functnm) => !!defaultServiceContentsHash[functnm]?.id)
      if (isKeyEnableFilled) {
        break
      }
      if (isEnable && !isExistsInHash) {
        defaultServiceContentsHash[d.functnm] = d
      }
    }

    return sortBy(values(defaultServiceContentsHash), (e) => masterServiceContentFuncHash[e.functnm]?.id)
  }, [defaultServiceContentsHash, masterServiceContentFuncResponse, serviceContentFuncEnable, serviceContentsResponse])

  const initialValues = useMemo((): Partial<EncoServiceContentFormValues> => {
    return {
      id: serviceResponse?.id,
      serviceName: serviceResponse?.name,
      emergencyContacts: emergencyContactResponse || [],
      serviceContents: serviceContents || [],
    }
  }, [emergencyContactResponse, serviceContents, serviceResponse?.id, serviceResponse?.name])

  const isLoading = useMemo(() => {
    return (
      isServiceLoading ||
      isEmergencyContactLoading ||
      isServiceContentLoading ||
      isMasterServiceContentFuncResponseLoading
    )
  }, [isServiceLoading, isEmergencyContactLoading, isServiceContentLoading, isMasterServiceContentFuncResponseLoading])

  const onCancel = useCallback(() => {
    navigate(
      {
        pathname: backendPaths.serviceList(),
        search: qs.stringify({
          tab: serviceResponse?.mdServiceTypeId,
        }),
      },
      { replace: true },
    )
  }, [navigate, serviceResponse?.mdServiceTypeId])

  const onSubmit = useCallback(
    async (formValues: EncoServiceContentFormValues) => {
      try {
        await onSubmitEncoServiceContents(formValues)
        navigate(
          {
            pathname: backendPaths.serviceList(),
            search: qs.stringify({
              tab: serviceResponse?.mdServiceTypeId,
            }),
          },
          { replace: true },
        )
      } catch (error) {
        return Promise.reject(error)
      }
    },
    [navigate, onSubmitEncoServiceContents, serviceResponse?.mdServiceTypeId],
  )

  useEffect(() => {
    if (!serviceId || (!isLoading && !serviceResponse)) {
      navigate(
        {
          pathname: backendPaths.serviceList(),
          search: qs.stringify({
            tab: serviceResponse?.mdServiceTypeId,
          }),
        },
        { replace: true },
      )
    }
  }, [isLoading, navigate, serviceId, serviceResponse])

  return (
    <>
      <ContentHeader title={serviceResponse?.name || '-'} subTitle="Content Setting" />
      <ContentBody>
        {!isLoading && serviceResponse && (
          <EncoServiceContentForm
            initialValues={initialValues}
            onSubmit={onSubmit}
            onCancel={onCancel}
            isOzone={isOzone}
          />
        )}
      </ContentBody>
    </>
  )
}
