export const theme = {
  color: {
    white: '#fff',
    grayPrimaryText: '#181818',
    graySecondaryText: '#393939',
    grayPlaceholder: '#5a5a5a',
    grayBorder: '#9c9c9c',
    grayFlatButton: '#bdbdbd',
    grayBackground: '#f5f5f5',
    grayText: '#737791',
    grayCard: '#efeff2',

    lightBlueHover: '#80c4ef',
    lightBlueBorder: '#99d0f2',
    lightBlueFlatButton: '#b3dbf6',
    lightBlueBackground: '#e6f3fc',
    lightBlueBackground2: '#e6f7ff',

    lightGreenPrimary: '#a6ce39',
    lightGreenBackground: '#edf5d7',

    orangePrimaryText: '#fa8c16',
    orangeHover: '#f4a652',
    orangeBackground: '#fff7e6',

    purplePrimary: '#9687F0',

    //frontend
    softGray: '#cdd4d9',
    gray: '#737791',
    darkGray: '#989999',
    datePickerMonthBg: '#ebf5fc',
    datePickerHederHover: '#0b657b',
    lightBluePrimary: '#4dace9',
    blue: '#2a2d81',
    greenPrimary: '#aecc55',
    orangePrimary: '#ceaf4f',
    redPrimary: '#c70c0c',

    // tag
    tagWarning: '#faad14',
    tagSuccess: '#52c41a',
    tagError: '#ff4d4f',
    tagDefault: 'rgba(0,0,0,.88)',

    antdError: '#ff4d4f',
  },
}

export type AppTheme = { theme?: typeof theme }
export type AppColor = typeof theme.color

export default theme
