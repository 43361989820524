import { notification } from 'antd'
import { map } from 'lodash'
import { useCallback, useMemo } from 'react'
import { useParams, useNavigate } from 'react-router-dom'

import { ContentHeader } from '_backend/components/layout/ContentHeader'
import { useVisible } from 'helpers/modal-helper'
import { useGetServiceQRY } from 'services/enco-service/enco-service-service'
import { MdKioskProductPropListDto } from 'services/kiosk-master/kiosk-master-response'
import {
  useDeleteKioskMasterProductMTT,
  useSaveKioskMasterProductMTT,
} from 'services/kiosk-master/kiosk-master-service'
import { useGetServicePropListQRY } from 'services/service-property/service-property-service'

import { IKioskMasterProductFormValues, KioskMasterProductForm } from './KioskMasterProductForm'

export const PageKioskMasterProductNew = () => {
  const { serviceId } = useParams()
  const navigate = useNavigate()

  const { data: service } = useGetServiceQRY(Number(serviceId))
  const { mutate: saveKioskMasterProduct } = useSaveKioskMasterProductMTT()
  const { mutate: deleteKioskMasterProduct } = useDeleteKioskMasterProductMTT()
  const { data: serviceProperties, isFetching: isLoadingServiceProperties } = useGetServicePropListQRY({
    ServiceId: Number(serviceId),
  })
  const modalKioskPeriodVisible = useVisible()

  const onModalKioskPeriodConfirmClick = useCallback(
    async (data: IKioskMasterProductFormValues) => {
      if (serviceId) {
        saveKioskMasterProduct(
          { ...data, tbServiceId: +serviceId },
          {
            onSuccess() {
              navigate(-1)
            },
          },
        )
        modalKioskPeriodVisible.close()
      }
    },
    [modalKioskPeriodVisible, navigate, saveKioskMasterProduct, serviceId],
  )

  const onConfirmDeleteMasterProduct = useCallback(
    (masterProductId: number) => {
      if (masterProductId) {
        deleteKioskMasterProduct(masterProductId, {
          onSuccess() {
            notification.success({ message: 'สำเร็จ', description: 'ยกเลิกรายการสำเร็จ', duration: 2 })
          },
        })
        modalKioskPeriodVisible.close()
      }
    },
    [deleteKioskMasterProduct, modalKioskPeriodVisible],
  )

  const onCancel = useCallback(() => {
    navigate(-1)
  }, [navigate])

  const initialValues = useMemo(() => {
    const newKioskProductPropList = map(serviceProperties ?? [], (e): MdKioskProductPropListDto => {
      return {
        id: 0,
        tbServiceid: Number(serviceId),
        mdKioskProductId: 0,
        tbServicePropId: e.id,
        serviceName: service?.name ?? '',
        servicePropName: e.name,
        isActive: 0,
      }
    })
    const _initialValues: Partial<IKioskMasterProductFormValues> = {
      mdKioskProductPropListDto: newKioskProductPropList,
    }

    return _initialValues
  }, [service?.name, serviceId, serviceProperties])

  return (
    <>
      <ContentHeader title={service?.name} subTitle="Master Data" />
      {!isLoadingServiceProperties && (
        <KioskMasterProductForm
          initialValues={initialValues}
          serviceId={Number(serviceId)}
          onSubmit={onModalKioskPeriodConfirmClick}
          onCancel={onCancel}
          onConfirmDeleteMasterProduct={onConfirmDeleteMasterProduct}
        />
      )}
    </>
  )
}
