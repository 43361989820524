import { Form, notification } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import dayjs from 'dayjs'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { ContentBody } from '_backend/components/layout/ContentBody'
import { ContentHeader } from '_backend/components/layout/ContentHeader'
import { useModalConfirm } from 'components/ModalConfirmProvider'
import { notifyFormValidateFailed } from 'helpers/form-helper'
import { backendPaths } from 'paths/backend-path-config'
import { useGetServiceOrderQRY } from 'services/order-response/order-response-service-service'
import {
  useGetQuotationDetailQRY,
  useQuotationDeleteMTT,
  useQuotationSaveMTT,
} from 'services/sale/quotation/quotation-service'

import { SaleButtonPrint } from '../ButtonPrint'
import { SectionCustomerFields } from '../SectionCustomerFields'
import { SectionFormAction } from '../SectionFormAction'
import { SectionOrderFields } from '../SectionOrderFields'
import { DiscountUnitEnum } from '../sale-constants'
import { IRecordOrderItem, mapSaleDataItemToRecord } from '../sale-helper'

import { IFormQuotationValues, mapQuotationSaveParams } from './PageQuotationNew'
import { SectionQuotationFields } from './SectionQuotationFields'

export const PageQuotationEdit = () => {
  const { quotationId } = useParams()
  const [form] = useForm()
  const modalConfirm = useModalConfirm()
  const navigate = useNavigate()

  const { data: quotation } = useGetQuotationDetailQRY(Number(quotationId))
  const { data: order } = useGetServiceOrderQRY(Number(quotation?.tbOrderId))
  const { mutateAsync: saveQuotation } = useQuotationSaveMTT()
  const { mutateAsync: deleteQuotation } = useQuotationDeleteMTT()

  const readonly = useMemo(() => quotation?.mdQuotaSt !== 1, [quotation?.mdQuotaSt])
  const exitingCustomerId = useMemo(() => quotation?.tbCustomerId, [quotation?.tbCustomerId])

  const [orderItems, setOrderItems] = useState<IRecordOrderItem[]>([])
  const handleChangeItems = useCallback((newItems: IRecordOrderItem[]) => {
    setOrderItems(newItems)
  }, [])

  const initialValues = useMemo(() => {
    const discountUnit =
      quotation?.discountPercent === 0 && quotation?.discount > 0 ? DiscountUnitEnum.BATH : DiscountUnitEnum.PERCENT
    return {
      id: quotation?.id,
      orderTypeNm: order?.orderTypeNm,
      serviceNm: order?.serviceNm,
      orderNo: order?.orderNo,
      orderDateTimeNm: order?.orderDateTimeNm,
      quotaNo: quotation?.quotaNo,
      quotaDate: dayjs(quotation?.quotaDate),
      remark: quotation?.remark,
      attention: quotation?.attention,
      customerId: exitingCustomerId,
      vatType: quotation?.vatType,
      vatPercent: quotation?.vatPercent,
      discountType: quotation?.discountType,
      discountUnit,
      discount: discountUnit === DiscountUnitEnum.PERCENT ? quotation?.discountPercent : quotation?.discount,
    }
  }, [order, quotation, exitingCustomerId])

  const handleFinish = useCallback(
    (values: IFormQuotationValues) => {
      if (!order || !quotation) return
      modalConfirm.show({
        type: 'default',
        title: 'ยืนยันการแก้ไขใบเสนอราคา',
        content: `คุณยืนยันที่จะแก้ไขใบเสนอราคา ${quotation.quotaNo} นี้หรือไม่ ?`,
        modalProps: {
          width: 700,
        },
        onOk: async () => {
          const saveParams = mapQuotationSaveParams({ order, orderItems, values })
          await saveQuotation(saveParams)
          navigate(backendPaths.quotationList())
          notification.success({ message: 'สำเร็จ', description: 'บันทึกข้อมูลใบเสนอราคาสำเร็จ', duration: 2 })
        },
      })
    },
    [modalConfirm, navigate, order, orderItems, quotation, saveQuotation],
  )

  const handleReject = useCallback(() => {
    if (!quotation) return
    modalConfirm.show({
      type: 'danger',
      title: 'ยืนยันการยกเลิกใบเสนอราคา',
      content: `คุณยืนยันที่จะยกเลิกใบเสนอราคา ${quotation.quotaNo} นี้หรือไม่?`,
      modalProps: {
        width: 700,
      },
      onOk: async () => {
        await deleteQuotation(quotation.id)
        navigate(backendPaths.quotationList())
        notification.success({ message: 'สำเร็จ', description: 'ยกเลิกใบเสนอราคาสำเร็จ', duration: 2 })
      },
    })
  }, [deleteQuotation, modalConfirm, navigate, quotation])

  useEffect(() => {
    setOrderItems(mapSaleDataItemToRecord(quotation?.dataItems))
  }, [quotation?.dataItems])

  useEffect(() => {
    form.setFieldsValue(initialValues)
  }, [form, initialValues])

  return (
    <>
      <ContentHeader title="Sale" subTitle="Edit Quotation">
        {quotation?.quotaFilePDFBaseString && (
          <SaleButtonPrint fileName={`${quotation.quotaNo}.pdf`} base64Data={quotation.quotaFilePDFBaseString} />
        )}
      </ContentHeader>
      <ContentBody>
        <Form
          layout="vertical"
          form={form}
          initialValues={initialValues}
          onFinish={handleFinish}
          onFinishFailed={notifyFormValidateFailed}
          scrollToFirstError
        >
          <SectionQuotationFields readonly={readonly} quotationNo={quotation?.quotaNo} />
          <SectionCustomerFields readonly={readonly} form={form} exitingCustomerId={exitingCustomerId} />
          <SectionOrderFields
            readonly={readonly}
            form={form}
            orderItems={orderItems}
            onChangItems={handleChangeItems}
          />
          <SectionFormAction
            readonly={readonly}
            backLink={backendPaths.quotationList()}
            rejectProps={{ title: 'ยกเลิกใบเสนอราคา', onReject: handleReject }}
          />
        </Form>
      </ContentBody>
    </>
  )
}
