export interface ISearchMemberParams {
  memberType: number
  tbServiceId: number
  txtSearch?: string
}

export enum GetMemberAndSponsorEnum {
  MEMBER = 1,
  SPONSOR = 2,
}

export interface ISaveRemarkParams {
  id: number
  remark?: string
}
