import { Col, Form, Row } from 'antd'
import { FormInstance, useWatch } from 'antd/es/form/Form'
import { styled } from 'goober'
import { CSSProperties, useEffect, useMemo, useState } from 'react'

import { REQUIRED_RULES } from '_backend/constants/form-rules'
import { Txt } from 'components/Txt'

import { UploadImagePanel } from './UploadImagePanel'

export type UploadBannerValueType = {
  desktop?: File | string
  mobile?: File | string
}

interface IUploadBannerProps {
  style?: CSSProperties
  className?: string
  mobileFieldName: string
  desktopFieldName: string
  required?: boolean
  label?: React.ReactNode
  form: FormInstance
}

const LayoutStyled = styled.div`
  min-height: 370px;
  .image {
    height: 100%;
    width: 100%;
    min-height: 316px;
    img {
      max-height: 228px;
    }
  }
  .ant-form-item-explain-error {
    text-align: start;
  }
  .ant-form-item-label {
    padding-bottom: 0;
  }
`
interface IImagePanelProps {
  form: FormInstance
  name: string
  required?: boolean
  title: string
  subTitle: string
}

const ImagePanel = (props: IImagePanelProps) => {
  const { form, name, required, title, subTitle } = props
  const [initialData] = useState(form.getFieldValue(name))
  const data = useWatch(name, form)
  const isFile = useMemo(() => {
    return data instanceof File
  }, [data])
  const [url, setUrl] = useState<string>()

  useEffect(() => {
    if (typeof data === 'string') {
      setUrl(data)
    }
    if (typeof initialData === 'string') {
      setUrl(initialData)
    }
  }, [data, initialData])

  useEffect(() => {
    if (!isFile) {
      form.setFieldsValue({ [name]: undefined })
    }
  }, [form, isFile, name, data])

  if (url && !data) {
    return (
      <>
        <Form.Item name={name} hidden>
          <input />
        </Form.Item>
        <Form.Item style={{ marginBottom: 0 }}>
          <UploadImagePanel
            className="image"
            title={title}
            subTitle={subTitle}
            value={url}
            onChange={(file) => {
              form.setFieldsValue({ [name]: file })
            }}
          />
        </Form.Item>
      </>
    )
  }

  return (
    <Form.Item name={name} style={{ marginBottom: 0 }} rules={required ? REQUIRED_RULES : undefined}>
      <UploadImagePanel className="image" title={title} subTitle={subTitle} />
    </Form.Item>
  )
}

export const UploadBannerFormItem = (props: IUploadBannerProps) => {
  const {
    mobileFieldName,
    desktopFieldName,
    required = true,
    label = <Txt ag="header30">ภาพแบนเนอร์ (Banner)</Txt>,
    style,
    className,
    form,
  } = props

  return (
    <LayoutStyled style={style} className={className}>
      <Form.Item labelCol={{ span: 24 }} label={label} required={required}>
        <Txt ag="body20">(ไฟล์ที่รองรับ .jpeg .jpg .png ขนาดไฟล์ไม่เกิน 10 MB)</Txt>
        <Row gutter={[24, 24]} style={{ height: '100%', marginTop: 12 }}>
          <Col span={24} md={{ span: 12 }}>
            <ImagePanel
              form={form}
              name={desktopFieldName}
              title="หน้า Desktop"
              subTitle="(ขนาดที่แนะนำ 1600 x 800 หรืืออัตราส่วน 2:1)"
              required={required}
            />
          </Col>
          <Col span={24} md={{ span: 12 }}>
            <ImagePanel
              form={form}
              name={mobileFieldName}
              title="หน้า Mobile"
              subTitle="(ขนาดที่แนะนำ 1280 x 960 หรืืออัตราส่วน 4:3)"
              required={required}
            />
          </Col>
        </Row>
      </Form.Item>
    </LayoutStyled>
  )
}
