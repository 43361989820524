import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import { ContentType } from 'services/http-client/base-http-client'
import { encoClient } from 'services/http-client/enco-client'
import { ResponseType } from 'services/response-type'

import { ISaveRemarkParams, ISearchMemberParams } from './member-and-sponsor-param'
import { IMemberAndSponsorResponse } from './member-and-sponsor-response'

const POST_SEARCH_MEMBER_PATH = '/SearchMember'
export const useGetSearchMemberPropQRY = (params: ISearchMemberParams) => {
  return useQuery([POST_SEARCH_MEMBER_PATH, params], async () => {
    const res = await encoClient.post<ResponseType<IMemberAndSponsorResponse[]>>(POST_SEARCH_MEMBER_PATH, params, {
      headers: { 'Content-Type': ContentType.FORM_DATA },
    })
    return res.data.data
  })
}

const POST_SERVICE_PROP = '/Member'
export const useSaveRemarkMemberMTT = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async (param: ISaveRemarkParams) => {
      const res = await encoClient.post<ResponseType<IMemberAndSponsorResponse>>(POST_SERVICE_PROP, param, {
        headers: { 'Content-Type': ContentType.FORM_DATA },
      })
      return res.data.data
    },
    {
      onSuccess: (response) => {
        queryClient.invalidateQueries([POST_SEARCH_MEMBER_PATH])
      },
    },
  )
}
