import { Button, Col, Row } from 'antd'
import { ItemType } from 'antd/lib/menu/hooks/useItems'
import { ReactNode, useCallback, useState } from 'react'
import { useNavigate } from 'react-router'

import { SearchInput } from '_backend/components/SearchInput'
import { Tabs } from '_backend/components/Tabs'
import { ContentBody } from '_backend/components/layout/ContentBody'
import { ContentHeader } from '_backend/components/layout/ContentHeader'
import { Txt } from 'components/Txt'
import { backendPaths } from 'paths/backend-path-config'
import { useGetUserInfoQRY } from 'services/auth/auth-service'
import { GetServiceTypeEnum } from 'services/enco-service/enco-service-param'
import { IServiceData } from 'services/enco-service/enco-service-response'

import { ModalServiceType } from './ModalServiceType'
import { ServiceDisplayTable } from './ServiceDisplayTable'
import { ServiceTable } from './ServiceTable'

interface IMenuItem {
  to: string
  onClick?: (to: string) => void
  label?: ReactNode
  disabled?: boolean
}

const makeMenuLabel = (item: IMenuItem): ItemType => {
  return {
    key: `${item.label}-${item.to}`,
    label: (
      <Button
        style={{
          textAlign: 'left',
        }}
        block
        onClick={item.onClick?.bind(null, item.to)}
        type="text"
        size="small"
        disabled={item.disabled}
      >
        <Txt ag="body20" color={item.disabled ? 'grayBorder' : undefined}>
          {item.label}
        </Txt>
      </Button>
    ),
  }
}

export enum ServiceTabsEnum {
  ENCO_SERVICE = 1,
  OTHER_SERVICE = 2,
  ENCO_SERVICE_DISPLAY_SETTING = 3,
  OTHER_SERVICE_DISPLAY_SETTING = 4,
}

export const PageServiceList = () => {
  const navigate = useNavigate()
  const [visible, setVisible] = useState(false)
  const [tabActive, setTabActive] = useState<number>(ServiceTabsEnum.ENCO_SERVICE)
  const [txtSearchHash, setTxtSearchHash] = useState<Record<string, string | undefined>>({})
  const { data: currentUser } = useGetUserInfoQRY()

  const onShowAddServiceModal = useCallback(() => {
    setVisible(true)
  }, [])

  const onDismissClick = useCallback(() => {
    setVisible(false)
  }, [])

  const onSearchService = useCallback(
    (value?: string) => {
      setTxtSearchHash((prev) => ({ ...prev, [tabActive.toString()]: value }))
    },
    [tabActive],
  )

  const onTabChange = useCallback((value: string) => {
    setTabActive(Number(value))
  }, [])

  const onAddServiceClick = useCallback(
    (serviceType: GetServiceTypeEnum) => {
      setVisible(false)
      switch (serviceType) {
        case GetServiceTypeEnum.ENCO_SERVICE:
          navigate(backendPaths.serviceEncoNew(), {
            state: {
              serviceType,
            },
          })
          break
        case GetServiceTypeEnum.OTHER_SERVICE:
          navigate(backendPaths.serviceOtherNew(), {
            state: {
              serviceType,
            },
          })
          break
        default:
          break
      }
    },
    [navigate],
  )

  const onClickMenuItem = useCallback(
    (to: string) => {
      navigate(to)
    },
    [navigate],
  )

  const renderEncoServiceMenuItems = useCallback(
    (record: IServiceData): ItemType[] => {
      const { id: serviceId, mdServiceCatId } = record
      const isKiosk = +mdServiceCatId === 3
      let items: IMenuItem[] = []
      if (isKiosk) {
        items = [
          {
            label: 'Service Setting',
            to: backendPaths.serviceKioskEdit({
              routeParam: {
                serviceId,
              },
            }),
            onClick: onClickMenuItem,
          },
          {
            label: 'Order Management',
            // disabled: true,
            to: backendPaths.serviceOrderList({
              routeParam: {
                serviceId,
              },
            }),
            onClick: onClickMenuItem,
          },
          {
            label: 'Content Setting',
            to: backendPaths.serviceEncoContent({
              routeParam: {
                serviceId,
              },
            }),
            onClick: onClickMenuItem,
          },
          {
            label: 'Promotion Code Management',
            to: backendPaths.serviceEncoPromotionCodeList({
              routeParam: {
                serviceId,
              },
            }),
            onClick: onClickMenuItem,
          },
          {
            label: 'Item Management',
            disabled: true,
            to: backendPaths.itemManagementList({
              routeParam: {
                serviceId,
              },
            }),
            onClick: onClickMenuItem,
          },
          {
            label: 'Property Management',
            // disabled: true,
            to: backendPaths.serviceEncoPropertyManagementList({
              routeParam: {
                serviceId,
              },
            }),
            onClick: onClickMenuItem,
          },
          {
            label: 'Member Management',
            disabled: true,
            to: backendPaths.serviceEncoPropertyManagementList({
              routeParam: {
                serviceId,
              },
            }),
            onClick: onClickMenuItem,
          },
          {
            label: 'Master Data',
            to: backendPaths.serviceKioskMasterProductList({
              routeParam: {
                serviceId,
              },
            }),
            onClick: onClickMenuItem,
          },
          {
            label: 'รอบการขาย',
            to: backendPaths.serviceKioskPeriodList({
              routeParam: {
                serviceId,
              },
            }),
            onClick: onClickMenuItem,
          },
          // {
          //   label: 'Member & Sponsor Package Customer',
          //   to: backendPaths.memberAndSponsor({
          //     routeParam: {
          //       serviceId,
          //     },
          //   }),
          //   onClick: onClickMenuItem,
          // },
        ]
      } else {
        items = [
          {
            label: 'Service Setting',
            to: backendPaths.serviceEncoEdit({
              routeParam: {
                serviceId,
              },
            }),
            onClick: onClickMenuItem,
          },
          {
            label: 'Order Management',
            to: backendPaths.serviceOrderList({
              routeParam: {
                serviceId,
              },
            }),
            onClick: onClickMenuItem,
            disabled: false,
          },
          {
            label: 'Content Setting',
            to: backendPaths.serviceEncoContent({
              routeParam: {
                serviceId,
              },
            }),
            onClick: onClickMenuItem,
          },
          {
            label: 'Promotion Code Management',
            to: backendPaths.serviceEncoPromotionCodeList({
              routeParam: {
                serviceId,
              },
            }),
            onClick: onClickMenuItem,
          },
          {
            label: 'Item Management',
            to: backendPaths.itemManagementList({
              routeParam: {
                serviceId,
              },
            }),
            onClick: onClickMenuItem,
            disabled: false,
          },
          {
            label: 'Property Management',
            to: backendPaths.serviceEncoPropertyManagementList({
              routeParam: {
                serviceId,
              },
            }),
            onClick: onClickMenuItem,
          },
          {
            label: 'Member & Sponsor Package Customer',
            to: backendPaths.memberAndSponsor({
              routeParam: {
                serviceId,
              },
            }),
            onClick: onClickMenuItem,
          },
        ]
      }

      return items.map(makeMenuLabel)
    },
    [onClickMenuItem],
  )

  const renderOtherServiceMenuItems = useCallback(
    (record: IServiceData) => {
      const { id: serviceId } = record
      const items: IMenuItem[] = [
        {
          label: 'Service Setting',
          to: backendPaths.serviceOtherEdit({
            routeParam: {
              serviceId,
            },
          }),
          onClick: onClickMenuItem,
        },
        {
          label: 'รายการติดต่อกลับลูกค้า',
          to: backendPaths.serviceOtherOrder({
            routeParam: {
              serviceId,
            },
          }),
          onClick: onClickMenuItem,
        },
      ]
      return items.map(makeMenuLabel)
    },
    [onClickMenuItem],
  )

  return (
    <>
      <ContentHeader title="Service Management">
        <Row
          gutter={[
            {
              sm: 24,
              xs: 16,
            },
            {
              sm: 16,
              xs: 16,
            },
          ]}
          justify="end"
          align="middle"
        >
          <Col>
            <SearchInput placeholder="ค้นหาบริการของคุณ" onSearch={onSearchService} />
          </Col>
          {currentUser?.userGrpId === 1 && (
            <Col>
              <Button type="primary" onClick={onShowAddServiceModal}>
                เพิ่มบริการ
              </Button>
            </Col>
          )}
        </Row>
      </ContentHeader>

      <ContentBody>
        <Tabs type="card" defaultActiveKey={tabActive?.toString()} onChange={onTabChange}>
          <Tabs.TabPane tab={'EnCo Service'} key={ServiceTabsEnum.ENCO_SERVICE}>
            <ServiceTable
              txtSearch={txtSearchHash[GetServiceTypeEnum.ENCO_SERVICE]}
              serviceType={GetServiceTypeEnum.ENCO_SERVICE}
              renderMenuItems={renderEncoServiceMenuItems}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab={'Other Service'} key={ServiceTabsEnum.OTHER_SERVICE}>
            <ServiceTable
              txtSearch={txtSearchHash[GetServiceTypeEnum.OTHER_SERVICE]}
              serviceType={GetServiceTypeEnum.OTHER_SERVICE}
              renderMenuItems={renderOtherServiceMenuItems}
            />
          </Tabs.TabPane>

          <Tabs.TabPane tab={'EnCo Service Display Setting'} key={ServiceTabsEnum.ENCO_SERVICE_DISPLAY_SETTING}>
            <ServiceDisplayTable
              txtSearch={txtSearchHash[GetServiceTypeEnum.ENCO_SERVICE]}
              serviceType={GetServiceTypeEnum.ENCO_SERVICE}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab={'Other Service Display Setting'} key={ServiceTabsEnum.OTHER_SERVICE_DISPLAY_SETTING}>
            <ServiceDisplayTable
              txtSearch={txtSearchHash[GetServiceTypeEnum.OTHER_SERVICE]}
              serviceType={GetServiceTypeEnum.OTHER_SERVICE}
            />
          </Tabs.TabPane>
        </Tabs>
      </ContentBody>
      <ModalServiceType visible={visible} onDismiss={onDismissClick} onConfirm={onAddServiceClick} />
    </>
  )
}
