import { useCallback, useState } from 'react'

export const usePaginateState = (defaultPage: number = 1, defaultPageSize: number = 5) => {
  const [page, setPage] = useState(defaultPage)
  const [pageSize, setPageSize] = useState(defaultPageSize)

  const onPageChange = useCallback((newPage: number) => {
    setPage(Math.max(newPage, 0))
  }, [])

  const onPageSizeChange = useCallback((newPageSize: number) => {
    setPageSize(Math.max(newPageSize, 1))
  }, [])

  return {
    page,
    pageSize,
    onPageChange,
    onPageSizeChange,
  }
}
