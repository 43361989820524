import { Col, Row, Space } from 'antd'
import { css, styled } from 'goober'
import { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { AppSwiper, IAppSwiperItem } from 'components/AppSwiper'
import { Txt } from 'components/Txt'
import { mediaQuery, useResponsive } from 'helpers/responsive-helper'
import { appPaths } from 'paths/path-config'
import { GetServiceContactStatusFilterEnum, GetServiceTypeEnum } from 'services/enco-service/enco-service-param'
import { IServiceData } from 'services/enco-service/enco-service-response'
import { useGetServicesQRY } from 'services/enco-service/enco-service-service'

import defaultImage from '../../assets/images/cover-blue.png'

const Layout = styled('div')`
  position: relative;
  background: transparent url(${defaultImage}) 0% 0% no-repeat padding-box;
  opacity: 1;

  padding: 67px 54px;
  padding-bottom: 46px;
  box-sizing: border-box;

  @media ${mediaQuery.mdLte} {
    padding: 36px 60px;
  }

  @media ${mediaQuery.smLte} {
    padding: 24px 38px;
  }
`

const TextLayout = styled(Col)`
  @media ${mediaQuery.mdLte} {
    margin-top: 30px;
  }
`

const SwiperLayout = styled('div')`
  position: relative;
  z-index: 2;
  margin-top: 66px;

  @media ${mediaQuery.smLte} {
    margin-top: 80px;
  }
`

const ServiceSwiperItemCss = (props: { bgUrl: string }) => {
  return css`
    display: flex;
    flex-flow: column;
    justify-content: flex-end;
    height: 400px;

    box-sizing: border-box;

    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 75%, rgba(0, 0, 0, 0.8)), url(${props.bgUrl});
    overflow: hidden;
    background-size: cover;
    border-radius: 13px;
    opacity: 1;

    cursor: pointer;

    span {
      padding: 20px;

      text-shadow: 0 1px 0 rgba(0, 0, 0, 0.7);
    }

    @media screen and (max-width: 1024px) and (orientation: portrait) {
      height: 300px;
    }
  `
}

export const ShortCutOtherService = () => {
  const navigate = useNavigate()
  const { isXs } = useResponsive()
  const { data: services = [], isLoading } = useGetServicesQRY({
    serviceType: GetServiceTypeEnum.OTHER_SERVICE,
    isActive: GetServiceContactStatusFilterEnum.ACTIVE,
  })

  const onItemClick = useCallback(
    (service: IServiceData) => {
      const { id: serviceId, autoLink } = service

      if (autoLink) {
        let link = autoLink
        if (!autoLink.startsWith('https://')) {
          link = 'https://' + link
        }
        window.open(link, '_blank', 'noopener,noreferrer')
      } else {
        navigate(`${appPaths.otherService()}/${serviceId}`)
      }
    },
    [navigate],
  )

  const swiperItems = useMemo(() => {
    return services.map(
      (service): IAppSwiperItem => ({
        key: service.id,
        content: (
          <div className={ServiceSwiperItemCss({ bgUrl: service.icon })} onClick={onItemClick.bind(null, service)}>
            <Txt
              ag="header30"
              color="white"
              ellipsis={{
                tooltip: service.name,
              }}
            >
              {service.name}
            </Txt>
          </div>
        ),
      }),
    )
  }, [onItemClick, services])

  return (
    <Layout>
      <TextLayout flex={1}>
        <Row className="w-100" justify="center">
          <Space direction="vertical" align="center">
            <Txt ag="header40" color="white">
              Other Service
            </Txt>
            <Txt ag="header24" color="white">
              บริการอื่น ๆ อีกมากมายจาก EnCo
            </Txt>
          </Space>
        </Row>
      </TextLayout>
      <SwiperLayout>
        {!isLoading && (
          <AppSwiper
            items={swiperItems}
            breakpoints={{
              1: {
                slidesPerView: Math.min(swiperItems.length, 1),
                spaceBetween: 5,
              },
              576: {
                slidesPerView: Math.min(swiperItems.length, 2),
                spaceBetween: 15,
              },
              1025: {
                slidesPerView: Math.min(swiperItems.length, 3),
                spaceBetween: 15,
              },
            }}
            withPadding={!isXs}
          />
        )}
      </SwiperLayout>
    </Layout>
  )
}
