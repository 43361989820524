import { faEdit, faTrashCan } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Space, Tag } from 'antd'
import Table, { ColumnType } from 'antd/lib/table'
import { styled } from 'goober'
import { compact } from 'lodash'
import { forwardRef, useCallback, useImperativeHandle, useMemo, useState } from 'react'

import { useModalConfirm } from 'components/ModalConfirmProvider'
import { breakpoint } from 'helpers/responsive-helper'
import { ISubmitServiceEmergencyContact } from 'services/emergency-contact/emergency-contact-service-param'
import theme from 'theme/goober'

import { IModalEmergencyContactFormValues, ModalEmergencyContact } from './ModalEmergencyContact'

const Icon = styled(FontAwesomeIcon)`
  color: ${theme.color.lightBluePrimary};
  cursor: pointer;
`

export interface ITableEmergencyContactsContext {
  openAddEmergencyContact: () => void
}

interface ITableEmergencyContactsProps {
  value?: ISubmitServiceEmergencyContact[]
  onChange?: (value?: ISubmitServiceEmergencyContact[]) => void
}
export const TableEmergencyContacts = forwardRef<ITableEmergencyContactsContext, ITableEmergencyContactsProps>(
  (props, contextRef) => {
    const { value: emergencyContacts = [], onChange } = props

    const modalConfirm = useModalConfirm()

    const [editEmergencyContractVisible, setEditEmergencyContractVisible] = useState<boolean>(false)
    const [editEmergencyContract, setEditEmergencyContract] = useState<Partial<IModalEmergencyContactFormValues>>()

    const renderTagActive = useCallback((isActive: boolean) => {
      if (isActive) {
        return <Tag color="success">Show</Tag>
      }
      return <Tag color="default">Hide</Tag>
    }, [])

    const onConfirmEditEmergencyContactClick = useCallback(
      (value: IModalEmergencyContactFormValues) => {
        const { isEditMode, recordIndex } = value
        let newEmergencyContacts: ISubmitServiceEmergencyContact[] = [...emergencyContacts]

        if (isEditMode) {
          newEmergencyContacts[recordIndex] = value
        } else {
          newEmergencyContacts.push(value)
        }

        onChange?.(newEmergencyContacts)
        setEditEmergencyContract(undefined)
        setEditEmergencyContractVisible(false)
      },
      [emergencyContacts, onChange],
    )

    const onDismissEditEmergencyContactClick = useCallback(() => {
      setEditEmergencyContract(undefined)
      setEditEmergencyContractVisible(false)
    }, [])

    const onEmergencyContactClick = useCallback((value?: Partial<IModalEmergencyContactFormValues>) => {
      setEditEmergencyContract(value)
      setEditEmergencyContractVisible(true)
    }, [])

    const onEmergencyContactDeleteClick = useCallback(
      (recordIndex: number) => {
        modalConfirm.show({
          type: 'danger',
          title: 'ยืนยันที่การลบผู้ดูแล (Emergency Contacts)',
          content: 'คุณยืนยันที่จะลบผู้ดูแล (Emergency Contacts) นี้หรือไม่?',
          onOk: () => {
            const newEmergencyContacts = emergencyContacts.filter((e, index) => index !== recordIndex)
            onChange?.(newEmergencyContacts)
          },
        })
      },
      [emergencyContacts, modalConfirm, onChange],
    )

    const emergencyContactColumns = useMemo(
      (): ColumnType<ISubmitServiceEmergencyContact>[] => [
        {
          title: 'No.',
          width: '60px',
          align: 'center',
          render: (value, record, index) => index + 1,
        },
        {
          title: 'ชื่อ - นามสกุล',
          render: (value, record) => compact([record.firstname, record.lastname]).join(' '),
        },
        { title: 'ตำแหน่ง', dataIndex: 'emPosition' },
        { title: 'เบอร์โทรศัพท์', dataIndex: 'tel' },
        { title: 'อีเมล', dataIndex: 'email' },
        {
          title: 'การแสดงผล',
          dataIndex: 'active',
          render: renderTagActive,
        },
        {
          width: '92px',
          fixed: 'right',
          render: (value, record, recordIndex) => {
            return (
              <Space size={20} direction="horizontal">
                <Icon icon={faEdit} onClick={onEmergencyContactClick.bind(null, { ...record, recordIndex })} />
                <Icon icon={faTrashCan} onClick={onEmergencyContactDeleteClick.bind(null, recordIndex)} />
              </Space>
            )
          },
        },
      ],
      [renderTagActive, onEmergencyContactClick, onEmergencyContactDeleteClick],
    )

    useImperativeHandle(contextRef, () => {
      return {
        openAddEmergencyContact: onEmergencyContactClick.bind(null, undefined),
      }
    })

    return (
      <>
        <Table
          rowKey={(e) => `${e.firstname}-${e.lastname}-${e.email}`}
          columns={emergencyContactColumns}
          dataSource={emergencyContacts}
          scroll={{ x: breakpoint.smMax }}
          pagination={false}
        />
        {editEmergencyContractVisible && (
          <ModalEmergencyContact
            visible={editEmergencyContractVisible}
            initialValues={editEmergencyContract}
            emergencyContactEmpCodesSelected={emergencyContacts}
            onConfirm={onConfirmEditEmergencyContactClick}
            onDismiss={onDismissEditEmergencyContactClick}
          />
        )}
      </>
    )
  },
)
