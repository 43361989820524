import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Card, Col, Divider, Form, notification, Radio, Row, Select, Space } from 'antd'
import dayjs from 'dayjs'
import { styled } from 'goober'
import { isNull } from 'lodash'
import { useCallback, useMemo, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { apiDateFormat } from '_backend/constants/date-format'
import { REQUIRED_RULES } from '_backend/constants/form-rules'
import DatePicker from 'components/DatePicker'
import { Input, TextArea } from 'components/Input'
import { useModalConfirm } from 'components/ModalConfirmProvider'
import { Txt } from 'components/Txt'
import { notifyFormValidateFailed } from 'helpers/form-helper'
import { backendPaths } from 'paths/backend-path-config'
import { ENCO_TYPE_OZONE_ID } from 'services/enco-service/enco-service-service'
import { ISearchOrderResponse } from 'services/order-response/order-response-service-response'
import {
  useGetMasterServiceTimeQRY,
  useSaveServiceOrderMTT,
} from 'services/order-response/order-response-service-service'
import { useGetMasterServiceLocationQRY } from 'services/service-location/service-location-service-service'
import theme from 'theme/goober'

import { TextOrderStatus } from './TextOrderStatus'
import { ButtonFullReceipt } from './full-receipt/ButtonFullReceipt'

const FlexCenterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
`

const ChevronDivider = (props: { span: number }) => (
  <Col span={24} sm={{ span: props.span }}>
    <FlexCenterContainer>
      <FontAwesomeIcon icon={faChevronRight} color={theme.color.lightBluePrimary} />
    </FlexCenterContainer>
  </Col>
)

const isFieldRequired = (isCancel: boolean) => {
  if (isCancel) return undefined
  return REQUIRED_RULES
}

const dashIfNull = (value?: string | number) => {
  return value || '-'
}

interface IFormOrderRequestProps {
  order: ISearchOrderResponse
  isSponsor?: boolean
}

export const FormOrderRequest = (props: IFormOrderRequestProps) => {
  const navigate = useNavigate()
  const modalConfirm = useModalConfirm()
  const [form] = Form.useForm()
  const { order, isSponsor = false } = props

  const { data: serviceLocations } = useGetMasterServiceLocationQRY()
  const { data: serviceTimes } = useGetMasterServiceTimeQRY()

  const { mutate: saveServiceOrder } = useSaveServiceOrderMTT()

  const initialIsCanceled = useMemo(() => !isNull(order.canceldate), [order])

  const [isCancel, setIsCancel] = useState<boolean>(initialIsCanceled)

  const initialValues = useMemo(
    () => ({
      orderNo: order.orderNo,
      orderType: order.orderTypeNm,
      orderDate: order.orderDateTimeNm,
      quotationNo: dashIfNull(order.quotaNo),
      quotaDatenm: dashIfNull(order.quotaDatenm),
      invoiceNo: dashIfNull(order.invoiceNo),
      invoiceDatenm: dashIfNull(order.invoiceDatenm),
      receiptNo: dashIfNull(order.receiptNo),
      receiptDateNm: dashIfNull(order.receiptDateNm),

      fullName: `${order.firstname} ${order.lastname}`,
      tel: order.tel,
      email: order.email,
      company: order.company,

      ozoneType: order.tbServiceId === ENCO_TYPE_OZONE_ID && order.mdOrderTypeId === ENCO_TYPE_OZONE_ID && 'พื้นที่',
      locationType: order.mdServiceLocationId,
      areaSize: order.areaSize,
      distance: order.distance,
      locationDesc: order.locationDesc,
      serviceDate: order.serviceDate,
      serviceTimeId: order.serviceTimeId,

      responseDate: order.responseDate,
      orderStatus: initialIsCanceled,
      rejectUserDes: order.rejectUserDes,
      usernm: order.usernm,
      customernm: order.customernm,
    }),
    [order, initialIsCanceled],
  )

  const handleChangeIsCancel = useCallback(
    (value: boolean) => {
      form.setFields([
        { name: 'locationType', errors: undefined },
        { name: 'areaSize', errors: undefined },
        { name: 'distance', errors: undefined },
        { name: 'serviceDate', errors: undefined },
        { name: 'serviceTimeId', errors: undefined },
      ])
      form.validateFields(['locationType', 'areaSize', 'distance', 'serviceDate', 'serviceTimeId'])
      setIsCancel(value)
    },
    [setIsCancel, form],
  )

  const checkValuesIsNull = useCallback((value: any, key: string, params: any) => {
    if (value) {
      const newValue = key === 'serviceDate' ? dayjs(value).format(apiDateFormat) : value
      params[key] = newValue
    }
  }, [])

  const handleClickConfirmSubmit = useCallback(
    (values: typeof initialValues) => {
      const params: any = {
        id: order.id,
      }

      if (values.responseDate) {
        params.ResponseDate = dayjs(values.responseDate).format(apiDateFormat)
      }

      if (!isSponsor) {
        checkValuesIsNull(values.locationType, 'MdServiceLocationId', params)
        checkValuesIsNull(values.areaSize, 'AreaSize', params)
        checkValuesIsNull(values.distance, 'Distance', params)
        checkValuesIsNull(values.serviceDate, 'serviceDate', params)
        checkValuesIsNull(values.serviceTimeId, 'serviceTimeId', params)
        params.LocationDesc = values.locationDesc
      }

      if (isCancel) {
        params.rejectUserDes = values.rejectUserDes
      }
      saveServiceOrder(params)
      navigate(backendPaths.serviceOrderList({ routeParam: { serviceId: order.tbServiceId } }))
      notification.success({ message: 'สำเร็จ', description: 'บันทึกข้อมูลออเดอร์สำเร็จ', duration: 2 })
      form.resetFields()
    },
    [order, saveServiceOrder, navigate, isSponsor, isCancel, form, checkValuesIsNull],
  )

  const handleClickSubmit = useCallback(
    (values: typeof initialValues) => {
      modalConfirm.show({
        type: 'default',
        title: 'ยืนยันบันทึกข้อมูลการติดต่อกลับ',
        content: `คุณยืนยันที่จะบันทึกข้อมูลการติดต่อกลับหมายเลข Order ${order.orderNo} นี้หรือไม่?`,
        onOk: () => handleClickConfirmSubmit(values),
      })
    },
    [modalConfirm, order, handleClickConfirmSubmit],
  )

  const serviceOrderListPath = useMemo(
    () => backendPaths.serviceOrderList({ routeParam: { serviceId: order.tbServiceId } }),
    [order.tbServiceId],
  )

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={handleClickSubmit}
      onFinishFailed={notifyFormValidateFailed}
      initialValues={initialValues}
      scrollToFirstError
    >
      <Card>
        <Row gutter={24}>
          <Col span={24} sm={{ span: 12 }} className="text-left">
            <Txt ag="body20">สถานะการชำระเงิน</Txt>
          </Col>
          {!isSponsor && order.tbServiceId !== ENCO_TYPE_OZONE_ID && (
            <Col span={24} sm={{ span: 12 }} className="text-right">
              <ButtonFullReceipt order={order} />
            </Col>
          )}
          <Col span={24} style={{ marginBottom: '16px' }}>
            <TextOrderStatus order={order} />
          </Col>
          <Col span={24} sm={{ span: 12 }}>
            <Form.Item name="usernm" label="ผู้บันทึก Order ">
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={24} sm={{ span: 12 }}>
            <Form.Item name="customernm" label="ข้อมูลลูกค้า">
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={24} sm={{ span: 8 }}>
            <Form.Item name="orderNo" label="หมายเลขออเดอร์">
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={24} sm={{ span: 8 }}>
            <Form.Item name="orderType" label="ประเภทออเดอร์">
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={24} sm={{ span: 8 }}>
            <Form.Item name="orderDate" label="Order Date">
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={24} sm={{ span: 12 }}>
            <Form.Item name="quotationNo" label="หมายเลขใบเสนอราคา">
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={24} sm={{ span: 12 }}>
            <Form.Item name="quotaDatenm" label="วันที่ออกใบเสนอราคา">
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={24} sm={{ span: 12 }}>
            <Form.Item name="invoiceNo" label="หมายเลขใบแจ้งหนี้">
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={24} sm={{ span: 12 }}>
            <Form.Item name="invoiceDatenm" label="วันที่ออกใบแจ้งหนี้">
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={24} sm={{ span: 12 }}>
            <Form.Item name="receiptNo" label="หมายเลขใบเสร็จ">
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={24} sm={{ span: 12 }}>
            <Form.Item name="receiptDateNm" label="วันที่ออกใบเสร็จ">
              <Input disabled />
            </Form.Item>
          </Col>
        </Row>
        <Divider style={{ margin: '32px 0 56px 0' }} />

        <Row gutter={24}>
          <Col style={{ marginBottom: 16 }} span={24}>
            <Txt ag="header30">ข้อมูลลูกค้า</Txt>
          </Col>
          <Col span={24} sm={{ span: 12 }}>
            <Form.Item name="fullName" label="ชื่อลูกค้า">
              <Input placeholder="ชื่อ - นามสกุล" disabled />
            </Form.Item>
          </Col>
          <Col span={24} sm={{ span: 12 }}>
            <Form.Item name="tel" label="เบอร์โทรศัพท์">
              <Input placeholder="เบอร์โทรศัพท์" disabled />
            </Form.Item>
          </Col>
          <Col span={24} sm={{ span: 12 }}>
            <Form.Item name="email" label="อีเมล">
              <Input placeholder="อีเมล" disabled />
            </Form.Item>
          </Col>
          <Col span={24} sm={{ span: 12 }}>
            <Form.Item name="company" label="ชื่อบริษัท">
              <Input placeholder="ชื่อบริษัท" disabled />
            </Form.Item>
          </Col>
        </Row>
        <Divider style={{ margin: '32px 0 56px 0' }} />

        {!isSponsor && (
          <>
            <Row gutter={24}>
              <Col style={{ marginBottom: 16 }} span={24}>
                <Txt ag="header30">รายละเอียดการจองอบโอโซน</Txt>
              </Col>
              <Col span={24} sm={{ span: 12 }}>
                <Form.Item name="ozoneType" label="ประเภทการอบโอโซน">
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col span={12} />
              <Col span={24} sm={{ span: 12 }}>
                <Form.Item name="locationType" label="ประเภทพื้นที่" rules={isFieldRequired(isCancel)}>
                  <Select disabled={initialIsCanceled}>
                    {serviceLocations?.map((location) => (
                      <Select.Option key={location.id} value={location.id}>
                        {location.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <ChevronDivider span={1} />
              <Col span={24} sm={{ span: 3 }}>
                <Form.Item name="areaSize" label="ขนาดพื้นที่" rules={isFieldRequired(isCancel)}>
                  <Input disabled={initialIsCanceled} />
                </Form.Item>
              </Col>
              <Col span={24} sm={{ span: 2 }}>
                <FlexCenterContainer>
                  <Txt ag="body20">ตารางเมตร</Txt>
                </FlexCenterContainer>
              </Col>
              <ChevronDivider span={1} />
              <Col span={24} sm={{ span: 3 }}>
                <Form.Item name="distance" label="ระยะทาง" rules={isFieldRequired(isCancel)}>
                  <Input disabled={initialIsCanceled} />
                </Form.Item>
              </Col>
              <Col span={24} sm={{ span: 2 }}>
                <FlexCenterContainer>
                  <Txt ag="body20">กิโลเมตร</Txt>
                </FlexCenterContainer>
              </Col>
              <Col span={24} sm={{ span: 24 }}>
                <Form.Item name="locationDesc" label="ลักษณะพื้นที่">
                  <TextArea rows={3} autoSize={{ minRows: 3, maxRows: 3 }} disabled={initialIsCanceled} />
                </Form.Item>
              </Col>
              <Col span={24} sm={{ span: 11 }}>
                <Form.Item name="serviceDate" label="วันที่จอง" rules={isFieldRequired(isCancel)}>
                  <DatePicker disabled={initialIsCanceled} />
                </Form.Item>
              </Col>
              <ChevronDivider span={1} />
              <Col span={24} sm={{ span: 11 }}>
                <Form.Item name="serviceTimeId" label="ช่วงเวลาที่จอง" rules={isFieldRequired(isCancel)}>
                  <Select placeholder="กรุณาเลือกช่วงเวลา" disabled={initialIsCanceled}>
                    {serviceTimes?.map((time) => (
                      <Select.Option key={time.id} value={time.id}>
                        {time.serviceTime}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Divider style={{ margin: '32px 0 56px 0' }} />
          </>
        )}

        <Row gutter={24}>
          <Col style={{ marginBottom: 16 }} span={24}>
            <Txt ag="header30">ข้อมูลผู้ติดต่อ</Txt>
          </Col>
          <Col span={24} sm={{ span: 8 }}>
            <Row gutter={24}></Row>
            <Form.Item
              name="responseDate"
              label="วันที่ติดต่อลูกค้า"
              required={form.getFieldValue('orderStatus')}
              rules={form.getFieldValue('orderStatus') ? REQUIRED_RULES : []}
            >
              <DatePicker placeholder="กรุณาเลือก" disabled={initialIsCanceled} />
            </Form.Item>
            <Form.Item name="orderStatus" label="สถานะออเดอร์">
              <Radio.Group
                onChange={(e) => {
                  handleChangeIsCancel(e.target.value)
                  if (!form.getFieldValue('orderStatus')) {
                    form.setFields([{ name: 'rejectUserDes', errors: [] }])
                    form.setFieldsValue({ rejectUserDes: '' })

                    form.setFields([{ name: 'responseDate', errors: [] }])
                  }
                }}
                disabled={initialIsCanceled}
              >
                <Radio value={false}>ดำเนินการต่อ</Radio>
                <Radio value={true}>ยกเลิกออเดอร์</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={24} sm={{ span: 16 }}>
            <Form.Item
              name="rejectUserDes"
              label="หมายเหตุ"
              required={form.getFieldValue('orderStatus')}
              rules={form.getFieldValue('orderStatus') ? REQUIRED_RULES : []}
            >
              <TextArea rows={5} autoSize={{ minRows: 5, maxRows: 5 }} disabled={initialIsCanceled || !isCancel} />
            </Form.Item>
          </Col>
        </Row>
      </Card>
      <Row style={{ marginTop: 8 }}>
        <Col span={24}>
          <Card className="w-100">
            <Space style={{ justifyContent: 'space-between', width: '100%' }}>
              <Link to={serviceOrderListPath}>
                <Button>กลับ</Button>
              </Link>
              <Button type="primary" htmlType="submit">
                บันทึก
              </Button>
            </Space>
          </Card>
        </Col>
      </Row>
    </Form>
  )
}
