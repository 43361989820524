import { Button } from 'antd'
import { useCallback, useState } from 'react'

import { AppModal } from 'components/AppModal'

export const WikiAppModal = () => {
  const [visible, setVisible] = useState(false)
  const onDismiss = useCallback(() => {
    setVisible(false)
  }, [])
  return (
    <>
      <Button onClick={() => setVisible(true)}>Open Modal</Button>
      <AppModal visible={visible}>
        <AppModal.Header onCloseClick={onDismiss}>
          Title...Title...Title...Title...Title...Title...Title...Title...Title...Title...Title...Title...Title...Title...Title...Title...
        </AppModal.Header>
        <AppModal.Body>Bodydddd</AppModal.Body>
        <AppModal.Footer>
          <Button onClick={onDismiss}>Cancel</Button>
          <Button onClick={onDismiss}>Maybe</Button>
          <Button onClick={onDismiss}>Ok</Button>
        </AppModal.Footer>
      </AppModal>
      <div style={{ textAlign: 'start', marginTop: 16, whiteSpace: 'pre' }}>{codeText}</div>
    </>
  )
}

const codeText = `import { AppModal } from 'backend/components/AppModal'

export const Component = () => {
  const [visible, setVisible] = useState(false)
  const onDismiss = useCallback(() => {
    setVisible(false)
  }, [])

  return (
    <>
      <Button onClick={() => setVisible(true)}>Open Modal</Button>
      <AppModal visible={visible}>
        <AppModal.Header onCloseClick={onDismiss}>Title...</AppModal.Header>
        <AppModal.Body>Bodydddd</AppModal.Body>
        <AppModal.Footer>
          <Button onClick={onDismiss}>Cancel</Button>
          <Button onClick={onDismiss}>Maybe</Button>
          <Button onClick={onDismiss}>Ok</Button>
        </AppModal.Footer>
      </AppModal>
    </>
  )
}`
