import { Pagination } from 'antd'
import { styled } from 'goober'
import { useCallback, useMemo, useState } from 'react'

import coverBlueUrl from 'assets/images/cover-blue.png'
import coverPrNewsUrl from 'assets/images/cover-pr-news.jpg'
import { CardListLayout } from 'components/CardListLayout'
import ServiceShortcut from 'components/ServiceShortcut'
import { CoverPageList } from 'components/layout/CoverPageList'
import { appPaths } from 'paths/path-config'
import { usePrNewsGetListQRY } from 'services/pr-news/pr-news-service'

const ContentContainer = styled('div')`
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
`

const FooterContainer = styled('div')`
  display: flex;
  justify-content: center;
  min-height: 100px;
`

export const PagePrNewsList = () => {
  const [currentPage, setCurrentPage] = useState(1)
  const [currentPageSize, setCurrentPageSize] = useState(6)
  const { data: prList } = usePrNewsGetListQRY({ platForm: 2, isActive: 1 })

  const sortedPrList = useMemo(
    () => prList?.sort((a) => a.recNo).slice((currentPage - 1) * currentPageSize, currentPage * currentPageSize),
    [prList, currentPage, currentPageSize],
  )

  const onPageChange = useCallback(
    (page: number, pageSize: number) => {
      setCurrentPage(page)
      setCurrentPageSize(pageSize)
    },
    [setCurrentPage, setCurrentPageSize],
  )

  return (
    <>
      <CoverPageList title="PR & News" url={coverBlueUrl} insideUrl={coverPrNewsUrl} />
      <ContentContainer>
        <CardListLayout>
          {sortedPrList &&
            sortedPrList.map((pr) => {
              const { name, coverImgWeb, id, startdateDisplay } = pr
              return (
                <ServiceShortcut
                  key={id}
                  cover={coverImgWeb}
                  title={name}
                  date={startdateDisplay}
                  linkTo={`${appPaths.prNewsList()}/${id}`}
                />
              )
            })}
        </CardListLayout>
      </ContentContainer>
      <FooterContainer>
        <Pagination
          current={currentPage}
          pageSize={currentPageSize}
          total={prList?.length}
          defaultPageSize={6}
          defaultCurrent={1}
          onChange={onPageChange}
        />
      </FooterContainer>
    </>
  )
}
