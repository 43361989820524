import { Rule } from 'antd/lib/form'

export const REQUIRED_RULES: Rule[] = [{ required: true, message: 'ต้องไม่เว้นว่างไว้' }]
export const requiredCustomMessage = (message: string) => [{ required: true, message }]
export const NUMBER_MORE_THAN_ZERO = [{ pattern: new RegExp(/^[1-9][0-9]*$/), message: 'กรุณากรอกราคามากกว่า 0' }]

export const NUMBER_MORE_THAN_ZERO_AND_LESS_THAN_NINETY_NINE = [
  { pattern: new RegExp(/^[0-9]{0,2}$/), message: 'กรุณากรอกตัวเลขมากกว่า 0 และน้อยกว่า 99' },
]

export const TAX_ID_RULES = [{ pattern: /^[0-9]{13}$/, message: 'กรุณากรอกเป็นตัวเลข 13 หลัก' }]

export const EMAIL_FORMAT_RULES = [
  { pattern: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/, message: 'รูปแบบอีเมลไม่ถูกต้อง' },
]

export const PASSWORD_RULE: Rule[] = [
  {
    type: 'string',
    pattern: /[a-z]/,
    message: `At least 1 lowercase letter`,
  },
  {
    type: 'string',
    pattern: /[A-Z]/,
    message: `At least 1 uppercase letter`,
  },
  {
    type: 'string',
    pattern: /[~!@#$%^&*()_\-+=\[\]{}\\|;:"'<>,.?/]/,
    message: `At least 1 special character`,
  },
  {
    type: 'string',
    pattern: /\d/,
    message: `At least 1 number`,
  },
  {
    type: 'string',
    pattern: /.{8,16}/,
    message: `At least 8 characters`,
  },
]
