import { notification } from 'antd'
import Form, { useForm } from 'antd/lib/form/Form'
import dayjs from 'dayjs'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { ContentBody } from '_backend/components/layout/ContentBody'
import { ContentHeader } from '_backend/components/layout/ContentHeader'
import { apiDateFormat } from '_backend/constants/date-format'
import { useModalConfirm } from 'components/ModalConfirmProvider'
import { notifyFormValidateFailed } from 'helpers/form-helper'
import { backendPaths } from 'paths/backend-path-config'
import { IGetInvoiceResponse } from 'services/sale/invoice/invoice-response'
import { useGetInvoiceQRY } from 'services/sale/invoice/invoice-service'
import { useGetQuotationDetailQRY } from 'services/sale/quotation/quotation-service'
import { IReceiptSaveParams } from 'services/sale/receipt/receipt-param'
import { useReceiptSaveMTT } from 'services/sale/receipt/receipt-service'

import { SectionCustomerFields } from '../SectionCustomerFields'
import { SectionFormAction } from '../SectionFormAction'
import { SectionOrderFields } from '../SectionOrderFields'
import { DiscountUnitEnum, IReceiptFormFields, VatTypeEnum } from '../sale-constants'
import { IRecordOrderItem, mapSaleDataItemToRecord } from '../sale-helper'

import { SectionReceiptFields } from './SectionReceiptFields'

export interface IFormReceiptValues extends IReceiptFormFields {
  // Customer
  attention: string
  customerId: number
  customerTaxId: string
  customerAddress: string

  // Order
  vatPercent: number
  vatAmount: number
  discountUnit: DiscountUnitEnum
  discount: number
  discountAmount: number
  netPrice: number
}

interface IMapReceiptSaveParams {
  invoice: IGetInvoiceResponse
  orderItems: IRecordOrderItem[]
  values: IFormReceiptValues
}

export const mapReceiptSaveParams = (params: IMapReceiptSaveParams): IReceiptSaveParams => {
  const { invoice, orderItems, values } = params
  return {
    id: values.id,
    tbInvoiceId: invoice.id,
    receiveDate: dayjs(values.receiveDate).format(apiDateFormat),
    vatType: values.vatType,
    discountType: values.discountType,
    attention: values.attention,
    tbCustomerId: values.customerId,
    vatPercent: values.vatPercent,
    vat: values.vatAmount,
    discountPercent: values.discountUnit === DiscountUnitEnum.PERCENT ? values.discount : 0,
    discount: values.discountAmount,
    total: values.netPrice,
    remark: values.remark,
    receiptDts: orderItems.map((o) => ({
      id: o.id || 0,
      item: o.itemName,
      qty: o.quantity,
      unit: o.unit,
      unitPrice: o.unitPrice,
      total: o.unitPrice * o.quantity,
    })),
  }
}

export const PageReceiptNew = () => {
  const [searchParams] = useSearchParams()
  const invoiceId = searchParams.get('invoiceId')
  const [form] = useForm()
  const modalConfirm = useModalConfirm()
  const navigate = useNavigate()

  const [orderItems, setOrderItems] = useState<IRecordOrderItem[]>([])
  const handleChangeItems = useCallback((newItems: IRecordOrderItem[]) => {
    setOrderItems(newItems)
  }, [])

  const { data: invoice, isFetching } = useGetInvoiceQRY(Number(invoiceId))
  const { data: quotation } = useGetQuotationDetailQRY(Number(invoice?.tbQuotaId))
  const { mutateAsync: saveReceipt } = useReceiptSaveMTT()

  const exitingCustomerId = useMemo(() => quotation?.tbCustomerId, [quotation?.tbCustomerId])

  const initialValues = useMemo<Partial<IFormReceiptValues>>(() => {
    const discountUnit =
      invoice?.discountPercent === 0 && invoice?.discount > 0 ? DiscountUnitEnum.BATH : DiscountUnitEnum.PERCENT

    return {
      id: 0,
      mdQuotaStName: invoice?.mdQuotaStName,
      vatType: VatTypeEnum.VAT_EXCLUDED,
      discountType: invoice?.discountType,
      invoiceNo: invoice?.invoiceNo,
      invoiceDatedisplay: invoice?.invoiceDatedisplay,
      quotaNo: invoice?.quotaNo,
      quodatedisplay: invoice?.quodatedisplay,
      tbServiceName: invoice?.tbServiceName,
      orderNo: invoice?.orderNo,
      orderDatedisplay: invoice?.orderDatedisplay,
      remark: invoice?.remark,

      attention: quotation?.attention,
      customerId: quotation?.tbCustomerId,

      vatPercent: invoice?.vatPercent,
      discountUnit: discountUnit,
      discount: discountUnit === DiscountUnitEnum.PERCENT ? invoice?.discountPercent : invoice?.discount,
    }
  }, [invoice, quotation])

  const handleFinish = useCallback(
    (values: IFormReceiptValues) => {
      modalConfirm.show({
        type: 'default',
        title: 'ยืนยันการสร้างใบเสร็จรับเงิน',
        content: 'คุณยืนยันที่จะสร้างใบเสร็จรับเงินนี้หรือไม่',
        modalProps: {
          width: 700,
        },
        onOk: async () => {
          if (!invoice) return
          const saveParams = mapReceiptSaveParams({ invoice, orderItems, values })
          await saveReceipt(saveParams)
          navigate(backendPaths.receiptList())
          notification.success({ message: 'สำเร็จ', description: 'บันทึกข้อมูลใบเสร็จรับเงินสำเร็จ', duration: 2 })
        },
      })
    },
    [invoice, modalConfirm, navigate, orderItems, saveReceipt],
  )

  useEffect(() => {
    setOrderItems(mapSaleDataItemToRecord(invoice?.dataItems, true))
  }, [invoice?.dataItems])

  useEffect(() => {
    form.setFieldsValue(initialValues)
  }, [form, initialValues])

  return (
    <>
      <ContentHeader title="Sale" subTitle="New Receipt" />
      <ContentBody>
        {!isFetching && (
          <Form<IFormReceiptValues>
            layout="vertical"
            form={form}
            onFinish={handleFinish}
            onFinishFailed={notifyFormValidateFailed}
            scrollToFirstError
            initialValues={initialValues}
          >
            <SectionReceiptFields />
            <SectionCustomerFields readonly form={form} exitingCustomerId={exitingCustomerId} />
            <SectionOrderFields form={form} orderItems={orderItems} onChangItems={handleChangeItems} />
            <SectionFormAction backLink={backendPaths.receiptList()} />
          </Form>
        )}
      </ContentBody>
    </>
  )
}
