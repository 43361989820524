import { notification } from 'antd'
import { keyBy, map } from 'lodash'
import { useCallback, useMemo } from 'react'
import { useParams, useNavigate } from 'react-router-dom'

import { ContentHeader } from '_backend/components/layout/ContentHeader'
import { useVisible } from 'helpers/modal-helper'
import { useGetServiceQRY } from 'services/enco-service/enco-service-service'
import { MdKioskProductPropListDto } from 'services/kiosk-master/kiosk-master-response'
import {
  useDeleteKioskMasterProductMTT,
  useGetKioskMasterProductQRY,
  useSaveKioskMasterProductMTT,
} from 'services/kiosk-master/kiosk-master-service'
import { useGetServicePropListQRY } from 'services/service-property/service-property-service'

import { IKioskMasterProductFormValues, KioskMasterProductForm } from './KioskMasterProductForm'

export const PageKioskMasterProductEdit = () => {
  const { serviceId, productId } = useParams()
  const navigate = useNavigate()

  const { data: service } = useGetServiceQRY(Number(serviceId))
  const { mutate: saveKioskMasterProduct } = useSaveKioskMasterProductMTT()
  const { mutate: deleteKioskMasterProduct } = useDeleteKioskMasterProductMTT()
  const { data: masterProduct, isFetching: isFetchingMasterProduct } = useGetKioskMasterProductQRY(
    productId ? +productId : undefined,
  )
  const { data: serviceProperties, isFetching: isLoadingServiceProperties } = useGetServicePropListQRY({
    ServiceId: Number(serviceId),
  })

  const modalKioskPeriodVisible = useVisible()

  const onModalKioskPeriodConfirmClick = useCallback(
    async (data: IKioskMasterProductFormValues) => {
      if (serviceId) {
        saveKioskMasterProduct(
          { ...data, tbServiceId: +serviceId },
          {
            onSuccess() {
              navigate(-1)
            },
          },
        )
        modalKioskPeriodVisible.close()
      }
    },
    [modalKioskPeriodVisible, navigate, saveKioskMasterProduct, serviceId],
  )

  const onConfirmDeleteMasterProduct = useCallback(
    (masterProductId: number) => {
      if (masterProductId) {
        deleteKioskMasterProduct(masterProductId, {
          onSuccess() {
            navigate(-1)
            notification.success({ message: 'สำเร็จ', description: 'ยกเลิกรายการสำเร็จ', duration: 2 })
          },
        })
        modalKioskPeriodVisible.close()
      }
    },
    [deleteKioskMasterProduct, modalKioskPeriodVisible, navigate],
  )

  const onCancel = useCallback(() => {
    navigate(-1)
  }, [navigate])

  const isCanceled = useMemo(() => {
    return !!masterProduct?.canceldate
  }, [masterProduct?.canceldate])

  const initialValues = useMemo(() => {
    const _initialValues: Partial<IKioskMasterProductFormValues> = { ...masterProduct }
    const oldKioskProductPropHash = keyBy(_initialValues?.mdKioskProductPropListDto, (e) => e.tbServicePropId)

    if (_initialValues) {
      const newKioskProductPropList = map(serviceProperties ?? [], (e): MdKioskProductPropListDto => {
        const oldKioskProductProp = oldKioskProductPropHash[e.id]
        return {
          id: oldKioskProductProp?.id || 0,
          tbServiceid: Number(serviceId),
          mdKioskProductId: Number(productId),
          tbServicePropId: e.id,
          serviceName: service?.name ?? '',
          servicePropName: e.name,
          isActive: oldKioskProductProp?.isActive ? 1 : 0,
        }
      })
      _initialValues.mdKioskProductPropListDto = newKioskProductPropList

      return _initialValues
    }
  }, [masterProduct, productId, service?.name, serviceId, serviceProperties])

  return (
    <>
      <ContentHeader title={service?.name} subTitle="Master Data" />
      {!isFetchingMasterProduct && !isLoadingServiceProperties && (
        <KioskMasterProductForm
          initialValues={initialValues}
          serviceId={Number(serviceId)}
          onSubmit={onModalKioskPeriodConfirmClick}
          onCancel={onCancel}
          onConfirmDeleteMasterProduct={onConfirmDeleteMasterProduct}
          disabled={isCanceled}
        />
      )}
    </>
  )
}
