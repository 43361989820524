import { UserOutlined } from '@ant-design/icons'
import { MenuProps } from 'antd'
import { styled } from 'goober'
import { useCallback } from 'react'
import { MdOutlineAdminPanelSettings } from 'react-icons/md'
import { RiLogoutBoxLine } from 'react-icons/ri'
import { Link } from 'react-router-dom'

import { useModalConfirm } from 'components/ModalConfirmProvider'
import { backendPaths } from 'paths/backend-path-config'
import { appPaths } from 'paths/path-config'
import { useLogout } from 'services/auth/auth-service'

import { ReactComponent as MyOrderIconSvg } from '../assets/images/shopping-bag.svg'

const MyOrderIcon = styled(MyOrderIconSvg)`
  width: 16px;
  margin-right: 8px;
`

interface IProfileMenuItem {
  code: number
  label: string | React.ReactNode
  icon?: React.ReactNode
  path?: string
}

const LogoutMenu = () => {
  const { logout } = useLogout()
  const modalConfirm = useModalConfirm()
  const handleOnClick = useCallback(() => {
    modalConfirm.show({
      type: 'default',
      title: 'ออกจากระบบ',
      content: 'คุณแน่ใจที่จะออกจากระบบหรือไม่?',
      onOk: () => {
        logout()
      },
    })
  }, [logout, modalConfirm])

  return <div onClick={handleOnClick}>Logout</div>
}

export const PROFILE_MENU_LIST: IProfileMenuItem[] = [
  {
    code: 1,
    label: <Link to={backendPaths.backend()}>Admin</Link>,
    icon: <MdOutlineAdminPanelSettings size={20} />,
    path: backendPaths.backend(),
  },
  {
    code: 2,
    label: <Link to={appPaths.profile()}>Profile</Link>,
    icon: <UserOutlined />,
    path: appPaths.profile(),
  },
  {
    code: 3,
    label: <Link to={appPaths.orderList()}>My Order</Link>,
    icon: <MyOrderIcon />,
    path: appPaths.orderList(),
  },
  {
    code: 5,
    label: <LogoutMenu />,
    icon: <RiLogoutBoxLine />,
  },
]

export const profileMenuItems: MenuProps['items'] = PROFILE_MENU_LIST.map((menu) => {
  return {
    key: menu.code.toString(),
    label: menu.label,
    icon: menu.icon,
    path: menu.path,
  }
})
