import { notification } from 'antd'
import dayjs from 'dayjs'
import { useCallback } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { ContentBody } from '_backend/components/layout/ContentBody'
import { ContentHeader } from '_backend/components/layout/ContentHeader'
import { backendPaths } from 'paths/backend-path-config'
import { IPrNewsUpdateParam } from 'services/pr-news/pr-news-param'
import { usePrNewsGetDetailQRY, usePrNewsUpdateMTT } from 'services/pr-news/pr-news-service'

import { PrNewsForm } from './PrNewsForm'

export const PagePrNewsEdit = () => {
  const navigate = useNavigate()
  const { prNewsId = 'N/A' } = useParams()
  const { data: prNewsItem, isLoading } = usePrNewsGetDetailQRY({ id: prNewsId })
  const { mutateAsync: prNewsUpdate } = usePrNewsUpdateMTT()

  const handleFormSubmit = useCallback(
    async (values: IPrNewsUpdateParam) => {
      await prNewsUpdate({ ...values, id: prNewsId })
      notification.success({ message: 'สำเร็จ', description: 'บันทึกข้อมูลบทความสำเร็จ', duration: 2 })
      navigate(backendPaths.prNewsList())
    },
    [navigate, prNewsId, prNewsUpdate],
  )

  const handleBack = useCallback(() => {
    navigate(backendPaths.prNewsList())
  }, [navigate])

  if (!prNewsItem) return null

  return (
    <>
      <ContentHeader subTitle="แก้ไขบทความ PR News" />
      <ContentBody>
        <PrNewsForm
          onSubmit={handleFormSubmit}
          onBack={handleBack}
          initialValues={{
            ...prNewsItem,
            publishDateRange: [dayjs(prNewsItem.startdate), dayjs(prNewsItem.enddate)],
          }}
          isLoading={isLoading}
          isEdit
        />
      </ContentBody>
    </>
  )
}
