import { Button, Col, Form, Row } from 'antd'
import { styled } from 'goober'
import { useCallback, useLayoutEffect } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { useLocation, useNavigate } from 'react-router-dom'

import { REQUIRED_RULES } from '_backend/constants/form-rules'
import { Input } from 'components/Input'
import { getEnv } from 'helpers/env-config-helper'
import { useLoginMTT } from 'services/auth/auth-service'

import loginBgBottom from '../../assets/images/login-bg-bottom.png'
import loginBgContent from '../../assets/images/login-bg-content.png'
import loginBgTop from '../../assets/images/login-bg-top.png'
import loginBg from '../../assets/images/login-bg.png'
import logoEnCo from '../../assets/images/logo-enco.png'
import bodyStyle from '../../components/layout/FrontendLayout.module.less'

const RootLayoutStyled = styled('div')`
  height: 100vh;
`
const LeftLayoutStyled = styled('div')`
  position: relative;
  background-image: url(${loginBg});
  height: 100%;
`
const RightLayoutStyled = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`
const BgStyled = styled('img')`
  position: absolute;
`
const BgContentStyled = styled(BgStyled)`
  margin: auto 0;
  top: 0;
  bottom: -10%;
  right: 45px;
  width: 100%;
`
const BgTopStyled = styled(BgStyled)`
  right: 0;
  height: 43%;
  max-height: 450px;
`
const BgBottomStyled = styled(BgStyled)`
  bottom: 0;
  height: 34%;
  max-height: 367px;
`
const RowStyle = styled(Row)`
  height: 100%;
`

interface ISignInFormValue {
  username: string
  password: string
  reCaptchaToken: string
}

export const PageLogin = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { mutateAsync: login, isLoading } = useLoginMTT()
  const [form] = Form.useForm<ISignInFormValue>()

  const handleSubmit = useCallback(
    async (value: ISignInFormValue) => {
      const { username, password } = value
      try {
        await login({
          username,
          password,
          reCaptchaToken: value.reCaptchaToken,
        })
        navigate((location.state as any)?.from ?? '/', { replace: true })
      } catch {}
    },
    [location.state, login, navigate],
  )

  useLayoutEffect(() => {
    document.body.className = bodyStyle.body
  }, [])

  return (
    <RootLayoutStyled>
      <RowStyle>
        <Col span={0} lg={14}>
          <LeftLayoutStyled>
            <BgContentStyled src={loginBgContent} />
            <BgTopStyled src={loginBgTop} />
            <BgBottomStyled src={loginBgBottom} />
          </LeftLayoutStyled>
        </Col>
        <Col span={24} lg={10}>
          <RightLayoutStyled>
            <Form<ISignInFormValue>
              form={form}
              layout="vertical"
              onFinish={handleSubmit}
              style={{ width: '100%', maxWidth: 343, margin: '16px' }}
            >
              <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 43 }}>
                <img src={logoEnCo} alt="EnCo Logo" width="100px" />
              </div>
              <Form.Item
                label="User name (ตัวอย่าง: EnCo รหัสพนักงาน)"
                name="username"
                rules={REQUIRED_RULES}
                required={false}
              >
                <Input showCount={false} placeholder="User name (ตัวอย่าง: EnCo รหัสพนักงาน)" />
              </Form.Item>
              <Form.Item label="Password" name="password" rules={REQUIRED_RULES} required={false}>
                <Input showCount={false} type="password" placeholder="Password" />
              </Form.Item>
              <Form.Item
                name="reCaptchaToken"
                rules={[
                  {
                    required: true,
                    message: 'กรุณายืนยันตัวตน',
                  },
                ]}
              >
                <Button htmlType="submit" type="primary" style={{ width: '100%', marginTop: 53 }} loading={isLoading}>
                  Sign in
                </Button>
              </Form.Item>

              <Row justify="center" style={{ marginTop: 53 }}>
                <Form.Item
                  name="reCaptchaToken"
                  required={false}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                  noStyle
                >
                  <ReCAPTCHA sitekey={getEnv('googleRecaptchaClientKey')} />
                </Form.Item>
              </Row>
            </Form>
          </RightLayoutStyled>
        </Col>
      </RowStyle>
    </RootLayoutStyled>
  )
}
