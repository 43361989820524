import { Button } from 'antd'
import { styled } from 'goober'

import theme from 'theme/goober'

export const ButtonWarning = styled(Button)`
  color: ${theme.color.orangePrimaryText};
  border-color: ${theme.color.orangePrimaryText};
  &:focus {
    color: ${theme.color.orangePrimaryText};
    border-color: ${theme.color.orangePrimaryText};
  }
  &:hover {
    color: ${theme.color.orangeHover};
    border-color: ${theme.color.orangeHover};
  }
`
