import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import { ContentType } from 'services/http-client/base-http-client'
import { encoClient } from 'services/http-client/enco-client'
import { ResponseType } from 'services/response-type'

import {
  IGetKioskMasterProductListParams,
  IGetKioskMasterProductParams,
  ISubmitKioskMasterProductParams,
} from './kiosk-master-params'
import { IGetKioskMasterProductResponse } from './kiosk-master-response'

const GET_MASTER_KIOSK_PRODUCT_PATH = '/GetMasterKioskProduct'
const MASTER_KIOSK_PRODUCT_PATH = '/MasterKioskProduct'

export const useGetKioskMasterProductListQRY = (tbServiceId?: number, txtSearch?: string) => {
  const params: IGetKioskMasterProductListParams = {}
  if (tbServiceId) {
    params.tbServiceId = tbServiceId
  }
  if (txtSearch) {
    params.txtSearch = txtSearch
  }
  return useQuery(
    [GET_MASTER_KIOSK_PRODUCT_PATH, params],
    async () => {
      const res = await encoClient.get<ResponseType<IGetKioskMasterProductResponse[]>>(
        `${GET_MASTER_KIOSK_PRODUCT_PATH}`,
        {
          params,
        },
      )
      return res.data.data
    },
    {
      enabled: !!tbServiceId,
    },
  )
}

export const useGetKioskMasterProductQRY = (productId?: number, txtSearch?: string) => {
  const params: IGetKioskMasterProductParams = {}
  if (productId) {
    params.id = productId
  }
  if (txtSearch) {
    params.txtSearch = txtSearch
  }
  return useQuery(
    [GET_MASTER_KIOSK_PRODUCT_PATH, MASTER_KIOSK_PRODUCT_PATH, params],
    async () => {
      const res = await encoClient.get<ResponseType<IGetKioskMasterProductResponse>>(`${MASTER_KIOSK_PRODUCT_PATH}`, {
        params,
      })
      return res.data.data
    },
    {
      enabled: !!productId,
    },
  )
}

export const useSaveKioskMasterProductMTT = () => {
  const queryClient = useQueryClient()
  return useMutation<unknown, unknown, ISubmitKioskMasterProductParams>(
    async (data) => {
      const res = await encoClient.post<ResponseType<IGetKioskMasterProductResponse>>(
        `${MASTER_KIOSK_PRODUCT_PATH}`,
        data,
        {
          headers: { 'Content-Type': ContentType.JSON },
        },
      )
      return res.data.data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([GET_MASTER_KIOSK_PRODUCT_PATH])
      },
    },
  )
}

export const useDeleteKioskMasterProductMTT = () => {
  const queryClient = useQueryClient()
  return useMutation<unknown, unknown, number>(
    async (id) => {
      const res = await encoClient.delete<ResponseType<IGetKioskMasterProductResponse>>(
        `${MASTER_KIOSK_PRODUCT_PATH}`,
        {
          params: { id },
        },
      )
      return res.data.data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([GET_MASTER_KIOSK_PRODUCT_PATH])
      },
    },
  )
}
