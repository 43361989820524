import { Button, Col, Form, Select } from 'antd'
import { useForm } from 'antd/lib/form/Form'
import { styled } from 'goober'
import { useCallback, useEffect, useMemo } from 'react'

import { requiredCustomMessage } from '_backend/constants/form-rules'
import { FormModal } from 'components/FormModal'
import { Input } from 'components/Input'
import { useGetMasterUserInterestQRY } from 'services/user/user-service'

const Container = styled('div')`
  width: 100%;
  max-width: 400px;
`

const CustomSelector = styled(Select)`
  width: 100%;
  text-align: left;
  .big-selector {
    height: 66px !important;
  }

  .ant-select-selection-item {
    line-height: 30px !important;
    height: 48px;
    align-items: center;
  }
  .ant-select-item-option {
    align-items: center;
  }
`

const ButtonContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 40px;
`

const CustomButton = styled(Button)`
  width: 100%;
  max-width: 343px;
  margin-bottom: 14px;
`

export interface UserProfileValues {
  firstName: string
  lastName: string
  email: string
  company: string
  interestIds: number[]
}

interface EditProfileModalProps {
  value: UserProfileValues
  visible: boolean
  onClose: () => void
  onSave: (values: UserProfileValues) => void
}

export const EditProfileModal = (props: EditProfileModalProps) => {
  const { onClose, visible, onSave, value } = props
  const [form] = useForm()

  const { data: interests } = useGetMasterUserInterestQRY()

  const interestOptions = useMemo(() => {
    return interests
      ? interests.map((interest) => ({
          label: interest.name,
          value: interest.id,
        }))
      : []
  }, [interests])

  const handleClickClose = useCallback(() => {
    form.resetFields()
    onClose()
  }, [form, onClose])

  const handleFinishForm = useCallback(
    (values: UserProfileValues) => {
      onSave(values)
    },
    [onSave],
  )

  useEffect(() => {
    if (value) form.setFieldsValue(value)
  }, [form, value])

  return (
    <FormModal visible={visible} onCancel={handleClickClose} title="My Profile" width={800}>
      <Container>
        <Form form={form} layout="vertical" onFinish={handleFinishForm} initialValues={value}>
          <Col xs={24}>
            <Form.Item name="firstName" label="First Name" rules={requiredCustomMessage('กรุณากรอกชื่อ')}>
              <Input placeholder="First Name" />
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item name="lastName" label="Last Name" rules={requiredCustomMessage('กรุณากรอกนามสกุล')}>
              <Input placeholder="Last Name" />
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item name="email" label="Email" rules={[...requiredCustomMessage('กรุุณากรอก Email')]}>
              <Input placeholder="Email" />
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item name="company" label="Company" rules={requiredCustomMessage('กรุณากรอก Company')}>
              <Input placeholder="Company" />
            </Form.Item>
          </Col>

          <Col xs={24}>
            <Form.Item name="interestIds" label="สิ่งที่สนใจ" rules={requiredCustomMessage('กรุณาเลือกสิ่งที่สนใจ')}>
              <CustomSelector
                mode="multiple"
                className="big-selector"
                dropdownClassName="big-selector"
                placeholder="Interesting"
                options={interestOptions}
                value={value.interestIds}
              />
            </Form.Item>
          </Col>
          <ButtonContainer>
            <Form.Item style={{ width: '100%', maxWidth: '343px', margin: 0 }}>
              <CustomButton htmlType="submit" type="primary">
                Save
              </CustomButton>
            </Form.Item>
            <CustomButton onClick={handleClickClose}>Close</CustomButton>
          </ButtonContainer>
        </Form>
      </Container>
    </FormModal>
  )
}
