import { styled } from 'goober'
import { useCallback, useState } from 'react'

import { FormModal } from 'components/FormModal'
import { ISaveAddressParams } from 'services/address/address-params'
import { IGetAddrResponse } from 'services/address/address-response'
import { useSearchUserAddressMTT } from 'services/address/address-service'

import { AddressForm } from './AddressForm'
import { AddressSelector } from './AddressSelector'

const Container = styled('div')`
  width: 100%;
`

type ModalState = 'selector' | 'form'

interface AddressModalProps {
  checkboxLabel?: string
  isShowLabel?: boolean
  canSelect?: boolean
  visible: boolean
  onClose: () => void
  onSelectAddress?: (address: IGetAddrResponse) => void
  addresses: IGetAddrResponse[]
  currentSelected?: IGetAddrResponse
  onSaveAddress: (address: ISaveAddressParams) => Promise<boolean>
  onRemove?: (id: number) => void
}
export const AddressModal = (props: AddressModalProps) => {
  const {
    visible,
    addresses,
    onClose,
    onSelectAddress,
    currentSelected,
    onSaveAddress,
    canSelect,
    onRemove,
    isShowLabel = false,
    checkboxLabel,
  } = props

  const { mutateAsync } = useSearchUserAddressMTT()

  const [state, setState] = useState<ModalState>('selector')
  const [editAddr, setEditAddr] = useState<IGetAddrResponse | undefined>()

  const handleSelectAddress = useCallback(
    (address: IGetAddrResponse) => {
      if (onSelectAddress) {
        onSelectAddress(address)
        onClose()
      }
    },
    [onSelectAddress, onClose],
  )
  const handleClickAdd = useCallback(() => {
    setState('form')
    setEditAddr(undefined)
  }, [])

  const handleCloseModal = useCallback(() => {
    onClose()
    setTimeout(() => {
      setState('selector')
      setEditAddr(undefined)
    }, 1000)
  }, [onClose])

  const handleCreateNewAddress = useCallback(
    async (values: ISaveAddressParams) => {
      const isError = await onSaveAddress(values)
      if (!isError) {
        if (editAddr?.id === currentSelected?.id) {
          const res = await mutateAsync({ id: values.id })
          handleSelectAddress(res[0])
        }
        setEditAddr(undefined)
        setState('selector')
      }
    },
    [onSaveAddress, editAddr, handleSelectAddress, currentSelected, mutateAsync],
  )

  const handleRemove = useCallback(
    (id: number) => {
      if (onRemove) onRemove(id)
    },
    [onRemove],
  )

  const handleClickEdit = useCallback((address: IGetAddrResponse) => {
    setEditAddr(address)
    setState('form')
  }, [])

  return (
    <FormModal title="ข้อมูลใช้เพื่อออกใบเสร็จแบบเต็ม" onCancel={handleCloseModal} visible={visible} width={800}>
      <Container>
        {state === 'selector' && (
          <AddressSelector
            checkboxLabel={checkboxLabel}
            isShowLabel={isShowLabel}
            onRemove={handleRemove}
            canSelect={canSelect}
            currentSelected={currentSelected}
            onClickAdd={handleClickAdd}
            onClose={handleCloseModal}
            addresses={addresses}
            onSelect={handleSelectAddress}
            onClickEdit={handleClickEdit}
          />
        )}
        {state === 'form' && (
          <AddressForm onSave={handleCreateNewAddress} onClose={handleCloseModal} editAddr={editAddr} />
        )}
      </Container>
    </FormModal>
  )
}
