import { notification } from 'antd'
import dayjs from 'dayjs'
import { useCallback, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { ContentBody } from '_backend/components/layout/ContentBody'
import { ContentHeader } from '_backend/components/layout/ContentHeader'
import { apiDateFormat } from '_backend/constants/date-format'
import { backendPaths } from 'paths/backend-path-config'
import { useGetServiceQRY } from 'services/enco-service/enco-service-service'
import { IServiceItemSaveParams } from 'services/item-management/item-management-param'
import { useSaveServiceItemMTT } from 'services/item-management/item-management-service'

import { IServiceItemFormValue, ServiceItemForm } from './ServiceItemForm'

export const PageServiceItemNew = () => {
  const navigate = useNavigate()
  const { serviceId: ServiceIdParams } = useParams()
  const serviceId = Number(ServiceIdParams)

  const { data: service } = useGetServiceQRY(serviceId)
  const { mdServiceCatId = 0 } = service ?? {}
  const isKiosk = +mdServiceCatId === 3

  const { mutateAsync: saveItem } = useSaveServiceItemMTT()

  const initialValue = useMemo(
    (): Partial<IServiceItemFormValue> => ({
      active: true,
      serviceName: service?.name || '',
      name: '',
      unit: '',
      slotType: null,
    }),
    [service],
  )

  const handleSubmitForm = useCallback(
    async (value: IServiceItemFormValue) => {
      let saveParams: IServiceItemSaveParams = {
        Id: 0,
        ServiceId: serviceId,
        ItemTypeId: value.itemTypeId,
        Name: value.name,
        Unit: value.unit,
        SlotType: value.slotType,
        EncoPrice: value.encoPrice,
        GeneralPrice: value.generalPrice,
        MemberPrice: value.memberPrice,
        Picture: value.picture,
        Active: value.active ? 1 : 0,
      }
      if (value.discount > 0) saveParams.Discount = value.discount
      if (value.rageDate) {
        saveParams.StartDate = dayjs(value.rageDate[0]).format(apiDateFormat)
        saveParams.EndDate = dayjs(value.rageDate[1]).format(apiDateFormat)
      }
      if (value.servicePropId) saveParams.ServicePropId = value.servicePropId
      await saveItem(saveParams)
      navigate(backendPaths.itemManagementList({ routeParam: { serviceId: serviceId } }))
      notification.success({ message: 'สำเร็จ', description: 'บันทึกข้อมูลไอเทมสำเร็จ', duration: 2 })
    },
    [serviceId, saveItem, navigate],
  )

  if (!service) return null
  return (
    <>
      <ContentHeader title={service.name} subTitle="New Item" />
      <ContentBody>
        <ServiceItemForm
          serviceId={Number(serviceId)}
          initialValue={initialValue}
          onSubmitForm={handleSubmitForm}
          isKiosk={isKiosk}
        />
      </ContentBody>
    </>
  )
}
