import { Button, Card, Col, Form, Input, notification, Row, Space, Switch } from 'antd'
import { useCallback, useEffect, useMemo } from 'react'

import { REQUIRED_RULES } from '_backend/constants/form-rules'
import { useModalConfirm } from 'components/ModalConfirmProvider'
import { Txt } from 'components/Txt'
import { notifyFormValidateFailed } from 'helpers/form-helper'
import { useVisible } from 'helpers/modal-helper'
import { ISubmitKioskMasterProductParams } from 'services/kiosk-master/kiosk-master-params'

import { KioskMasterSubProduct } from './KioskMasterSubProduct'
import {
  IModalKioskMasterProductPropertyFormValues,
  ModalKioskMasterProductProperty,
} from './ModalKioskMasterProductProperty'

export interface IKioskMasterProductFormValues extends ISubmitKioskMasterProductParams {}

interface IKioskMasterProductFormProps {
  serviceId: number
  initialValues?: Partial<IKioskMasterProductFormValues>
  onSubmit: (values: IKioskMasterProductFormValues) => Promise<void>
  onCancel: () => void
  onConfirmDeleteMasterProduct: (masterProductId: number) => Promise<void> | void
  disabled?: boolean
}

export const KioskMasterProductForm = (props: IKioskMasterProductFormProps) => {
  const {
    serviceId,
    onSubmit,
    onCancel,
    initialValues: preInitialValues,
    onConfirmDeleteMasterProduct,
    disabled,
  } = props

  const productId = preInitialValues?.id ?? 0

  const modalConfirm = useModalConfirm()

  const [form] = Form.useForm<IKioskMasterProductFormValues>()
  const mdKioskProductPropListDto = Form.useWatch('mdKioskProductPropListDto', form)

  const modalKioskMasterProductPropertyVisible = useVisible()

  const isEditMode = useMemo(() => {
    return !!preInitialValues?.id
  }, [preInitialValues?.id])

  const initialValues = useMemo(() => {
    const temp: Partial<IKioskMasterProductFormValues> = { ...preInitialValues }
    temp.id ||= 0
    temp.isActive ??= 1
    temp.mdKioskProductPropListDto ??= []
    temp.mdKioskSubProductDtos ??= []
    return temp
  }, [preInitialValues])

  const onConfirmSaveClick = useCallback(async () => {
    const values = form.getFieldsValue()
    values.isActive = values.isActive ? 1 : 0
    await onSubmit({ ...values, tbServiceId: serviceId })
    notification.success({ message: 'สำเร็จ', description: 'บันทึกข้อมูลสำเร็จ', duration: 2 })
  }, [form, onSubmit, serviceId])

  const handleSubmit = useCallback(
    (values: IKioskMasterProductFormValues) => {
      modalConfirm.show({
        type: 'default',
        title: 'ยืนยันการบันทึกข้อมูล',
        content: 'คุณยืนยันที่จะบันทึกข้อมูลนี้ ใช่หรือไม่ ?',
        onOk: onConfirmSaveClick,
      })
    },
    [modalConfirm, onConfirmSaveClick],
  )

  const handleBackClick = useCallback(() => {
    modalConfirm.show({
      type: 'default',
      title: 'ยืนยันการออกจากหน้าจอ',
      content: 'คุณต้องการออกจากหน้าจอนี้ ใช่หรือไม่ ?',
      onOk: onCancel,
    })
  }, [modalConfirm, onCancel])

  const handleDeleteMasterProductClick = useCallback(() => {
    modalConfirm.show({
      type: 'default',
      title: 'ยืนยันการยกเลิกรายการ',
      content: 'คุณยืนยันที่จะยกเลิกรายการนี้ ใช่หรือไม่ ?',
      onOk: () => {
        onConfirmDeleteMasterProduct(productId)
      },
    })
  }, [modalConfirm, onConfirmDeleteMasterProduct, productId])

  const onModalKioskMasterProductPropertyDismissClick = useCallback(() => {
    modalKioskMasterProductPropertyVisible.close()
  }, [modalKioskMasterProductPropertyVisible])

  const onModalKioskMasterProductPropertyConfirmClick = useCallback(
    (data: IModalKioskMasterProductPropertyFormValues) => {
      form.setFieldsValue({
        mdKioskProductPropListDto: data.mdKioskProductPropListDto ?? [],
      })
      modalKioskMasterProductPropertyVisible.close()
    },
    [form, modalKioskMasterProductPropertyVisible],
  )

  useEffect(() => {
    form.setFieldsValue(initialValues)
  }, [form, initialValues])

  return (
    <Form
      layout="vertical"
      autoComplete="off"
      form={form}
      initialValues={initialValues}
      onFinish={handleSubmit}
      onFinishFailed={notifyFormValidateFailed}
      scrollToFirstError
      style={{ width: '100%' }}
    >
      {/* keep values */}
      <Form.Item name="id" hidden>
        <Input />
      </Form.Item>
      <Form.Item name="tbServiceId" hidden>
        <Input />
      </Form.Item>
      <Form.Item name="mdKioskProductPropListDto" hidden>
        <Input />
      </Form.Item>
      <Form.Item name="mdKioskSubProductDtos" hidden>
        <Input />
      </Form.Item>
      {/* keep values */}

      <Space size={20} direction="vertical" style={{ width: '100%' }}>
        <Card style={{ width: '100%' }}>
          <Space size={10} direction="vertical" style={{ width: '100%' }}>
            <Txt ag="header24">ประเภทสินค้า</Txt>
            <Row gutter={[24, 10]} align="middle">
              <Col sm={24} md={8}>
                <Form.Item label="ชื่อประเภทสินค้า" name="productName" rules={REQUIRED_RULES} required>
                  <Input placeholder="ตัวอย่าง เครื่องสำอาง/ยา/อาหารเสริม" maxLength={255} disabled={disabled} />
                </Form.Item>
              </Col>
              <Col sm={8} md={4}>
                <Row gutter={10} align="middle" wrap={false}>
                  <Col>
                    <Txt ag="body18">Active</Txt>
                  </Col>
                  <Col>
                    <Form.Item name="isActive" noStyle valuePropName="checked">
                      <Switch title="Active" disabled={disabled} />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col sm={10} md={6}>
                <Row justify="end">
                  <Button type="primary" onClick={modalKioskMasterProductPropertyVisible.open} disabled={disabled}>
                    กำหนด Kiosk ที่เปิดขาย
                  </Button>
                </Row>
              </Col>
            </Row>
          </Space>
        </Card>
        <Card style={{ width: '100%' }}>
          <Form.Item name="mdKioskSubProductDtos" shouldUpdate>
            <KioskMasterSubProduct disabled={disabled} />
          </Form.Item>
        </Card>
        <Row gutter={18} justify="space-between">
          <Col>
            <Button onClick={handleBackClick}>กลับ</Button>
          </Col>
          <Col>
            <Row gutter={18} justify="space-between">
              {isEditMode && (
                <Col>
                  <Button type="primary" onClick={handleDeleteMasterProductClick} disabled={disabled} danger>
                    ยกเลิกรายการ
                  </Button>
                </Col>
              )}
              <Col>
                <Button type="primary" htmlType="submit" disabled={disabled}>
                  บันทึก
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <ModalKioskMasterProductProperty
          visible={modalKioskMasterProductPropertyVisible.visible}
          initialValues={{
            mdKioskProductPropListDto,
          }}
          onDismiss={onModalKioskMasterProductPropertyDismissClick}
          onConfirm={onModalKioskMasterProductPropertyConfirmClick}
        />
      </Space>
    </Form>
  )
}
