import { Button, Form, notification } from 'antd'
import { useForm, useWatch } from 'antd/es/form/Form'
import { useCallback, useEffect, useMemo } from 'react'

import { REQUIRED_RULES } from '_backend/constants/form-rules'
import { AppModal } from 'components/AppModal'
import { TextArea } from 'components/Input'
import { useGetAppConfigQRY, useSaveAppConfigMTT } from 'services/enco-service/enco-service-service'

interface ModalAutoMessageSettingProps {
  visible: boolean
  onCloseClick: () => void
}

export const ModalAutoMessageSetting = (props: ModalAutoMessageSettingProps) => {
  const { visible, onCloseClick } = props
  const [form] = useForm()

  const { data: appConfigData } = useGetAppConfigQRY()
  const { mutateAsync: saveMessage } = useSaveAppConfigMTT()

  const handleSubmit = useCallback(
    async (value: { message: string }) => {
      try {
        await saveMessage(value.message)
        notification.success({ message: 'สำเร็จ', description: 'บันทึกข้อความสำเร็จ', duration: 2 })
        onCloseClick()
      } catch {}
    },
    [onCloseClick, saveMessage],
  )

  const messageValue = useWatch(['message'], form)

  const initialValue = useMemo(
    () => ({
      message: appConfigData,
    }),
    [appConfigData],
  )

  useEffect(() => {
    form.setFieldsValue(initialValue)
  }, [form, initialValue])

  useEffect(() => {
    if (!visible) {
      form.resetFields()
    }
  }, [form, visible])

  return (
    <AppModal visible={visible}>
      <Form onFinish={handleSubmit} form={form} initialValues={initialValue}>
        <AppModal.Header onCloseClick={onCloseClick}>ตั้งค่าข้อความตอบกลับอัตโนมัติ</AppModal.Header>
        <AppModal.Body>
          <Form.Item name="message" label="ข้อความตอบกลับ" required rules={REQUIRED_RULES} labelCol={{ span: 24 }}>
            <TextArea maxLength={200} />
          </Form.Item>
        </AppModal.Body>
        <AppModal.Footer>
          <Button onClick={onCloseClick}>ยกเลิก</Button>
          <Button type="primary" htmlType="submit" disabled={!messageValue}>
            บันทึก
          </Button>
        </AppModal.Footer>
      </Form>
    </AppModal>
  )
}
