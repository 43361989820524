import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import { FileHelper } from 'helpers/file-helper'
import { ContentType } from 'services/http-client/base-http-client'
import { encoClient } from 'services/http-client/enco-client'

import { ResponseType } from '../response-type'

import {
  IExportSuggestionParams,
  IGetSuggestionFileParams,
  IGetSuggestionParams,
  ISaveOrderSuggestionParams,
  ISearchSuggestionParams,
} from './suggestion-service-param'
import { IExportSuggestionResponse, ISuggestion } from './suggestion-service-response'

const SUGGESTION_PATH = '/Suggestion'
const SEARCH_SUGGEST_PATH = '/SearchSuggest'
const SUGGESTION_FILE_PATH = '/SuggestionFile'
const EXPORT_SUGGEST = '/ExportSuggest'

export const useSearchSuggestionQTY = (params: ISearchSuggestionParams, enabled: boolean = true) => {
  return useQuery(
    [SEARCH_SUGGEST_PATH, params],
    async () => {
      const response = await encoClient.post<ResponseType<ISuggestion[]>>(SEARCH_SUGGEST_PATH, params, {
        headers: {
          'Content-Type': ContentType.FORM_DATA,
        },
      })

      return response.data.data
    },
    {
      // enabled: (!!params.ServiceId || !!params.TbOrderId || !!params.txtSearch) && enabled,
      enabled,
    },
  )
}

export const useGetSuggestionQTY = (suggestionId: number) => {
  const params: IGetSuggestionParams = {
    id: suggestionId,
  }
  return useQuery(
    [SUGGESTION_PATH, params],
    async () => {
      const response = await encoClient.get<ResponseType<ISuggestion>>(SUGGESTION_PATH, {
        params,
      })

      return response.data.data
    },
    {
      enabled: !!suggestionId,
    },
  )
}

export const useSaveOrderSuggestionMTT = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async (params: ISaveOrderSuggestionParams) => {
      const response = await encoClient.post<ResponseType<ISuggestion>>(SUGGESTION_PATH, params, {
        headers: {
          'Content-Type': ContentType.JSON,
        },
      })

      return response.data.data
    },
    {
      onSuccess: (response, params) => {
        const { tbOrderId: TbOrderId } = params
        queryClient.invalidateQueries([
          SEARCH_SUGGEST_PATH,
          {
            TbOrderId,
          },
        ])
      },
    },
  )
}

export const useGetSuggestionFileQTY = (suggestionFileId: number) => {
  const params: IGetSuggestionFileParams = {
    id: suggestionFileId,
  }
  return useQuery(
    [SUGGESTION_FILE_PATH, params],
    async () => {
      const response = await encoClient.get<ResponseType<ISuggestion>>(SUGGESTION_FILE_PATH, {
        params,
      })

      return response.data.data
    },
    {
      enabled: !!suggestionFileId,
    },
  )
}

export const useExportSuggestMTT = () => {
  return useMutation(async (params: IExportSuggestionParams) => {
    const res = await encoClient.post<ResponseType<IExportSuggestionResponse>>(EXPORT_SUGGEST, params)
    const data = res.data.data
    const base64Response = await fetch(
      `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${data.fileString}`,
    )
    const blob = await base64Response.blob()
    const blobUrl = URL.createObjectURL(blob)
    FileHelper.download(blobUrl, data.fileName)
  })
}
