import { Form } from 'antd'
import { useForm } from 'antd/es/form/Form'
import { Dayjs } from 'dayjs'
import { useCallback, useState } from 'react'
import { useParams } from 'react-router-dom'

import { apiDateFormat } from '_backend/constants/date-format'
import { LayoutCardPage } from 'components/layout/LayoutCardPage'
import { useGetServiceQRY } from 'services/enco-service/enco-service-service'
import { useGetSearchServicePropQRY } from 'services/property-management/property-management-service'

import { OrderSummary } from '../OrderSummary'

import { OzoneOrderForm } from './OzoneOrderForm'
import { IOzoneSlotItem } from './OzoneSlotSelector'

export enum FieldNameEnum {
  LocationId = 'locationId',
  ItemId = 'itemId',
  Date = 'date',
  Slot = 'slot',
}
export interface IFormValue {
  [FieldNameEnum.LocationId]: number
  [FieldNameEnum.ItemId]: number
  [FieldNameEnum.Date]: Dayjs
  [FieldNameEnum.Slot]: IOzoneSlotItem[]
}

export const PageOzoneOrder = () => {
  const [form] = useForm<IFormValue>()
  const [step, setStep] = useState<number>(0)
  const { serviceId } = useParams()
  const { data: serviceData } = useGetServiceQRY(Number(serviceId))
  const { data: propertyData } = useGetSearchServicePropQRY({ ServiceId: Number(serviceId) })

  const toNextStep = useCallback(() => {
    setStep((step + 1) % 2)
  }, [step])

  const handleFormSubmit = useCallback(() => {
    if (step === 0) {
      toNextStep()
      document.documentElement.scrollTo(0, 0)
    }
  }, [step, toNextStep])

  const handleOnSummaryBack = useCallback(() => {
    setStep(0)
    document.documentElement.scrollTo(0, 0)
  }, [])

  return (
    <LayoutCardPage>
      <Form<IFormValue> form={form} onFinish={handleFormSubmit}>
        {step === 0 && <OzoneOrderForm form={form} />}
        {step === 1 && serviceData && propertyData && (
          <OrderSummary
            service={serviceData}
            onBack={handleOnSummaryBack}
            mdServiceLocationId={form.getFieldValue(FieldNameEnum.LocationId)}
            dataType="ozone"
            data={{
              property: propertyData[0],
              date: form.getFieldValue(FieldNameEnum.Date).format(apiDateFormat),
              slots: form.getFieldValue(FieldNameEnum.Slot),
              items: [],
            }}
          />
        )}
      </Form>
    </LayoutCardPage>
  )
}
