import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import { GET_USER_INFO_PATH } from 'services/auth/auth-service'
import { ContentType } from 'services/http-client/base-http-client'
import { encoClient } from 'services/http-client/enco-client'
import { ResponseType } from 'services/response-type'

import {
  ISearchParams,
  IUserProfileSaveParams,
  IUserSaveParams,
  IUserSavePassParams,
  IUserSavePinParams,
} from './user-param'
import {
  IGetAccountTypeResponse,
  IGetEmployeeResponse,
  IGetMasterInterestingResponse,
  IGetUserGroupResponse,
  IGetUserResponse,
  IGetUserTypeResponse,
} from './user-response'

export const GET_USER_PATH = '/SearchUser'
export const GET_EMPLOYEE_PATH = '/SearchEmployee'
export const GET_USER_GROUP_LIST_PATH = '/GetMasterUserGrp'
export const GET_ACCOUNT_TYPE_LIST_PATH = '/GetMasterAccountType'
export const GET_USER_TYPE_LIST_PATH = '/GetMasterUserType'
export const SAVE_USER_PATH = '/SaveUser'
const SET_PASS_USER_PATH = '/SetPassUser'
const SET_PIN_USER_PATH = '/SetPinUser'
const GET_USER_INTEREST_PATH = '/GetMasterInterest'
const UPDATE_USER_PROFILE_PATH = '/UpdateMyProfile'
const SAVE_USER_INTEREST_PATH = '/MyInterest'
const UPDATE_USER_PROFILE_PIC_PATH = '/UploadMyProfile'

export const useGetUserListQRY = (params: ISearchParams) => {
  return useQuery([GET_USER_PATH, params], async () => {
    const res = await encoClient.post<ResponseType<IGetUserResponse[]>>(GET_USER_PATH, params, {
      headers: { 'Content-Type': ContentType.FORM_DATA },
    })
    return res.data.data
  })
}

export const useGetEncoEmployeeQRY = (params: ISearchParams) => {
  return useQuery([GET_EMPLOYEE_PATH, params], async () => {
    const res = await encoClient.post<ResponseType<IGetEmployeeResponse[]>>(GET_EMPLOYEE_PATH, params, {
      headers: { 'Content-Type': ContentType.FORM_DATA },
    })
    return res.data.data
  })
}

export const useGetUserDetailQRY = (userId: string, enabled: boolean = true) => {
  return useQuery(
    [GET_USER_PATH, userId],
    async () => {
      const res = await encoClient.get<ResponseType<IGetUserResponse>>(GET_USER_PATH, {
        params: { userId },
      })
      return res.data.data
    },
    {
      enabled,
    },
  )
}

export const useGetUserGroupListQRY = () => {
  return useQuery([GET_USER_GROUP_LIST_PATH], async () => {
    const res = await encoClient.get<ResponseType<IGetUserGroupResponse[]>>(GET_USER_GROUP_LIST_PATH)
    return res.data.data
  })
}

export const useGetAccountTypeListQRY = () => {
  return useQuery([GET_ACCOUNT_TYPE_LIST_PATH], async () => {
    const res = await encoClient.get<ResponseType<IGetAccountTypeResponse[]>>(GET_ACCOUNT_TYPE_LIST_PATH)
    return res.data.data
  })
}

export const useGetUserTypeListQRY = () => {
  return useQuery([GET_USER_TYPE_LIST_PATH], async () => {
    const res = await encoClient.get<ResponseType<IGetUserTypeResponse[]>>(GET_USER_TYPE_LIST_PATH)
    return res.data.data
  })
}

export const useSaveUserDetailMTT = () => {
  const queryClient = useQueryClient()
  return useMutation<IGetUserResponse, unknown, IUserSaveParams>(
    async (params) => {
      const res = await encoClient.post<ResponseType<IGetUserResponse>>(`${SAVE_USER_PATH}`, params, {
        headers: { 'Content-Type': ContentType.FORM_DATA },
      })
      return res.data.data
    },
    {
      onSuccess: () => {
        queryClient.removeQueries([GET_USER_PATH])
      },
    },
  )
}

export const useSaveUserPassMTT = () => {
  const queryClient = useQueryClient()
  return useMutation<IGetUserResponse, unknown, IUserSavePassParams>(
    async (params) => {
      const res = await encoClient.post<ResponseType<IGetUserResponse>>(`${SET_PASS_USER_PATH}`, params, {
        headers: { 'Content-Type': ContentType.FORM_DATA },
      })
      return res.data.data
    },
    {
      onSuccess: () => {
        queryClient.removeQueries([GET_USER_PATH])
      },
    },
  )
}

export const useSaveUserPinMTT = () => {
  const queryClient = useQueryClient()
  return useMutation<IGetUserResponse, unknown, IUserSavePinParams>(
    async (params) => {
      // set empty string if null or undefined
      params.PinCode ??= ''

      const res = await encoClient.post<ResponseType<IGetUserResponse>>(`${SET_PIN_USER_PATH}`, params, {
        headers: { 'Content-Type': ContentType.FORM_DATA },
      })
      return res.data.data
    },
    {
      onSuccess: () => {
        queryClient.removeQueries([GET_USER_PATH])
      },
    },
  )
}

export const useGetMasterUserInterestQRY = () => {
  return useQuery([GET_USER_INTEREST_PATH], async () => {
    const res = await encoClient.get<ResponseType<IGetMasterInterestingResponse[]>>(GET_USER_INTEREST_PATH)
    return res.data.data
  })
}

export const useUpdateProfileMTT = () => {
  return useMutation<IGetUserResponse, unknown, IUserProfileSaveParams>(async (data) => {
    const res = await encoClient.post<ResponseType<IGetUserResponse>>(`${UPDATE_USER_PROFILE_PATH}`, data, {
      headers: { 'Content-Type': ContentType.FORM_DATA },
    })
    return res.data.data
  })
}

export const useSaveUserInterestMTT = () => {
  return useMutation<IGetUserResponse, unknown, { mdInterestId: number }[]>(async (data) => {
    const res = await encoClient.post<ResponseType<IGetUserResponse>>(`${SAVE_USER_INTEREST_PATH}`, data, {
      headers: { 'Content-Type': ContentType.JSON },
    })
    return res.data.data
  })
}

export const useUpdateUserProfileImgMTT = () => {
  const queryClient = useQueryClient()
  return useMutation<IGetUserResponse, unknown, { File: File }>(
    async (data) => {
      const res = await encoClient.post<ResponseType<IGetUserResponse>>(`${UPDATE_USER_PROFILE_PIC_PATH}`, data, {
        headers: { 'Content-Type': ContentType.FORM_DATA },
      })
      return res.data.data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([GET_USER_INFO_PATH])
      },
    },
  )
}
