import { Checkbox, Col, Row, Table, Typography } from 'antd'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { ColumnType } from 'antd/lib/table'
import { styled } from 'goober'
import { useCallback, useMemo } from 'react'

import { getColumnNumberByPage } from 'helpers/column-helper'

import { IDuplicateServicePropDayOffValue } from './SetDayOffDuplicateForm'

const WrapTableStyled = styled('div')`
  .ant-table-cell {
    vertical-align: text-top;

    .ant-typography {
      margin-bottom: 0;
    }
  }
`

interface ISetDayOffDuplicateTableProps {
  value?: IDuplicateServicePropDayOffValue[]
  onChange?: (value: IDuplicateServicePropDayOffValue[]) => void
  currentPage: number
  pageSize?: number
  onPageChange: (page: number, pageSize: number) => void
  isLoading?: boolean
}
export const SetDayOffDuplicateTable = (props: ISetDayOffDuplicateTableProps) => {
  const { value: dataSource = [], currentPage, pageSize = 5, onChange, onPageChange, isLoading } = props

  const onCheckboxChange = useCallback(
    (record: IDuplicateServicePropDayOffValue) => {
      return (event: CheckboxChangeEvent) => {
        const checked = event.target.checked
        const newServiceLocations = dataSource?.map((serviceLocation): IDuplicateServicePropDayOffValue => {
          if (serviceLocation.id === record.id) {
            return {
              ...serviceLocation,
              isCheck: checked,
            }
          } else {
            return serviceLocation
          }
        })

        onChange?.(newServiceLocations)
      }
    },
    [dataSource, onChange],
  )

  const serviceLocationColumns = useMemo(
    (): ColumnType<IDuplicateServicePropDayOffValue>[] => [
      {
        title: 'ลำดับ',
        width: '65px',
        align: 'center',
        render: (value, record, index) => getColumnNumberByPage(currentPage, index, pageSize),
      },
      {
        title: 'วันหยุด',
        width: '100px',
        dataIndex: 'dayOffDisplay',
      },
      {
        title: 'ช่วงเวลา',
        width: '100px',
        render: (value, record) => {
          const { starttime, endtime } = record
          return `${starttime} - ${endtime}`
        },
      },
      {
        title: 'หมายเหตุ',
        dataIndex: 'remark',
        render: (value) => {
          return (
            <Typography.Paragraph
              ellipsis={{
                rows: 3,
                tooltip: true,
                expandable: true,
                symbol: 'ดูเพิ่มเติม',
              }}
            >
              {value}
            </Typography.Paragraph>
          )
        },
      },
      {
        title: 'เลือก',
        width: '60px',
        align: 'center',
        dataIndex: 'isCheck',
        render: (value, record) => {
          return (
            <Row justify="center">
              <Col>
                <Checkbox checked={!!value} onChange={onCheckboxChange(record)} />
              </Col>
            </Row>
          )
        },
      },
    ],
    [currentPage, onCheckboxChange, pageSize],
  )

  return (
    <WrapTableStyled>
      <Table
        rowKey={(record) => `${record.id}-${record.dayOff}`}
        columns={serviceLocationColumns}
        dataSource={dataSource}
        loading={isLoading}
        size="small"
        pagination={{
          size: 'small',
          responsive: true,
          showSizeChanger: false,
          pageSize,
          current: currentPage,
          onChange: onPageChange,
        }}
      />
    </WrapTableStyled>
  )
}
