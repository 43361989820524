import { Image } from 'antd'
import { styled } from 'goober'
import { useMemo } from 'react'

import { AppSwiper, IAppSwiperItem } from 'components/AppSwiper'
import { mediaQuery } from 'helpers/responsive-helper'
import { useGetBannersQRY } from 'services/banner/banner-service'
import theme from 'theme/goober'

import defaultImage from '../../assets/images/cover-home-tall.png'

const colorGray50 = `${theme.color.graySecondaryText}50`

const Layout = styled('div')`
  position: relative;
  max-height: 768px;
  overflow: hidden;
  z-index: 0;

  .ant-image {
    background-color: ${colorGray50};
    width: 100%;
    img {
      object-fit: cover;
      max-height: 768px;
    }
  }

  .button-back {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }

  .button-next {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }

  @media ${mediaQuery.mdLte} {
    max-height: 430px;
    max-height: 430px;
    .ant-image > img {
      max-height: 430px;
    }
  }

  @media ${mediaQuery.smLte} {
    max-height: 260px;
    .ant-image > img {
      max-height: 260px;
    }
  }
`

interface IBannerProps {}
export const Banner = (props: IBannerProps) => {
  const { data: banners = [] } = useGetBannersQRY({
    web: 1,
  })

  const swiperItems = useMemo(() => {
    return banners.map(
      (banner): IAppSwiperItem => ({
        key: banner.bannerid,
        content: <Image src={banner.coverImgWeb} preview={false} alt="banner" fallback={defaultImage} />,
      }),
    )
  }, [banners])

  return (
    <Layout>
      <AppSwiper items={swiperItems} />
    </Layout>
  )
}
