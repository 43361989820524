import { Button } from 'antd'
import { useCallback, useState } from 'react'

import { FileHelper } from 'helpers/file-helper'

interface ISaleButtonPrintProps {
  fileName: string
  base64Data: string
}

export const SaleButtonPrint = (props: ISaleButtonPrintProps) => {
  const { fileName, base64Data } = props
  const [loading, setLoading] = useState(false)
  const handleClick = useCallback(async () => {
    setLoading(true)
    const base64Response = await fetch(`data:application/pdf;base64,${base64Data}`)
    const blob = await base64Response.blob()
    const blobUrl = URL.createObjectURL(blob)
    FileHelper.download(blobUrl, fileName)
    setLoading(false)
  }, [base64Data, fileName])

  return (
    <Button onClick={handleClick} loading={loading}>
      พิมพ์เอกสาร
    </Button>
  )
}
