import { useMutation } from '@tanstack/react-query'

import { encoClient } from 'services/http-client/enco-client'
import { ResponseType } from 'services/response-type'

import { IUploadAttachmentParam } from './attachment-param'
import { IUploadAttachmentResponse } from './attachment-response'

interface IUploadAttachmentParamWithCallbacks extends IUploadAttachmentParam {
  onUploadProgress?: (percent: number) => void
}

const ATTACHMENT_UPLOAD_PATH = '/UploadFile'
export const useUploadAttachmentMTT = () => {
  return useMutation(async (paramWithCallbacks: IUploadAttachmentParamWithCallbacks) => {
    const { onUploadProgress, ...param } = paramWithCallbacks
    const res = await encoClient.post<ResponseType<IUploadAttachmentResponse>>(ATTACHMENT_UPLOAD_PATH, param, {
      onUploadProgress: (progressEvent: ProgressEvent) => {
        const percent = (progressEvent.loaded * 100) / progressEvent.total
        onUploadProgress?.(percent)
      },
    })
    return res.data.data
  })
}
