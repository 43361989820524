import {
  Card,
  Form,
  Row,
  Col,
  Input,
  Table,
  Radio,
  Divider,
  Button,
  Space,
  Checkbox,
  TableColumnsType,
  notification,
} from 'antd'
import { useForm } from 'antd/es/form/Form'
import FormItem from 'antd/lib/form/FormItem'
import { useCallback, useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import { REQUIRED_RULES } from '_backend/constants/form-rules'
import { Txt } from 'components/Txt'
import { randomId } from 'helpers/string-helper'
import { backendPaths } from 'paths/backend-path-config'
import { IGetUserGrpMenuResponse, IGetUserGrpResponse } from 'services/permission-setting/permission-setting-response'
import {
  useSaveUserGrpMenuMTT,
  useUserGrpMenuQRY,
  useUserGrpMTT,
} from 'services/permission-setting/permission-setting-service'

interface IFormData {
  permissions: ITableData[]
  name?: string
  status?: boolean
}

interface ITableData extends IGetUserGrpMenuResponse {
  recNo: number
  isActive: boolean
}

interface FormPermissionSettingProps {
  userGroupData?: IGetUserGrpResponse
  mode: 'edit' | 'new'
}

export const FormPermissionSetting = (props: FormPermissionSettingProps) => {
  const { userGroupData } = props
  const [form] = useForm()
  const navigate = useNavigate()
  const { data: userGrpMenuData } = useUserGrpMenuQRY({ userGrp: Number(userGroupData?.id) || 99 })
  const { mutateAsync: saveGroup } = useUserGrpMTT()
  const { mutateAsync: saveMenu } = useSaveUserGrpMenuMTT()

  const dataSource = useMemo<ITableData[]>(() => {
    return (
      userGrpMenuData?.map((data, index) => ({
        ...data,
        recNo: index + 1,
        isActive: data.isCheck === 1,
      })) || []
    )
  }, [userGrpMenuData])

  const initialValues = useMemo<IFormData>(() => {
    return {
      permissions: dataSource,
      name: userGroupData?.userGrp,
      status: userGroupData?.active === 1,
    }
  }, [dataSource, userGroupData?.active, userGroupData?.userGrp])

  const columns: TableColumnsType<ITableData> = [
    {
      title: 'No.',
      align: 'center',
      width: '60px',
      dataIndex: 'recNo',
    },
    {
      title: 'ชื่อเมนู',
      dataIndex: 'name',
    },
    {
      title: 'กำหนดสิทธิ์',
      key: randomId(),
      render: (_, __, index) => {
        return (
          <Form.Item valuePropName="checked" name={['permissions', index, 'isActive']} style={{ marginBottom: 0 }}>
            <Checkbox>เปิดสิทธิ์การใช้งาน</Checkbox>
          </Form.Item>
        )
      },
    },
  ]

  const handleBackToPermissionList = useCallback(() => {
    navigate(backendPaths.permissionSettingList())
  }, [navigate])

  const handleFinish = useCallback(
    async (value: IFormData) => {
      try {
        const groupData = await saveGroup({
          Id: Number(userGroupData?.id) || 0,
          Active: value.status ? 1 : 0,
          UserGrp: value.name || '',
        })
        await saveMenu({
          UserGrp: groupData.id,
          MenuGrp: value.permissions.map((v, index) => ({
            menucode: userGrpMenuData?.[index].menucode,
            serviceid: userGrpMenuData?.[index].serviceid,
            isCheck: v.isActive ? 1 : 0,
          })),
        })
        notification.success({ message: 'สำเร็จ', description: 'บันทึกข้อมูลสำเร็จ', duration: 2 })
        handleBackToPermissionList()
      } catch {}
    },
    [handleBackToPermissionList, saveGroup, saveMenu, userGroupData?.id, userGrpMenuData],
  )

  useEffect(() => {
    form.setFieldsValue(initialValues)
  }, [form, initialValues])

  return (
    <Card>
      <Form<IFormData> form={form} onFinish={handleFinish} initialValues={initialValues}>
        <Row style={{ maxWidth: 660 }}>
          <Col span={24}>
            <FormItem name="name" rules={REQUIRED_RULES} label="ชื่อกลุ่มผู้ใช้" required labelCol={{ span: 24 }}>
              <Input style={{ width: '100%' }} placeholder="กรอกชื่อกลุ่มผู้ใช้" />
            </FormItem>
          </Col>
        </Row>
        <Row style={{ marginTop: 32 }}>
          <Txt ag="header24">สิทธิ์การใช้งาน</Txt>
        </Row>

        <Row>
          <Table scroll={{ y: 300 }} dataSource={dataSource} pagination={false} columns={columns} />
        </Row>
        <Row style={{ marginTop: 32 }}>
          <Txt ag={'body20'}>สถานะกลุ่มผู้ใช้งาน (Status)</Txt>
        </Row>
        <Row style={{ marginTop: 16 }}>
          <Form.Item name="status">
            <Radio.Group>
              <Radio value={true}>Active</Radio>
              <Radio value={false}>Inactive</Radio>
            </Radio.Group>
          </Form.Item>
        </Row>
        <Divider style={{ marginTop: 0 }} />
        <Row justify="end">
          <Space size={24}>
            <Button onClick={handleBackToPermissionList}>กลับ</Button>
            <Button type="primary" htmlType="submit">
              บันทึก
            </Button>
          </Space>
        </Row>
      </Form>
    </Card>
  )
}
