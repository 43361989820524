import { faGear } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Dropdown, Menu, Tag } from 'antd'
import { ItemType } from 'antd/lib/menu/hooks/useItems'
import Table, { ColumnType } from 'antd/lib/table'
import { styled } from 'goober'
import { orderBy } from 'lodash'
import { useMemo } from 'react'

import { GetServiceTypeEnum } from 'services/enco-service/enco-service-param'
import { IServiceData } from 'services/enco-service/enco-service-response'
import { useGetServicesQRY } from 'services/enco-service/enco-service-service'
import theme from 'theme/goober'

export const GearIcon = styled(FontAwesomeIcon)`
  color: ${theme.color.lightBluePrimary};
  cursor: pointer;
`

export const GearIconDisabled = styled(FontAwesomeIcon)`
  color: ${theme.color.darkGray};
  cursor: not-allowed;
`

interface ServiceTablePropsType {
  txtSearch?: string
  serviceType: GetServiceTypeEnum
  renderMenuItems: (record: IServiceData) => ItemType[]
}
export const ServiceTable = (props: ServiceTablePropsType) => {
  const { txtSearch, serviceType, renderMenuItems } = props

  const { data: services, isLoading } = useGetServicesQRY({
    serviceType,
    txtSearch,
  })

  const dataSource = useMemo(() => {
    return orderBy(services ?? [], ['recno'], ['asc'])
  }, [services])

  const columns = useMemo(
    (): ColumnType<IServiceData>[] => [
      {
        title: 'ลำดับ',
        dataIndex: 'recno',
        width: '50px',
        align: 'center',
        // render: (_: any, record, index: number) => (
        //   <Txt ag="body20">{getColumnNumberByPage(currentPage, index, currentPageSize)}</Txt>
        // ),
      },
      {
        title: 'ชื่อบริการ',
        dataIndex: 'name',
      },
      {
        title: 'ประเภทบริการ',
        dataIndex: 'serviceType',
      },
      {
        title: 'สถานะ',
        dataIndex: 'active',
        render(value) {
          return <Tag color={value ? 'success' : 'default'}>{value ? 'Active' : 'Inactive'}</Tag>
        },
      },
      {
        title: 'Modify By',
        dataIndex: 'modifystfnm',
      },
      {
        title: 'Modify Date',
        dataIndex: 'modifydatenm',
      },
      {
        title: '',
        dataIndex: 'action',
        width: '16px',
        render: (value, record) => {
          return record.isModify === 1 ? (
            <Dropdown trigger={['click']} overlay={<Menu items={renderMenuItems(record)} />} placement="bottomRight">
              <div>
                <GearIcon icon={faGear} />
              </div>
            </Dropdown>
          ) : (
            <div>
              <GearIconDisabled icon={faGear} />
            </div>
          )
        },
      },
    ],
    [renderMenuItems],
  )

  return <Table dataSource={dataSource} columns={columns} loading={isLoading} rowKey="id" />
}
