import { Editor } from '@tinymce/tinymce-react'
import { Skeleton } from 'antd'
import { styled } from 'goober'
import { useCallback, useState } from 'react'
import { Editor as TinyMCEEditor } from 'tinymce'

import { getEnv } from 'helpers/env-config-helper'
import { IUploadAttachmentResponse } from 'services/attachment/attachment-response'
import { encoClient } from 'services/http-client/enco-client'
import { ResponseType } from 'services/response-type'

interface ITextEditorProps {
  moduleName: 'pr-news' | 'enco-service' | 'other-service'
  value?: string
  onChange?: (value: string) => void
}

interface BlobInfo {
  id: () => string
  name: () => string
  filename: () => string
  blob: () => Blob
  base64: () => string
  blobUri: () => string
  uri: () => string | undefined
}
type ProgressFn = (percent: number) => void

const EditorWrapper = styled('div')`
  max-width: calc(100vw - 50px);
  textarea {
    display: none;
  }
`

export const TextEditor = (props: ITextEditorProps) => {
  const { moduleName, value, onChange } = props
  const [initializing, setInitializing] = useState(true)

  const handleInitialized = useCallback(() => {
    setInitializing(false)
  }, [])

  const handleEditorChange = useCallback(
    (text: string, editor: TinyMCEEditor) => {
      onChange?.(text)
    },
    [onChange],
  )

  const handleImageUpload = useCallback(
    (blobInfo: BlobInfo, progress: ProgressFn): Promise<string> =>
      new Promise((resolve, reject) => {
        const file = new File([blobInfo.blob()], blobInfo.filename())
        encoClient
          .post<ResponseType<IUploadAttachmentResponse>>(
            '/UploadFile',
            { Path: moduleName, File: file },
            {
              onUploadProgress: (progressEvent: ProgressEvent) => {
                const percent = (progressEvent.loaded * 100) / progressEvent.total
                progress?.(percent)
              },
            },
          )
          .then((res) => {
            return resolve(res.data.data.path)
          })
      }),
    [moduleName],
  )

  return (
    <EditorWrapper>
      {initializing && <Skeleton />}
      <Editor
        apiKey={getEnv('tinyApiKey')}
        value={value}
        onEditorChange={handleEditorChange}
        init={{
          init_instance_callback: handleInitialized,
          height: 500,
          menubar: false,
          content_css: '/css/content.css',
          plugins: [
            'importcss',
            'lists',
            'advlist',
            'image',
            'link',
            'media',
            'code',
            'table',
            'charmap',
            'emoticons',
            'fullscreen',
            'preview',
          ],
          toolbar1:
            'undo redo |' +
            'bold italic underline   |' +
            'blocks fontfamily fontsize |' +
            'hr link image media iframe table',
          toolbar2:
            'align lineheight numlist bullist advlist |' +
            'outdent indent |' +
            'strikethrough forecolor backcolor removeformat |' +
            'charmap emoticons|' +
            'code fullscreen preview |',
          font_size_formats: '8px 10px 12px 14px 16px 18px 20px 24px 28px 30px 32px 40px',
          block_formats: 'Paragraph=p; Header 1=h1; Header 2=h2; Header 3=h3',
          branding: false,
          images_file_types: 'jpeg,jpg,png',
          images_upload_handler: handleImageUpload,
          color_map: [
            '181818',
            'Black',
            '2A2D81',
            'Blue',
            '737791',
            'Gray',
            'C70C0C',
            'Red',
            'CEAF4F',
            'Yellow',
            'AECC55',
            'Green',
          ],
          font_css: '/css/font.css',
          font_family_formats:
            'Athiti=Athiti,sans-serif; IBM Plex Sans Thai=IBM Plex Sans Thai,sans-serif; Kanit=Kanit,sans-serif; Noto Sans Thai=Noto Sans Thai,sans-serif; Prompt=Prompt,sans-serif; Sarabun=Sarabun,sans-serif;',
          relative_urls: false,
          remove_script_host: false,
          newline_behavior: 'invert',
        }}
      />
    </EditorWrapper>
  )
}
