export enum UserTypeEnum {
  enco = 1,
  bsa = 2,
  other = 3,
}

export enum AccountTypeEnum {
  EnCo = 1,
  Facebook = 2,
  Google = 3,
  Email = 4,
}
