import { useMutation, useQuery, useQueryClient, UseQueryOptions } from '@tanstack/react-query'

import { ContentType } from 'services/http-client/base-http-client'
import { encoClient } from 'services/http-client/enco-client'
import { ResponseType } from 'services/response-type'

import { IInvoiceGetListParam, IInvoiceSaveParams } from './invoice-param'
import { IGetInvoiceResponse, IInvoiceGetListResponse } from './invoice-response'

const INVOICE_GET_LIST_PATH = '/SearchInvoice'
const GET_INVOICE_PATH = '/GetInvoice'
const INVOICE_SAVE_PATH = '/SaveInvoice'

export const useInvoiceGetListQRY = (
  param: IInvoiceGetListParam,
  options?: UseQueryOptions<IInvoiceGetListResponse[]>,
) => {
  return useQuery<IInvoiceGetListResponse[]>(
    [INVOICE_GET_LIST_PATH, param],
    async () => {
      const res = await encoClient.post<ResponseType<IInvoiceGetListResponse[]>>(INVOICE_GET_LIST_PATH, param)
      return res.data.data || []
    },
    options,
  )
}

export const useGetInvoiceQRY = (id: number) => {
  const params = { id }
  return useQuery(
    [GET_INVOICE_PATH, params],
    async () => {
      const res = await encoClient.get<ResponseType<IGetInvoiceResponse>>(GET_INVOICE_PATH, { params })
      return res.data.data
    },
    {
      enabled: !!id,
    },
  )
}

export const useInvoiceSaveMTT = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async (data: IInvoiceSaveParams) => {
      const res = await encoClient.post<ResponseType<IInvoiceGetListResponse>>(INVOICE_SAVE_PATH, data, {
        headers: { 'Content-Type': ContentType.JSON },
      })
      return res.data.data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([INVOICE_GET_LIST_PATH])
      },
    },
  )
}

export const useInvoiceDeleteMTT = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async (id: number) => {
      const res = await encoClient.delete<ResponseType<null>>(INVOICE_SAVE_PATH, {
        data: { id },
      })
      return res.data.data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([INVOICE_GET_LIST_PATH])
      },
    },
  )
}
