import { PropsWithChildren, useLayoutEffect, useMemo } from 'react'
import { BrowserRouter, Navigate, Outlet, Route, Routes, useLocation } from 'react-router-dom'

import { NoAuth } from '_backend/components/NoAuth'
import { RequireAuth } from '_backend/components/RequireAuth'
import { RequirePermission } from '_backend/components/RequirePermission'
import { BackendLayout } from '_backend/components/layout/BackendLayout'
import { BACKEND_MENU_LIST } from '_backend/constants/backend-menu-config'
import { PageHomeBanner } from '_backend/modules/home-banner/PageHomeBanner'
import { PageServiceItemEdit } from '_backend/modules/item-management/PageServiceItemEdit'
import { PageServiceItemList } from '_backend/modules/item-management/PageServiceItemList'
import { PageServiceItemNew } from '_backend/modules/item-management/PageServiceItemNew'
import { PageOrderDetail } from '_backend/modules/order-management/PageOrderDetail'
import { PageOrderList } from '_backend/modules/order-management/PageOrderList'
import { PagePermissionSettingList } from '_backend/modules/permission-setting/PagePermissionSettingList'
import { PagePermissionSettingNew } from '_backend/modules/permission-setting/PagePermissionSettingNew'
import { PagePermissionSettingEdit } from '_backend/modules/permission-setting/PagePermisssionSettingEdit'
import { PagePrNewsEdit } from '_backend/modules/pr-news/PagePrNewsEdit'
import { PagePrNewsList } from '_backend/modules/pr-news/PagePrNewsList'
import { PagePrNewsNew } from '_backend/modules/pr-news/PagePrNewsNew'
import { PageReport } from '_backend/modules/report/PageReport'
import { PageReportList } from '_backend/modules/report/PageReportList'
import { PageInvoiceEdit } from '_backend/modules/sale/invoice/PageInvoiceEdit'
import { PageInvoiceList } from '_backend/modules/sale/invoice/PageInvoiceList'
import { PageInvoiceNew } from '_backend/modules/sale/invoice/PageInvoiceNew'
import { PageQuotationEdit } from '_backend/modules/sale/quotation/PageQuotationEdit'
import { PageQuotationList } from '_backend/modules/sale/quotation/PageQuotationList'
import { PageQuotationNew } from '_backend/modules/sale/quotation/PageQuotationNew'
import { PageReceiptEdit } from '_backend/modules/sale/receipt/PageReceiptEdit'
import { PageReceiptList } from '_backend/modules/sale/receipt/PageReceiptList'
import { PageReceiptNew } from '_backend/modules/sale/receipt/PageReceiptNew'
import { PageEncoServiceContent } from '_backend/modules/service/PageEncoServiceContent'
import { PageEncoServiceEdit } from '_backend/modules/service/PageEncoServiceEdit'
import { PageEncoServiceNew } from '_backend/modules/service/PageEncoServiceNew'
import { PageKioskMasterProductEdit } from '_backend/modules/service/PageKioskMasterProductEdit'
import { PageKioskMasterProductList } from '_backend/modules/service/PageKioskMasterProductList'
import { PageKioskMasterProductNew } from '_backend/modules/service/PageKioskMasterProductNew'
import { PageKioskPeriodList } from '_backend/modules/service/PageKioskPeriodList'
import { PageKioskServiceEdit } from '_backend/modules/service/PageKioskServiceEdit'
import { PageMemberAndSponsorList } from '_backend/modules/service/PageMemberAndSponsorList'
import { PageOrderResponseList } from '_backend/modules/service/PageOrderResponseList'
import { PageOtherServiceEdit } from '_backend/modules/service/PageOtherServiceEdit'
import { PageOtherServiceNew } from '_backend/modules/service/PageOtherServiceNew'
import { PagePromotionCodeEdit } from '_backend/modules/service/PagePromotionCodeEdit'
import { PagePromotionCodeList } from '_backend/modules/service/PagePromotionCodeList'
import { PagePromotionCodeNew } from '_backend/modules/service/PagePromotionCodeNew'
import { PagePropertyEdit } from '_backend/modules/service/PagePropertyEdit'
import { PagePropertyManagementDayOffList } from '_backend/modules/service/PagePropertyManagementDayOffList'
import { PagePropertyManagementList } from '_backend/modules/service/PagePropertyManagementList'
import { PagePropertyNew } from '_backend/modules/service/PagePropertyNew'
import { PageServiceList } from '_backend/modules/service/PageServiceList'
import { PageSuggestionImage } from '_backend/modules/suggestion/PageSuggestionImage'
import { PageSuggestionList } from '_backend/modules/suggestion/PageSuggestionList'
import { PageUserEdit } from '_backend/modules/user-management/PageUserEdit'
import { PageUserList } from '_backend/modules/user-management/PageUserList'
import { PageUserNew } from '_backend/modules/user-management/PageUserNew'
import { PageBackendWiki } from '_backend/modules/wiki/PageBackendWiki'
import { Page404 } from 'components/Page404'
import { FrontendLayout } from 'components/layout/FrontendLayout'
import { PageLogin } from 'modules/auth/PageLogin'
import { PageHome } from 'modules/home/PageHome'
import { PageMyOrder } from 'modules/order/PageMyOrder'
import { PageOrderCheckout } from 'modules/order/PageOrderCheckout'
import { PageOrderPaymentCompleted } from 'modules/order/PageOrderPaymentCompleted'
import { PagePrNewsDetail } from 'modules/pr-news/PagePrNewsDetail'
import { PagePrNewsList as FEPagePrNewsList } from 'modules/pr-news/PagePrNewsList'
import { PageProfile } from 'modules/profile/PageProfile'
import { PageEncoServiceDetail } from 'modules/service/PageEncoServiceDetail'
import { PageEncoServiceList } from 'modules/service/PageEncoServiceList'
import { PageOrder } from 'modules/service/PageOrder'
import { PageOtherServiceDetail } from 'modules/service/PageOtherServiceDetail'
import { PageOtherServiceList } from 'modules/service/PageOtherServiceList'
import { PageOzoneOrder } from 'modules/service/ozone/PageOzoneOrder'
import { PageFrontendWiki } from 'modules/wiki/PageFrontendWiki'
import { backendPaths } from 'paths/backend-path-config'
import { appPaths } from 'paths/path-config'
import { useGetMenuWeb, useGetUserInfoQRY } from 'services/auth/auth-service'

const ScrollOnTop = (props: PropsWithChildren) => {
  const location = useLocation()
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0)
  }, [location.pathname])

  return <>{props.children}</>
}

export const RootRoute = () => {
  useGetUserInfoQRY()
  const { data: authMenu } = useGetMenuWeb()

  const defaultBackendPage = useMemo(() => {
    if (authMenu?.[0]?.menucode === 60 /* sale menu */) {
      return backendPaths.quotationList()
    }
    return BACKEND_MENU_LIST.find((m) => m.code === authMenu?.[0]?.menucode)?.path || '/'
  }, [authMenu])

  return (
    // <BrowserRouter basename={getEnv('basePath')}>
    <BrowserRouter>
      <ScrollOnTop>
        <Routes>
          <Route
            path={appPaths.login()}
            element={
              <NoAuth>
                <PageLogin />
              </NoAuth>
            }
          />
          <Route path={appPaths.notFound()} element={<Page404 />} />
          <Route path="/frontend-wiki" element={<PageFrontendWiki />} />
          <Route path="/backend-wiki" element={<PageBackendWiki />} />

          <Route
            element={
              <RequireAuth>
                <FrontendLayout>
                  <Outlet />
                </FrontendLayout>
              </RequireAuth>
            }
          >
            <Route path="/" element={<Navigate replace to={appPaths.home()} />} />
            <Route path={appPaths.home()} element={<PageHome />} />

            <Route path={appPaths.profile()} element={<PageProfile />} />
            <Route path={appPaths.prNewsDetail()} element={<PagePrNewsDetail />} />
            <Route path={appPaths.prNewsList()} element={<FEPagePrNewsList />} />
            <Route path={appPaths.encoServiceDetail()} element={<PageEncoServiceDetail />} />
            <Route path={appPaths.encoService()} element={<PageEncoServiceList />} />
            <Route path={appPaths.otherServiceDetail()} element={<PageOtherServiceDetail />} />
            <Route path={appPaths.otherService()} element={<PageOtherServiceList />} />
            <Route path={appPaths.encoServiceOrder()} element={<PageOrder />} />
            <Route path={appPaths.orderCheckout()} element={<PageOrderCheckout />} />
            <Route path={appPaths.orderPaymentCompleted()} element={<PageOrderPaymentCompleted />} />
            <Route path={appPaths.orderList()} element={<PageMyOrder />} />
            <Route path={appPaths.encoServiceOzoneOrder()} element={<PageOzoneOrder />} />
          </Route>

          <Route
            element={
              <RequireAuth>
                <RequirePermission>
                  <BackendLayout>
                    <Outlet />
                  </BackendLayout>
                </RequirePermission>
              </RequireAuth>
            }
          >
            <Route path={backendPaths.backend()} element={<Navigate replace to={defaultBackendPage} />} />

            <Route path={backendPaths.serviceList()} element={<PageServiceList />} />
            <Route path={backendPaths.serviceEncoContent()} element={<PageEncoServiceContent />} />
            <Route path={backendPaths.serviceEncoNew()} element={<PageEncoServiceNew />} />
            <Route path={backendPaths.serviceEncoEdit()} element={<PageEncoServiceEdit />} />

            <Route path={backendPaths.serviceKioskEdit()} element={<PageKioskServiceEdit />} />

            <Route path={backendPaths.serviceOtherOrder()} element={<PageOrderResponseList />} />
            <Route path={backendPaths.serviceOtherEdit()} element={<PageOtherServiceEdit />} />
            <Route path={backendPaths.serviceOtherNew()} element={<PageOtherServiceNew />} />

            <Route path={backendPaths.serviceOrderList()} element={<PageOrderList />} />
            <Route path={backendPaths.serviceOrderDetail()} element={<PageOrderDetail />} />

            <Route path={backendPaths.homeBannerList()} element={<PageHomeBanner />} />
            <Route path={backendPaths.prNewsList()} element={<PagePrNewsList />} />
            <Route path={backendPaths.prNewsNew()} element={<PagePrNewsNew />} />
            <Route path={backendPaths.prNewsEdit()} element={<PagePrNewsEdit />} />
            <Route path={backendPaths.suggestionList()} element={<PageSuggestionList />} />
            <Route path={backendPaths.suggestionImage()} element={<PageSuggestionImage />} />
            <Route path={backendPaths.userList()} element={<PageUserList />} />
            <Route path={backendPaths.userNew()} element={<PageUserNew />} />
            <Route path={backendPaths.userEdit()} element={<PageUserEdit />} />
            <Route path={backendPaths.reportList()} element={<PageReportList />} />
            <Route path={backendPaths.quotationList()} element={<PageQuotationList />} />
            <Route path={backendPaths.quotationNew()} element={<PageQuotationNew />} />
            <Route path={backendPaths.quotationEdit()} element={<PageQuotationEdit />} />
            <Route path={backendPaths.invoiceList()} element={<PageInvoiceList />} />
            <Route path={backendPaths.invoiceNew()} element={<PageInvoiceNew />} />
            <Route path={backendPaths.invoiceEdit()} element={<PageInvoiceEdit />} />
            <Route path={backendPaths.receiptList()} element={<PageReceiptList />} />
            <Route path={backendPaths.receiptNew()} element={<PageReceiptNew />} />
            <Route path={backendPaths.receiptEdit()} element={<PageReceiptEdit />} />
            <Route path={backendPaths.itemManagementList()} element={<PageServiceItemList />} />
            <Route path={backendPaths.itemManagementNew()} element={<PageServiceItemNew />} />
            <Route path={backendPaths.itemManagementEdit()} element={<PageServiceItemEdit />} />
            <Route path={backendPaths.serviceEncoPropertyManagementNew()} element={<PagePropertyNew />} />
            <Route path={backendPaths.serviceEncoPropertyManagementList()} element={<PagePropertyManagementList />} />
            <Route path={backendPaths.serviceEncoPropertyManagementEdit()} element={<PagePropertyEdit />} />
            <Route path={backendPaths.serviceKioskPeriodList()} element={<PageKioskPeriodList />} />
            <Route path={backendPaths.serviceKioskMasterProductList()} element={<PageKioskMasterProductList />} />
            <Route path={backendPaths.serviceKioskMasterProductNew()} element={<PageKioskMasterProductNew />} />
            <Route path={backendPaths.serviceKioskMasterProductEdit()} element={<PageKioskMasterProductEdit />} />

            <Route
              path={backendPaths.serviceEncoPropertyManagementDayOffList()}
              element={<PagePropertyManagementDayOffList />}
            />
            <Route path={backendPaths.serviceEncoPromotionCodeList()} element={<PagePromotionCodeList />} />
            <Route path={backendPaths.serviceEncoPromotionNew()} element={<PagePromotionCodeNew />} />
            <Route path={backendPaths.serviceEncoPromotionEdit()} element={<PagePromotionCodeEdit />} />
            <Route path={backendPaths.memberAndSponsor()} element={<PageMemberAndSponsorList />} />
            <Route path={backendPaths.reportList()} element={<PageReportList />} />
            <Route path={backendPaths.report()} element={<PageReport />} />
            <Route path={backendPaths.permissionSettingList()} element={<PagePermissionSettingList />} />
            <Route path={backendPaths.permissionSettingNew()} element={<PagePermissionSettingNew />} />
            <Route path={backendPaths.permissionSettingEdit()} element={<PagePermissionSettingEdit />} />
          </Route>

          <Route path="*" element={<Navigate replace to={appPaths.notFound()} />} />
        </Routes>
      </ScrollOnTop>
    </BrowserRouter>
  )
}
