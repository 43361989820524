import { MenuProps } from 'antd'
import { useMemo } from 'react'

import { backendPaths } from 'paths/backend-path-config'
import { useGetMenuWeb } from 'services/auth/auth-service'

interface IMenuConfigType {
  code: number
  children?: IMenuConfigType[]
}

interface IBackendMenuItem {
  code: number
  name?: string
  path?: string
}

export const BACKEND_MENU_LIST: IBackendMenuItem[] = [
  {
    code: 52,
    name: 'Service',
    path: backendPaths.serviceList(),
  },
  {
    code: 53,
    name: 'Home Banner',
    path: backendPaths.homeBannerList(),
  },
  {
    code: 54,
    name: 'PR News',
    path: backendPaths.prNewsList(),
  },
  {
    code: 56,
    name: 'Suggestion Monitor',
    path: backendPaths.suggestionList(),
  },
  {
    code: 57,
    name: 'User Management',
    path: backendPaths.userList(),
  },
  {
    code: 59,
    name: 'Reports',
    path: backendPaths.reportList(),
  },
  {
    code: 60,
    name: 'Sale',
  },
  {
    code: 601,
    name: 'Quotation',
    path: backendPaths.quotationList(),
  },
  {
    code: 602,
    name: 'Invoice',
    path: backendPaths.invoiceList(),
  },
  {
    code: 603,
    name: 'Receipt',
    path: backendPaths.receiptList(),
  },
  {
    code: 61,
    name: 'Permission Setting',
    path: backendPaths.permissionSettingList(),
  },
]

export const BACKEND_MENU_CONFIGS: IMenuConfigType[] = [
  {
    code: 52,
  },
  {
    code: 53,
  },
  {
    code: 54,
  },
  {
    code: 56,
  },
  {
    code: 57,
  },
  {
    code: 59,
  },
  {
    code: 60,
    children: [
      {
        code: 601,
      },
      {
        code: 602,
      },
      {
        code: 603,
      },
    ],
  },
  {
    code: 61,
  },
]

export const useMenuItems = () => {
  const { data: menuItemData } = useGetMenuWeb()

  const authMenu = useMemo(() => {
    return (
      menuItemData?.reduce((acc: IBackendMenuItem[], item) => {
        const menuItem = BACKEND_MENU_LIST.find((v) => v.code === item.menucode)
        if (menuItem) {
          acc.push(menuItem)
        }
        return acc
      }, []) || []
    )
  }, [menuItemData])

  return useMemo(() => {
    return BACKEND_MENU_CONFIGS.reduce<MenuProps['items']>((acc, config) => {
      const menu = authMenu.find((m) => m.code === config.code)
      if (menu) {
        acc?.push({
          key: menu?.code.toString() || '',
          label: menu?.name || '',
          children: config?.children?.reduce<MenuProps['items']>((children, conf) => {
            const subMenu = BACKEND_MENU_LIST.find((v) => v.code === conf.code)
            if (subMenu) {
              children?.push({
                key: subMenu?.code.toString() || '',
                label: subMenu?.name || '',
              })
            }
            return children
          }, []),
        })
      }
      return acc
    }, [])
  }, [authMenu])
}
