import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Col, Image, Row } from 'antd'
import { styled } from 'goober'
import { Fragment, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'

import { Txt } from 'components/Txt'
import { mediaQuery } from 'helpers/responsive-helper'
import { appPaths } from 'paths/path-config'
import { IPrNewsGetListResponse } from 'services/pr-news/pr-news-response'
import { usePrNewsGetListQRY } from 'services/pr-news/pr-news-service'

const Layout = styled('div')`
  display: flex;
  flex-flow: column;
  grid-row-gap: 23px;
  opacity: 1;

  padding: 28px 134px;
  padding-bottom: 30px;
  box-sizing: border-box;

  @media ${mediaQuery.mdLte} {
    padding: 28px 60px;
    padding-bottom: 30px;
  }

  @media ${mediaQuery.smLte} {
    padding: 24px 38px;
    padding-bottom: 26px;
  }
`

const GridLayout = styled('div')`
  display: grid;
  grid-column-gap: 20px;

  .grid {
    display: grid;
  }

  .item-wrapper-1 {
    width: 50%;
    height: 100%;
  }

  .item-wrapper-2,
  .item-wrapper-3 {
    height: 30%;
    width: 50%;
  }

  .ant-image {
    height: 100%;
    overflow: hidden;
    display: flex;
  }

  [class^='item-image'] {
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;

    img {
      object-fit: cover;
    }
  }

  [class^='item-detail'] {
    display: flex;
    flex-flow: column;
    grid-row-gap: 8px;
    border-radius: 10px;
    padding: 10px;
    cursor: pointer;
  }

  .item-detail-1 {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    margin-top: -6px;
    padding-top: 16px;
    background: #efeff2 0% 0% no-repeat padding-box;
  }

  .wrap-item-2-and-3 {
    grid-area: wrap-item-2-and-3;
    overflow: hidden;

    display: grid;
    grid-gap: 20px;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, calc(50% - 10px));

    .wrap-item-2,
    .wrap-item-3 {
      display: grid;
      box-sizing: border-box;
      grid-template-columns: 284px auto;

      span {
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      [class^='item-image'] {
        max-height: 170px;
      }

      @media ${mediaQuery.lgOnly} {
        grid-template-columns: 210px auto;

        > * {
          max-height: 170px;
        }
      }

      @media ${mediaQuery.mdOnly} {
        grid-template-columns: 160px auto;
        > * {
          max-height: 140px;
        }
        span {
          -webkit-line-clamp: 3;
        }
      }

      @media ${mediaQuery.smLte} {
        grid-template-columns: 100px auto;
      }

      @media ${mediaQuery.xsOnly} {
        grid-template-columns: 1fr 1fr;
      }
    }

    .wrap-item-2 {
      grid-template-areas: 'item-image-2 item-detail-2';
    }

    .wrap-item-3 {
      grid-template-areas: 'item-image-3 item-detail-3';
    }
  }

  grid-template-columns: 1fr 1fr;
  grid-template-rows: 360px 110px;
  grid-template-areas:
    'item-image-1 wrap-item-2-and-3'
    'item-detail-1 no-item';

  @media ${mediaQuery.mdOnly} {
    grid-template-rows: 300px 110px;
  }

  @media ${mediaQuery.smLte} {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-template-areas:
      'item-image-1'
      'item-detail-1'
      'wrap-item-2-and-3';

    .wrap-item-2-and-3 {
      margin-top: 20px;
    }
  }
`
const SeeMoreButton = styled(Button)`
  margin-right: 30px;
  padding-left: 19px;
  padding-right: 14px;
  span {
    font-size: 16px;
  }

  @media ${mediaQuery.xsOnly} {
    margin-right: 0;
  }
`
const SeeMoreIcon = styled(FontAwesomeIcon)`
  margin-left: 2px;
  width: 6px;
  height: 100%;
`

export const PrNews = () => {
  const navigate = useNavigate()

  const { data: prNewsList = [] } = usePrNewsGetListQRY({
    isActive: 1,
    platForm: 2,
  })

  const [firstPrNewsItem, secondPrNewsItem, thirdPrNewsItem] = prNewsList

  const onPrNewsCardClick = useCallback(
    (prNewsId: string) => {
      navigate(
        appPaths.prNewsDetail({
          routeParam: {
            prNewsId: Number(prNewsId),
          },
        }),
      )
    },
    [navigate],
  )

  const onSeeMoreClick = useCallback(() => {
    navigate(appPaths.prNewsList())
  }, [navigate])

  const renderPrNewsItem = useCallback(
    (prNewsItem: IPrNewsGetListResponse, index: number) => {
      const cls = (v: string[]) => v.join(' ')
      const itemCls = (type: string) => `item-${type}-${index + 1}`
      const itemImageCls = itemCls('image')
      const itemDetailCls = itemCls('detail')

      return (
        <Fragment key={prNewsItem.id}>
          <div
            className={cls([itemImageCls])}
            style={{
              gridArea: itemImageCls,
            }}
            onClick={onPrNewsCardClick.bind(null, prNewsItem.id)}
          >
            <Image preview={false} src={prNewsItem.coverImgWeb} loading="lazy" />
          </div>

          <div
            className={cls([itemDetailCls])}
            style={{
              gridArea: itemDetailCls,
            }}
            onClick={onPrNewsCardClick.bind(null, prNewsItem.id)}
          >
            <Txt ag="body24" color="lightBluePrimary">
              {prNewsItem.startdateDisplay}
            </Txt>
            <Txt ag="header24">{prNewsItem.name}</Txt>
          </div>
        </Fragment>
      )
    },
    [onPrNewsCardClick],
  )

  return (
    <Layout>
      <Row className="w-100" align="middle" justify="space-between">
        <Col flex={1}>
          <Txt ag="header40" color="blue">
            PR & News
          </Txt>
        </Col>
        <Col>
          <SeeMoreButton type="primary" size="small" shape="round" onClick={onSeeMoreClick} ghost>
            <Row align="middle">
              <Txt ag="body16" color="lightBluePrimary">
                See More
              </Txt>
              <SeeMoreIcon icon={faChevronRight} />
            </Row>
          </SeeMoreButton>
        </Col>
      </Row>
      <GridLayout>
        {firstPrNewsItem && renderPrNewsItem(firstPrNewsItem, 0)}
        <div className="wrap-item-2-and-3">
          <div className="wrap-item-2">{secondPrNewsItem && renderPrNewsItem(secondPrNewsItem, 1)}</div>
          <div className="wrap-item-3">{thirdPrNewsItem && renderPrNewsItem(thirdPrNewsItem, 2)}</div>
        </div>
      </GridLayout>
    </Layout>
  )
}
