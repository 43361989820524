import { faCamera } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { notification } from 'antd'
import Upload, { RcFile } from 'antd/lib/upload'
import { styled } from 'goober'
import React, { useCallback } from 'react'

import theme from 'theme/goober'

const Container = styled('div')`
  height: 200px;
  width: 200px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  overflow: hidden;
`

const EditText = styled('span')`
  height: 40px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  background: rgba(0, 0, 0, 0.7);
  color: ${theme.color.white};
  line-height: 30px;
  font-size: 24px;
  opacity: 0;
  &:hover {
    transition: opacity 0.5s;
    opacity: 0.7;
    cursor: pointer;
  }
`

const ImageProfile = styled('img')`
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
`

interface UploadAvatarProps {
  image: string
  onChange: (file: File) => void
  accept?: string
}

export const UploadAvatar = (props: UploadAvatarProps) => {
  const { image, onChange, accept = 'image/png, image/jpeg' } = props

  const onBeforeUpload = useCallback(
    (file: RcFile) => {
      if (accept && !accept.includes(file.type)) {
        notification.error({ message: 'Incorrect file type' })
        return
      }
      const isLt10M = file.size / 1024 / 1024 < 10
      if (!isLt10M) {
        notification.error({ message: 'File must smaller than 10MB' })
        return
      }

      onChange(file)
      return false
    },
    [accept, onChange],
  )

  return (
    <Container>
      {image && <ImageProfile src={image} />}
      <Upload showUploadList={false} maxCount={1} beforeUpload={onBeforeUpload} accept={accept}>
        <EditText>
          <FontAwesomeIcon style={{ marginTop: 8 }} icon={faCamera} />
        </EditText>
      </Upload>
    </Container>
  )
}
