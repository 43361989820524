import { Button } from 'antd'
import { useCallback, useState } from 'react'

import { FormModal } from 'components/FormModal'

export const WikiFormModal = () => {
  const [visible, setVisible] = useState(false)
  const handleOnCancel = useCallback(() => {
    setVisible(false)
  }, [])
  return (
    <>
      <Button onClick={() => setVisible(true)}>Open Modal</Button>
      <FormModal title="Member" visible={visible} onCancel={handleOnCancel}>
        <div style={{ background: 'mediumseagreen' }}>
          Content... Content... Content... Content... Content... Content... Content... Content... Content... Content...
          Content... Content... Content... Content... Content... Content... Content... Content... Content... Content...
          Content... Content... Content... Content... Content... Content... Content... Content... Content... Content...
          Content... Content... Content... Content... Content... Content...
        </div>
      </FormModal>
      <div style={{ textAlign: 'start', marginTop: 16, whiteSpace: 'pre' }}>{codeText}</div>
    </>
  )
}

const codeText = `import { Button } from 'antd'
import { useCallback, useState } from 'react'

import { FormModal } from 'components/FormModal'

export const WikiFormModal = () => {
  const [visible, setVisible] = useState(false)
  const handleOnCancel = useCallback(() => {
    setVisible(false)
  }, [])
  return (
    <>
      <Button onClick={() => setVisible(true)}>Open Modal</Button>
      <FormModal title="Member" visible={visible} onCancel={handleOnCancel}>
        <div style={{ background: 'red' }}>
          Content... Content... Content... Content... Content... Content... Content... Content... Content... Content...
          Content... Content... Content... Content... Content... Content... Content... Content... Content... Content...
          Content... Content... Content... Content... Content... Content... Content... Content... Content... Content...
          Content... Content... Content... Content... Content... Content...
        </div>
      </FormModal>
    </>
  )
}`
