import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import { GET_SERVICES } from 'services/enco-service/enco-service-service'
import { ContentType } from 'services/http-client/base-http-client'
import { encoClient } from 'services/http-client/enco-client'

import { ResponseType } from '../response-type'

import { IMutationServiceLocationsParams } from './service-location-service-param'
import { IGetServiceLocationResponse, IServiceLocation } from './service-location-service-response'

const SERVICE_LOCATION = '/ServiceLocation'
const GET_MASTER_SERVICE_LOCATION_PATH = '/GetMasterServiceLocation'

export const useGetServiceLocationsQRY = (enabled: boolean) => {
  return useQuery(
    [SERVICE_LOCATION],
    async () => {
      const response = await encoClient.get<ResponseType<IServiceLocation[]>>(`${SERVICE_LOCATION}`)
      return response.data.data
    },
    {
      enabled,
    },
  )
}

export const useMutationServiceLocationsMTT = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async (params: IMutationServiceLocationsParams) => {
      const response = await encoClient.post<ResponseType<IServiceLocation[]>>(SERVICE_LOCATION, params, {
        headers: {
          'Content-Type': ContentType.JSON,
        },
      })

      return response.data.data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([GET_SERVICES])
        queryClient.invalidateQueries([SERVICE_LOCATION])
      },
    },
  )
}

export const useGetMasterServiceLocationQRY = () => {
  return useQuery([GET_MASTER_SERVICE_LOCATION_PATH], async () => {
    const response = await encoClient.get<ResponseType<IGetServiceLocationResponse[]>>(
      `${GET_MASTER_SERVICE_LOCATION_PATH}`,
    )
    return response.data.data
  })
}
