import { Form, notification } from 'antd'
import { useForm } from 'antd/es/form/Form'
import dayjs from 'dayjs'
import { useCallback, useEffect, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { ContentBody } from '_backend/components/layout/ContentBody'
import { ContentHeader } from '_backend/components/layout/ContentHeader'
import { useModalConfirm } from 'components/ModalConfirmProvider'
import { notifyFormValidateFailed } from 'helpers/form-helper'
import { backendPaths } from 'paths/backend-path-config'
import { useGetInvoiceQRY } from 'services/sale/invoice/invoice-service'
import { useGetQuotationDetailQRY } from 'services/sale/quotation/quotation-service'
import { useGetReceiptQRY, useReceiptDeleteMTT } from 'services/sale/receipt/receipt-service'

import { SaleButtonPrint } from '../ButtonPrint'
import { SectionCustomerFields } from '../SectionCustomerFields'
import { SectionFormAction } from '../SectionFormAction'
import { SectionOrderFields } from '../SectionOrderFields'
import { DiscountUnitEnum } from '../sale-constants'
import { mapSaleDataItemToRecord } from '../sale-helper'

import { IFormReceiptValues } from './PageReceiptNew'
import { SectionReceiptFields } from './SectionReceiptFields'

export const PageReceiptEdit = () => {
  const [form] = useForm()
  const { receiptId } = useParams()
  const modalConfirm = useModalConfirm()
  const navigate = useNavigate()

  const { data: receipt, isFetching: isReceiptFetching } = useGetReceiptQRY(Number(receiptId))
  const { data: invoice, isFetching: isInvoiceFetching } = useGetInvoiceQRY(Number(receipt?.tbInvoiceId))
  const { data: quotation, isFetching: isQuotationFetching } = useGetQuotationDetailQRY(Number(receipt?.tbQuotaId))
  const { mutateAsync: deleteReceipt } = useReceiptDeleteMTT()

  const orderItems = useMemo(() => mapSaleDataItemToRecord(receipt?.dataItems), [receipt?.dataItems])
  const exitingCustomerId = useMemo(() => quotation?.tbCustomerId, [quotation?.tbCustomerId])

  const initialValues = useMemo<Partial<IFormReceiptValues>>(() => {
    const discountUnit =
      receipt?.discountPercent === 0 && receipt?.discount > 0 ? DiscountUnitEnum.BATH : DiscountUnitEnum.PERCENT

    return {
      id: receipt?.id,
      receiveDate: dayjs(receipt?.receiveDate),
      mdQuotaStName: receipt?.mdQuotaStName,
      vatType: receipt?.vatType,
      discountType: receipt?.discountType,
      invoiceNo: receipt?.invoiceNo,
      invoiceDatedisplay: receipt?.invoiceDatedisplay,
      quotaNo: receipt?.quotaNo,
      quodatedisplay: receipt?.quodatedisplay,
      tbServiceName: receipt?.tbServiceName,
      orderNo: receipt?.orderNo,
      orderDatedisplay: receipt?.orderDatedisplay,
      remark: invoice?.remark,

      attention: quotation?.attention,
      customerId: quotation?.tbCustomerId,

      vatPercent: receipt?.vatPercent,
      discountUnit: discountUnit,
      discount: discountUnit === DiscountUnitEnum.PERCENT ? receipt?.discountPercent : receipt?.discount,
    }
  }, [quotation, receipt, invoice])

  const handleReject = useCallback(() => {
    if (!receipt) return
    modalConfirm.show({
      type: 'danger',
      title: 'ยืนยันการยกเลิกใบเสร็จรับเงิน',
      content: `คุณยืนยันที่จะยกเลิกใบเสร็จรับเงิน ${receipt.receiptNo} นี้หรือไม่?`,
      modalProps: {
        width: 700,
      },
      onOk: async () => {
        await deleteReceipt(receipt.id)
        navigate(backendPaths.receiptList())
        notification.success({ message: 'สำเร็จ', description: 'ยกเลิกใบเสร็จรับเงินสำเร็จ', duration: 2 })
      },
    })
  }, [deleteReceipt, modalConfirm, navigate, receipt])

  useEffect(() => {
    form.setFieldsValue(initialValues)
  }, [form, initialValues])

  return (
    <>
      <ContentHeader title="Sale" subTitle="Edit Receipt">
        {receipt?.receiptFilePDFBaseString && (
          <SaleButtonPrint fileName={`${receipt.receiptNo}.pdf`} base64Data={receipt.receiptFilePDFBaseString} />
        )}
      </ContentHeader>
      <ContentBody>
        {!isReceiptFetching && !isQuotationFetching && !isInvoiceFetching && (
          <Form<IFormReceiptValues>
            layout="vertical"
            form={form}
            onFinishFailed={notifyFormValidateFailed}
            scrollToFirstError
            initialValues={initialValues}
          >
            <SectionReceiptFields readonly receiptNo={receipt?.receiptNo} />
            <SectionCustomerFields readonly form={form} exitingCustomerId={exitingCustomerId} />
            <SectionOrderFields readonly form={form} orderItems={orderItems} />
            <SectionFormAction
              backLink={backendPaths.receiptList()}
              rejectProps={{ title: 'ยกเลิกใบเสร็จรับเงิน', onReject: handleReject }}
              hideSubmit
            />
          </Form>
        )}
      </ContentBody>
    </>
  )
}
