import numeral from 'numeral'

export const numberFormat = (number: any, precision = 2) => {
  return numeral(number).format(`0,0[.][${'0'.repeat(precision)}]`)
}

export const round = (value: number, precision: number) => {
  var multiplier = Math.pow(10, precision || 0)
  return Math.round(value * multiplier) / multiplier
}
