import { Tabs as AntdTabs } from 'antd'
import { TabsProps } from 'antd/lib/tabs'
import { styled } from 'goober'
import { useCallback, useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

const TabsContainer = styled('div')`
  .ant-tabs-card .ant-tabs-content {
    margin-top: -16px;
  }

  .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
    padding: 4px;
  }

  .ant-tabs-tab {
    padding: 8px 16px;
    border: 1px solid #bdbdbd !important;

    &.ant-tabs-tab-active {
      border-bottom: 0px !important;
    }
  }
  .ant-tabs-card > .ant-tabs-nav::before {
    display: none;
  }
  .ant-tabs-card .ant-tabs-tab,
  [data-theme='compact'] .ant-tabs-card .ant-tabs-tab {
    background: transparent;
    border-color: transparent;
  }
  .ant-tabs-card .ant-tabs-tab-active,
  [data-theme='compact'] .ant-tabs-card .ant-tabs-tab-active {
    background: #fff;
    border-color: #fff;
  }
  #components-tabs-demo-card-top .code-box-demo {
    padding: 24px;
    overflow: hidden;
    background: #f5f5f5;
  }
`
interface ITabsProps extends TabsProps {
  defaultTabActive?: string
  tabParamKey?: string
}

export const Tabs = (props: ITabsProps) => {
  const { defaultActiveKey, tabParamKey = 'tab', children, onChange, ...restProps } = props
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const location = useLocation()
  const [tabActive, setTabActive] = useState<string>(searchParams.get(tabParamKey) || defaultActiveKey || '')

  const onTabChange = useCallback(
    (value: string) => {
      setTabActive(value)
      onChange?.(value)
      searchParams.set(tabParamKey, value)
      navigate({
        pathname: location.pathname,
        search: searchParams.toString(),
      })
    },
    [location.pathname, navigate, onChange, searchParams, tabParamKey],
  )

  return (
    <TabsContainer>
      <AntdTabs type="card" {...restProps} defaultActiveKey={tabActive?.toString()} onChange={onTabChange}>
        {children}
      </AntdTabs>
    </TabsContainer>
  )
}
Tabs.TabPane = AntdTabs.TabPane
