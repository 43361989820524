import { Button, Form, Row } from 'antd'
import { useForm } from 'antd/es/form/Form'

import { UploadBannerFormItem } from '_backend/components/upload/UploadBannerFormItem'

export const WikiUploadBannerFormItem = () => {
  const [form] = useForm()
  return (
    <>
      <Form<{ mobileFile?: File | string; desktopFile?: File | string }>
        form={form}
        onFinish={(values) => {
          console.log(values)
        }}
        initialValues={{
          // mobileFile: 'https://laz-img-sg.alicdn.com/p/6a78913c131cfcd539813bd4b7c42459.png',
          desktopFile: 'https://laz-img-sg.alicdn.com/p/6a78913c131cfcd539813bd4b7c42459.png',
        }}
      >
        <UploadBannerFormItem form={form} mobileFieldName="mobileFile" desktopFieldName="desktopFile" required />
        <Row justify="start">
          <Button htmlType="submit">Submit</Button>
        </Row>
      </Form>
      <div style={{ textAlign: 'start', marginTop: 16, whiteSpace: 'pre' }}>{codeText}</div>
    </>
  )
}

const codeText = `import { Button, Form, Row } from 'antd'
import { useForm } from 'antd/es/form/Form'

import { UploadBannerFormItem } from '_backend/components/upload/UploadBannerFormItem'

export const WikiUploadBannerFormItem = () => {
  const [form] = useForm()
  return (
    <>
      <Form<{ mobileFile?: File | string; desktopFile?: File | string }>
        form={form}
        onFinish={(values) => {
          console.log(values)
        }}
        initialValues={{
          // mobileFile: 'https://laz-img-sg.alicdn.com/p/6a78913c131cfcd539813bd4b7c42459.png',
          desktopFile: 'https://laz-img-sg.alicdn.com/p/6a78913c131cfcd539813bd4b7c42459.png',
        }}
      >
        <UploadBannerFormItem form={form} mobileFieldName="mobileFile" desktopFieldName="desktopFile" required />
        <Row justify="start">
          <Button htmlType="submit">Submit</Button>
        </Row>
      </Form>
    </>
  )
}`
