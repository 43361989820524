import { sumBy } from 'lodash'

import { DiscountUnitEnum, VatTypeEnum } from '_backend/modules/sale/sale-constants'
import { IInvoiceDataItem } from 'services/sale/invoice/invoice-response'
import { IQuotationOrderItem } from 'services/sale/quotation/quotation-param'
import { IReceiptOrderItemResponse } from 'services/sale/receipt/receipt-response'

export interface IRecordOrderItem {
  id: number
  itemName: string
  quantity: number
  unit: string
  unitPrice: number
}

interface ICalculateExcludingVatPriceParams {
  orderItems: IRecordOrderItem[]
  vatType: VatTypeEnum
  vatPercent: number
}
export const calculateExcludingVatPrice = (params: ICalculateExcludingVatPriceParams) => {
  const { orderItems, vatType, vatPercent } = params
  const summaryPrice = sumBy(orderItems, (o) => o.quantity * o.unitPrice)
  switch (vatType) {
    case VatTypeEnum.VAT_INCLUDED:
      return summaryPrice / ((100 + vatPercent) / 100)
    case VatTypeEnum.VAT_EXCLUDED:
      return summaryPrice
    default:
      return 0
  }
}

interface ICalculateVatAmountParams {
  excludingVatPrice: number
  discountAmount: number
  vatPercent: number
}
export const calculateVatAmount = (params: ICalculateVatAmountParams) => {
  const { excludingVatPrice, discountAmount, vatPercent } = params
  let price = discountAmount > 0 ? excludingVatPrice - discountAmount : excludingVatPrice
  return price * (vatPercent / 100)
}

interface IGetDiscountRateParams {
  discountUnit: DiscountUnitEnum
  discount: number
}
export const getDiscountRate = (params: IGetDiscountRateParams) => {
  const { discountUnit, discount } = params
  if (discountUnit !== DiscountUnitEnum.PERCENT) return 0
  if (discount <= 0) return 0
  return discount / 100
}

interface ICalculateDiscountAmountParams {
  basePrice: number
  discountUnit: DiscountUnitEnum
  discount: number
}
export const calculateDiscountAmount = (params: ICalculateDiscountAmountParams) => {
  const { basePrice, discountUnit, discount } = params
  switch (discountUnit) {
    case DiscountUnitEnum.BATH:
      return discount
    case DiscountUnitEnum.PERCENT:
      const discountRate = discount > 0 ? discount / 100 : 0
      return basePrice * discountRate
    default:
      throw new Error('Invalid discountUnit')
  }
}

export const mapSaleDataItemToRecord = (
  items: IQuotationOrderItem[] | IInvoiceDataItem[] | IReceiptOrderItemResponse[] = [],
  isNew?: boolean,
): IRecordOrderItem[] => {
  return items.map((i) => ({
    id: isNew ? 0 : i.id || 0,
    itemName: i.item,
    quantity: i.qty,
    unit: i.unit,
    unitPrice: i.unitPrice,
  }))
}
