import { Button, Col, Divider, Form, FormInstance, Row, Select } from 'antd'
import { useWatch } from 'antd/es/form/Form'
import dayjs, { Dayjs } from 'dayjs'
import { useCallback, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { apiDateFormat } from '_backend/constants/date-format'
import { REQUIRED_RULES } from '_backend/constants/form-rules'
import DatePicker from 'components/DatePicker'
import { useModalConfirm, UseModalConfirmParamType } from 'components/ModalConfirmProvider'
import { Txt } from 'components/Txt'
import { useGetUserInfoQRY } from 'services/auth/auth-service'
import { IGetServiceSlotSelectParams } from 'services/enco-service/enco-service-param'
import {
  useGetLocationOzoneBookingQtyQRY,
  useGetServiceQRY,
  useGetServiceSlotSelectQRY,
  useSearchServiceItemQRY,
} from 'services/enco-service/enco-service-service'
import { useGetSearchServicePropQRY } from 'services/property-management/property-management-service'

import { IOzoneItem, OzoneItemSelector } from './OzoneItemSelector'
import { OzoneItemSummary } from './OzoneItemSummary'
import { OzoneSlotSelector } from './OzoneSlotSelector'
import { IFormValue, FieldNameEnum } from './PageOzoneOrder'

const alertModalConfig: UseModalConfirmParamType = {
  type: 'warning',
  title: 'คำเตือน',
}

interface IOzoneOrderFormProps {
  form: FormInstance<IFormValue>
}

export const OzoneOrderForm = (props: IOzoneOrderFormProps) => {
  const { form } = props
  const { serviceId } = useParams()
  const navigate = useNavigate()
  const modalConfirm = useModalConfirm()
  const [prevLocationId, setPrevLocationId] = useState(form.getFieldValue(FieldNameEnum.LocationId))
  const [prevDate, setPrevDate] = useState(form.getFieldValue(FieldNameEnum.Date))
  const { data: userInfo } = useGetUserInfoQRY()
  const { data: serviceData } = useGetServiceQRY(Number(serviceId))
  const { data: propertyData } = useGetSearchServicePropQRY({ ServiceId: Number(serviceId) })
  const selectedLocationId = useWatch<number | undefined>([FieldNameEnum.LocationId], form)
  const selectedItemId = useWatch<number | undefined>([FieldNameEnum.ItemId], form)
  const selectedDate = useWatch<Dayjs | undefined>([FieldNameEnum.Date], form)

  const minOrderDate = useMemo(() => {
    return dayjs()
      .add(serviceData?.minDateBookingDay || 0, 'days')
      .startOf('days')
  }, [serviceData?.minDateBookingDay])
  const disabledDate = useCallback((date: Dayjs) => date.isBefore(minOrderDate), [minOrderDate])

  const { data: ozoneLocation } = useGetLocationOzoneBookingQtyQRY()
  const locationOptions = useMemo(() => {
    return ozoneLocation?.map((loc) => ({
      label: loc.name,
      value: loc.id,
    }))
  }, [ozoneLocation])

  const { data: ozoneItems } = useSearchServiceItemQRY()
  const ozoneItemOptions = useMemo<IOzoneItem[] | []>(() => {
    return (
      ozoneItems?.map((i) => ({
        label: i.name,
        value: i.id,
      })) || []
    )
  }, [ozoneItems])

  const serviceSlotSelectParams = useMemo<IGetServiceSlotSelectParams | {}>(() => {
    if (propertyData && selectedItemId && selectedDate && selectedLocationId) {
      return {
        servicePropId: propertyData[0].id,
        itemId: selectedItemId,
        bookdate: selectedDate.format(apiDateFormat),
      }
    }
    return {}
  }, [propertyData, selectedDate, selectedItemId, selectedLocationId])
  const { data: slotItems } = useGetServiceSlotSelectQRY(serviceSlotSelectParams)

  const clearForm = useCallback(() => {
    form.setFieldsValue({
      [FieldNameEnum.LocationId]: undefined,
      [FieldNameEnum.ItemId]: undefined,
      [FieldNameEnum.Date]: undefined,
      [FieldNameEnum.Slot]: undefined,
    })
    setPrevDate(undefined)
    setPrevLocationId(undefined)
  }, [form])

  const handleLocationChange = useCallback(() => {
    const newValue = form.getFieldValue(FieldNameEnum.LocationId)
    if (!prevLocationId) {
      setPrevLocationId(newValue)
      return
    }
    if (prevLocationId !== newValue) {
      modalConfirm.show({
        ...alertModalConfig,
        content: 'หากเปลี่ยนสถานที่ข้อมูลที่ระบุไว้จะถูกลบทั้งหมด',
        onCancel: () => {
          form.setFieldsValue({
            [FieldNameEnum.LocationId]: prevLocationId,
          })
        },
        onOk: () => {
          clearForm()
          setPrevLocationId(newValue)
          form.setFieldsValue({
            [FieldNameEnum.LocationId]: newValue,
          })
        },
      })
    }
  }, [clearForm, form, modalConfirm, prevLocationId])

  const handleDateChange = useCallback(() => {
    const newValue = form.getFieldValue([FieldNameEnum.Date])
    if (!prevDate) {
      setPrevDate(newValue)
      return
    }
    if (prevDate !== newValue) {
      modalConfirm.show({
        ...alertModalConfig,
        content: 'หากเปลี่ยนวันที่ข้อมูลที่ระบุไว้จะถูกลบทั้งหมด',
        onCancel: () => {
          form.setFieldsValue({
            [FieldNameEnum.Date]: prevDate,
          })
        },
        onOk: () => {
          clearForm()
          setPrevDate(newValue)
          form.setFieldsValue({
            [FieldNameEnum.Date]: newValue,
          })
        },
      })
    }
  }, [clearForm, form, modalConfirm, prevDate])

  const handleBackButton = useCallback(() => {
    navigate(-1)
  }, [navigate])

  return (
    <>
      <Row justify="center">
        <Txt ag="header40" color="lightBluePrimary">
          จองอบโอโซนรถยนต์
        </Txt>
      </Row>

      <Row style={{ marginTop: 32 }}>
        <Col span={24} md={{ span: 12 }} lg={{ span: 16 }} style={{ textAlign: 'center' }}>
          <Txt ag="header30">รายละเอียดการจองอบโอโซนรถยนต์</Txt>
        </Col>
        <Col span={24} md={{ span: 12 }} lg={{ span: 8 }} style={{ textAlign: 'center' }}>
          <Txt ag="header30">Your Credit : {userInfo?.credit}</Txt>
        </Col>
      </Row>

      <Row gutter={[24, 24]} style={{ marginTop: 24 }}>
        <Col span={24} md={{ span: 12 }} lg={{ span: 16 }}>
          <Row gutter={[24, 24]}>
            <Col span={24} lg={{ span: 12 }}>
              <Row>
                <Col span={24}>
                  <Form.Item
                    label={<Txt ag="body28">เลือกสถานที่อบโอโซน</Txt>}
                    labelCol={{ span: 24 }}
                    name={[FieldNameEnum.LocationId]}
                    required={true}
                    rules={REQUIRED_RULES}
                  >
                    <Select
                      allowClear
                      className="big-selector"
                      dropdownClassName="big-selector"
                      placeholder="เลือกสถานที่อบโอโซน"
                      options={locationOptions}
                      onChange={handleLocationChange}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col span={24}>
                  <Form.Item
                    label={<Txt ag="body28">เลือกประเภทรถ</Txt>}
                    labelCol={{ span: 24 }}
                    name={[FieldNameEnum.ItemId]}
                    required={true}
                    rules={REQUIRED_RULES}
                  >
                    <OzoneItemSelector options={ozoneItemOptions} />
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            <Col span={24} lg={{ span: 12 }}>
              <Row>
                <Col span={24}>
                  <Form.Item
                    label={<Txt ag="body28">เลือกวันที่</Txt>}
                    labelCol={{ span: 24 }}
                    name={[FieldNameEnum.Date]}
                    required={true}
                    rules={REQUIRED_RULES}
                  >
                    <DatePicker allowClear disabledDate={disabledDate} onChange={handleDateChange} />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>

        <Col span={24} md={{ span: 12 }} lg={{ span: 8 }}>
          <Row>
            <Col span={24}>
              <Form.Item
                label={<Txt ag="body28">ตารางเวลา</Txt>}
                labelCol={{ span: 24 }}
                name={[FieldNameEnum.Slot]}
                required={true}
                rules={REQUIRED_RULES}
              >
                <OzoneSlotSelector options={slotItems} />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>

      <Divider />

      <Row justify="center">
        <Txt ag="header30">สรุปรายการ</Txt>
      </Row>

      <Row style={{ marginTop: 32 }}>
        <Col span={24} lg={{ span: 12, push: 6 }}>
          <Form.Item name={[FieldNameEnum.Slot]}>
            <OzoneItemSummary ozoneItems={ozoneItems || []} />
          </Form.Item>
        </Col>
      </Row>

      <Row justify="center" style={{ marginTop: 80 }}>
        <Button htmlType="submit" style={{ width: '100%', maxWidth: 343 }} type="primary">
          Continue
        </Button>
      </Row>

      <Row justify="center" style={{ marginTop: 12 }}>
        <Button style={{ width: '100%', maxWidth: 343 }} onClick={handleBackButton}>
          Back
        </Button>
      </Row>
    </>
  )
}
