import { Badge } from 'antd'
import dayjs, { Dayjs } from 'dayjs'
import { styled } from 'goober'
import { chain, isEmpty } from 'lodash'
import { useCallback, useState, useRef, useMemo } from 'react'
import { Event, EventWrapperProps } from 'react-big-calendar'

import { componentDisplayDateFormat } from '_backend/constants/date-format'
import { AppBigCalendar } from 'components/AppBigCalendar'
import { randomId } from 'helpers/string-helper'
import { useGetOrderCalendarQRY } from 'services/order-response/order-response-service-service'
import theme from 'theme/goober'

import type { BadgeProps } from 'antd'

const timeFormat = (time: string) => {
  const times = time.split(':')
  return `${times[0]} : ${times[1]}`
}

const mapBadgeDotColor = (status: BadgeProps['status']) =>
  status === 'success' ? theme.color.lightGreenPrimary : theme.color.softGray
const mapBadgeTextColor = (status: BadgeProps['status']) =>
  status === 'success' ? theme.color.lightGreenBackground : theme.color.grayCard

const Container = styled.div``

const ListBadge = styled<BadgeProps>(Badge)`
  .ant-badge-status-dot {
    margin-right: -4px;
    background-color: ${(props) => mapBadgeDotColor(props.status)};
  }
  .ant-badge-status-text {
    font-size: 14px;
    overflow-wrap: unset;
    padding: 2px 4px;
    border-radius: 4px;
    background-color: ${(props) => mapBadgeTextColor(props.status)};
  }
`

interface IOrderCalendarEvent extends Event {
  id: number
  badge?: BadgeProps['status']
}

interface IOrderCalendarProps {
  serviceId: number
  selectedValue: Dayjs | null
  onSelected: (date: Dayjs | null) => void
}

export const OrderCalendar = (props: IOrderCalendarProps) => {
  const [filter, setFilter] = useState<Dayjs>(dayjs())

  const { data: orders, isLoading } = useGetOrderCalendarQRY({
    month: (filter?.month() || 0) + 1,
    year: filter?.year(),
    serviceId: props.serviceId,
  })

  const groupOrder = useCallback(() => {
    let OrderItemGrouped: {
      [key: string]: { key: string; time: string; name: string; badge: BadgeProps['status']; mdSlotId: number }[]
    } = {}
    orders?.forEach((order) => {
      const badge: BadgeProps['status'] = isEmpty(order.canceldate) ? 'success' : 'default'
      order.orderItem
        .filter((item) => item.mdSlotId !== null)
        .forEach((item) => {
          const bookingTime = dayjs(item.bookingDate).format(componentDisplayDateFormat)
          const displayItem = {
            key: item.itemnm + item.mdSlotId,
            time: `${timeFormat(item.slotStart)} - ${timeFormat(item.slotEnd)} น.`,
            name: item.itemnm,
            badge,
            mdSlotId: item.mdSlotId,
          }
          if (OrderItemGrouped[bookingTime]) {
            const existed = OrderItemGrouped[bookingTime].find((grouped) => grouped.key === displayItem.key)
            if (!existed) {
              OrderItemGrouped = { ...OrderItemGrouped, [bookingTime]: [...OrderItemGrouped[bookingTime], displayItem] }
            }
          } else {
            OrderItemGrouped = { ...OrderItemGrouped, [bookingTime]: [displayItem] }
          }
        })
    })
    return OrderItemGrouped
  }, [orders])

  const isDateSelectedRef = useRef(false)

  const handlePanelChange = useCallback(
    (date: Dayjs, mode: string) => {
      setFilter(date)

      if (!props.selectedValue) {
        setTimeout(() => {
          props.onSelected(null)
          isDateSelectedRef.current = false
        }, 0)
      }
    },
    [props],
  )

  const handleSelect = useCallback(
    (date: Dayjs) => {
      isDateSelectedRef.current = true
      props.onSelected(date)
    },
    [props],
  )

  const dateCellRender = (value: Dayjs) => {
    const listData = groupOrder()[value.format(componentDisplayDateFormat)]
    return (
      <>
        <div
          style={{
            position: 'absolute',
            inset: 0,
            background: value.isSame(props.selectedValue) ? '#00ccff1c' : 'unset',
            pointerEvents: 'none',
          }}
        />
        {listData &&
          listData
            .sort((a, b) => a.mdSlotId - b.mdSlotId)
            .map((item) => <ListBadge key={randomId()} status={item.badge} text={`${item.time} ${item.name}`} />)}
      </>
    )
  }

  const events = useMemo<IOrderCalendarEvent[]>(() => {
    return (
      chain(orders ?? [])
        // .filter((order) => isEmpty(order.canceldate))
        .transform((acc: IOrderCalendarEvent[], order) => {
          const { orderItem, canceldate } = order
          const badge: BadgeProps['status'] = isEmpty(canceldate) ? 'success' : 'default'
          const orderItemFiltered = orderItem.filter((item) => !!item.mdSlotId)
          for (const item of orderItemFiltered) {
            const bookingStart = dayjs(item.bookingDateNm + 'T' + item.slotStart).toDate()
            const bookingEnd = dayjs(item.bookingDateNm + 'T' + item.slotEnd).toDate()
            const text = [order.orderNo, item.itemnm].join(' ')
            // console.log({
            //   bookingDate: item.bookingDate,
            //   slotStart: item.slotStart,
            //   slotEnd: item.slotEnd,
            //   bookingStart,
            //   bookingEnd,
            // })
            acc.push({
              id: item.id,
              badge,
              title: text,
              // title: <Badge status={badge} text={text} title={text} />,
              start: bookingStart,
              end: bookingEnd,
              allDay: false,
            })
          }
          return acc
        }, [])
        .uniqBy((e) => e.id)
        .concat([
          {
            id: -1,
            title: 'Today',
            start: dayjs().hour(6).startOf('hour').toDate(),
            end: dayjs().hour(6).startOf('hour').toDate(),
          },
        ])
        .value()
    )
  }, [orders])

  // const customEventPropGetter = useCallback<EventPropGetter<IOrderCalendarEvent>>((event, start, end, isSelected) => {
  //   if (start.getDate() === 7 || start.getDate() === 15) {
  //     return {
  //       className: 'date-selected',
  //       style: {
  //         border: 'solid 3px ' + (start.getDate() === 7 ? '#faa' : '#afa'),
  //       },
  //     }
  //   }

  //   return {}
  // }, [])

  return (
    <Container>
      <AppBigCalendar<IOrderCalendarEvent>
        events={events}
        onNavigate={(newDate, view, action) => {
          setFilter(dayjs(newDate))
          props.onSelected(null)
        }}
        onSelectEvent={(d) => {
          props.onSelected(dayjs(d.start))
        }}
        // eventPropGetter={customEventPropGetter}
        // components={
        //   {
        //     eventWrapper: OrderCalendarEventWrapper,
        //   }
        // }
        // onSelect={handleSelect}
        // onPanelChange={handlePanelChange}
        style={{
          height: '600px',
        }}
        showMultiDayTimes
        doShowMoreDrillDown
        popup
      />
    </Container>
  )
}

interface IOrderCalendarEventWrapperProps extends EventWrapperProps<IOrderCalendarEvent> {}
const OrderCalendarEventWrapper = (props: IOrderCalendarEventWrapperProps) => {
  const { event, onClick, className, style, ...restProps } = props
  return (
    <div {...props}>
      <ListBadge
        status={event.badge}
        text={`${event.title}`}
        /* {...restProps} */
      />
    </div>
  )
}
