import { Divider } from 'antd'

import { DemoSearchAutoComplete } from './DemoSearchAutoComplete'
import { DemoSearchInput } from './DemoSearchInput'

export const WikiSearch = () => {
  return (
    <>
      <DemoSearchInput />
      <Divider />
      <DemoSearchAutoComplete />
    </>
  )
}
