import { Space } from 'antd'
import { styled } from 'goober'
import { useMemo } from 'react'
import { FaRegCheckCircle } from 'react-icons/fa'
import { Link, useParams } from 'react-router-dom'

import { AppButton } from 'components/AppButton'
import { Txt } from 'components/Txt'
import { LayoutCardPage } from 'components/layout/LayoutCardPage'
import { EnumReceiptType } from 'constants/enum-receipt-type'
import { mediaQuery } from 'helpers/responsive-helper'
import { appPaths } from 'paths/path-config'
import {
  downloadReceiptFile,
  useGetOrderReceiptInfoQry,
  useGetServiceOrderQRY,
} from 'services/order-response/order-response-service-service'
import theme from 'theme/goober'

const Title = styled('div')`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 26px;
`
const Content = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`
const WrapperIcon = styled('div')`
  padding: 100px 0;
  @media ${mediaQuery.xs} {
    padding: 50px 0;
  }
`
const WrapperButton = styled('div')`
  div {
    padding: 12px;
  }

  @media ${mediaQuery.xs} {
    button {
      width: 100%;
      min-width: 250px;
    }
  }
`

export const PageOrderPaymentCompleted = () => {
  const query = useParams()
  const orderId = Number(query.orderId)
  const { data: receiptData } = useGetOrderReceiptInfoQry(orderId)
  const { data: order } = useGetServiceOrderQRY(orderId)

  const isShowDownloadButton = useMemo(() => {
    return receiptData?.receiptFileImgBaseString || receiptData?.receiptFilePDFBaseString
  }, [receiptData])

  return (
    <LayoutCardPage>
      <Title>
        <Txt ag="header40" color="lightBluePrimary">
          ชำระเงินสำเร็จ
        </Txt>
      </Title>
      {order?.mdReceiptTypeId === EnumReceiptType.full && (
        <div className="text-center mt-16">
          <Txt ag="header28">เจ้าหน้าที่จะจัดส่งใบเสร็จรับเงินให้ทางอีเมล</Txt>
        </div>
      )}
      <Content>
        <WrapperIcon>
          <FaRegCheckCircle fontSize={'100px'} color={theme.color.lightBluePrimary} />
        </WrapperIcon>
        <WrapperButton>
          {receiptData && isShowDownloadButton && (
            <Space direction="vertical" align="center" size="middle" className="w-100">
              <AppButton onClick={() => downloadReceiptFile(receiptData)}>ดาวน์โหลดใบเสร็จ</AppButton>
            </Space>
          )}
          <Link to={appPaths.home()}>
            <Space direction="vertical" align="center" size="middle" className="w-100">
              <AppButton type="default">Home</AppButton>
            </Space>
          </Link>
        </WrapperButton>
      </Content>
    </LayoutCardPage>
  )
}
