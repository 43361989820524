import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'

import { encoClient } from 'services/http-client/enco-client'
import { ResponseType } from 'services/response-type'

import {
  IPrNewsCreateParam,
  IPrNewsGetDetailParam,
  IPrNewsGetListParam,
  IPrNewsUpdateParam,
  IPrNewsUpdatePlatformParam,
  IPrNewsUpdateSeqParam,
} from './pr-news-param'
import { IPrNewsGetListResponse } from './pr-news-response'

const PR_NEWS_GET_LIST_PATH = '/GetPr'
export const usePrNewsGetListQRY = (param: IPrNewsGetListParam) => {
  return useQuery([PR_NEWS_GET_LIST_PATH, param], async () => {
    const res = await encoClient.post<ResponseType<IPrNewsGetListResponse[]>>(PR_NEWS_GET_LIST_PATH, param)
    return res.data.data
  })
}

const PR_NEWS_GET_DETAIL_PATH = '/GetPr'
export const usePrNewsGetDetailQRY = (param: IPrNewsGetDetailParam) => {
  return useQuery([PR_NEWS_GET_DETAIL_PATH, param], async () => {
    const res = await encoClient.get<ResponseType<IPrNewsGetListResponse>>(PR_NEWS_GET_DETAIL_PATH, { params: param })
    return res.data.data
  })
}

const PR_NEWS_UPDATE_SEQ_PATH = '/SetPrRecno'
export const usePrNewsUpdateSeqMTT = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async (param: IPrNewsUpdateSeqParam) => {
      const res = await encoClient.post<ResponseType<IPrNewsGetListResponse[]>>(PR_NEWS_UPDATE_SEQ_PATH, param)
      return res.data.data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([PR_NEWS_GET_LIST_PATH])
      },
    },
  )
}

const PR_NEWS_UPDATE_PLATFORM_PATH = '/SavePrPlatForm'
export const usePrNewsUpdatePlatformMTT = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async (param: IPrNewsUpdatePlatformParam) => {
      const res = await encoClient.post<ResponseType<IPrNewsGetListResponse>>(PR_NEWS_UPDATE_PLATFORM_PATH, param)
      return res.data.data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([PR_NEWS_GET_LIST_PATH])
      },
    },
  )
}

const PR_NEWS_CREATE_PATH = '/SavePr'
export const usePrNewsCreateMTT = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async (param: IPrNewsCreateParam) => {
      const res = await encoClient.post<ResponseType<IPrNewsGetListResponse>>(PR_NEWS_CREATE_PATH, param)
      return res.data.data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([PR_NEWS_GET_LIST_PATH])
      },
    },
  )
}

const PR_NEWS_UPDATE_PATH = '/SavePr'
export const usePrNewsUpdateMTT = () => {
  const queryClient = useQueryClient()
  return useMutation(
    async (param: IPrNewsUpdateParam) => {
      const res = await encoClient.post<ResponseType<IPrNewsGetListResponse>>(PR_NEWS_UPDATE_PATH, param)
      return res.data.data
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([PR_NEWS_GET_LIST_PATH])
      },
    },
  )
}
