import { faStar } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Col, Form, Rate, RateProps, Row } from 'antd'
import { Rule, RuleObject } from 'antd/lib/form'
import dayjs from 'dayjs'
import { styled } from 'goober'
import { chain } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { FormModal } from 'components/FormModal'
import { TextArea } from 'components/Input'
import { Txt } from 'components/Txt'
import { mediaQuery } from 'helpers/responsive-helper'
import { ISubmitSuggestionFile } from 'services/suggestion/suggestion-service-param'
import { useSearchSuggestionQTY } from 'services/suggestion/suggestion-service-service'
import theme from 'theme/goober'

import { UploadSuggestionFiles } from './UploadSuggestionFiles'

const Layout = styled(Row)`
  width: 500px;

  button {
    max-width: 343px;
  }

  .star-item-wrapper {
    text-align: center;
  }

  @media ${mediaQuery.xsOnly} {
    width: 100%;
  }
`

const ButtonBlue = styled(Button)`
  color: #78c9f5;
  border-color: #78c9f5;
  background: transparent;
`

const CustomRate = styled(Rate)`
  &.ant-rate {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-column-gap: 4px;
    max-width: 380px;
    margin: 0 auto;

    color: ${theme.color.lightBluePrimary};

    .ant-rate-star-zero {
      .ant-rate-star-first,
      .ant-rate-star-second {
        color: ${theme.color.grayBorder};
      }
    }

    li,
    div {
      display: flex;
      flex-flow: column;
      justify-content: flex-start;
      align-items: center;

      grid-row-gap: 6px;

      margin: 0px !important;
    }
  }
`

const InputSuggestion = styled(TextArea)`
  height: 100px !important;
  min-height: 100px !important;
  max-height: 100px !important;
  textarea {
    resize: none;
  }
`

const ratingLabelMap: Record<number, string> = {
  0: 'ควรปรับปรุง',
  1: 'น้อย',
  2: 'พอใจ',
  3: 'ดี',
  4: 'ดีมาก',
}

export interface IModalOrderSuggestionFormValues {
  id?: number
  rating: number
  suggest: string
  suggestFile: ISubmitSuggestionFile[]
}

interface IModalOrderSuggestionProps {
  visible: boolean
  orderId: number
  onConfirmClick: (values: IModalOrderSuggestionFormValues) => Promise<void>
  onCancelClick: () => void
}

export const ModalOrderSuggestion = (props: IModalOrderSuggestionProps) => {
  const { visible, orderId, onConfirmClick, onCancelClick } = props
  const [form] = Form.useForm<IModalOrderSuggestionFormValues>()

  const [submitting, setSubmitting] = useState(false)
  const [isUploading, setUploading] = useState(false)

  const { data: searchSuggestionRes = [], isLoading } = useSearchSuggestionQTY(
    {
      TbOrderId: orderId,
    },
    !!orderId && visible,
  )

  const latestOrderSuggestion = useMemo(() => {
    return chain(searchSuggestionRes)
      .orderBy(({ firstdate }) => dayjs(firstdate), ['asc'])
      .first()
      .value()
  }, [searchSuggestionRes])

  const isDisabled = useMemo(() => {
    return isLoading || !!latestOrderSuggestion || isUploading
  }, [isLoading, isUploading, latestOrderSuggestion])

  const onUploadProgress = useCallback((progress: number) => {
    setUploading(progress !== 100)
  }, [])

  useEffect(() => {
    const { id = 0, rating = 1, suggest, suggestFile = [] } = latestOrderSuggestion || {}
    form.setFieldsValue({
      id,
      rating,
      suggest,
      suggestFile: suggestFile.map((e) => ({
        id: e.id,
        FilePath: e.path,
      })),
    })
  }, [form, latestOrderSuggestion])

  const onSubmit = useCallback(
    async (values: IModalOrderSuggestionFormValues) => {
      setSubmitting(true)
      await onConfirmClick(values)
      setSubmitting(false)
    },
    [onConfirmClick],
  )

  const ratingCharacterRender = useCallback(
    <
      T extends Required<RateProps>['characterRender'],
      OriginComponentType extends Parameters<T>[0],
      StarProps extends Parameters<T>[1],
    >(
      OriginComponent: OriginComponentType,
      starProps: StarProps,
    ) => {
      const { index = 0, value = 0 } = starProps
      const { props: originComponentProps } = OriginComponent
      return (
        <div {...originComponentProps} className={[originComponentProps.className, 'star-item-wrapper'].join(' ')}>
          {OriginComponent}
          <Txt ag="body20" color={index + 1 <= value ? 'grayPrimaryText' : 'grayBorder'}>
            {ratingLabelMap[index]}
          </Txt>
        </div>
      )
    },
    [],
  )

  const validateSuggestionFiles = useMemo((): Rule => {
    return {
      validator(rule: RuleObject, value: File[]) {
        if (!value?.length) {
          return Promise.reject('กรุณาแนบไฟล์ อย่างน้อย 1 ไฟล์')
        } else if (value?.length > 10) {
          return Promise.reject('คุณสามารถแนบไฟล์ ได้มากสุด 10 ไฟล์')
        }
        return Promise.resolve()
      },
    }
  }, [])

  const submitButton = useMemo(() => {
    if (isDisabled) return
    return (
      <Col span={24}>
        <Row justify="center">
          <Button
            type="primary"
            size="small"
            htmlType="submit"
            disabled={isDisabled || submitting}
            loading={submitting}
            block
          >
            Submit
          </Button>
        </Row>
      </Col>
    )
  }, [isDisabled, submitting])

  const cancelButtonLabel = useMemo(() => {
    if (isDisabled) return 'Back'
    return 'Cancel'
  }, [isDisabled])

  return (
    <FormModal title="ให้คะแนน / ข้อเสนอแนะ" visible={visible} onCancel={onCancelClick} width={800}>
      <Form
        form={form}
        onFinish={onSubmit}
        initialValues={{
          rating: 1,
        }}
        layout="vertical"
      >
        <Layout gutter={[0, 24]}>
          <Col span={24}>
            <Form.Item name="rating">
              <CustomRate
                disabled={isDisabled}
                characterRender={ratingCharacterRender}
                character={<FontAwesomeIcon icon={faStar} size="lg" />}
                allowClear={false}
                allowHalf={false}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="ข้อเสนอแนะเพิ่มเติม"
              name="suggest"
              rules={[
                {
                  type: 'string',
                  required: true,
                  message: 'โปรดกรอกข้อมูล',
                },
              ]}
              required
            >
              <InputSuggestion disabled={isDisabled} showCount={!isDisabled} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label="แนบไฟล์" name="suggestFile" rules={[validateSuggestionFiles]} required>
              <UploadSuggestionFiles disabled={isDisabled} onUploadProgress={onUploadProgress} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Row gutter={[16, 16]}>
              {submitButton}
              <Col span={24}>
                <Row justify="center">
                  <ButtonBlue type="ghost" size="small" block onClick={onCancelClick}>
                    {cancelButtonLabel}
                  </ButtonBlue>
                </Row>
              </Col>
            </Row>
          </Col>
        </Layout>
      </Form>
    </FormModal>
  )
}
