import { styled } from 'goober'
import { useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import coverBlueUrl from 'assets/images/cover-blue.png'
import coverOtherService from 'assets/images/cover-other-service.png'
import { CardListLayout } from 'components/CardListLayout'
import ServiceShortcut from 'components/ServiceShortcut'
import { CoverPageList } from 'components/layout/CoverPageList'
import { appPaths } from 'paths/path-config'
import { IServiceData } from 'services/enco-service/enco-service-response'
import { useGetServicesQRY } from 'services/enco-service/enco-service-service'

const ContentContainer = styled('div')`
  display: flex;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
  max-width: 1600px;
  min-height: 200px;
`

export const PageOtherServiceList = () => {
  const navigate = useNavigate()
  const { data: services } = useGetServicesQRY({ serviceType: 2, isActive: 1 })
  const sortedService = useMemo(() => services?.sort((a) => a.recno), [services])

  const onClickMore = useCallback(
    (service: IServiceData) => {
      const { id: serviceId, autoLink } = service
      if (autoLink) {
        let link = autoLink
        if (!autoLink.startsWith('https://')) {
          link = 'https://' + link
        }
        window.open(link, '_blank', 'noopener,noreferrer')
      } else {
        navigate(`${appPaths.otherService()}/${serviceId}`)
      }
    },
    [navigate],
  )
  return (
    <>
      <CoverPageList title="Other Service" url={coverBlueUrl} insideUrl={coverOtherService} />
      <ContentContainer>
        <CardListLayout>
          {sortedService &&
            sortedService.map((service) => {
              const { name, icon, id } = service
              return (
                <ServiceShortcut
                  key={id}
                  cover={icon}
                  title={name}
                  linkTo={`${appPaths.otherService()}/${id}`}
                  onClickMore={onClickMore.bind(null, service)}
                  coverObjectFit="none"
                />
              )
            })}
        </CardListLayout>
      </ContentContainer>
    </>
  )
}
