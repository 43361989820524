import { Button, Col, Form, Row } from 'antd'
import { styled } from 'goober'
import { useCallback, useState } from 'react'

import { FormModal } from 'components/FormModal'
import { TextArea } from 'components/Input'
import { mediaQuery } from 'helpers/responsive-helper'

const Layout = styled(Row)`
  width: 500px;

  button {
    max-width: 343px;
  }

  @media ${mediaQuery.xsOnly} {
    width: 100%;
  }
`

const ButtonBlue = styled(Button)`
  color: #78c9f5;
  border-color: #78c9f5;
  background: transparent;
`

const InputCancelReason = styled(TextArea)`
  height: 100px !important;
  min-height: 100px !important;
  max-height: 100px !important;
  textarea {
    resize: none;
  }
`

interface IModalCancelOrderFormValues {
  reason: string
}

interface IModalCancelOrderProps {
  visible: boolean
  onConfirmClick: (reason: string) => Promise<void>
  onCancelClick: () => void
}

export const ModalCancelOrder = (props: IModalCancelOrderProps) => {
  const { visible, onConfirmClick, onCancelClick } = props
  const [form] = Form.useForm<IModalCancelOrderFormValues>()

  const [submitting, setSubmitting] = useState(false)

  const onSubmit = useCallback(
    async (values: IModalCancelOrderFormValues) => {
      setSubmitting(true)
      await onConfirmClick(values.reason)
      setSubmitting(false)
    },
    [onConfirmClick],
  )

  return (
    <FormModal title="ยกเลิกจอง" visible={visible} onCancel={onCancelClick} width={800}>
      <Form form={form} onFinish={onSubmit} layout="vertical">
        <Layout gutter={[0, 24]}>
          <Col span={24}>
            <Form.Item
              label="เหตุผลยกเลิกการจอง"
              name="reason"
              rules={[
                {
                  type: 'string',
                  required: true,
                  message: 'โปรดกรอกข้อมูล',
                },
              ]}
              required
            >
              <InputCancelReason />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Row gutter={[16, 16]}>
              <Col span={24}>
                <Row justify="center">
                  <Button
                    type="primary"
                    size="small"
                    htmlType="submit"
                    loading={submitting}
                    disabled={submitting}
                    block
                  >
                    Confirm
                  </Button>
                </Row>
              </Col>
              <Col span={24}>
                <Row justify="center">
                  <ButtonBlue type="ghost" size="small" block onClick={onCancelClick}>
                    Cancel
                  </ButtonBlue>
                </Row>
              </Col>
            </Row>
          </Col>
        </Layout>
      </Form>
    </FormModal>
  )
}
