import { Card, Row } from 'antd'
import { styled } from 'goober'

import { Txt } from 'components/Txt'

const CardStyled = styled(Card)`
  border: unset;
  border-radius: 6px;
  background: #efeff2;
  .ant-card-cover {
    img {
      border-radius: 6px 6px 0 0;
    }
  }
  .ant-card-body {
    padding: 12px 18px;
    text-align: start;
  }
`

export const WikiCard = () => {
  return (
    <>
      <CardStyled
        style={{ width: 370, minHeight: 350 }}
        cover={
          <img
            height={200}
            style={{ objectFit: 'cover' }}
            alt="example"
            src="https://images.unsplash.com/photo-1472457897821-70d3819a0e24?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8c21hbGx8ZW58MHx8MHx8&w=1000&q=80"
          />
        }
      >
        <Row>
          <Txt ag="subtitle20" style={{ fontWeight: 500, fontSize: 24, lineHeight: '29px' }}>
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
          </Txt>
        </Row>
        <Row>
          <Txt ag="subtitle20" color="grayText" style={{ marginTop: 9 }}>
            The quick, brown fox jumps over a lazy dog. DJs flock
          </Txt>
        </Row>
      </CardStyled>
    </>
  )
}
