import { faEdit, faTrashCan } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Space } from 'antd'
import Table, { ColumnType } from 'antd/lib/table'
import { styled } from 'goober'
import { map } from 'lodash'
import { forwardRef, useCallback, useImperativeHandle, useMemo, useState } from 'react'

import { useModalConfirm } from 'components/ModalConfirmProvider'
import { breakpoint } from 'helpers/responsive-helper'
import { ISubmitServiceLink } from 'services/service-link/service-link-service-param'
import theme from 'theme/goober'

import { IModalServiceLinkFormValues, ModalServiceLink } from './ModalServiceLink'

const Icon = styled(FontAwesomeIcon)`
  color: ${theme.color.lightBluePrimary};
  cursor: pointer;
`

export interface ITableServiceLinkContext {
  openAddServiceLink: () => void
}

interface ITableServiceLinkProps {
  value?: ISubmitServiceLink[]
  onChange?: (value?: ISubmitServiceLink[]) => void
}
export const TableServiceLink = forwardRef<ITableServiceLinkContext, ITableServiceLinkProps>((props, contextRef) => {
  const { value: serviceLinks = [], onChange } = props

  const modalConfirm = useModalConfirm()

  const [editServiceLinkIdVisible, setEditServiceLinkIdVisible] = useState<boolean>(false)
  const [editServiceLink, setEditServiceLink] = useState<Partial<IModalServiceLinkFormValues>>()

  const onServiceLinkClick = useCallback((value?: Partial<IModalServiceLinkFormValues>) => {
    setEditServiceLink(value)
    setEditServiceLinkIdVisible(true)
  }, [])

  const onServiceLinkDeleteClick = useCallback(
    (serviceLinkIndex: number) => {
      modalConfirm.show({
        type: 'danger',
        title: 'ยืนยันการลบลิ้งก์',
        content: 'คุณยืนยันที่จะลบลิงก์นี้หรือไม่?',
        onOk: () => {
          const newServiceLinks = serviceLinks.filter((e, index) => index !== serviceLinkIndex)
          onChange?.(newServiceLinks)
        },
      })
    },
    [modalConfirm, onChange, serviceLinks],
  )

  const onConfirmEditServiceLinkClick = useCallback(
    (value: IModalServiceLinkFormValues) => {
      const { recordIndex, isEditMode } = value
      let newServiceLinks: ISubmitServiceLink[] = [...serviceLinks]

      if (isEditMode) {
        newServiceLinks[recordIndex] = value
      } else {
        newServiceLinks.push(value)
      }

      onChange?.(newServiceLinks)
      setEditServiceLink(undefined)
      setEditServiceLinkIdVisible(false)
    },
    [onChange, serviceLinks],
  )

  const onDismissEditServiceLinkClick = useCallback(() => {
    setEditServiceLink(undefined)
    setEditServiceLinkIdVisible(false)
  }, [])

  const serviceLinkColumns = useMemo(
    (): ColumnType<ISubmitServiceLink>[] => [
      {
        title: 'No.',
        width: '60px',
        align: 'center',
        render: (value, record, index) => index + 1,
      },
      {
        title: 'คำอธิบาย',
        dataIndex: 'description',
        ellipsis: true,
      },
      {
        title: 'URL',
        dataIndex: 'link',
      },
      {
        title: 'Modify date',
        dataIndex: 'modifydatedisplay',
      },
      {
        width: '92px',
        fixed: 'right',
        render: (value, record, recordIndex) => {
          return (
            <Space size={20} direction="horizontal">
              <Icon icon={faEdit} onClick={onServiceLinkClick.bind(null, { ...record, recordIndex })} />
              <Icon icon={faTrashCan} onClick={onServiceLinkDeleteClick.bind(null, recordIndex)} />
            </Space>
          )
        },
      },
    ],
    [onServiceLinkClick, onServiceLinkDeleteClick],
  )
  useImperativeHandle(contextRef, () => {
    return {
      openAddServiceLink: onServiceLinkClick.bind(null, undefined),
    }
  })

  const serviceLinksSelected = useMemo(() => map(serviceLinks, (e) => e.link), [serviceLinks])

  return (
    <>
      <Table
        rowKey={(e) => `${e.id || ''}-${e.link}-${e.description}`}
        columns={serviceLinkColumns}
        dataSource={serviceLinks}
        scroll={{ x: breakpoint.smMax }}
        pagination={false}
      />
      {editServiceLinkIdVisible && (
        <ModalServiceLink
          visible={editServiceLinkIdVisible}
          initialValues={editServiceLink}
          serviceLinksSelected={serviceLinksSelected}
          onConfirm={onConfirmEditServiceLinkClick}
          onDismiss={onDismissEditServiceLinkClick}
        />
      )}
    </>
  )
})
