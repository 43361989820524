import { styled } from 'goober'

import { Banner } from './Banner'
import { PrNews } from './PrNews'
import { ShortCutEnCoService } from './ShortCutEnCoService'
import { ShortCutOtherService } from './ShortCutOtherService'

const Layout = styled('div')`
  position: relative;
  max-width: 1600px;
  margin: 0 auto;
`

export const PageHome = () => {
  return (
    <Layout>
      <Banner />
      <ShortCutEnCoService />
      <ShortCutOtherService />
      <PrNews />
    </Layout>
  )
}
