import { get } from 'lodash'
import qs from 'qs'
import { useCallback, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { ContentBody } from '_backend/components/layout/ContentBody'
import { ContentHeader } from '_backend/components/layout/ContentHeader'
import { backendPaths } from 'paths/backend-path-config'
import { ServiceActiveStatusEnum } from 'services/enco-service/enco-service-param'
import { useSubmitEncoService } from 'services/enco-service/enco-service-service'

import { EncoServiceForm, EncoServiceFormValues } from './EncoServiceForm'

export const PageEncoServiceNew = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const serviceType = get(location.state, 'serviceType', null)

  const { onSubmitEncoService } = useSubmitEncoService(0, serviceType, true)

  const initialValues = useMemo(
    (): Partial<EncoServiceFormValues> => ({
      active: ServiceActiveStatusEnum.ACTIVE,
    }),
    [],
  )

  const onCancel = useCallback(() => {
    navigate(
      {
        pathname: backendPaths.serviceList(),
        search: qs.stringify({
          tab: serviceType,
        }),
      },
      { replace: true },
    )
  }, [navigate, serviceType])

  const onSubmit = useCallback(
    async (values: EncoServiceFormValues) => {
      try {
        await onSubmitEncoService(values)
        navigate(
          {
            pathname: backendPaths.serviceList(),
            search: qs.stringify({
              tab: serviceType,
            }),
          },
          { replace: true },
        )
      } catch (error) {
        return Promise.reject(error)
      }
    },
    [navigate, onSubmitEncoService, serviceType],
  )

  return (
    <>
      <ContentHeader title="Service Management" subTitle="เพิ่มบริการ EnCo Service" />
      <ContentBody>
        <EncoServiceForm initialValues={initialValues} onSubmit={onSubmit} onCancel={onCancel} isOzone={false} />
      </ContentBody>
    </>
  )
}
