import { Typography } from 'antd'
import { TextProps as AntdTextProps } from 'antd/lib/typography/Text'
import { styled } from 'goober'
import { CSSProperties, PropsWithChildren } from 'react'

import { AppColor, theme } from 'theme/goober'

const { Text: AntdText } = Typography

const DbHeavent = 'DB Heavent'
type AppFont = typeof DbHeavent

const config = {
  header40: { font: DbHeavent, size: '40px', weight: 700, lineHeight: '48px' },
  header30: { font: DbHeavent, size: '30px', weight: 700, lineHeight: '36px' },
  header28: { font: DbHeavent, size: '28px', weight: 700, lineHeight: '33px' },
  header24: { font: DbHeavent, size: '24px', weight: 700, lineHeight: '30px' },
  header20: { font: DbHeavent, size: '20px', weight: 700, lineHeight: '24px' },
  header18: { font: DbHeavent, size: '18px', weight: 700, lineHeight: '22px' },

  subtitle40: { font: DbHeavent, size: '40px', weight: 500, lineHeight: '48px' },
  subtitle34: { font: DbHeavent, size: '34px', weight: 500, lineHeight: '40px' },
  subtitle28: { font: DbHeavent, size: '28px', weight: 500, lineHeight: '33px' },
  subtitle20: { font: DbHeavent, size: '20px', weight: 500, lineHeight: '22px' },
  subtitle14: { font: DbHeavent, size: '14px', weight: 500, lineHeight: '18px' },
  subtitle10: { font: DbHeavent, size: '10px', weight: 500, lineHeight: '12px' },

  body28: { font: DbHeavent, size: '28px', weight: 400, lineHeight: '33px' },
  body24: { font: DbHeavent, size: '24px', weight: 400, lineHeight: '30px' },
  body20: { font: DbHeavent, size: '20px', weight: 400, lineHeight: '22px' },
  body18: { font: DbHeavent, size: '18px', weight: 400, lineHeight: '22px' },
  body16: { font: DbHeavent, size: '16px', weight: 400, lineHeight: '20px' },
  body10: { font: DbHeavent, size: '10px', weight: 400, lineHeight: '12px' },

  link18: { font: DbHeavent, size: '18px', weight: 400, lineHeight: '12px' },
}

interface StyledTextProps extends AntdTextProps {
  font: AppFont
  weight: number
  size: string
  color?: keyof AppColor
  'line-height': string
}

const StyledText = styled<{}, StyledTextProps>(AntdText)`
  font-family: ${({ font }) => font};
  font-weight: ${({ weight }) => weight};
  font-size: ${({ size }) => size};
  line-height: ${(props) => props['line-height']};
  color: ${({ color }) => {
    if (color) {
      return theme.color[color]
    } else {
      return theme.color.grayPrimaryText
    }
  }};
`

interface TextProps extends Pick<AntdTextProps, 'ellipsis'> {
  ag: keyof typeof config
  color?: keyof AppColor
  className?: string
  style?: CSSProperties
  onClick?: (e?: React.MouseEvent<HTMLDivElement, MouseEvent> | undefined) => void
  title?: string
}

export const Txt = (props: PropsWithChildren<TextProps>) => {
  const { ag, color, children, className, style, onClick, ellipsis, title } = props
  return (
    <StyledText
      title={title}
      font={config[ag].font as AppFont}
      size={config[ag].size}
      weight={config[ag].weight}
      line-height={config[ag].lineHeight}
      children={children}
      color={color}
      className={className}
      style={style}
      onClick={onClick}
      ellipsis={ellipsis}
    />
  )
}
