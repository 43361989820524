import { Button } from 'antd'
import { styled } from 'goober'
import { useEffect, useState } from 'react'

import { Txt } from 'components/Txt'
import { FileHelper } from 'helpers/file-helper'

const Grid = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: start;
  justify-items: flex-start;
  grid-gap: 16px;

  > button {
    width: 100px;
  }
`

export const WikiFileHelper = () => {
  const [url, setUrl] = useState<string>()
  useEffect(() => {
    const loadUrl = async () => {
      const response = await fetch('https://s29.q4cdn.com/816090369/files/doc_downloads/test.pdf')
      const blob = await response.blob()
      setUrl(URL.createObjectURL(blob))
    }
    loadUrl()
  }, [])
  return (
    <>
      <Grid>
        <Txt ag="body24">FileHelper.download(url, 'filename.pdf')</Txt>
        <Button
          onClick={() => {
            url && FileHelper.download(url, 'filename.pdf')
          }}
        >
          Download
        </Button>

        <Txt ag="body24">FileHelper.open(url)</Txt>
        <Button
          onClick={() => {
            url && FileHelper.open(url)
          }}
        >
          Open
        </Button>
      </Grid>
    </>
  )
}
