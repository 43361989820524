import { map, orderBy } from 'lodash'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'

import {
  useGetServicePropQRY,
  useGetServicePropRelateQRY,
  useGetServicePropSlotQRY,
} from 'services/property-management/property-management-service'

import { ISlot, PropertySlotForm } from './PropertySlotForm'

const renameKeys = (keysMap: any, obj: any) =>
  Object.keys(obj).reduce(
    (acc, key) => ({
      ...acc,
      ...{ [keysMap[key] || key]: obj[key] },
    }),
    {},
  )

export const PagePropertyEdit = () => {
  const query = useParams()
  const serviceId = Number(query.serviceId)
  const propertyId = Number(query.propertyId)

  const { data: getServicePropSlot, isFetching: isFetchingGetServicePropSlot } = useGetServicePropSlotQRY({
    serviceId: serviceId,
    servicePropId: propertyId,
    day: 'all',
  })

  const { data: getServiceProp, isFetching: isFetchingGetServiceProp } = useGetServicePropQRY({
    id: propertyId,
  })

  const { data: servicePropRelates, isFetching: isFetchingServicePropRelates } = useGetServicePropRelateQRY({
    tbServicePropId: propertyId,
  })

  const renameKey = useMemo(() => {
    const mapKeyItem = orderBy(getServicePropSlot, 'mdSlotIdStart')

    const itemList = mapKeyItem.reduce((acc: Record<string, ISlot[]>, curr) => {
      if (!acc[curr.day]) {
        acc[curr.day] = []
      }
      acc[curr.day].push(curr)
      return acc
    }, {})

    return renameKeys(
      {
        sun: 'วันอาทิตย์',
        mon: 'วันจันทร์',
        tue: 'วันอังคาร',
        wed: 'วันพุธ',
        thu: 'วันพฤหัส',
        fri: 'วันศุกร์',
        sat: 'วันเสาร์',
      },
      itemList,
    )
  }, [getServicePropSlot])

  const initialValuesServiceProp = useMemo(() => {
    return {
      active: getServiceProp?.active,
      name: getServiceProp?.name,
      servicePropRelates: map(servicePropRelates ?? [], (e) => e.relatePropId),
    }
  }, [getServiceProp?.active, getServiceProp?.name, servicePropRelates])

  const initialValuesSlot = useMemo(() => {
    return {
      sun: !!getServiceProp?.sun,
      mon: !!getServiceProp?.mon,
      tue: !!getServiceProp?.tue,
      wed: !!getServiceProp?.wed,
      thu: !!getServiceProp?.thu,
      fri: !!getServiceProp?.fri,
      sat: !!getServiceProp?.sat,
      ...renameKey,
    }
  }, [
    getServiceProp?.fri,
    getServiceProp?.mon,
    getServiceProp?.sat,
    getServiceProp?.sun,
    getServiceProp?.thu,
    getServiceProp?.tue,
    getServiceProp?.wed,
    renameKey,
  ])

  return !isFetchingGetServiceProp && !isFetchingGetServicePropSlot && !isFetchingServicePropRelates ? (
    <PropertySlotForm
      isEdit
      initialValuesSlot={initialValuesSlot}
      initialValuesServiceProp={initialValuesServiceProp}
    />
  ) : (
    <></>
  )
}
