import { Row, Col } from 'antd'
import { styled } from 'goober'
import { pullAt } from 'lodash'
import { useCallback, useMemo } from 'react'

import { Txt } from 'components/Txt'
import { useUploadAttachmentMTT } from 'services/attachment/attachment-service'
import { ISubmitSuggestionFile } from 'services/suggestion/suggestion-service-param'

import { UploadButton } from '../../_backend/components/upload/UploadButton'

import { UploadSuggestionFileItem } from './UploadSuggestionFileItem'

const Layout = styled('div')`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 14px;

  border: 1px dashed #cdd4d9;
  border-radius: 6px;
  padding: 17px;
  box-sizing: border-box;

  .files-empty {
    grid-column: 1 / -1;
  }
`
const LayoutCount = styled('div')<{ count: string }>`
  &::after {
    margin-bottom: -22px;
    float: right;
    color: rgba(0, 0, 0, 0.45);
    white-space: nowrap;
    content: attr(count);
    pointer-events: none;
  }
`

const UploadButtonLayout = styled('div')`
  grid-column: 1 / -1;

  display: flex;
  justify-content: center;
  margin-top: 24px;

  button {
    max-width: 100px;
    max-height: 40px;
  }
`

interface IUploadSuggestionFilesProps {
  value?: ISubmitSuggestionFile[]
  onChange?: (suggestionFiles: ISubmitSuggestionFile[]) => void
  onUploadProgress?: (uploadProgress: number) => void
  disabled?: boolean
  maxFiles?: number
}

export const UploadSuggestionFiles = (props: IUploadSuggestionFilesProps) => {
  const { value: suggestionFiles = [], onChange, onUploadProgress, disabled, maxFiles = 10 } = props
  const { mutate: uploadFile } = useUploadAttachmentMTT()

  const onSelect = useCallback(
    (file: File) => {
      uploadFile(
        { file, path: 'order-suggestion', onUploadProgress },
        {
          onSuccess: (response) => {
            const { path } = response
            onChange?.([
              ...suggestionFiles,
              {
                id: 0,
                FilePath: path,
              },
            ])
          },
        },
      )
    },
    [onChange, onUploadProgress, suggestionFiles, uploadFile],
  )

  const onRemoveClick = useCallback(
    (index: number) => {
      const newValue = [...suggestionFiles]
      pullAt(newValue, index)
      onChange?.(newValue)
    },
    [onChange, suggestionFiles],
  )

  const disableUploadButton = useMemo(() => {
    if (disabled) return true
    if (suggestionFiles?.length >= maxFiles) return true

    return false
  }, [disabled, maxFiles, suggestionFiles?.length])

  const uploadButton = useMemo(() => {
    if (disabled) return
    return (
      <UploadButtonLayout>
        <UploadButton onSelect={onSelect} text="+ Add" disabled={disableUploadButton} accept="image/png, image/jpeg" />
      </UploadButtonLayout>
    )
  }, [disableUploadButton, disabled, onSelect])

  const emptySuggestionsForView = useMemo(() => {
    if (!disabled) return
    if (!!suggestionFiles?.length) return
    return (
      <Row className="files-empty" justify="center">
        <Col>
          <Txt ag="body20" color="grayFlatButton">
            ไม่มีไฟล์แนบ
          </Txt>
        </Col>
      </Row>
    )
  }, [disabled, suggestionFiles?.length])

  const renderSuggestionFileUploadItems = useCallback(
    () =>
      suggestionFiles?.map((suggestionFile, index) => (
        <UploadSuggestionFileItem
          key={suggestionFile.id}
          data={suggestionFile}
          disabled={disabled}
          onRemoveClick={onRemoveClick.bind(null, index)}
        />
      )),
    [disabled, onRemoveClick, suggestionFiles],
  )

  return (
    <LayoutCount count={`${suggestionFiles?.length} / ${maxFiles}`}>
      <Layout>
        {renderSuggestionFileUploadItems()}
        {emptySuggestionsForView}
        {uploadButton}
      </Layout>
    </LayoutCount>
  )
}
