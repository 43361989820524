import { Button, Upload, notification } from 'antd'
import { RcFile } from 'antd/lib/upload'
import { useCallback } from 'react'

type UploadButtonPropsType = {
  onSelect: (file: File) => void
  text?: string
  accept?: string
  disabled?: boolean
}

export const UploadButton = (props: UploadButtonPropsType) => {
  const { onSelect, accept, text = 'อัปโหลดภาพ', disabled } = props
  const onBeforeUpload = useCallback(
    (file: RcFile) => {
      if (accept && !accept.includes(file.type)) {
        notification.error({ message: 'Incorrect file type' })
        return
      }
      const isLt10M = file.size / 1024 / 1024 < 10
      if (!isLt10M) {
        notification.error({ message: 'File must smaller than 10MB' })
        return
      }

      onSelect(file)
      return false
    },
    [accept, onSelect],
  )
  return (
    <Upload beforeUpload={onBeforeUpload} showUploadList={false} maxCount={1} accept={accept} disabled={disabled}>
      <Button disabled={disabled}>{text}</Button>
    </Upload>
  )
}
