import { Button, Card, Col, Divider, Form, Row, Select, Space, Table } from 'antd'
import { FormInstance } from 'antd/es/form/Form'
import { BaseOptionType } from 'antd/lib/select'
import { ColumnType } from 'antd/lib/table'
import { styled } from 'goober'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { FaEdit, FaTrashAlt } from 'react-icons/fa'

import { DiscountTypeEnum, DiscountUnitEnum } from '_backend/modules/sale/sale-constants'
import { Input } from 'components/Input'
import { useModalConfirm } from 'components/ModalConfirmProvider'
import NumberInput from 'components/NumberInput'
import { Txt } from 'components/Txt'
import { getColumnNumberByPage } from 'helpers/column-helper'
import { numberFormat } from 'helpers/number-helper'
import { mediaQuery } from 'helpers/responsive-helper'
import theme from 'theme/goober'

import { ModalOrderItemForm } from './ModalOrderItemForm'
import { calculateDiscountAmount, calculateExcludingVatPrice, IRecordOrderItem } from './sale-helper'

interface ISectionOrderFieldsProps {
  form: FormInstance
  orderItems: IRecordOrderItem[]
  readonly?: boolean
  hideAddBtn?: boolean
  onChangItems?: (newOrderItems: IRecordOrderItem[]) => void
}

export interface ISummaryPriceData {
  excludingVatPrice: number
  vatAmount: number
  beforeDiscountPrice: number
  discountAmount: number
  afterDiscountPrice: number
  netPrice: number
}

const discountUnitOptions: BaseOptionType[] = [
  {
    value: DiscountUnitEnum.PERCENT,
    label: '%',
  },
  {
    value: DiscountUnitEnum.BATH,
    label: 'บาท',
  },
]

const WrapperVatInput = styled('div')`
  .ant-input-number {
    width: 100px;
    margin-left: 8px;
    margin-right: 8px;
  }
`
const DiscountItemSpace = styled(Space)`
  @media ${mediaQuery.xs} {
    flex-direction: column;
  }
`

const updateOrderItem = (orderItems: IRecordOrderItem[], orderItem: IRecordOrderItem, orderIndex?: number) => {
  return orderItems.map((o, index) => (orderIndex === index ? orderItem : o))
}
const calculateVatRate = (vatPercentValue: number) => (vatPercentValue > 0 ? vatPercentValue / 100 : 0)
const checkIsAnyItem = (orderItems: IRecordOrderItem[]) => (orderItems.length > 0 ? 'true' : undefined)

export const SectionOrderFields = (props: ISectionOrderFieldsProps) => {
  const { form, orderItems, onChangItems, readonly = false, hideAddBtn = false } = props

  const [modalItemVisible, setModalItemVisible] = useState(false)
  const modalConfirm = useModalConfirm()
  const [editOrderItem, setEditOrderItem] = useState<IRecordOrderItem>()
  const [editOrderIndex, setEditOrderIndex] = useState<number>()
  const [summaryPriceData, setSummaryPriceData] = useState<ISummaryPriceData>({
    excludingVatPrice: 0,
    vatAmount: 0,
    beforeDiscountPrice: 0,
    discountAmount: 0,
    afterDiscountPrice: 0,
    netPrice: 0,
  })

  const vatTypeValue = Form.useWatch('vatType', form)
  const vatPercentValue = Form.useWatch('vatPercent', form)
  const discountTypeValue = Form.useWatch('discountType', form)
  const discountUnitValue = Form.useWatch('discountUnit', form) || DiscountUnitEnum.PERCENT
  const discountValue = Form.useWatch('discount', form) || 0

  const handleCloseItemModal = useCallback(() => {
    setModalItemVisible(false)
  }, [])
  const handleClickAddItem = useCallback(() => {
    setEditOrderItem(undefined)
    setEditOrderIndex(undefined)
    setModalItemVisible(true)
  }, [])
  const handleSubmitAddItem = useCallback(
    (orderItem: IRecordOrderItem) => {
      onChangItems?.([...orderItems, orderItem])
      setModalItemVisible(false)
    },
    [onChangItems, orderItems],
  )
  const handleClickEditItem = useCallback((record: IRecordOrderItem, recordIndex: number) => {
    setEditOrderItem(record)
    setEditOrderIndex(recordIndex)
    setModalItemVisible(true)
  }, [])
  const handleSubmitEditItem = useCallback(
    (orderItem: IRecordOrderItem, orderIndex?: number) => {
      const editedOrderItems = updateOrderItem(orderItems, orderItem, orderIndex)
      onChangItems?.(editedOrderItems)
      setModalItemVisible(false)
    },
    [onChangItems, orderItems],
  )
  const handleClickRemoveItem = useCallback(
    (record: IRecordOrderItem) => {
      modalConfirm.show({
        type: 'danger',
        title: 'ยืนยันการลบรายการ',
        content: `คุณยืนยันที่จะลบรายการ ${record.itemName} นี้หรือไม่?`,
        modalProps: {
          width: 700,
        },
        onOk: () => {
          onChangItems?.(orderItems.filter((o) => o.itemName !== record.itemName))
        },
      })
    },
    [modalConfirm, orderItems, onChangItems],
  )

  useEffect(() => {
    if (discountTypeValue === DiscountTypeEnum.NO_DISCOUNT) {
      form.setFieldsValue({ discount: 0, discountUnit: DiscountUnitEnum.PERCENT })
    }
  }, [discountTypeValue, form])

  useEffect(() => {
    const excludingVatPrice = calculateExcludingVatPrice({
      orderItems,
      vatType: vatTypeValue,
      vatPercent: vatPercentValue,
    })
    const vatRate = calculateVatRate(vatPercentValue)

    let vatAmount = 0
    let beforeDiscountPrice = 0
    let discountAmount = 0
    let afterDiscountPrice = 0
    let netPrice = 0

    switch (discountTypeValue) {
      case DiscountTypeEnum.NO_DISCOUNT:
        vatAmount = excludingVatPrice * vatRate
        netPrice = excludingVatPrice + vatAmount
        break
      case DiscountTypeEnum.BEFORE_VAT:
        discountAmount = calculateDiscountAmount({
          basePrice: excludingVatPrice,
          discountUnit: discountUnitValue,
          discount: discountValue,
        })
        afterDiscountPrice = excludingVatPrice - discountAmount
        vatAmount = afterDiscountPrice * vatRate
        netPrice = excludingVatPrice - discountAmount + vatAmount
        break
      case DiscountTypeEnum.AFTER_VAT:
        vatAmount = excludingVatPrice * vatRate
        beforeDiscountPrice = excludingVatPrice + vatAmount
        discountAmount = calculateDiscountAmount({
          basePrice: beforeDiscountPrice,
          discountUnit: discountUnitValue,
          discount: discountValue,
        })
        netPrice = excludingVatPrice + vatAmount - discountAmount
        break
    }
    setSummaryPriceData({
      excludingVatPrice,
      vatAmount,
      beforeDiscountPrice,
      discountAmount,
      afterDiscountPrice,
      netPrice,
    })
    form.setFieldsValue({
      vatAmount,
      discountAmount,
      netPrice,
    })
  }, [vatTypeValue, vatPercentValue, discountTypeValue, discountUnitValue, discountValue, orderItems, form])

  useEffect(() => {
    form.setFieldsValue({ isAnyOrderItem: checkIsAnyItem(orderItems) })
  }, [form, orderItems])

  const columns = useMemo(() => {
    const readOnlyColumns: ColumnType<IRecordOrderItem>[] = [
      {
        title: 'ลำดับ',
        width: '50px',
        align: 'center',
        render: (_: any, record: any, index: number) => <Txt ag="body20">{getColumnNumberByPage(1, index)}</Txt>,
      },
      {
        title: 'รายการ',
        width: '200px',
        dataIndex: 'itemName',
      },
      {
        title: 'ราคาต่อชิ้น',
        width: '100px',
        align: 'right',
        dataIndex: 'unitPrice',
        render: (value: number) => {
          return <span>{numberFormat(value)}</span>
        },
      },
      {
        title: 'จำนวน',
        width: '100px',
        align: 'center',
        dataIndex: 'quantity',
        render: (value: number) => {
          return <span>{numberFormat(value)}</span>
        },
      },
      {
        title: 'ราคารวม',
        width: '100px',
        align: 'right',
        render: (_: any, record: IRecordOrderItem) => {
          return <span>{numberFormat(record.quantity * record.unitPrice)}</span>
        },
      },
    ]
    const actionColumn: ColumnType<IRecordOrderItem> = {
      title: '',
      width: '20px',
      render: (_: any, record: IRecordOrderItem, recordIndex: number) => {
        return (
          <Space size={20} direction="horizontal">
            <FaEdit
              className="cursor-pointer "
              color={theme.color.lightBluePrimary}
              onClick={() => handleClickEditItem(record, recordIndex)}
            />
            <FaTrashAlt
              className="cursor-pointer "
              color={theme.color.lightBluePrimary}
              onClick={() => handleClickRemoveItem(record)}
            />
          </Space>
        )
      },
    }
    return readonly ? readOnlyColumns : [...readOnlyColumns, actionColumn]
  }, [handleClickEditItem, handleClickRemoveItem, readonly])
  return (
    <Card style={{ marginBottom: 8 }}>
      <Row gutter={24} style={{ marginBottom: 16 }}>
        <Col span={16}>
          <Txt ag="header30">รายการคำสั่งซื้อ</Txt>
        </Col>
        {!readonly && !hideAddBtn && (
          <Col span={8} className="text-right">
            <Button onClick={handleClickAddItem}>เพิ่มรายการ</Button>
          </Col>
        )}
      </Row>
      <Row gutter={24}>
        <Col span={24}>
          <Table
            scroll={{ x: '100%' }}
            pagination={false}
            columns={columns}
            dataSource={orderItems}
            rowKey="itemName"
          />
          <Form.Item
            hidden
            name="isAnyOrderItem"
            rules={[
              {
                required: true,
                message: 'ต้องไม่เว้นว่างไว้',
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row style={{ marginTop: 40 }}>
        <Col span={24} md={{ span: 12, offset: 12 }}>
          <Row style={{ marginBottom: 24 }}>
            <Col span={16} className="text-right">
              <Txt ag="body20">ราคาไม่รวมภาษีมูลค่าเพิ่ม (Excluding VAT)</Txt>
            </Col>
            <Col span={8} className="text-right">
              <Txt ag="body20">{`${numberFormat(summaryPriceData.excludingVatPrice)} บาท`}</Txt>
            </Col>
          </Row>
          {discountTypeValue === DiscountTypeEnum.BEFORE_VAT && (
            <>
              <Row>
                <Col span={16} className="text-right">
                  <DiscountItemSpace align="baseline">
                    <Txt ag="body20">ส่วนลด (Discount)</Txt>
                    <Form.Item noStyle name="discount">
                      <NumberInput style={{ width: 100 }} align="center" disabled={readonly} />
                    </Form.Item>
                    <Form.Item name="discountUnit">
                      <Select
                        options={discountUnitOptions}
                        style={{ width: 100, textAlign: 'center' }}
                        disabled={readonly}
                      />
                    </Form.Item>
                  </DiscountItemSpace>
                </Col>
                <Col span={8} className="text-right" style={{ marginTop: 8 }}>
                  <Txt ag="body20">{`${numberFormat(summaryPriceData.discountAmount)} บาท`}</Txt>
                  <Form.Item name="discountAmount" hidden>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row style={{ marginBottom: 24 }}>
                <Col span={16} className="text-right">
                  ราคาหลังหักส่วนลด (After Discount)
                </Col>
                <Col span={8} className="text-right">
                  <Txt ag="body20">{`${numberFormat(summaryPriceData.afterDiscountPrice)} บาท`}</Txt>
                </Col>
              </Row>
            </>
          )}
          <Row style={{ marginBottom: 24 }}>
            <Col span={16} className="text-right">
              <WrapperVatInput>
                <Txt ag="body20" style={{ marginTop: 6 }}>
                  ภาษี (VAT)
                </Txt>
                <Form.Item name="vatPercent" noStyle>
                  <NumberInput align="center" disabled={readonly} />
                </Form.Item>
                <span style={{ marginTop: 6 }}>%</span>
              </WrapperVatInput>
            </Col>
            <Col span={8} className="text-right" style={{ marginTop: 6 }}>
              <Txt ag="body20">{`${numberFormat(summaryPriceData.vatAmount)} บาท`}</Txt>
              <Form.Item name="vatAmount" hidden>
                <Input />
              </Form.Item>
            </Col>
          </Row>
          {discountTypeValue === DiscountTypeEnum.AFTER_VAT && (
            <>
              <Row style={{ marginBottom: 24 }}>
                <Col span={16} className="text-right">
                  ราคาก่อนหักส่วนลด (Before Discount)
                </Col>
                <Col span={8} className="text-right">
                  <Txt ag="body20">{`${numberFormat(summaryPriceData.beforeDiscountPrice)} บาท`}</Txt>
                </Col>
              </Row>
              <Row>
                <Col span={16} className="text-right">
                  <DiscountItemSpace align="baseline">
                    <Txt ag="body20">ส่วนลด (Discount)</Txt>
                    <Form.Item noStyle name="discount">
                      <NumberInput style={{ width: 100 }} align="center" disabled={readonly} />
                    </Form.Item>
                    <Form.Item name="discountUnit">
                      <Select
                        options={discountUnitOptions}
                        style={{ width: 100, textAlign: 'center' }}
                        disabled={readonly}
                      />
                    </Form.Item>
                  </DiscountItemSpace>
                </Col>
                <Col span={8} className="text-right" style={{ marginTop: 8 }}>
                  <Txt ag="body20">{`${numberFormat(summaryPriceData.discountAmount)} บาท`}</Txt>
                  <Form.Item name="discountAmount" hidden>
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
          <Divider />
          <Row>
            <Col span={16} className="text-right">
              <Txt ag="header30">รวมสุทธิ (Total)</Txt>
            </Col>
            <Col span={8} className="text-right">
              <Txt ag="header30">{`${numberFormat(summaryPriceData.netPrice)} บาท`}</Txt>
              <Form.Item name="netPrice" hidden>
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
      <ModalOrderItemForm
        visible={modalItemVisible}
        orderItems={orderItems}
        editOrderItem={editOrderItem}
        editOrderIndex={editOrderIndex}
        onDismiss={handleCloseItemModal}
        onSubmit={editOrderItem ? handleSubmitEditItem : handleSubmitAddItem}
      />
    </Card>
  )
}
