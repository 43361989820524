import { ContentBody } from '_backend/components/layout/ContentBody'

import { FormPermissionSetting } from './FormPermissionSetting'

export const PagePermissionSettingNew = () => {
  return (
    <ContentBody max-width="920px" style={{ marginTop: 32 }}>
      <FormPermissionSetting mode="new" />
    </ContentBody>
  )
}
