import { sanitizeUrl } from '@braintree/sanitize-url'
import qs from 'qs'

type ParamType = Record<string, any> | undefined
type Option<T extends ParamType, V extends ParamType> = {
  routeParam?: T
  queryParam?: V
}

export const generate =
  //T: route param, V: query param


    <T extends ParamType, V extends ParamType>(url: string) =>
    (option?: Option<T, V>) => {
      const { routeParam, queryParam } = option || {}
      let newQueryParam = ''
      if (queryParam) {
        newQueryParam = `?${qs.stringify(queryParam)}`
      }

      let newUrl = sanitizeUrl(url)
      if (routeParam) {
        const urls = url.split('/')
        newUrl = urls
          .map((u) => {
            if (/:.+/.test(u)) {
              return routeParam[u.slice(1)]
            }
            return u
          })
          .join('/')
      }

      return `${newUrl}${newQueryParam}`
    }
