import { styled } from 'goober'
import { PropsWithChildren } from 'react'

import { mediaQuery } from 'helpers/responsive-helper'

import formModalBg from '../assets/images/form-modal-bg.png'

import { AppModal, IAppModalProps } from './AppModal'
import { Txt } from './Txt'

interface IFormModalProps extends IAppModalProps {
  visible: boolean
  onCancel: (e: React.MouseEvent<HTMLElement>) => void
  title: string
}

const Body = styled('div')`
  background: url(${formModalBg});
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 65px 40px 80px;
  @media ${mediaQuery.xs} {
    padding: 32px 20px 40px;
  }
`

export const FormModal = (props: PropsWithChildren<IFormModalProps>) => {
  const { title, children, ...restProps } = props
  return (
    <AppModal maskClosable={false} closable centered {...restProps}>
      <Body className="form-modal-body">
        <Txt ag="subtitle34" color="lightBluePrimary" style={{ marginBottom: 30 }}>
          {title}
        </Txt>
        {children}
      </Body>
    </AppModal>
  )
}
