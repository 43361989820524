import { useCallback, useState } from 'react'

export const useVisible = (defaultVisible = false) => {
  const [visible, setVisible] = useState(defaultVisible)
  const open = useCallback(() => {
    setVisible(true)
  }, [])
  const close = useCallback(() => {
    setVisible(false)
  }, [])

  return { visible, open, close }
}
