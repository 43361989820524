import { Layout } from 'antd'
import { Content, Header } from 'antd/lib/layout/layout'
import { PropsWithChildren, useLayoutEffect } from 'react'

import { useResponsive } from 'helpers/responsive-helper'

import { EnCoFooter } from './EnCoFooter'
import bodyStyle from './FrontendLayout.module.less'
import { HeadMenu } from './HeaderMenu'
import { MobileHeaderMenu } from './MobileHederMenu'

export const FrontendLayout = (props: PropsWithChildren) => {
  const { isXs } = useResponsive()

  useLayoutEffect(() => {
    document.body.className = bodyStyle.body
  }, [])

  return (
    <>
      {isXs && <MobileHeaderMenu />}
      <Layout>
        {!isXs && (
          <Header>
            <HeadMenu />
          </Header>
        )}
        <Content>{props.children}</Content>
        <EnCoFooter />
      </Layout>
    </>
  )
}
