import { Button } from 'antd'
import { ButtonProps } from 'antd/es/button'
import { ButtonType } from 'antd/lib/button'

import { Txt } from 'components/Txt'

interface IAppButtonProps extends ButtonProps {}

const mapColor = (type: ButtonType) => {
  if (type === 'primary') {
    return 'white'
  } else {
    return 'lightBluePrimary'
  }
}

export const AppButton = (props: IAppButtonProps) => {
  const { children, type = 'primary', disabled = false, ...restProps } = props

  return (
    <Button className="w-300" type={type} disabled={disabled} {...restProps}>
      <Txt ag="body28" color={disabled ? 'darkGray' : mapColor(type)}>
        {children}
      </Txt>
    </Button>
  )
}
